/* eslint-disable */
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";

function Tests(props) {
  const navigate = useNavigate();

  const testColumns = [
    { field: "id", headerName: "Test No", headerAlign: "center", align: "center", width: 100 },
    {
      field: "date",
      headerName: "Date Time",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.date).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "score",
      headerName: "Score",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "Status",
      headerName: "Annotation Status",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return data.row.agentStatus;
      },
    },
    {
      field: "testMode",
      headerName: "Test Mode",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return data.row.isKiosk ? "Kiosk" : "App";
      },
    },
    {
      field: "isPayment",
      headerName: "Payment",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return data.row.isPayment ? "paid" : "unpaid";
      },
    },
    {
      field: "imageCount",
      headerName: "Test Type",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return data.row.imageCount == 3 ? "Full" : "Partial";
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        const onButtonClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(params.row);
        };
        return (
          <>
            <MDButton variant="gradient" onClick={onButtonClick} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];

  const handleDetailsClick = (datavalue) => {
    const { testDataObjectVersion, tid } = datavalue;
    const result = props.data.tests.filter((patientList) => patientList.tid === datavalue.tid);
    if (testDataObjectVersion === 2) {
      navigate("/report/" + tid, {
        state: {
          patientId: props.patientId,
          testDetails: result[0],
        },
      });
    } else {
      navigate("/testdetails/" + result[0].tid, {
        state: {
          testDetails: result,
          patientDetails: props.data,
          patientId: props.patientId,
          isAnnotation: props.isAnnotation,
        },
      });
    }
  };

  return (
    <div style={{ height: "370px", width: "100%" }}>
      {props && props.testData ? (
        <DataGrid
          rows={props.testData ? props.testData : []}
          columns={testColumns}
          disableSelectionOnClick
          pageSize={5}
        />
      ) : (
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </MDBox>
      )}
    </div>
  );
}
export default Tests;
