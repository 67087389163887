/*eslint-disable*/

exports.oralCareTypes = [
  {
    title: "General Oral Type",
    data: {
      title: "General Oral Type",
      description: " ",
    },
  },
  {
    title: "Cavity Prone mouth",
    data: {
      title: "Cavity Prone mouth",
      description:
        "Cavity prone mouth: If more than 4-5 teeth are affected and 2 of them are deep caries.Only Lower anteriors(3-3) are crowded and 3-4 teeth have pit and fissure with more than 1 deep caries . Grossly decayed or root stumps are present",
    },
  },
  {
    title: "Malaligned Mouth",
    data: {
      title: "Malaligned Mouth",
      description:
        "Mal-Aligned :Upper and lower both are mal-Aligned( Crowding, Rotated crossbite or (deepbite more than 2-3mm) .No findings except mal-Aligned teeth . 3-4 teeth have pit and fissure caries and 1 or 0 deep caries ) . No grossly decayed or root stumps present .",
    },
  },
  {
    title: "Malaligned with maintenance",
    data: {
      title: "Malaligned with maintenance",
      description:
        "Mal-Aligned :Upper and lower both are mal-Aligned( Crowding, Rotated crossbite or (deepbite more than 2-3mm) .No findings except mal-Aligned teeth . 3-4 teeth have pit and fissure caries and 1 or 0 deep caries ) . No grossly decayed or root stumps present .",
    },
  },
  {
    title: "Prosthetic Mouth",
    data: {
      title: "Prosthetic Mouth",
      description:
        "Prosthetic mouth: 3 or more crowns are present. No findings except crowns/ implants or dentures. Less than 2-3 pit and fissure caries. 1 or zero deep caries.",
    },
  },
  {
    title: "Dry Mouth",
    data: {
      title: "Dry Mouth",
      description: "Dry Mouth : generalised carious teeth present. Dryness of tongue and lips .",
    },
  },
  {
    title: "Sensitive Mouth",
    data: {
      title: "Sensitive Mouth",
      description:
        "Sensitive mouth: Genralised attrition or attrition with more than 6 teeth . Abrasion,abfraction ,occlusal facet or erosion seen for more than 3-4 teeth . Bone-loss or triangular pattern teeth gap , 1/3rd root/ Cementum visible .Mobility- grade 2-3 ",
    },
  },
  {
    title: "Bleeding Mouth",
    data: {
      title: "Bleeding Mouth",
      description:
        "Bleeding mouth- red/ Inflamed gums Calculus and plaque-++/+++ Mobility -Grade 1-2",
    },
  },
];
