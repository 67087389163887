// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  updateFollowUpSuccess,
  updateFollowUpFailure,
  addFollowUpSuccess,
  addFollowUpFailure,
  getFollowUpByIdSuccess,
  getFollowUpByIdFailure,
} from "store/reducers/followUpSlice"; // Import your action creator
import followUpService from "services/followup"; // Import your service function

function* updateFollowUp(action) {
  try {
    const response = yield call(followUpService.updateFollowUp, action.payload);
    yield put(updateFollowUpSuccess(response.data));
  } catch (error) {
    yield put(updateFollowUpFailure(error));
  }
}
function* addFollowUp(action) {
  try {
    const response = yield call(followUpService.addFollowUp, action.payload);
    yield put(addFollowUpSuccess(response.data));
  } catch (error) {
    yield put(addFollowUpFailure(error));
  }
}
function* getFollowUpById(action) {
  try {
    const response = yield call(followUpService.getFollowUpById, action.payload);
    yield put(getFollowUpByIdSuccess(response.data));
  } catch (error) {
    yield put(getFollowUpByIdFailure(error));
  }
}

// Watcher Saga
function* followUpSaga() {
  yield takeLatest("followUps/updateFollowUp", updateFollowUp);
  yield takeLatest("followUps/addFollowUp", addFollowUp);
  yield takeLatest("followUps/getFollowUpById", getFollowUpById);
}

// Export all sagas
export default followUpSaga;
