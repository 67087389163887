/* eslint-disable */

import axios from "axios";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getDoctorsList = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/doctor/getDoctorsList",
    {},
    {
      headers,
    }
  );
};

const searchDoctors = (searchData) => {
  return cachedAxiosGet(constant.API_URL + "/agent/doctor/searchDoctors/" + searchData, {
    headers,
  });
};

const AddNewDoctor = (formdata) => {
  return axios.post(constant.API_URL + "/agent/doctor/addDoctor", formdata, {
    headers,
  });
};
const getDoctorsDetails = (did) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/doctor/getDoctorDetails",
    { did },
    {
      headers,
    }
  );
};

const updateDoctorDetails = (formdata) => {
  return axios.post(constant.API_URL + "/agent/doctor/updateDoctor", formdata, {
    headers,
  });
};

const getDoctorAppointments = (did) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/appointment/getDoctorAppointments",
    {
      did: did,
    },
    {
      headers,
    }
  );
};

const getRateCardList = (doctorId) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/doctor/getRateCard",
    { doctorId },
    {
      headers,
    }
  );
};
const getDoctorPatientAppointment = (formData) => {
  if (formData?.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData?.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/appointment/getDoctorPatientAppointments",
    { doctorId: formData.doctorId, pid: formData.patientId },
    {
      headers,
    }
  );
};
const getFollowUps = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/followUp/getfollowUps",
    { followUptakenBy: formData.newDoctorID, followUpDate: formData.followUpDate },
    {
      headers,
    }
  );
};

const getFollowUpsWeekly = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/followUp/getWeeklyFollowUps",
    { followUptakenBy: formData.newDoctorID },
    {
      headers,
    }
  );
};

const assignKiosk = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  const { clinicId, kioskData } = formData;
  return cachedAxiosPost(
    constant.API_URL + "/agent/doctor/clinic/assignKiosk",
    {
      clinicId,
      kioskData,
    },
    {
      headers,
    }
  );
};

const removeKiosk = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  const { clinicId, kioskId } = formData;
  return cachedAxiosPost(
    constant.API_URL + "/agent/doctor/clinic/removeKiosk",
    {
      clinicId,
      kioskId,
    },
    {
      headers,
    }
  );
};

const doctorService = {
  getDoctorsList,
  getDoctorPatientAppointment,
  searchDoctors,
  AddNewDoctor,
  getDoctorsDetails,
  updateDoctorDetails,
  getDoctorAppointments,
  getRateCardList,
  getFollowUps,
  getFollowUpsWeekly,
  assignKiosk,
  removeKiosk,
};

export default doctorService;
