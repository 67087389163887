// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getCouponSuccess,
  deleteCouponSuccess,
  addCouponSuccess,
  updateCouponSuccess,
  addCouponFailure,
  deleteCouponFailure,
  updateCouponFailure,
  getCouponFailure,
} from "store/reducers/couponSlice"; // Import your action creator
import couponService from "services/coupon"; // Import your service function

function* getAllCoupons() {
  try {
    const response = yield call(couponService.getAllCoupons);
    yield put(getCouponSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getCouponFailure(error));
  }
}

function* deleteCoupon(action) {
  try {
    const response = yield call(couponService.deleteCoupon, action.payload);
    yield put(deleteCouponSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(deleteCouponFailure(error));
  }
}

function* updateCoupon(action) {
  try {
    const response = yield call(couponService.updateCoupon, action.payload);
    yield put(updateCouponSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateCouponFailure(error));
  }
}

function* addCoupon(action) {
  try {
    const response = yield call(couponService.addCoupon, action.payload);
    yield put(addCouponSuccess(response.data));
  } catch (error) {
    yield put(addCouponFailure(error));
  }
}

// Watcher Saga
function* couponSaga() {
  yield takeLatest("coupons/getAllCoupons", getAllCoupons);
  yield takeLatest("coupons/deleteCoupon", deleteCoupon);
  yield takeLatest("coupons/updateCoupon", updateCoupon);
  yield takeLatest("coupons/addCoupon", addCoupon);
}

// Export all sagas
export default couponSaga;
