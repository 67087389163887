import axios from "axios";
import constant from "utils/constants";

// Login API call
export const agentLogin = async (email, password) => {
  try {
    const response = await axios.post(`${constant.API_URL}/auth/agent/loginUser`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Invalid credentials");
  }
};
