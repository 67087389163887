// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getDoctorsListSuccess,
  getDoctorsListFailure,
  getDoctorPatientAppointmentSuccess,
  getDoctorPatientAppointmentFailure,
  searchDoctorsSuccess,
  searchDoctorsFailure,
  AddNewDoctorSuccess,
  AddNewDoctorFailure,
  getDoctorsDetailsSuccess,
  getDoctorsDetailsFailure,
  updateDoctorDetailsSuccess,
  updateDoctorDetailsFailure,
  getDoctorAppointmentsSuccess,
  getDoctorAppointmentsFailure,
  getRateCardListSuccess,
  getRateCardListFailure,
  getFollowUpsSuccess,
  getFollowUpsFailure,
  getFollowUpsWeeklySuccess,
  getFollowUpsWeeklyFailure,
  assignKioskSuccess,
  assignKioskFailure,
  removeKioskSuccess,
  removeKioskFailure,
} from "store/reducers/doctorSlice"; // Import your action creator
import doctorService from "services/doctor"; // Import your service function

function* getDoctorsList(action) {
  try {
    const response = yield call(doctorService.getDoctorsList, action.payload);
    yield put(getDoctorsListSuccess(response.data));
  } catch (error) {
    yield put(getDoctorsListFailure(error));
  }
}

function* getDoctorPatientAppointment(action) {
  try {
    const response = yield call(doctorService.getDoctorPatientAppointment, action.payload);
    yield put(getDoctorPatientAppointmentSuccess(response.data));
  } catch (error) {
    yield put(getDoctorPatientAppointmentFailure(error));
  }
}

function* searchDoctors(action) {
  try {
    const response = yield call(doctorService.searchDoctors, action.payload);
    yield put(searchDoctorsSuccess(response.data));
  } catch (error) {
    yield put(searchDoctorsFailure(error));
  }
}

function* AddNewDoctor(action) {
  try {
    const response = yield call(doctorService.AddNewDoctor, action.payload);
    yield put(AddNewDoctorSuccess(response.data));
  } catch (error) {
    yield put(AddNewDoctorFailure(error));
  }
}

function* getDoctorsDetails(action) {
  try {
    const response = yield call(doctorService.getDoctorsDetails, action.payload);
    yield put(getDoctorsDetailsSuccess(response.data));
  } catch (error) {
    yield put(getDoctorsDetailsFailure(error));
  }
}

function* updateDoctorDetails(action) {
  try {
    const response = yield call(doctorService.updateDoctorDetails, action.payload);
    yield put(updateDoctorDetailsSuccess(response.data));
  } catch (error) {
    yield put(updateDoctorDetailsFailure(error));
  }
}

function* getDoctorAppointments(action) {
  try {
    const response = yield call(doctorService.getDoctorAppointments, action.payload);
    yield put(getDoctorAppointmentsSuccess(response.data));
  } catch (error) {
    yield put(getDoctorAppointmentsFailure(error));
  }
}

function* getRateCardList(action) {
  try {
    const response = yield call(doctorService.getRateCardList, action.payload);
    yield put(getRateCardListSuccess(response.data));
  } catch (error) {
    yield put(getRateCardListFailure(error));
  }
}

function* getFollowUps(action) {
  try {
    const response = yield call(doctorService.getFollowUps, action.payload);
    yield put(getFollowUpsSuccess(response.data));
  } catch (error) {
    yield put(getFollowUpsFailure(error));
  }
}

function* getFollowUpsWeekly(action) {
  try {
    const response = yield call(doctorService.getFollowUpsWeekly, action.payload);
    yield put(getFollowUpsWeeklySuccess(response.data));
  } catch (error) {
    yield put(getFollowUpsWeeklyFailure(error));
  }
}

function* assignKiosk(action) {
  try {
    const response = yield call(doctorService.assignKiosk, action.payload);
    yield put(assignKioskSuccess(response.data));
  } catch (error) {
    yield put(assignKioskFailure(error));
  }
}

function* removeKiosk(action) {
  try {
    const response = yield call(doctorService.removeKiosk, action.payload);
    yield put(removeKioskSuccess(response.data));
  } catch (error) {
    yield put(removeKioskFailure(error));
  }
}

// Watcher Saga
function* doctorSaga() {
  yield takeLatest("doctors/getDoctorsList", getDoctorsList);
  yield takeLatest("doctors/getDoctorPatientAppointment", getDoctorPatientAppointment);
  yield takeLatest("doctors/searchDoctors", searchDoctors);
  yield takeLatest("doctors/AddNewDoctor", AddNewDoctor);
  yield takeLatest("doctors/getDoctorsDetails", getDoctorsDetails);
  yield takeLatest("doctors/updateDoctorDetails", updateDoctorDetails);
  yield takeLatest("doctors/getDoctorAppointments", getDoctorAppointments);
  yield takeLatest("doctors/getRateCardList", getRateCardList);
  yield takeLatest("doctors/getFollowUps", getFollowUps);
  yield takeLatest("doctors/getFollowUpsWeekly", getFollowUpsWeekly);
  yield takeLatest("doctors/assignKiosk", assignKiosk);
  yield takeLatest("doctors/removeKiosk", removeKiosk);
}

// Export all sagas
export default doctorSaga;
