/* eslint-disable */

import { useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Select, FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { createUser } from "store/reducers/agentSlice";

function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [roleData] = useState(["Admin", "Agent"]);
  const [operationsData] = useState(["Add", "Edit", "Delete"]);
  const [entityData] = useState(["patient", "doctor", "agent"]);
  const [selectedRole, setSelectedRole] = useState(state && state.data ? state.data.role : "");
  const [rolesArray, setRolesArray] = useState(
    state && state.data ? state.data.permissions : [{ name: "", operations: [] }]
  );
  const [email, setEmail] = useState(state && state.data ? state.data.email : "");
  const [password, setPassword] = useState("");
  const [city, setCity] = useState(state && state.data ? state.data.city : "");
  const [name, setName] = useState(state && state.data ? state.data.name : "");

  const handleAddClick = () => {
    setRolesArray([...rolesArray, { name: "", operations: [] }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...rolesArray];
    list.splice(index, 1);
    setRolesArray(list);
  };

  const handleChange = (e, data, index) => {
    let isChecked = e.target.checked;
    let list = [...rolesArray];
    if (isChecked) {
      list[index]["operations"].push(data);
    } else {
      const toRemove = data;
      const indexValue = list[index].operations.indexOf(toRemove);
      if (indexValue > -1) {
        list[index].operations.splice(indexValue, 1);
      }
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rolesArray];
    list[index][name] = value;
    setRolesArray(list);
  };

  const validateLogin = () => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email === "") {
      Swal.fire({
        icon: "error",
        text: "Email id cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "error",
        text: "Email id is not valid",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (password === "") {
      Swal.fire({
        icon: "error",
        text: "Password cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (city === "") {
      Swal.fire({
        icon: "error",
        text: "City cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (name === "") {
      Swal.fire({
        icon: "error",
        text: "Name cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (selectedRole === "") {
      Swal.fire({
        icon: "error",
        text: "Role cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };

  const validateUpdate = () => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "error",
        text: "Email id is not valid",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };

  const handleSubmitUser = () => {
    const formdata = {
      email: email,
      password: password,
      city: city,
      name: name,
      role: selectedRole,
      permission: rolesArray,
    };
    if (validateLogin() === true) {
      // dispatch(createUser(formdata))
      //   .unwrap()
      //   .then((response) => {
      //     Swal.fire({
      //       icon: "success",
      //       text: "User added successfully",
      //       showConfirmButton: false,
      //       timer: 2000,
      //     });
      //     navigate("/agent");
      //   })
      //   .catch((error) => {
      //     // Handle API error if needed
      //     Swal.fire({
      //       icon: "error",
      //       text: "Error occurred",
      //       showConfirmButton: false,
      //       timer: 2000,
      //     });
      //     console.log(error);
      //   });
    } else {
      console.log("Form not validated");
    }
  };

  const handleUpdateUser = () => {
    const formdata = {
      agentId: state.data.agentId,
      agentsData: {
        email: email,
        city: city,
        name: name,
        role: selectedRole,
        permission: rolesArray,
      },
    };
    if (validateUpdate() === true) {
      dispatch(updateUser(formdata))
        .unwrap()
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: response.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/agent");
        })
        .catch((error) => {
          // Handle API error if needed
          Swal.fire({
            icon: "error",
            text: "Error occurred",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(error);
        });
    } else {
      console.log("Update not validated");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox p={2}>
                <br />
                <h4>
                  <center>{state && state.data ? "Update User" : "Create User"}</center>
                </h4>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={4}>
                    <MDInput
                      type="text"
                      label="Name"
                      variant="standard"
                      fullWidth
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      variant="standard"
                      fullWidth
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </MDBox>
                  {state && state.data ? (
                    <></>
                  ) : (
                    <MDBox mb={2}>
                      <MDInput
                        type="password"
                        label="Password"
                        variant="standard"
                        fullWidth
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </MDBox>
                  )}

                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="City"
                      variant="standard"
                      fullWidth
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </MDBox>
                  <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selectedRole}
                      onChange={(event) => setSelectedRole(event.target.value)}
                      label="Age"
                    >
                      {roleData.map((data) => {
                        return <MenuItem value={data.toLowerCase()}>{data}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <MDBox mb={2} mt={2}>
                    {rolesArray.map((x, i) => {
                      return (
                        <div>
                          <div class="control-group">
                            {rolesArray.length - 1 === i && (
                              <MDButton
                                variant="gradient"
                                color="info"
                                absolute
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "#17A2B8",
                                  marginLeft: "10px",
                                }}
                                onClick={handleAddClick}
                              >
                                +&nbsp;
                              </MDButton>
                            )}
                            {rolesArray.length !== 1 && (
                              <MDButton
                                variant="gradient"
                                color="error"
                                absolute
                                className="btn btn-primary"
                                style={{
                                  backgroundColor: "#17A2B8",
                                  marginLeft: "10px",
                                }}
                                onClick={() => handleRemoveClick(i)}
                              >
                                -
                              </MDButton>
                            )}

                            <div class="controls">
                              <MDBox mb={2}>
                                <FormControl
                                  variant="standard"
                                  fullWidth
                                  sx={{ mb: 2, mt: 2, minWidth: 200 }}
                                >
                                  <InputLabel id="demo-simple-select-standard-label">
                                    Permission
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    name="name"
                                    value={x.name}
                                    onChange={(e) => handleInputChange(e, i)}
                                    label="Age"
                                  >
                                    {entityData.map((data) => {
                                      return <MenuItem value={data}>{data}</MenuItem>;
                                    })}
                                  </Select>
                                </FormControl>
                              </MDBox>
                            </div>
                          </div>
                          <div class="control-group" style={{ marginBottom: "10px" }}>
                            <label
                              class="control-label"
                              for="username"
                              style={{ fontSize: "medium" }}
                            >
                              Operation
                            </label>
                            <div class="controls">
                              {operationsData.map((data, index) => {
                                return (
                                  <label style={{ fontSize: "medium" }}>
                                    <Checkbox
                                      name="operations"
                                      defaultChecked={x.operations[index] == data}
                                      onChange={(e) => handleChange(e, data, i)}
                                    />
                                    {data}
                                  </label>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </MDBox>
                  {state && state.data ? (
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleUpdateUser}
                      >
                        Update
                      </MDButton>
                    </MDBox>
                  ) : (
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleSubmitUser}
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Notifications;
