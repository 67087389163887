// followUpReminderSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const followUpReminderSlice = createSlice({
  name: "followUpReminders",
  initialState: {
    followUpReminders: [],
    isloading: false,
    getWeeklyFollowUpReminderSuccess: false,
    getWeeklyFollowUpReminderFailure: false,
    getFollowUpRemindersByDateSuccess: false,
    getFollowUpRemindersByDateFailure: false,
    getFollowUpRemindersSuccess: false,
    getFollowUpRemindersFailure: false,
    addAggresiveFollowUpSuccess: false,
    addAggresiveFollowUpFailure: false,
    // Other initial state properties
  },

  reducers: {
    getWeeklyFollowUpReminder: (state, action) => {
      state.isloading = false;
    },
    getWeeklyFollowUpReminderSuccess: (state, action) => {
      state.followUpReminders = action.payload;
      state.isloading = true;
      state.getWeeklyFollowUpReminderSuccess = true;
    },
    getWeeklyFollowUpReminderFailure: (state) => {
      state.isloading = false;
      state.getWeeklyFollowUpReminderFailure = true;
    },
    getFollowUpRemindersByDate: (state, action) => {
      state.isloading = false;
    },
    getFollowUpRemindersByDateSuccess: (state, action) => {
      state.followUpReminders = action.payload;
      state.isloading = true;
      state.getFollowUpRemindersByDateSuccess = true;
    },
    getFollowUpRemindersByDateFailure: (state) => {
      state.isloading = false;
      state.getFollowUpRemindersByDateFailure = true;
    },
    getFollowUpReminders: (state, action) => {
      state.isloading = false;
    },
    getFollowUpRemindersSuccess: (state, action) => {
      state.followUpReminders = action.payload;
      state.isloading = true;
      state.getFollowUpRemindersSuccess = true;
    },
    getFollowUpRemindersFailure: (state) => {
      state.isloading = false;
      state.getFollowUpRemindersFailure = true;
    },
    addAggresiveFollowUp: (state, action) => {
      state.isloading = false;
    },
    addAggresiveFollowUpSuccess: (state, action) => {
      state.followUpReminders = action.payload;
      state.isloading = true;
      state.addAggresiveFollowUpSuccess = true;
    },
    addAggresiveFollowUpFailure: (state) => {
      state.isloading = false;
      state.addAggresiveFollowUpFailure = true;
    },
    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.followUpReminder;

export const followUpReminderSelector = {
  getWeeklyFollowUpReminderSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getWeeklyFollowUpReminderSuccess
  ),
  getWeeklyFollowUpReminderFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getWeeklyFollowUpReminderFailure
  ),
  getFollowUpRemindersByDateSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpRemindersByDateSuccess
  ),
  getFollowUpRemindersByDateFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpRemindersByDateFailure
  ),
  getFollowUpRemindersSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpRemindersSuccess
  ),
  getFollowUpRemindersFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpRemindersFailure
  ),
  addAggresiveFollowUpSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addAggresiveFollowUpSuccess
  ),
  addAggresiveFollowUpFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addAggresiveFollowUpFailure
  ),

  // Add other selectors if needed
};

export const {
  getWeeklyFollowUpReminder,
  getWeeklyFollowUpReminderSuccess,
  getWeeklyFollowUpReminderFailure,
  getFollowUpRemindersByDate,
  getFollowUpRemindersByDateSuccess,
  getFollowUpRemindersByDateFailure,
  getFollowUpReminders,
  getFollowUpRemindersSuccess,
  getFollowUpRemindersFailure,
  addAggresiveFollowUp,
  addAggresiveFollowUpSuccess,
  addAggresiveFollowUpFailure,
} = followUpReminderSlice.actions;
export default followUpReminderSlice.reducer;
