/* eslint-disable */

import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));
const ImageURL = constant.IMAGE_URL;

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getBasePathArray = (bodyData) => {
  return cachedAxiosPost(
    ImageURL + "/getbasepath",
    { jwt: constant.JWT, testId: bodyData.testId, paths: [...bodyData.formData] },
    {
      headers,
    }
  );
};

const getBasePathVideo = (bodyData) => {
  return axios.post(
    ImageURL + "/getbasepathvideo",
    { jwt: constant.JWT, testId: bodyData.testId, paths: [...bodyData.formData] },
    {
      headers,
    }
  );
};

const getReportHistory = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosPost(constant.API_URL + "/agent/patient/getTestHistory", formData, {
    headers,
  });
};

const sendReport = (formdata) => {
  return axios.post(constant.API_URL + "/agent/patient/sendReport", formdata, {
    headers,
  });
};

const downloadReport = (formdata) => {
  return axios.post(constant.API_URL + "/agent/patient/downloadReport", formdata, {
    headers,
  });
};

const getPendingAnnotation = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosGet(
    constant.API_URL +
      "/agent/patient/getAllPendingAnnotation?pageNumber=" +
      formData.pageNumber +
      "&pageSize=" +
      formData.pageSize,
    {
      headers,
    }
  );
};

const approveAnnotation = (formData, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/patient/approveAnnotation", formData, {
    headers,
  });
};

const rejectAnnotation = (formData, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/patient/rejectAnnotation", formData, {
    headers,
  });
};

const getDiseaseData = (formData) => {
  return axios.post(
    `${constant.API_URL}/user/test/getDiseaseDataByPatientId`,
    {
      test_id: formData.testId,
      patientId: formData.patientId,
    },
    {
      headers,
    }
  );
};

const addAnnotationsOnTest = (testId, patientId, detectionId, mode, diseaseName, coordinates) => {
  return axios.put(
    `${constant.API_URL}/user/test/addAnnotationsOnTest`,
    {
      testId,
      patientId,
      detectionId,
      mode,
      diseaseName,
      coordinates,
    },
    {
      headers,
    }
  );
};

const removeAnnotationsOnTest = (
  testId,
  patientId,
  detectionId,
  diseaseName,
  diseaseDetailedListId
) => {
  return axios.put(
    `${constant.API_URL}/user/test/removeAnnotationsOnTest`,
    {
      testId,
      patientId,
      detectionId,
      diseaseName,
      diseaseDetailedListId,
    },
    {
      headers,
    }
  );
};

const generateUrlFromFileKey = (fileKey) => {
  return axios.post(
    `${constant.API_URL}/user/test/generateUrlFromFileKey`,
    {
      fileKey,
    },
    {
      headers,
    }
  );
};

const testDetailsService = {
  getBasePathArray,
  getBasePathVideo,
  getReportHistory,
  sendReport,
  downloadReport,
  getPendingAnnotation,
  approveAnnotation,
  rejectAnnotation,
  getDiseaseData,
  generateUrlFromFileKey,
  addAnnotationsOnTest,
  removeAnnotationsOnTest,
};

export default testDetailsService;
