/* eslint-disable */

import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getUsers = (formData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + formData.token,
  };
  return cachedAxiosPost(constant.API_URL + "/agent/patient/getUsers", formData, {
    headers,
  });
};

const addNewPatient = (formdata, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/patient/addUser", formdata, {
    headers,
  });
};

const updatePatient = (formdata, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/patient/updateProfile", formdata, { headers });
};
const sendNotificationUser = (formdata) => {
  return axios.post(constant.API_URL + "/agent/patient/sendCheckupReminder", formdata, {
    headers,
  });
};

const sendMessage = (MobileNo, token) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + token,
  };
  return axios.post(
    constant.API_URL + "/agent/patient/sendMessage",
    { numbers: [MobileNo] },
    {
      headers,
    }
  );
};
const sendPaymentNotification = (formData) => {
  return axios.post(constant.API_URL + "/agent/patient/sendPaymentNotification", formData, {
    headers,
  });
};

const assignDentalCoach = (formData) => {
  return axios.post(constant.API_URL + "/agent/patient/assignDentalCoach", formData, {
    headers,
  });
};

const patientService = {
  getUsers,
  addNewPatient,
  sendNotificationUser,
  sendMessage,
  updatePatient,
  sendPaymentNotification,
  assignDentalCoach,
};

export default patientService;
