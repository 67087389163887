/* eslint-disable */

import axios from "axios";
import Constants from "../utils/constants";
import { cachedAxiosGet } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getAllChats = (agentId) => {
  return cachedAxiosGet(Constants.API_URL + "/chat/getAllChatData?agentId=" + agentId, {
    headers,
  });
};

const getChatsById = (userId) => {
  return cachedAxiosGet(Constants.API_URL + "/chat/getChatsById/" + userId, {
    headers,
  });
};
const searchPatients = (searchData) => {
  return cachedAxiosGet(Constants.API_URL + "/chat/searchPatients/" + searchData, {
    headers,
  });
};

const sendAttachment = (body, loginData) => {
  if (loginData) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + loginData.token,
    };
  }
  return axios.post(Constants.API_URL + "/chat/sendAttachment", body, {
    headers,
  });
};

const updateChatByUserId = (userId, loginData) => {
  if (loginData) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + loginData.token,
    };
  }
  return axios.put(
    Constants.API_URL + "/chat/updateChatByUserId/" + userId,
    {},
    {
      headers,
    }
  );
};

const inAppChatServices = {
  getChatsById,
  getAllChats,
  updateChatByUserId,
  searchPatients,
  sendAttachment,
};

export default inAppChatServices;
