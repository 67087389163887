/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getPatientsTestsMapping,
  updateAnnotationStatus,
  agentSelector,
} from "store/reducers/agentSlice";
import Swal from "sweetalert2";
import routes from "routes";

function PatientsTestsMapping() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const [patientTestList, setPatientTestList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const patientsTests = useSelector((state) => state?.agent?.patientsTestsMapping);
  const getPatientsTestsMappingSuccess = useSelector(agentSelector.getPatientsTestsMappingSuccess);
  const getPatientsTestsMappingFailure = useSelector(agentSelector.getPatientsTestsMappingFailure);
  const annotationStatus = useSelector((state) => state?.agent?.annotationStatus);
  const updateAnnotationStatusSuccess = useSelector(agentSelector.updateAnnotationStatusSuccess);
  const updateAnnotationStatusFailure = useSelector(agentSelector.updateAnnotationStatusFailure);

  useEffect(() => {
    getAllPatientsTest();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const patientTestColumns = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center", width: 80 },
    {
      field: "user",
      headerName: "Patient Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (data) => {
        return <div>{toTitleCase(data.row.name)}</div>;
      },
    },
    {
      field: "created_date",
      headerName: "Test Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY hh:mm A");
        return `${valueFormatted}`;
      },
    },
    {
      field: "quiz_score",
      headerName: "Score",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "date",
      renderCell: (data) => {
        return data.row.testsData?.quizScore;
      },
    },
    {
      field: "agent",
      headerName: "Agent",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return data.row.agent &&
          data.row.agent.length &&
          data.row.test_status.toLowerCase() === "done"
          ? data.row.agent[0].name
          : "NA";
      },
    },
    {
      field: "oral_type",
      headerName: "Oral Type",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return data?.row?.testsData && data?.row?.testsData?.oralType?.title
          ? data?.row?.testsData?.oralType?.title
          : "NA";
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (data) => {
        return <div>{toTitleCase(data.row.test_status)}</div>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        return (
          <>
            <MDButton
              variant="gradient"
              onClick={(e) => {
                onDetailsClick(e);
              }}
              color="info"
            >
              Details
            </MDButton>
          </>
        );
      },
    },
  ];

  const getAllPatientsTest = (page) => {
    const pageNumber = page === undefined ? "0" : page;
    const pageSize = "10";
    const formData = {
      pageNumber: parseInt(pageNumber) + 1,
      pageSize,
    };
    dispatch(getPatientsTestsMapping(formData));
  };

  useEffect(() => {
    if (patientsTests?.tests) {
      setPatientTestList(patientsTests?.tests);
      setIsLoading(false);
      setResponseData(patientsTests);
    }
  }, [patientsTests]);

  useEffect(() => {
    if (getPatientsTestsMappingFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientsTestsMappingFailure]);

  const handleDetailsClick = (thisRow) => {
    const newTestData = patientTestList.filter((testList) => testList.id === thisRow.id);
    updateAnnotation(newTestData[0].testsData._id, newTestData[0].patientId);
    navigate("/testdetails/" + newTestData[0].testsData._id, {
      state: {
        testId:
          newTestData[0] && newTestData[0].testsData && newTestData[0].testsData._id
            ? newTestData[0].testsData._id
            : "",
        patientId: newTestData[0] && newTestData[0].patientId ? newTestData[0].patientId : "",
        testDetails: newTestData[0],
        patientDetails: newTestData[0],
      },
    });
  };

  const handlePageChange = (newPage) => {
    getAllPatientsTest(newPage);
  };

  const updateAnnotation = (testId, patientId) => {
    const formData = {
      patientId: patientId,
      testId: testId,
      annotationStatus: "inprogress",
    };
    dispatch(updateAnnotationStatus(formData));
  };

  useEffect(() => {
    if (annotationStatus?.tests) {
      setPatientTestList(annotationStatus?.tests);
      setIsLoading(false);
      setResponseData(annotationStatus);
    }
  }, [annotationStatus]);

  useEffect(() => {
    if (updateAnnotationStatusFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateAnnotationStatusFailure]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Patients Tests Mapping
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <DataGrid
                    disableSelectionOnClick={true}
                    rows={patientTestList}
                    columns={patientTestColumns}
                    pageSize={10}
                    rowCount={
                      responseData && responseData.totalCount ? responseData.totalCount : ""
                    }
                    paginationMode="server"
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default PatientsTestsMapping;
