/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SendGreetings from "./components/sendMessages";
function SendBulkMessages() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />
        <SendGreetings />
      </DashboardLayout>
    </>
  );
}
export default SendBulkMessages;
