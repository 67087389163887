/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { getInvoiceDetails, patientDetailsSelector } from "store/reducers/patientdetailsSlice";
import { useDispatch, useSelector } from "react-redux";

function Invoice(props) {
  const dispatch = useDispatch();
  const [invoiceList, setInvoiceList] = useState([]);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const invoiceData = useSelector((state) => state?.patientDetails?.invoiceData);
  const getInvoiceDetailsSuccess = useSelector(patientDetailsSelector.getInvoiceDetailsSuccess);
  const getInvoiceDetailsFailure = useSelector(patientDetailsSelector.getInvoiceDetailsFailure);

  const invoiceColumns = [
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.date).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "total",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "notes",
      headerName: "Notes",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
  ];

  useEffect(() => {
    getInvoiceList();
  }, []);

  const getInvoiceList = () => {
    dispatch(
      getInvoiceDetails(props.patientId, loginData && loginData.token ? loginData.token : "")
    );
  };

  useEffect(() => {
    if (invoiceData?.invoices) {
      setInvoiceList(invoiceData?.invoices);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (getInvoiceDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getInvoiceDetailsFailure]);

  return (
    <div style={{ height: "370px", width: "100%" }}>
      <DataGrid
        rows={invoiceList ? invoiceList : []}
        columns={invoiceColumns}
        disableSelectionOnClick
        pageSize={5}
      />
    </div>
  );
}
export default Invoice;
