/* eslint-disable */

import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosPost } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const addAggresiveFollowUp = (patientId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(
    constant.API_URL + "/agent/followUpReminder/addAggresiveFollowUp",
    { patientId },
    {
      headers,
    }
  );
};

const getFollowUpReminders = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/getFollowUpReminder",
    { patientId: formData.patientId },
    {
      headers,
    }
  );
};

const getFollowUpRemindersByDate = (doctorId, date, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/followUpReminder/getReminderByDate",
    { doctorId, date },
    {
      headers,
    }
  );
};

const getWeeklyFollowUpReminder = (doctorId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/followUpReminder/getWeeklyReminders",
    { doctorId },
    {
      headers,
    }
  );
};

const followUpReminderService = {
  getWeeklyFollowUpReminder,
  getFollowUpRemindersByDate,
  getFollowUpReminders,
  addAggresiveFollowUp,
};

export default followUpReminderService;
