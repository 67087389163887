// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getBasePathArraySuccess,
  getBasePathArrayFailure,
  getBasePathVideoSuccess,
  getBasePathVideoFailure,
  getReportHistorySuccess,
  getReportHistoryFailure,
  sendReportSuccess,
  sendReportFailure,
  downloadReportSuccess,
  downloadReportFailure,
  getPendingAnnotationSuccess,
  getPendingAnnotationFailure,
  approveAnnotationSuccess,
  approveAnnotationFailure,
  rejectAnnotationSuccess,
  rejectAnnotationFailure,
  getDiseaseDataSuccess,
  getDiseaseDataFailure,
} from "store/reducers/testDetailsSlice"; // Import your action creator
import testDetailsService from "services/testDetails"; // Import your service function

function* getBasePathArray(action) {
  try {
    const response = yield call(testDetailsService.getBasePathArray, action.payload);
    yield put(getBasePathArraySuccess(response.data));
  } catch (error) {
    yield put(getBasePathArrayFailure(error));
  }
}
function* getBasePathVideo(action) {
  try {
    const response = yield call(testDetailsService.getBasePathVideo, action.payload);
    yield put(getBasePathVideoSuccess(response.data));
  } catch (error) {
    yield put(getBasePathVideoFailure(error));
  }
}
function* getReportHistory(action) {
  try {
    const response = yield call(testDetailsService.getReportHistory, action.payload);
    yield put(getReportHistorySuccess(response.data));
  } catch (error) {
    yield put(getReportHistoryFailure(error));
  }
}
function* sendReport(action) {
  try {
    const response = yield call(testDetailsService.sendReport, action.payload);
    yield put(sendReportSuccess(response.data));
  } catch (error) {
    yield put(sendReportFailure(error));
  }
}
function* downloadReport(action) {
  try {
    const response = yield call(testDetailsService.downloadReport, action.payload);
    yield put(downloadReportSuccess(response.data));
  } catch (error) {
    yield put(downloadReportFailure(error));
  }
}
function* getPendingAnnotation(action) {
  try {
    const response = yield call(testDetailsService.getPendingAnnotation, action.payload);
    yield put(getPendingAnnotationSuccess(response.data));
  } catch (error) {
    yield put(getPendingAnnotationFailure(error));
  }
}
function* approveAnnotation(action) {
  try {
    const response = yield call(testDetailsService.approveAnnotation, action.payload);
    yield put(approveAnnotationSuccess(response.data));
  } catch (error) {
    yield put(approveAnnotationFailure(error));
  }
}
function* rejectAnnotation(action) {
  try {
    const response = yield call(testDetailsService.rejectAnnotation, action.payload);
    yield put(rejectAnnotationSuccess(response.data));
  } catch (error) {
    yield put(rejectAnnotationFailure(error));
  }
}
function* getDiseaseData(action) {
  try {
    const response = yield call(testDetailsService.getDiseaseData, action.payload);
    yield put(getDiseaseDataSuccess(response.data));
  } catch (error) {
    yield put(getDiseaseDataFailure(error));
  }
}

// Watcher Saga
function* testDetailSaga() {
  yield takeLatest("testDetails/getBasePathArray", getBasePathArray);
  yield takeLatest("testDetails/getBasePathVideo", getBasePathVideo);
  yield takeLatest("testDetails/getReportHistory", getReportHistory);
  yield takeLatest("testDetails/sendReport", sendReport);
  yield takeLatest("testDetails/downloadReport", downloadReport);
  yield takeLatest("testDetails/getPendingAnnotation", getPendingAnnotation);
  yield takeLatest("testDetails/approveAnnotation", approveAnnotation);
  yield takeLatest("testDetails/rejectAnnotation", rejectAnnotation);
  yield takeLatest("testDetails/getDiseaseData", getDiseaseData);
}

// Export all sagas
export default testDetailSaga;
