/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PatientsWithTests from "./components/agentTestsTable";
function PatientsTests() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <PatientsWithTests />
      </DashboardLayout>
    </>
  );
}
export default PatientsTests;
