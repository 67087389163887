import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const addKiosk = (formData) => {
  return axios.post(constant.API_URL + "/kiosk/addKiosk", formData, {
    headers,
  });
};

const getKioskList = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.get(constant.API_URL + "/kiosk/getAllKiosk", {
    headers,
  });
};

const updateKiosk = (kioskData) => {
  return axios.put(constant.API_URL + "/kiosk/updateKiosk", kioskData, {
    headers,
  });
};

const getKioskUsers = (pageNumber, pageSize, token, agentId) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + token,
  };
  return axios.get(
    constant.API_URL +
      "/agent/kiosk/getKioskUser?agentId=" +
      agentId +
      "&pageNumber=" +
      pageNumber +
      "&pageSize=" +
      pageSize,
    {
      headers,
    }
  );
};

// const getKioskUsers = (couponId) => {
//   return axios.delete(constant.API_URL + "/agent/coupon/deleteCoupon?couponId=" + couponId, {
//     headers,
//   });
// };

const kioskService = {
  addKiosk,
  getKioskList,
  updateKiosk,
  getKioskUsers,
};

export default kioskService;
