// couponSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const agentSlice = createSlice({
  name: "agents",
  initialState: {
    agents: [],
    isloading: false,
    getAgentsFailure: false,
    getAgentsSuccess: false,
    getAgentsDataFailure: false,
    getAgentsDataSuccess: false,
    updateUserFailure: false,
    updateUserSuccess: false,
    getAgentDetailsFailure: false,
    getAgentDetailsSuccess: false,
    getPatientTestByAgentFailure: false,
    getPatientTestByAgentSuccess: false,
    getPatientTestFailure: false,
    getPatientTestSuccess: false,
    assignDoctorFailure: false,
    assignDoctorSuccess: false,
    getInternalDoctorsFailure: false,
    getInternalDoctorsSuccess: false,
    transferPatientsFailure: false,
    transferPatientsSuccess: false,
    getPatientsTestsMappingFailure: false,
    getPatientsTestsMappingSuccess: false,
    updateAnnotationStatusFailure: false,
    updateAnnotationStatusSuccess: false,
    createUserFailure: false,
    createUserSuccess: false,
    checkAgentOnlineFailure: false,
    checkAgentOnlineSuccess: false,
    updateAgentDetailsFailure: false,
    updateAgentDetailsSuccess: false,
    agentLoginSuccess: false,
    agentLoginFailure: false,
    // Other initial state properties
  },
  reducers: {
    authToken: (state, action) => {
      state.authToken = action.payload;
    },
    resetAssignDoctorSuccess: (state) => {
      state.assignDoctorSuccess = false;
    },
    resetUpdateAgentDetailsSuccess: (state) => {
      state.assignDoctorSuccess = false;
    },
    getAgents: (state, action) => {
      state.isloading = false;
    },
    getAgentsSuccess: (state, action) => {
      state.agents = action.payload;
      state.isloading = true;
      state.getAgentsSuccess = true;
    },
    getAgentsFailure: (state) => {
      state.isloading = false;
      state.getAgentsFailure = true;
    },
    getAgentsData: (state, action) => {
      state.isloading = false;
    },
    getAgentsDataSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.getAgentsDataSuccess = true;
    },
    getAgentsDataFailure: (state) => {
      state.isloading = false;
      state.getAgentsDataFailure = true;
    },
    addNewAgent: (state, action) => {
      state.isloading = false;
    },
    addNewAgentSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.addNewAgentSuccess = true;
    },
    addNewAgentFailure: (state) => {
      state.isloading = false;
      state.addNewAgentFailure = true;
    },
    updateUser: (state, action) => {
      state.isloading = false;
    },
    updateUserSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.updateUserSuccess = true;
    },
    updateUserFailure: (state) => {
      state.isloading = false;
      state.updateUserFailure = true;
    },
    getAgentDetails: (state, action) => {
      state.isloading = false;
    },
    getAgentDetailsSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.getAgentDetailsSuccess = true;
    },
    getAgentDetailsFailure: (state) => {
      state.isloading = false;
      state.getAgentDetailsFailure = true;
    },
    getPatientTestByAgent: (state, action) => {
      state.isloading = false;
    },
    getPatientTestByAgentSuccess: (state, action) => {
      state.patientTestByAgent = action.payload;
      state.isloading = true;
      state.getPatientTestByAgentSuccess = true;
    },
    getPatientTestByAgentFailure: (state) => {
      state.isloading = false;
      state.getPatientTestByAgentFailure = true;
    },
    getPatientTest: (state, action) => {
      state.isloading = false;
    },
    getPatientTestSuccess: (state, action) => {
      state.patientTest = action.payload;
      state.isloading = true;
      state.getPatientTestSuccess = true;
    },
    getPatientTestFailure: (state) => {
      state.isloading = false;
      state.getPatientTestFailure = true;
    },
    assignDoctor: (state, action) => {
      state.isloading = false;
    },
    assignDoctorSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.assignDoctorSuccess = true;
    },
    assignDoctorFailure: (state) => {
      state.isloading = false;
      state.assignDoctorFailure = true;
    },
    getInternalDoctors: (state, action) => {
      state.isloading = false;
    },
    getInternalDoctorsSuccess: (state, action) => {
      state.internalDoctors = action.payload;
      state.isloading = true;
      state.getInternalDoctorsSuccess = true;
    },
    getInternalDoctorsFailure: (state) => {
      state.isloading = false;
      state.getInternalDoctorsFailure = true;
    },
    transferPatients: (state, action) => {
      state.isloading = false;
    },
    transferPatientsSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.transferPatientsSuccess = true;
    },
    transferPatientsFailure: (state) => {
      state.isloading = false;
      state.transferPatientsFailure = true;
    },
    getPatientsTestsMapping: (state, action) => {
      state.isloading = false;
    },
    getPatientsTestsMappingSuccess: (state, action) => {
      state.patientsTestsMapping = action.payload;
      state.isloading = true;
      state.getPatientsTestsMappingSuccess = true;
    },
    getPatientsTestsMappingFailure: (state) => {
      state.isloading = false;
      state.getPatientsTestsMappingFailure = true;
    },
    updateAnnotationStatus: (state, action) => {
      state.isloading = false;
    },
    updateAnnotationStatusSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.updateAnnotationStatusSuccess = true;
    },
    updateAnnotationStatusFailure: (state) => {
      state.isloading = false;
      state.updateAnnotationStatusFailure = true;
    },
    createUser: (state, action) => {
      state.isloading = false;
    },
    createUserSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.createUserSuccess = true;
    },
    createUserFailure: (state) => {
      state.isloading = false;
      state.createUserFailure = true;
    },
    checkAgentOnline: (state, action) => {
      state.isloading = false;
    },
    resetAgentOnlineSuccess: (state) => {
      state.checkAgentOnlineSuccess = false;
    },
    checkAgentOnlineSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.checkAgentOnlineSuccess = true;
    },
    checkAgentOnlineFailure: (state) => {
      state.isloading = false;
      state.checkAgentOnlineFailure = true;
    },
    updateAgentDetails: (state, action) => {
      state.isloading = false;
    },
    updateAgentDetailsSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.updateAgentDetailsSuccess = true;
    },
    updateAgentDetailsFailure: (state) => {
      state.isloading = false;
      state.updateAgentDetailsFailure = true;
    },
    agentLoginSuccess: (state, action) => {
      state.agentsData = action.payload;
      state.isloading = true;
      state.agentLoginSuccess = true;
    },
    agentLoginFailure: (state) => {
      state.isloading = false;
      state.agentLoginFailure = true;
    },
    // Other reducers if needed
  },
});

const selectAgent = (state) => state.agent;

export const agentSelector = {
  getAgentsSuccess: createSelector(selectAgent, (agentState) => agentState.getAgentsSuccess),
  getAgentsFailure: createSelector(selectAgent, (agentState) => agentState.getAgentsFailure),
  getAgentsDataSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getAgentsDataSuccess
  ),
  getAgentsDataFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getAgentsDataFailure
  ),
  addNewAgentSuccess: createSelector(selectAgent, (agentState) => agentState.addNewAgentSuccess),
  addNewAgentFailure: createSelector(selectAgent, (agentState) => agentState.addNewAgentFailure),
  updateUserSuccess: createSelector(selectAgent, (agentState) => agentState.updateUserSuccess),
  updateUserFailure: createSelector(selectAgent, (agentState) => agentState.updateUserFailure),
  getAgentDetailsSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getAgentDetailsSuccess
  ),
  getAgentDetailsFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getAgentDetailsFailure
  ),
  getPatientTestByAgentSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientTestByAgentSuccess
  ),
  getPatientTestByAgentFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientTestByAgentFailure
  ),
  getPatientTestSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientTestSuccess
  ),
  getPatientTestFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientTestFailure
  ),
  assignDoctorSuccess: createSelector(selectAgent, (agentState) => agentState.assignDoctorSuccess),
  assignDoctorFailure: createSelector(selectAgent, (agentState) => agentState.assignDoctorFailure),
  getInternalDoctorsSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getInternalDoctorsSuccess
  ),
  getInternalDoctorsFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getInternalDoctorsFailure
  ),
  transferPatientsSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.transferPatientsSuccess
  ),
  transferPatientsFailure: createSelector(
    selectAgent,
    (agentState) => agentState.transferPatientsFailure
  ),
  getPatientsTestsMappingSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientsTestsMappingSuccess
  ),
  getPatientsTestsMappingFailure: createSelector(
    selectAgent,
    (agentState) => agentState.getPatientsTestsMappingFailure
  ),
  updateAnnotationStatusSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.updateAnnotationStatusSuccess
  ),
  updateAnnotationStatusFailure: createSelector(
    selectAgent,
    (agentState) => agentState.updateAnnotationStatusFailure
  ),
  createUserSuccess: createSelector(selectAgent, (agentState) => agentState.createUserSuccess),
  createUserFailure: createSelector(selectAgent, (agentState) => agentState.createUserFailure),
  checkAgentOnlineSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.checkAgentOnlineSuccess
  ),
  checkAgentOnlineFailure: createSelector(
    selectAgent,
    (agentState) => agentState.checkAgentOnlineFailure
  ),
  updateAgentDetailsSuccess: createSelector(
    selectAgent,
    (agentState) => agentState.updateAgentDetailsSuccess
  ),
  updateAgentDetailsFailure: createSelector(
    selectAgent,
    (agentState) => agentState.updateAgentDetailsFailure
  ),
  agentLoginSuccess: createSelector(selectAgent, (agentState) => agentState.agentLoginSuccess),
  agentLoginFailure: createSelector(selectAgent, (agentState) => agentState.agentLoginFailure),
  // Add other selectors if needed
};

export const {
  getAgents,
  getAgentsSuccess,
  getAgentsFailure,
  getAgentsData,
  getAgentsDataSuccess,
  getAgentsDataFailure,
  addNewAgent,
  addNewAgentSuccess,
  addNewAgentFailure,
  updateUser,
  updateUserSuccess,
  updateUserFailure,
  getAgentDetails,
  getAgentDetailsSuccess,
  getAgentDetailsFailure,
  getPatientTestByAgent,
  getPatientTestByAgentSuccess,
  getPatientTestByAgentFailure,
  getPatientTest,
  getPatientTestSuccess,
  getPatientTestFailure,
  assignDoctor,
  assignDoctorSuccess,
  assignDoctorFailure,
  getInternalDoctors,
  getInternalDoctorsSuccess,
  getInternalDoctorsFailure,
  transferPatients,
  transferPatientsSuccess,
  transferPatientsFailure,
  getPatientsTestsMapping,
  getPatientsTestsMappingSuccess,
  getPatientsTestsMappingFailure,
  updateAnnotationStatus,
  updateAnnotationStatusSuccess,
  updateAnnotationStatusFailure,
  createUser,
  createUserSuccess,
  createUserFailure,
  checkAgentOnline,
  checkAgentOnlineSuccess,
  checkAgentOnlineFailure,
  updateAgentDetails,
  updateAgentDetailsSuccess,
  updateAgentDetailsFailure,
  agentLogin,
  agentLoginSuccess,
  agentLoginFailure,
  resetAssignDoctorSuccess,
  resetUpdateAgentDetailsSuccess,
  resetAgentOnlineSuccess,
  authToken,
} = agentSlice.actions;
export default agentSlice.reducer;
