import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  loading: false,
  error: null,
};

const agentAuthSlice = createSlice({
  name: "agentAuth",
  initialState,
  reducers: {
    agentAuthStart(state) {
      state.loading = true;
    },
    agentAuthSuccess(state, action) {
      state.token = action.payload;
      state.loading = false;
      state.error = null;
    },
    agentAuthFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { agentAuthStart, agentAuthSuccess, agentAuthFailure } = agentAuthSlice.actions;

export default agentAuthSlice.reducer;
