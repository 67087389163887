// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getPatientDetailsSuccess,
  getPatientDetailsFailure,
  getInvoiceDetailsSuccess,
  getInvoiceDetailsFailure,
  getAppointmentListSuccess,
  getAppointmentListFailure,
  searchPatientSuccess,
  searchPatientFailure,
  getPatientWithTestsSuccess,
  getPatientWithTestsFailure,
  removeFileDataSuccess,
  removeFileDataFailure,
  removeDiagnosticsDataSuccess,
  removeDiagnosticsDataFailure,
  getAllNotificationsSuccess,
  getAllNotificationsFailure,
  getPatientTestsSuccess,
  getPatientTestsFailure,
  getReferralsSuccess,
  getReferralsFailure,
} from "store/reducers/patientdetailsSlice"; // Import your action creator
import patientdetailService from "services/patientDetails"; // Import your service function

function* getPatientDetails(action) {
  try {
    const response = yield call(patientdetailService.getPatientDetails, action.payload);
    yield put(getPatientDetailsSuccess(response.data));
  } catch (error) {
    yield put(getPatientDetailsFailure(error));
  }
}
function* getInvoiceDetails(action) {
  try {
    const response = yield call(patientdetailService.getInvoiceDetails, action.payload);
    yield put(getInvoiceDetailsSuccess(response.data));
  } catch (error) {
    yield put(getInvoiceDetailsFailure(error));
  }
}
function* getAppointmentList(action) {
  try {
    const response = yield call(patientdetailService.getAppointmentList, action.payload);
    yield put(getAppointmentListSuccess(response.data));
  } catch (error) {
    yield put(getAppointmentListFailure(error));
  }
}
function* searchPatient(action) {
  try {
    const response = yield call(patientdetailService.searchPatient, action.payload);
    yield put(searchPatientSuccess(response.data));
  } catch (error) {
    yield put(searchPatientFailure(error));
  }
}
function* getPatientWithTests(action) {
  try {
    const response = yield call(patientdetailService.getPatientWithTests, action.payload);
    yield put(getPatientWithTestsSuccess(response.data));
  } catch (error) {
    yield put(getPatientWithTestsFailure(error));
  }
}
function* removeFileData(action) {
  try {
    const response = yield call(patientdetailService.removeFileData, action.payload);
    yield put(removeFileDataSuccess(response.data));
  } catch (error) {
    yield put(removeFileDataFailure(error));
  }
}
function* removeDiagnosticsData(action) {
  try {
    const response = yield call(patientdetailService.removeDiagnosticsData, action.payload);
    yield put(removeDiagnosticsDataSuccess(response.data));
  } catch (error) {
    yield put(removeDiagnosticsDataFailure(error));
  }
}
function* getAllNotifications(action) {
  try {
    const response = yield call(patientdetailService.getAllNotifications, action.payload);
    yield put(getAllNotificationsSuccess(response.data));
  } catch (error) {
    yield put(getAllNotificationsFailure(error));
  }
}
function* getPatientTests(action) {
  try {
    const response = yield call(patientdetailService.getPatientTests, action.payload);
    yield put(getPatientTestsSuccess(response.data));
  } catch (error) {
    yield put(getPatientTestsFailure(error));
  }
}
function* getReferrals(action) {
  try {
    const response = yield call(patientdetailService.getReferrals, action.payload);
    yield put(getReferralsSuccess(response.data));
  } catch (error) {
    yield put(getReferralsFailure(error));
  }
}

function* defaultTabKey(action) {
  try {
    const response = action.payload;
  } catch (error) {
    console.log(error);
  }
}

// Watcher Saga
function* kioskSaga() {
  yield takeLatest("patientDetails/getPatientDetails", getPatientDetails);
  yield takeLatest("patientDetails/getInvoiceDetails", getInvoiceDetails);
  yield takeLatest("patientDetails/getAppointmentList", getAppointmentList);
  yield takeLatest("patientDetails/searchPatient", searchPatient);
  yield takeLatest("patientDetails/getPatientWithTests", getPatientWithTests);
  yield takeLatest("patientDetails/removeFileData", removeFileData);
  yield takeLatest("patientDetails/removeDiagnosticsData", removeDiagnosticsData);
  yield takeLatest("patientDetails/getAllNotifications", getAllNotifications);
  yield takeLatest("patientDetails/getPatientTests", getPatientTests);
  yield takeLatest("patientDetails/getReferrals", getReferrals);
  yield takeLatest("agents/defaultTabKey", defaultTabKey);
}

// Export all sagas
export default kioskSaga;
