// src/store/index.js
import { configureStore } from "@reduxjs/toolkit";
import agentReducer from "./reducers/agentSlice";
import appointmentReducer from "store/reducers/appointmentSlice";
import chatReducer from "store/reducers/chatSlice";
import couponReducer from "store/reducers/couponSlice";
import dashboardReducer from "store/reducers/dashboardSlice";
import ddAccountReducer from "store/reducers/ddAccountSlice";
import doctorReducer from "store/reducers/doctorSlice";
import followUpReducer from "store/reducers/followUpSlice";
import followUpReminderReducer from "store/reducers/followUpReminderSlice";
import inAppChatReducer from "store/reducers/inAppChatSlice";
import kioskReducer from "store/reducers/kioskSlice";
import patientDetailsReducer from "store/reducers/patientdetailsSlice";
import patientReducer from "store/reducers/patientSlice";
import testDetailsReducer from "store/reducers/testDetailsSlice";
import videoConsultationReducer from "store/reducers/videoConsultationSlice";
import subscriptionReducer from "store/reducers/subscriptionSlice";
import agentSaga from "store/sagas/agentSaga";
import appointmentSaga from "store/sagas/appointmentSaga";
import chatSaga from "store/sagas/chatSaga";
import couponSaga from "store/sagas/couponSaga";
import dashboardSaga from "store/sagas/dashboardSaga";
import ddAccountSaga from "store/sagas/ddAccountSaga";
import doctorSaga from "store/sagas/doctorSaga";
import followUpSaga from "store/sagas/followUpSaga";
import followUpReminderSaga from "store/sagas/followUpReminderSaga";
import inAppChatSaga from "store/sagas/inAppChatSaga";
import kioskSaga from "store/sagas/kioskSaga";
import patientSaga from "store/sagas/patientSaga";
import patientDetailsSaga from "store/sagas/patientDetailsSaga";
import subscriptionSaga from "store/sagas/subscriptionSaga";
import testDetailsSaga from "store/sagas/testDetailSaga";
import videoConsultationSaga from "store/sagas/videoConsultationSaga";

import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    agent: agentReducer,
    appointment: appointmentReducer,
    chat: chatReducer,
    coupon: couponReducer,
    dashboard: dashboardReducer,
    ddAccount: ddAccountReducer,
    doctor: doctorReducer,
    followUp: followUpReducer,
    followUpReminder: followUpReminderReducer,
    inAppChat: inAppChatReducer,
    kiosk: kioskReducer,
    patientDetails: patientDetailsReducer,
    patient: patientReducer,
    subscription: subscriptionReducer,
    testDetails: testDetailsReducer,
    videoConsultation: videoConsultationReducer,
    // Add other reducers here if you have more slices of state
  },
  middleware: [sagaMiddleware],
});
sagaMiddleware.run(agentSaga);
sagaMiddleware.run(appointmentSaga);
sagaMiddleware.run(chatSaga);
sagaMiddleware.run(couponSaga);
sagaMiddleware.run(dashboardSaga);
sagaMiddleware.run(ddAccountSaga);
sagaMiddleware.run(doctorSaga);
sagaMiddleware.run(followUpSaga);
sagaMiddleware.run(followUpReminderSaga);
sagaMiddleware.run(inAppChatSaga);
sagaMiddleware.run(kioskSaga);
sagaMiddleware.run(patientDetailsSaga);
sagaMiddleware.run(patientSaga);
sagaMiddleware.run(subscriptionSaga);
sagaMiddleware.run(testDetailsSaga);
sagaMiddleware.run(videoConsultationSaga);

export default store;
