/* eslint-disable */

import axios from "axios";
import { cachedAxiosGet } from "./apiCacheService";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};
const updateFollowUp = (formdata, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/followUp/updateFollowUp", formdata, {
    headers,
  });
};

const addFollowUp = (formdata, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/followUp/addFollowUp", formdata, {
    headers,
  });
};

const getFollowUpById = (followUpId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/followUp/getFollowUp/" + followUpId, {
    headers,
  });
};

const followUpService = {
  updateFollowUp,
  addFollowUp,
  getFollowUpById,
};

export default followUpService;
