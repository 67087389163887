// patientDetailSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const patientDetailSlice = createSlice({
  name: "patientDetails",
  initialState: {
    patientDetails: [],
    isloading: false,
    getPatientDetailsSuccess: false,
    getPatientDetailsFailure: false,
    getInvoiceDetailsSuccess: false,
    getInvoiceDetailsFailure: false,
    getAppointmentListSuccess: false,
    getAppointmentListFailure: false,
    searchPatientSuccess: false,
    searchPatientFailure: false,
    getPatientWithTestsSuccess: false,
    getPatientWithTestsFailure: false,
    removeFileDataSuccess: false,
    removeFileDataFailure: false,
    removeDiagnosticsDataSuccess: false,
    removeDiagnosticsDataFailure: false,
    getAllNotificationsSuccess: false,
    getAllNotificationsFailure: false,
    getPatientTestsSuccess: false,
    getPatientTestsFailure: false,
    getReferralsSuccess: false,
    getReferralsFailure: false,
    // Other initial state properties
  },

  reducers: {
    defaultTabKey: (state, action) => {
      state.defaultTabKey = action.payload;
    },
    getPatientDetails: (state, action) => {
      state.isloading = false;
    },
    getPatientDetailsSuccess: (state, action) => {
      state.patientDetails = action.payload;
      state.isloading = true;
      state.getPatientDetailsSuccess = true;
    },
    getPatientDetailsFailure: (state) => {
      state.isloading = false;
      state.getPatientDetailsFailure = true;
    },
    getInvoiceDetails: (state, action) => {
      state.isloading = false;
    },
    getInvoiceDetailsSuccess: (state, action) => {
      state.invoiceData = action.payload;
      state.isloading = true;
      state.getInvoiceDetailsSuccess = true;
    },
    getInvoiceDetailsFailure: (state) => {
      state.isloading = false;
      state.getInvoiceDetailsFailure = true;
    },
    getAppointmentList: (state, action) => {
      state.isloading = false;
    },
    getAppointmentListSuccess: (state, action) => {
      state.appointmentList = action.payload;
      state.isloading = true;
      state.getAppointmentListSuccess = true;
    },
    getAppointmentListFailure: (state) => {
      state.isloading = false;
      state.getAppointmentListFailure = true;
    },
    searchPatient: (state, action) => {
      state.isloading = false;
    },
    searchPatientSuccess: (state, action) => {
      state.searchPatients = action.payload;
      state.isloading = true;
      state.searchPatientSuccess = true;
    },
    searchPatientFailure: (state) => {
      state.isloading = false;
      state.searchPatientFailure = true;
    },
    getPatientWithTests: (state, action) => {
      state.isloading = false;
    },
    getPatientWithTestsSuccess: (state, action) => {
      state.patientWithTests = action.payload;
      state.isloading = true;
      state.getPatientWithTestsSuccess = true;
    },
    getPatientWithTestsFailure: (state) => {
      state.isloading = false;
      state.getPatientWithTestsFailure = true;
    },
    removeFileData: (state, action) => {
      state.isloading = false;
    },
    removeFileDataSuccess: (state, action) => {
      state.patientDetails = action.payload;
      state.isloading = true;
      state.removeFileDataSuccess = true;
    },
    removeFileDataFailure: (state) => {
      state.isloading = false;
      state.removeFileDataFailure = true;
    },
    removeDiagnosticsData: (state, action) => {
      state.isloading = false;
    },
    removeDiagnosticsDataSuccess: (state, action) => {
      state.patientDetails = action.payload;
      state.isloading = true;
      state.removeDiagnosticsDataSuccess = true;
    },
    removeDiagnosticsDataFailure: (state) => {
      state.isloading = false;
      state.removeDiagnosticsDataFailure = true;
    },
    getAllNotifications: (state, action) => {
      state.isloading = false;
    },
    getAllNotificationsSuccess: (state, action) => {
      state.notificationData = action.payload;
      state.isloading = true;
      state.getAllNotificationsSuccess = true;
    },
    getAllNotificationsFailure: (state) => {
      state.isloading = false;
      state.getAllNotificationsFailure = true;
    },
    getPatientTests: (state, action) => {
      state.isloading = false;
    },
    getPatientTestsSuccess: (state, action) => {
      state.patientTests = action.payload;
      state.isloading = true;
      state.getPatientTestsSuccess = true;
    },
    getPatientTestsFailure: (state) => {
      state.isloading = false;
      state.getPatientTestsFailure = true;
    },
    getReferrals: (state, action) => {
      state.isloading = false;
    },
    getReferralsSuccess: (state, action) => {
      state.patientDetails = action.payload;
      state.isloading = true;
      state.getReferralsSuccess = true;
    },
    getReferralsFailure: (state) => {
      state.isloading = false;
      state.getReferralsFailure = true;
    },

    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.kiosk;

export const patientDetailsSelector = {
  getPatientDetailsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientDetailsSuccess
  ),
  getPatientDetailsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientDetailsFailure
  ),
  getInvoiceDetailsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getInvoiceDetailsSuccess
  ),
  getInvoiceDetailsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getInvoiceDetailsFailure
  ),
  getAppointmentListSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAppointmentListSuccess
  ),
  getAppointmentListFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAppointmentListFailure
  ),
  searchPatientSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchPatientSuccess
  ),
  searchPatientFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchPatientFailure
  ),
  getPatientWithTestsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientWithTestsSuccess
  ),
  getPatientWithTestsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientWithTestsFailure
  ),
  removeFileDataSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.removeFileDataSuccess
  ),
  removeFileDataFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.removeFileDataFailure
  ),
  removeDiagnosticsDataSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.removeDiagnosticsDataSuccess
  ),
  removeDiagnosticsDataFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.removeDiagnosticsDataFailure
  ),
  getAllNotificationsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAllNotificationsSuccess
  ),
  getAllNotificationsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAllNotificationsFailure
  ),
  getPatientTestsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientTestsSuccess
  ),
  getPatientTestsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientTestsFailure
  ),
  getReferralsSuccess: createSelector(selectKiosk, (kioskState) => kioskState.getReferralsSuccess),
  getReferralsFailure: createSelector(selectKiosk, (kioskState) => kioskState.getReferralsFailure),

  // Add other selectors if needed
};

export const {
  getPatientDetails,
  getPatientDetailsSuccess,
  getPatientDetailsFailure,
  getInvoiceDetails,
  getInvoiceDetailsSuccess,
  getInvoiceDetailsFailure,
  getAppointmentList,
  getAppointmentListSuccess,
  getAppointmentListFailure,
  searchPatient,
  searchPatientSuccess,
  searchPatientFailure,
  getPatientWithTests,
  getPatientWithTestsSuccess,
  getPatientWithTestsFailure,
  removeFileData,
  removeFileDataSuccess,
  removeFileDataFailure,
  removeDiagnosticsData,
  removeDiagnosticsDataSuccess,
  removeDiagnosticsDataFailure,
  getAllNotifications,
  getAllNotificationsSuccess,
  getAllNotificationsFailure,
  getPatientTests,
  getPatientTestsSuccess,
  getPatientTestsFailure,
  getReferrals,
  getReferralsSuccess,
  getReferralsFailure,
  defaultTabKey,
} = patientDetailSlice.actions;
export default patientDetailSlice.reducer;
