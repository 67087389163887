/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import PlanCard from "./planCard";
import Swal from "sweetalert2";
import { getSubscriptionPlans, subscriptionSelector } from "store/reducers/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import routes from "routes";

function PlanDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [planList, setPlanList] = useState([]);
  const subscriptionPlans = useSelector((state) => state?.subscription?.subscriptionPlans);
  const getSubscriptionPlansSuccess = useSelector(subscriptionSelector.getSubscriptionPlansSuccess);
  const getSubscriptionPlansFailure = useSelector(subscriptionSelector.getSubscriptionPlansFailure);

  useEffect(() => {
    getPlanList();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const handleClickAddPlan = () => {
    navigate("/add-edit-plans");
  };

  const getPlanList = () => {
    dispatch(getSubscriptionPlans());
  };

  useEffect(() => {
    if (subscriptionPlans) {
      setIsLoading(false);
      setPlanList([...subscriptionPlans].reverse());
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (getSubscriptionPlansFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getSubscriptionPlansFailure]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Plans
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="right"
                mt={{ xs: 2, sm: 0.1 }}
                ml={{ xs: -1.5, sm: 0.1 }}
                pt={1}
                px={2}
              >
                <MDButton
                  style={{ float: "left" }}
                  variant="gradient"
                  color="info"
                  onClick={handleClickAddPlan}
                >
                  <AddIcon />
                  &nbsp;Add Plan
                </MDButton>
              </MDBox>
              <Card id="delete-account">
                <MDBox pt={1} px={2}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Plan's List
                  </MDTypography>
                </MDBox>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <>
                    <MDBox pt={1} pb={2} px={2}>
                      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        {planList && planList.length ? (
                          planList.map((data) => {
                            return (
                              <PlanCard
                                planType={data.planType}
                                features={data.features}
                                planId={data._id}
                                price={data.price}
                                annualPrice={data.annualPrice}
                                discountPercent={data.discountPercent}
                                originalPrice={data.originalPrice}
                                //validityInMonths={data.validityInMonths} //required
                              />
                            );
                          })
                        ) : (
                          <MDTypography variant="h6" fontWeight="medium">
                            No Plans Available
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </>
                )}
              </Card>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default PlanDetails;
