/* eslint-disable */

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment";
import MDTypography from "components/MDTypography";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "utils/constants";
import io from "socket.io-client";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
import Tooltip from "@material-ui/core/Tooltip";
import isEmpty from "layouts/IsEmpty";
import AttachFile from "@material-ui/icons/AttachFile";
import { useMaterialUIController } from "context";
import ddLogo from "../../../utilities/icon/scanoLogo1.png";
import "../components/chatweb.css";
import { Badge } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  TextField,
  Paper,
  Divider,
  // Badge,
  Box,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItem,
  Fab,
  List,
} from "@mui/material";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  updateChatMode,
  updateChatStatus,
  getPatientChats,
  searchChatPatients,
  filterChatData,
  chatSelector,
} from "store/reducers/chatSlice";
import Swal from "sweetalert2";
import routes from "routes";

const permission = Notification.permission;
const REACT_APP_CHAT_URL = Constants.CHAT_URL;
const REACT_APP_SOCKET_URL = Constants.SOCKET_URL;
const loginData = JSON.parse(localStorage.getItem("loginData"));
const socket = io(REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
  autoconnect: true,
});
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "100%",
    // border: "solid #3886ed",
  },

  MenuIconClass: {
    float: "right",
    transform: "scale(1.8)",
    margin: "10px",
    color: " #04acf1",
  },
  attachIconClass: {
    height: "40px",
    width: "30px",
  },
  dateClass: {
    fontSize: "2px",
    color: "black",
  },
  listItemClass: {
    backgroundColor: "#FFFFFF",
    marginBottom: "10px",
  },

  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 400,
    marginBottom: "20px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChatWeb() {
  const dispatch = useDispatch();
  const [patientList, setPatientList] = useState([]);
  const [patientsChats, setPatientsChats] = useState([]);
  const [newPatientList, setNewPatientList] = useState([]);
  const [message, setMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [patientmobileNumber, setPatientMobileNumber] = useState("");
  const [patientMode, setPatientMode] = useState("");
  const [chatValue, setChatValue] = useState([]);
  const [patientId, setPatientId] = useState("");
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [chatData, setChatData] = useState();
  const [messageNotification, setMessageNotification] = useState("");
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [toggle, setToggle] = useState(patientMode === "OPERATOR" ? true : false);
  const CurrentDate = new Date();
  const inputFile = useRef(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [currPage, setCurrPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const chats = useSelector((state) => state?.chat?.chats);
  const filterChatDataSuccess = useSelector(chatSelector.filterChatDataSuccess);
  const filterChatDataFailure = useSelector(chatSelector.filterChatDataFailure);
  const patientchats = useSelector((state) => state?.chat?.patientchats);
  const getPatientChatsSuccess = useSelector(chatSelector.getPatientChatsSuccess);
  const getPatientChatsFailure = useSelector(chatSelector.getPatientChatsFailure);
  const updateChatStatusSuccess = useSelector(chatSelector.updateChatStatusSuccess);
  const updateChatStatusFailure = useSelector(chatSelector.updateChatStatusFailure);
  const searchPatientchats = useSelector((state) => state?.chat?.searchPatientchats);
  const searchChatPatientsSuccess = useSelector(chatSelector.searchChatPatientsSuccess);
  const searchChatPatientsFailure = useSelector(chatSelector.searchChatPatientsFailure);
  // updateChatMode
  const updateChatModeSuccess = useSelector(chatSelector.updateChatModeSuccess);
  const updateChatModeFailure = useSelector(chatSelector.updateChatModeFailure);

  useEffect(() => {
    getPatientList();
  }, [limit]);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("chatsId");
    element.scrollTop = element.scrollHeight;
    socketConnection();
    getPatientList();
  }, []);

  useEffect(() => {
    const element = document.getElementById("chatsId");
    element.scrollTop = element.scrollHeight;
    socket.once("customer message", (data) => {
      let result = chatValue ? chatValue.map((data) => data.id) : "";
      let maxPatientId = Math.max.apply(null, result);
      patientMessageAdded(data, chatValue, maxPatientId, patientList);
    });
  }, [chatValue]);

  useEffect(() => {
    socket.on("customer message", (data) => {
      incrementCount(data, patientList);
    });
    const maxPatientCount =
      patientList && patientList.length
        ? patientList.reduce((previous, current) => (previous.id > current.id ? previous : current))
        : "";
    socket.once("customer connected", (data) => {
      patientAdded(data, maxPatientCount, patientList);
    });
  }, [patientList]);

  const socketConnection = () => {
    socket.on("connect", () => {
      console.log("socket connected successfully");
    });
  };

  useEffect(() => {
    socket.once("operator requested", (data) => {
      setToggle(!toggle);
    });
  });

  const getFilteredData = (searchValue) => {
    if (searchValue && searchValue.length) {
      const patientData = newPatientList.filter(
        (patient) => patient.name.indexOf(searchValue) !== -1
      );
      setPatientList(patientData);
    } else {
      setPatientList(newPatientList);
    }
  };

  const patientAdded = (data, maxPatientCount, patientList) => {
    console.log("new user added");
    // for existing patient
    if (patientList.length) {
      const filterData = patientList.filter((patients) => patients.mobile === "+" + data);
      // console.log("filterData", filterData.length);
      if (filterData[0] && filterData[0].lastMessageTime) {
        filterData[0].lastMessageTime = new Date().toISOString() + "";
      } else if (filterData.length === 0) {
        // for new patient
        const newFilterData = patientList.filter((patients) => patients.mobile !== "+" + data);
        newFilterData.push({
          createdDate: new Date().toISOString() + "",
          id: maxPatientCount + 1,
          lastMessageTime: new Date().toISOString() + "",
          messageStatus: 0,
          mobile: "+" + data,
          mode: "AGENT",
          name: data,
          updatedDate: new Date().toISOString() + "",
        });
        setPatientList(newFilterData);
      }
    }
  };

  const patientMessageAdded = (patientMessage, chatValue, patientId, patientList) => {
    if (Notification.permission === "granted" && patientMessage.isAgentResponse === false) {
      showNotification(patientMessage.utterance);
    } else if (Notification.permission === "denied") {
      Notification.requestPermission().then((getperm) => {
        console.log("Perm granted", getperm);
      });
    }
    if (!patientMessage.isAgentResponse) {
      setOpen(true);
    }
    console.log("Message received");
    setMessageNotification(patientMessage.utterance);
    if (
      chatData &&
      chatData.mobile &&
      chatData.mobile.replace("+", "") === patientMessage.customerId
    ) {
      setChatValue([
        ...chatValue,
        {
          id: patientId + 1,
          createdAt: new Date().toISOString() + "",
          customerId: patientMessage.customerId,
          isSender: patientMessage.isAgentResponse,
          messageStatus: "not_view",
          messageType: "text",
          text: patientMessage.utterance,
          updatedAt: new Date().toISOString() + "",
        },
      ]);
    }
  };

  const showNotification = (message) => {
    new Notification("New message from chat", { body: message });
  };

  const incrementCount = (patientMessage, patientList) => {
    if (!patientMessage.isAgentResponse) {
      setOpen(true);
    }
    setMessageNotification(patientMessage.utterance);
    if (patientMessage.customerId !== mobileNumber) {
      patientList.forEach((patients) => {
        if (patients.mobile === "+" + patientMessage.customerId) {
          patients.messageStatus = parseInt(patients.messageStatus) + 1;
          patients.lastMessageTime = new Date().toISOString() + "";
        }
      });
    }
  };

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      //setCurrPage(currPage + 1);
      setLimit(limit + 10);
    }
  };

  const getPatientList = () => {
    const pageLimit = limit;
    const pageNumber = 0;
    dispatch(filterChatData(pageLimit));
  };

  useEffect(() => {
    if (chats?.patients) {
      setIsLoading(false);
      setPatientList(chats?.patients);
      setNewPatientList(chats?.patients);
    }
  }, [chats]);

  useEffect(() => {
    if (filterChatDataFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [filterChatDataFailure]);

  const handleShowChats = (data) => {
    const element = document.getElementById("chatsId");
    element.scrollTop = element.scrollHeight;
    setChatData(data);
    setMessageCount(data.name);
    setMobileNumber(data.name);
    setPatientMobileNumber(data.mobile);
    getChatsByPatient(data.mobile.replace("+", ""));
    setPatientMode(data.mode);
    setToggle(data.mode === "OPERATOR" ? false : true);
  };

  const setMessageCount = (mobileNumber) => {
    const updatedPatientList = patientList?.map((patient) => {
      if (patient.name === mobileNumber) {
        return { ...patient, messageStatus: 0 };
      }
      return patient;
    });
    return updatedPatientList;
  };

  const getChatsByPatient = (mobileNumber) => {
    dispatch(getPatientChats(mobileNumber));
  };

  useEffect(() => {
    if (patientchats) {
      setPatientsChats([...patientchats].reverse());
      setIsLoading(false);
      setChatValue(patientchats);
      checkPatientId(patientchats);
      let result = patientchats.length ? patientchats.map((data) => data.id) : "";
      if (result) {
        let maxValue = Math.max.apply(null, result);
        handleUpdateChatStatus(mobileNumber, maxValue);
      } else {
        Swal.fire({
          icon: "error",
          text: "No chats found",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  }, [patientchats]);

  useEffect(() => {
    if (getPatientChatsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientChatsFailure]);

  const checkPatientId = (responseData) => {
    if (responseData && responseData.length) {
      let result = responseData ? responseData.map((data) => data.id) : "";
      let maxValue = Math.max.apply(null, result);
      setPatientId(maxValue);
    }
  };

  const handleUpdateChatStatus = (mobileNumber, lastMessageId) => {
    dispatch(updateChatStatus(mobileNumber, lastMessageId.toString()));
  };

  useEffect(() => {
    if (updateChatStatusSuccess) {
      console.log("Chat status updated successfully");
    }
  }, [updateChatStatusSuccess]);

  useEffect(() => {
    if (updateChatStatusFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateChatStatusFailure]);

  const handleClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleClickAttachment = () => {
    inputFile.current.click();
  };

  const handleSendAttachment = (event) => {
    // event.preventDefault();
    if (inputValue) {
      sendAttachment();
    }
    if (message !== "") {
      socket.emit("operator message", {
        customerId: chatData.mobile.replace("+", ""),
        utterance: message,
      });
      setMessage("");
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (inputValue) {
      sendAttachment();
    }
    console.log("message", message);
    if (message !== "") {
      socket.emit("operator message", {
        customerId: chatData.mobile.replace("+", ""),
        utterance: message,
      });
      setMessage("");
    } else {
      alert("msg error");
    }
  };

  const sendAttachment = () => {
    let body = new FormData();
    const config = {
      "Content-Type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${loginData.token}`,
      },
    };
    body.append("file", inputValue);
    body.append("id", patientmobileNumber.replace("+", ""));
    axios
      .post(REACT_APP_CHAT_URL + "/sendAttachment", body, config)
      .then((res) => {
        setOpen(true);
        setMessageNotification("File sent");
        setInputValue("");
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.statusText);
        }
        alert(error);
        console.log(error);
      });
  };

  const searchChatUsers = (filterName) => {
    dispatch(searchChatPatients(filterName));
  };

  useEffect(() => {
    if (searchPatientchats) {
      setPatientList(searchPatientchats.patients);
      setIsLoading(false);
    }
  }, [searchPatientchats]);

  useEffect(() => {
    if (searchChatPatientsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [searchChatPatientsFailure]);

  const handleSwitchMode = () => {
    setToggle(!toggle);
    const data = {
      id: chatData.mobile.replace("+", ""),
      mode: patientMode === "OPERATOR" ? "AGENT" : "OPERATOR",
    };
    if (!toggle) {
      data.mode = patientMode === "AGENT" ? "OPERATOR" : "AGENT";
    }
    dispatch(updateChatMode(data));
  };

  useEffect(() => {
    if (updateChatModeSuccess) {
      Swal.fire({
        icon: "success",
        text: "Chat mode updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateChatModeSuccess]);

  useEffect(() => {
    if (updateChatModeFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateChatModeFailure]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <>
      <MDBox pt={6} pb={3}>
        <Card
          sx={{
            height: 900,
            width: "100%",
          }}
        >
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Chat Board
            </MDTypography>
          </MDBox>
          <Grid
            // className={classes.chatSection}
            item
            xs={12}
            container
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            borderRadius="lg"
            p={3}
            mx={2}
          >
            <Grid item xs={3.8}>
              <MDInput
                label="Search Patients"
                style={{ postion: "relative", maxWidth: "250px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <MDButton
                        style={{ background: "transparent", marginRight: "-20px" }}
                        variant="gradient"
                        onClick={() =>
                          filterName.length ? searchChatUsers(filterName) : getPatientList("0")
                        }
                      >
                        <SearchIcon />
                      </MDButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setFilterName(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && filterName.length) {
                    searchChatUsers(filterName);
                  } else if (event.key === "Enter" && filterName.length === 0) {
                    getPatientList("0");
                  }
                }}
              />

              <Divider />
              {isLoading ? (
                <>
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "150px" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                </>
              ) : (
                // <LinearProgress color="inherit" />
                <List style={{ overflowY: "scroll", height: "700px" }} onScroll={onScroll}>
                  {!isEmpty(patientList)
                    ? _.sortBy(patientList, "lastMessageTime")
                        .reverse()
                        .map((data, index) => {
                          return (
                            <>
                              <ListItem
                                // className={classes.listItemClass}
                                key={index}
                                button
                                onClick={() => handleShowChats(data)}
                              >
                                <ListItemIcon>
                                  <Avatar alt="avtar image" style={{ color: "#04acf1" }} />
                                  <Badge
                                    overlap="rectangular"
                                    className={classes.padding}
                                    color="primary"
                                    badgeContent={data.messageStatus}
                                  ></Badge>
                                </ListItemIcon>
                                <ListItemText
                                  primary={toTitleCase(data.name)}
                                  secondary={
                                    moment(CurrentDate).format("DD/MM/YYYY") ===
                                    moment(data.lastMessageTime).format("DD/MM/YYYY")
                                      ? moment(data.lastMessageTime).format("hh:mm a")
                                      : moment(data.lastMessageTime).format("DD/MM/YYYY")
                                  }
                                >
                                  {toTitleCase(data.name)}
                                </ListItemText>
                              </ListItem>
                              <hr />
                              {/* <Divider /> */}
                            </>
                          );
                        })
                    : ""}
                </List>
              )}
            </Grid>
            <Grid item xs={8.2}>
              <MDBox className="chatHeaderClass">
                {mobileNumber ? (
                  <MDBox className="columnClass">
                    <ListItemIcon>
                      <Avatar alt="Profile Photo" style={{ color: "#04acf1" }} />
                    </ListItemIcon>
                    <span className="headerClass">
                      {mobileNumber ? mobileNumber + "(" + patientmobileNumber + ")" : ""}
                    </span>
                    {toggle ? (
                      <Tooltip title="Switch to user" placement="right">
                        <ToggleOn
                          className={classes.MenuIconClass}
                          onClick={handleSwitchMode}
                        ></ToggleOn>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Switch to bot" placement="right">
                        <ToggleOff
                          className={classes.MenuIconClass}
                          onClick={handleSwitchMode}
                        ></ToggleOff>
                      </Tooltip>
                    )}
                  </MDBox>
                ) : (
                  ""
                )}
              </MDBox>
              <div className="chatBoxMainClass" id="chatsId">
                <Grid mb={2} mx={2} pt={3}>
                  {!isEmpty(chatValue)
                    ? chatValue.map((data) => {
                        return (
                          <List>
                            <ListItem key="1">
                              <Grid container>
                                <Grid item xs={12}>
                                  <MDBox
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "50%",
                                    }}
                                  >
                                    <MDBox className="dateBoxClass">
                                      {moment(data.createdAt).format("DD/MM/YYYY")}
                                    </MDBox>
                                  </MDBox>
                                </Grid>
                                <Grid
                                  container
                                  className={data.isSender === true ? "leftClass" : "rightClass"}
                                >
                                  <Grid item xs>
                                    <Avatar
                                      alt="Profile Photo"
                                      src={data.isSender == true ? ddLogo : ""}
                                      style={{ color: "#04acf1" }}
                                    />
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Grid
                                      className={
                                        data.isSender == true
                                          ? "chatBoxLeftClass"
                                          : "chatBoxRightClass"
                                      }
                                    >
                                      {data.text}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <ListItemText
                                    align={data.isSender == true ? "left" : "right"}
                                    fontSize="5px"
                                    secondary={moment(data.createdAt).format("hh:mm a")}
                                  ></ListItemText>
                                </Grid>
                              </Grid>
                            </ListItem>
                          </List>
                        );
                      })
                    : ""}
                </Grid>
                {mobileNumber ? (
                  <form className={classes.form} noValidate onSubmit={handleSendMessage}>
                    <Grid
                      container
                      style={{
                        padding: "10px",
                        position: "absolute",
                        bottom: "0",
                        alignSelf: "flex-end",
                        width: "74%",
                        height: "8%",
                      }}
                      item
                      xs={8}
                    >
                      {!toggle ? (
                        <>
                          <Grid item xs={8}>
                            <TextField
                              item
                              xs={12}
                              sm={8}
                              id="outlined-basic-email"
                              label={!inputValue ? "Message ..." : inputValue.name}
                              fullWidth
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                            ></TextField>
                          </Grid>
                          <Grid>
                            <AttachFile
                              className={classes.attachIconClass}
                              xs={1}
                              onClick={handleClickAttachment}
                            ></AttachFile>
                            <input
                              type="file"
                              id="file"
                              ref={inputFile}
                              onChange={(e) => setInputValue(e.target.files[0])}
                              style={{ display: "none" }}
                            />
                          </Grid>
                          <Grid item xs={1} align="right">
                            <Fab
                              color="primary"
                              aria-label="add"
                              style={{ backgroundColor: "#3886ed" }}
                            >
                              <SendIcon
                                type="submit"
                                fontSize="medium"
                                onClick={() => handleSendAttachment()}
                              />
                            </Fab>
                          </Grid>
                          &nbsp;
                          {/* <Tooltip title="Switch to bot" placement="right">
                            <ToggleOff
                              style={{ float: "right" }}
                              className={classes.MenuIconClass}
                              onClick={handleSwitchMode}
                            ></ToggleOff>
                          </Tooltip> */}
                          {/* <Grid item xs={1}>
                            <Fab style={{ backgroundColor: "#3886ed" }}>
                              <DoneIcon
                                fontSize="medium"
                                style={{ color: "white" }}
                                type="submit"
                                onClick={handleConfirmChat}
                              />
                            </Fab>
                          </Grid> */}
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClosePopup}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClosePopup} severity="success">
          {messageNotification}
        </Alert>
      </Snackbar>
    </>
  );
}
