import axios from "axios";
const cache = new Map();

const cachedAxiosPost = async (url, data, config) => {
  // const key = JSON.stringify({ url, data });
  // if (cache.has(key)) {
  //   return cache.get(key);
  // }
  return axios.post(url, data, config);
  // cache.set(key, promise);
  // const response = await promise;
  // return response;
};

const cachedAxiosGet = async (url, config) => {
  // if (cache.has(url)) {
  //   return cache.get(url);
  // }
  return axios.get(url, config);
  // cache.set(url, promise);
  // const response = await promise;
  // return response;
};

export { cachedAxiosGet, cachedAxiosPost };
