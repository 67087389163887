/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorAppointments, doctorSelector } from "store/reducers/doctorSlice";

function Appointment(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [appointmentList, setAppointmentList] = useState([]);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const appointmentData = useSelector((state) => state?.doctor?.appointmentData);
  const getDoctorAppointmentsSuccess = useSelector(doctorSelector.getDoctorAppointmentsSuccess);
  const getDoctorAppointmentsFailure = useSelector(doctorSelector.getDoctorAppointmentsFailure);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const appointmentColumns = [
    {
      field: "id",
      headerName: "No",
      headerAlign: "center",
      align: "center",
      width: 20,
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => {
        return <div>{toTitleCase(params.row.patientName)}</div>;
      },
    },
    {
      field: "startTime",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return moment(data.row.startTime).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      width: 300,
    },
  ];

  useEffect(() => {
    GetDoctorAppointments(props.doctorId);
  }, []);

  const GetDoctorAppointments = (doctorId) => {
    dispatch(getDoctorAppointments(doctorId));
  };

  useEffect(() => {
    if (getDoctorAppointmentsSuccess) {
      setAppointmentList([...appointmentData?.doctors].reverse());
    }
  }, [getDoctorAppointmentsSuccess]);

  useEffect(() => {
    if (getDoctorAppointmentsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorAppointmentsFailure]);

  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        mb={2}
      >
        <MDTypography variant="text" fontWeight="medium" textTransform="capitalize"></MDTypography>
      </MDBox>
      <div style={{ height: "370px", width: "100%" }}>
        <DataGrid
          rows={appointmentList}
          columns={appointmentColumns}
          disableSelectionOnClick
          pageSize={5}
          getRowId={(row) => row.aid}
          // getRowHeight={() => "auto"}
        />
      </div>
    </>
  );
}
export default Appointment;
