import React, { useEffect, useState } from "react";
import { Grid, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useStore from "./../store";
import affiliateService from "./../affliateService";
import Box from "@mui/material/Box";
import routes from "routes";

const AffiliateTable = () => {
  const affiliate = useStore((state) => state.affiliate);
  const setAffiliates = useStore((state) => state.setAffiliates);

  useEffect(() => {
    getAllAffiliates();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const getAllAffiliates = () => {
    affiliateService
      .getAllAffiliates()
      .then((response) => {
        console.log("response", response);
        setAffiliates(response);
      })
      .catch((error) => console.log(error));
  };
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-GB");
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const columns = [
    {
      field: "index",
      headerName: "No",
      align: "center",
      headerAlign: "center",
      width: 15,
    },
    {
      field: "user",
      headerName: "User",
      align: "center",
      headerAlign: "center",
      width: 150,
      renderCell: (params) => {
        return <div>{toTitleCase(params?.row?.user?.name)}</div>;
      },
    },
    {
      field: "referralCode",
      headerName: "Referral Code",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "affiliateAmount",
      headerName: "Affiliate Amount",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "referralAmount",
      headerName: "Referral Amount",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "minOrderValue",
      headerName: "Min Order Value",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
  ];

  const rows = affiliate.map((data, index) => ({
    ...data,
    index: index + 1,
    expiryDate: formatDate(data.expiryDate),
  }));
  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Affiliate Table
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              {rows.length === 0 ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <p>No affiliate records found.</p>
                </Box>
              ) : (
                <Box style={{ height: "100%", width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                    autoHeight
                  />
                </Box>
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
};

export default AffiliateTable;
