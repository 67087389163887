import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { FormControl } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addKiosk, updateKiosk, kioskSelector, resetKioskSuccess } from "store/reducers/kioskSlice";
import { useDispatch, useSelector } from "react-redux";

const AddEditKiosk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [kioskData, setKioskData] = useState({
    name: "",
    location: "",
    code: "",
    autoHeightAdjust: true,
    bypassOtp: true,
  });
  const [kioskId, setKioskId] = useState(location?.state?.kioskData?._id);
  const addKioskSuccess = useSelector(kioskSelector.addKioskSuccess);
  const addKioskFailure = useSelector(kioskSelector.addKioskFailure);
  const updateKioskSuccess = useSelector(kioskSelector.updateKioskSuccess);
  const updateKioskFailure = useSelector(kioskSelector.updateKioskFailure);

  const onchangeHandler = (e) => {
    setKioskData({ ...kioskData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setKioskData({
      name: location?.state?.kioskData?.name,
      location: location?.state?.kioskData?.location,
      code: location?.state?.kioskData?.code,
      autoHeightAdjust: location?.state?.kioskData?.autoHeightAdjust,
      bypassOtp: location?.state?.kioskData?.bypassOtp,
    });
  }, []);

  const handleSubmit = (value) => {
    if (value == "update") {
      let formData = {};
      formData.kioskId = kioskId;
      formData.kioskData = kioskData;
      dispatch(updateKiosk(formData));
    } else {
      dispatch(addKiosk(kioskData));
    }
  };

  useEffect(() => {
    if (addKioskSuccess) {
      Swal.fire({
        icon: "success",
        text: "Kiosk added successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetKioskSuccess());
      navigate("/kiosk");
    }
  }, [addKioskSuccess]);

  useEffect(() => {
    if (updateKioskSuccess) {
      Swal.fire({
        icon: "success",
        text: "Kiosk updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetKioskSuccess());
      navigate("/kiosk");
    }
  }, [updateKioskSuccess]);

  useEffect(() => {
    if (addKioskFailure || updateKioskFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addKioskFailure, updateKioskFailure]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card id="delete-account">
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <br />
                  <h4>
                    <center> Add Kiosk</center>
                  </h4>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    borderRadius="lg"
                    p={3}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox mb={1} lineHeight={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Name"
                              name="name"
                              value={kioskData?.name}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Location"
                              name="location"
                              value={kioskData?.location}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Code"
                              name="code"
                              value={kioskData?.code}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>Auto Height Adjust</InputLabel>
                              <Select
                                label="Auto Height Adjust"
                                name="autoHeightAdjust"
                                value={kioskData?.autoHeightAdjust}
                                onChange={onchangeHandler}
                                style={{ height: "3.1em" }}
                              >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>ByPass OTP</InputLabel>
                              <Select
                                label="ByPass OTP"
                                name="bypassOtp"
                                value={kioskData?.bypassOtp}
                                onChange={onchangeHandler}
                                style={{ height: "3.1em" }}
                              >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <br />

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <MDButton
                              fullWidth
                              variant="gradient"
                              color="info"
                              onClick={() => {
                                navigate("/kiosk");
                              }}
                            >
                              Cancel
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {location?.state?.kioskData ? (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                color="info"
                                onClick={() => handleSubmit("update")}
                              >
                                Update
                              </MDButton>
                            ) : (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                color="info"
                                onClick={() => handleSubmit("add")}
                              >
                                Submit
                              </MDButton>
                            )}
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AddEditKiosk;
