/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MDInput from "components/MDInput";
import moment from "moment";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { searchDoctors, getDoctorsList, doctorSelector } from "store/reducers/doctorSlice";
import routes from "routes";

function DoctorTable() {
  const dispatch = useDispatch();
  const [doctorDetail, setDoctorsDetail] = useState([]);
  const [page, setPage] = useState(JSON.parse(localStorage.getItem("PageNumber")));
  const [filterName, setFilterName] = useState("");
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const doctors = useSelector((state) => state?.doctor?.doctors);
  const getDoctorsListSuccess = useSelector(doctorSelector.getDoctorsListSuccess);
  const getDoctorsListFailure = useSelector(doctorSelector.getDoctorsListFailure);
  const doctorsData = useSelector((state) => state?.doctor?.doctorsData);
  const searchDoctorsSuccess = useSelector(doctorSelector.searchDoctorsSuccess);
  const searchDoctorsFailure = useSelector(doctorSelector.searchDoctorsFailure);

  useEffect(() => {
    fetchDoctorsList();
  }, []);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const doctorColumns = [
    { field: "id", headerName: "No", headerAlign: "center", align: "center", width: 100 },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => {
        return <div>{toTitleCase(params?.row?.name)}</div>;
      },
    },
    { field: "mobileNumber", headerName: "Mobile No", width: 150 },
    { field: "pincode", headerName: "Pincode", width: 100 },
    {
      field: "validTill",
      headerName: "Valid Till",
      width: 130,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY");
        return `${valueFormatted}`;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={new Date(params.row.validTill) > new Date() ? "Active" : "InActive"}
            color={new Date(params.row.validTill) > new Date() ? "primary" : "secondary"}
          />
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const handleclickDetails = () => {
          navigate("/doctordetails/" + params.row.did, { state: { data: params.row } });
        };
        return (
          <>
            <MDButton variant="gradient" onClick={handleclickDetails} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];
  const getRoleData =
    loginData &&
    loginData.roleData &&
    loginData.roleData.permissions.find((data) => data?.name.toLowerCase() === "doctor");
  const fetchDoctorsList = (page) => {
    dispatch(getDoctorsList(loginData?.token));
  };

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    if (doctors?.doctors?.length) {
      const doctorsCopy = [...doctors?.doctors];
      setDoctorsDetail(doctorsCopy.reverse());
      setIsLoading(false);
    }
  }, [doctors]);

  useEffect(() => {
    if (getDoctorsListFailure || searchDoctorsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorsListFailure, searchDoctorsFailure]);

  const searchDoctorsData = (filterName) => {
    dispatch(searchDoctors(filterName));
  };

  useEffect(() => {
    if (doctorsData?.doctors) {
      setDoctorsDetail(doctorsData?.doctors);
      setIsLoading(false);
    }
  }, [doctorsData]);

  const handlePageChange = (newPage) => {
    fetchDoctorsList(newPage);
  };
  return (
    <>
      <MDBox pt={6} pb={3}>
        <MDInput
          label="Search Doctor"
          style={{ postion: "relative", maxWidth: "235px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <MDButton
                  style={{ background: "transparent", marginRight: "-20px" }}
                  variant="gradient"
                  onClick={() =>
                    filterName.length ? searchDoctorsData(filterName) : fetchDoctorsList("0")
                  }
                >
                  <SearchIcon />
                </MDButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => setFilterName(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter" && filterName.length) {
              setIsLoading(true);
              searchDoctorsData(filterName);
            } else if (event.key === "Enter" && filterName.length === 0) {
              fetchDoctorsList("0");
            }
          }}
        />

        {getRoleData?.name === "doctor" ? (
          <MDButton
            style={{ float: "right" }}
            variant="gradient"
            color="info"
            onClick={() => {
              navigate("/add-edit-doctor");
            }}
          >
            <AddIcon /> Add New Doctor
          </MDButton>
        ) : (
          <div></div>
        )}

        <br />
        <br />
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Doctor's
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <DataGrid
                    //  checkboxSelection
                    rows={doctorDetail}
                    columns={doctorColumns}
                    pageSize={10}
                  />
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default DoctorTable;
