// chatSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const chatSlice = createSlice({
  name: "chats",
  initialState: {
    chats: [],
    isloading: false,
    updateChatModeSuccess: false,
    updateChatModeFailure: false,
    updateChatStatusSuccess: false,
    updateChatStatusFailure: false,
    getPatientChatsSuccess: false,
    getPatientChatsFailure: false,
    filterChatDataSuccess: false,
    filterChatDataFailure: false,
    searchChatPatientsSuccess: false,
    searchChatPatientsFailure: false,
    // Other initial state properties
  },

  reducers: {
    updateChatMode: (state, action) => {
      state.isloading = false;
    },
    updateChatModeSuccess: (state, action) => {
      state.chats = action.payload;
      state.isloading = true;
      state.updateChatModeSuccess = true;
    },
    updateChatModeFailure: (state) => {
      state.isloading = false;
      state.updateChatModeFailure = true;
    },
    updateChatStatus: (state, action) => {
      state.isloading = false;
    },
    updateChatStatusSuccess: (state, action) => {
      state.chats = action.payload;
      state.isloading = true;
      state.updateChatStatusSuccess = true;
    },
    updateChatStatusFailure: (state) => {
      state.isloading = false;
      state.updateChatStatusFailure = true;
    },
    getPatientChats: (state, action) => {
      state.isloading = false;
    },
    getPatientChatsSuccess: (state, action) => {
      state.patientchats = action.payload;
      state.isloading = true;
      state.getPatientChatsSuccess = true;
    },
    getPatientChatsFailure: (state) => {
      state.isloading = false;
      state.getPatientChatsFailure = true;
    },
    filterChatData: (state, action) => {
      state.isloading = false;
    },
    filterChatDataSuccess: (state, action) => {
      state.chats = action.payload;
      state.isloading = true;
      state.filterChatDataSuccess = true;
    },
    filterChatDataFailure: (state) => {
      state.isloading = false;
      state.filterChatDataFailure = true;
    },
    searchChatPatients: (state, action) => {
      state.isloading = false;
    },
    searchChatPatientsSuccess: (state, action) => {
      state.searchPatientchats = action.payload;
      state.isloading = true;
      state.searchChatPatientsSuccess = true;
    },
    searchChatPatientsFailure: (state) => {
      state.isloading = false;
      state.searchChatPatientsFailure = true;
    },
    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.chat;

export const chatSelector = {
  updateChatModeSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatModeSuccess
  ),
  updateChatModeFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatModeFailure
  ),
  updateChatStatusSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatStatusSuccess
  ),
  updateChatStatusFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatStatusFailure
  ),
  getPatientChatsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientChatsSuccess
  ),
  getPatientChatsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPatientChatsFailure
  ),
  filterChatDataSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.filterChatDataSuccess
  ),
  filterChatDataFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.filterChatDataFailure
  ),
  searchChatPatientsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchChatPatientsSuccess
  ),
  searchChatPatientsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchChatPatientsFailure
  ),

  // Add other selectors if needed
};

export const {
  updateChatMode,
  updateChatModeSuccess,
  updateChatModeFailure,
  updateChatStatus,
  updateChatStatusSuccess,
  updateChatStatusFailure,
  getPatientChats,
  getPatientChatsSuccess,
  getPatientChatsFailure,
  filterChatData,
  filterChatDataSuccess,
  filterChatDataFailure,
  searchChatPatients,
  searchChatPatientsSuccess,
  searchChatPatientsFailure,
} = chatSlice.actions;
export default chatSlice.reducer;
