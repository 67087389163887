/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SubscriptionDetails from "./components/subscriptionDetails";
function Subscriptions() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SubscriptionDetails />
      </DashboardLayout>
    </>
  );
}
export default Subscriptions;
