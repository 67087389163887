/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PatientsTestsMapping from "./components/testsMappingTable";
function TestsMapping() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <PatientsTestsMapping />
      </DashboardLayout>
    </>
  );
}
export default TestsMapping;
