/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlanDetails from "./components/plans";
function Plan() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <PlanDetails />
      </DashboardLayout>
    </>
  );
}
export default Plan;
