/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useState, useEffect } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AutoDeleteTwoToneIcon from "@mui/icons-material/AutoDeleteTwoTone";
import { Select } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { Grid, Paper, MenuItem } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangePicker from "@mui/lab/DateRangePicker";
import RangePicker from "react-range-picker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import moment from "moment";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  addClinicSlots,
  appointmentSelector,
  updateTimeSlots,
  getTimeSlots,
  getAvailableStatus,
  resetAddClinicSlotsSuccess,
} from "store/reducers/appointmentSlice";

function AddClinicSlots() {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [time1, setTime1] = useState("");
  const [time2, setTime2] = useState("");
  const locationParams = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(
    locationParams && locationParams.state && locationParams.state.isEditMode
      ? locationParams.state.isEditMode
      : ""
  );
  const dispatch = useDispatch();
  const { clinicDetails, doctorId, clinicId } = locationParams.state;
  const daysData = [
    {
      day: "Monday",
      timeSlots: [],
    },
    {
      day: "Tuesday",
      timeSlots: [],
    },
    {
      day: "Wednesday",
      timeSlots: [],
    },
    {
      day: "Thursday",
      timeSlots: [],
    },
    {
      day: "Friday",
      timeSlots: [],
    },
    {
      day: "Saturday",
      timeSlots: [],
    },
    {
      day: "Sunday",
      timeSlots: [],
    },
  ];
  const [array, setArray] = useState(daysData);
  const timeSlotsDropdown = [
    "8:00",
    "8:30",
    "9:00",
    "9:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }

  useEffect(() => {
    if (isEditMode === "true") {
      fetchTimeSlots(clinicId);
    }
  }, []);
  const [sameAsAboveIndex, setSameAsAboveIndex] = useState("");
  const [sameAsAboveDayData, setSameAsAboveDayData] = useState({});
  const [sameAsAbovePrevData, setSameAsAbovePrevData] = useState({});
  const appointments = useSelector((state) => state?.doctor?.appointments);
  const addClinicSlotsSuccess = useSelector(appointmentSelector.addClinicSlotsSuccess);
  const addClinicSlotsFailure = useSelector(appointmentSelector.addClinicSlotsFailure);
  const clinicSlots = useSelector((state) => state?.appointment?.clinicSlots);
  const getTimeSlotsSuccess = useSelector(appointmentSelector.getTimeSlotsSuccess);
  const getTimeSlotsFailure = useSelector(appointmentSelector.getTimeSlotsFailure);
  const updateTimeSlotsSuccess = useSelector(appointmentSelector.updateTimeSlotsSuccess);
  const updateTimeSlotsFailure = useSelector(appointmentSelector.updateTimeSlotsFailure);
  const availableStatusData = useSelector((state) => state?.appointment?.availableStatusData);
  const getAvailableStatusSuccess = useSelector(appointmentSelector.getAvailableStatusSuccess);
  const getAvailableStatusFailure = useSelector(appointmentSelector.getAvailableStatusFailure);

  useEffect(() => {
    let timeSlotsArray = [];

    if (Object.keys(sameAsAbovePrevData).length !== 0) {
      sameAsAbovePrevData.timeSlots.forEach((element) => {
        timeSlotsArray.push({ time: element.time });
      });
    }
    let newArray = [...array];
    newArray[sameAsAboveIndex] = {
      day: sameAsAboveDayData.day,
      _id: sameAsAboveDayData._id,
      date: sameAsAboveDayData.date,
      endDate: sameAsAboveDayData.endDate,
      startDate: sameAsAboveDayData.startDate,
      timeSlots: timeSlotsArray,
    };
    setArray(newArray);
  }, [sameAsAboveIndex, sameAsAboveDayData, sameAsAbovePrevData]);

  const handleChange = (e, itemData, mainIndex, slotIndex) => {
    e.preventDefault();
    let time1 = "";
    let time2 = "";
    if (e.target.name === "time1") {
      time1 =
        e.target.value === "8:00"
          ? "08:00"
          : e.target.value === "8:30"
          ? "08:30"
          : e.target.value === "9:00"
          ? "09:00"
          : e.target.value === "9:30"
          ? "09:30"
          : e.target.value;
      setTime1(time1);
    } else {
      time2 =
        e.target.value === "8:00"
          ? "08:00"
          : e.target.value === "8:30"
          ? "08:30"
          : e.target.value === "9:00"
          ? "09:00"
          : e.target.value === "9:30"
          ? "09:30"
          : e.target.value;
      setTime2(time2);
    }

    const index = e.target.id;
    let newData = itemData;
    if (e.target.name === "time1" && newData.timeSlots[slotIndex].time.length === 0) {
      newData.timeSlots[slotIndex].time = e.target.value;
    } else if (
      (e.target.name === "time2" && newData.timeSlots[slotIndex].time.length === 5) ||
      newData.timeSlots[slotIndex].time.length === 4
    ) {
      newData.timeSlots[slotIndex].time = `${newData.timeSlots[slotIndex].time}-${e.target.value}`;
    } else if (
      (e.target.name === "time1" && newData.timeSlots[slotIndex].time.length === 11) ||
      newData.timeSlots[slotIndex].time.length === 10
    ) {
      const newString = newData.timeSlots[slotIndex].time.substr(6);
      newData.timeSlots[slotIndex].time = `${e.target.value}-${newString}`;
    } else if (
      (e.target.name === "time2" && newData.timeSlots[slotIndex].time.length === 11) ||
      newData.timeSlots[slotIndex].time.length === 10
    ) {
      newData.timeSlots[slotIndex].time =
        newData.timeSlots[slotIndex].time.slice(0, -5) + e.target.value;
    }
    let newArr = [...array]; // copying the old datas array
    newArr[mainIndex] = newData; // replace e.target.value with whatever you want to change it to
    setArray(newArr);
  };

  const addNewSlot = (dayData, i) => {
    let newData = dayData;
    newData.timeSlots.push({
      time: "",
    });
    let newArr = [...array]; // copying the old datas array
    newArr[i] = newData;
    setArray(newArr);
  };

  //same as above
  const sameAsAbove = (dayData, index) => {
    let newArray = [...array];
    setSameAsAboveIndex(index);
    setSameAsAboveDayData(dayData);
    setSameAsAbovePrevData(newArray[index - 1]);
    setArray(newArray);
  };

  const handleSubmit = () => {
    const formData = {
      clinicId: clinicDetails._id,
      doctorId: doctorId,
      startDate: startDate,
      endDate: endDate,
      timeSlots: array,
      token: loginData.token,
    };
    dispatch(addClinicSlots(formData));
    for (const slotsArray of array) {
      for (const timesArray of slotsArray.timeSlots) {
        if (timesArray.time === "") {
          Swal.fire({
            icon: "error",
            text: "Time Slot Can Not Be Blank",
            showConfirmButton: false,
            timer: 1000,
          });
          return false;
        }
      }
    }
  };

  useEffect(() => {
    if (addClinicSlotsSuccess) {
      setArray(daysData);
      setStartDate();
      setEndDate();
      Swal.fire({
        icon: "success",
        text: "Slots Added Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate("/doctordetails/" + doctorId);
      dispatch(resetAddClinicSlotsSuccess());
    }
  }, [addClinicSlotsSuccess]);

  const handleUpdateSlots = () => {
    const formData = {
      clinicId: clinicDetails._id,
      startDate: startDate,
      endDate: endDate,
      timeSlots: array,
    };
    for (const slotsUpdatedArray of array) {
      for (const timesUpdatedArray of slotsUpdatedArray.timeSlots) {
        if (timesUpdatedArray.time === "") {
          Swal.fire({
            icon: "success",
            text: "Slot Updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          Swal.fire({
            icon: "error",
            text: "Slots Can Not Be Blank",
            showConfirmButton: false,
            timer: 1000,
          });
          return false;
        }
      }
    }
    dispatch(updateTimeSlots(formData));
  };

  useEffect(() => {
    if (updateTimeSlotsSuccess) {
      Swal.fire({
        icon: "success",
        text: "Slot Updated Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchTimeSlots(clinicId);
      navigate("/doctordetails/" + doctorId);
    }
  }, [updateTimeSlotsSuccess]);

  const getAvailableStatus = () => {
    dispatch(getAvailableStatus());
  };

  useEffect(() => {
    if (getAvailableStatusSuccess) {
      setDoctorDetail(availableStatusData?.data);
    }
  }, [getAvailableStatusSuccess]);

  //Delete slots
  const deleteSlot = (dayData, i, dayIndex) => {
    const slotsCopy = [...array];
    slotsCopy[dayIndex].timeSlots.splice(i, 1);
    setArray([...slotsCopy]);
    Swal.fire({
      icon: "success",
      text: "Slot Deleted Successfully",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  //Slot Range
  const [value, setValue] = React.useState([new Date(), new Date().getDate]);
  // console.log("value", value);
  const [startDate, setStartDate] = useState();
  const [responseData, setResponseData] = useState([]);
  const [endDate, setEndDate] = useState("");

  const onDateChanges = (date, date2) => {
    setValue(date);
    setStartDate(date);
    setEndDate(date2);

    let newArray = [];
    if (isEditMode === "true") {
      responseData.forEach((data) => {
        if (
          data.date > new Date(date[0]).toISOString() &&
          data.date < new Date(date[1]).toISOString()
        ) {
          newArray.push(data);
        }
      });
      setArray(newArray);
    }
  };

  const fetchTimeSlots = (clinicId, timeSlots) => {
    dispatch(getTimeSlots(clinicId));
  };

  useEffect(() => {
    if (getTimeSlotsSuccess) {
      setIsLoading(false);
      setArray(clinicSlots?.timeSlots);
      setResponseData(clinicSlots?.timeSlots);
      const filterData = clinicSlots?.timeSlots.filter((data) => data.startDate);
      const newValue = value;
      newValue[0] = filterData[0].startDate;
      newValue[1] = filterData[0].endDate;
      setValue(newValue);
      setEndDate(filterData[0].endDate);
      const timeRange = clinicSlots?.timeSlots.filter(
        (data) =>
          data.date >= new Date(new Date().setHours(0, 0, 0, 0)).toISOString() &&
          data.date < moment().add(7, "days")._d.toISOString()
      );
      setArray(timeRange);
    }
  }, [getTimeSlotsSuccess]);

  useEffect(() => {
    if (updateTimeSlotsFailure || addClinicSlotsFailure || getAvailableStatusFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateTimeSlotsFailure, addClinicSlotsFailure, getAvailableStatusFailure]);

  useEffect(() => {
    if (getTimeSlotsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate("/doctordetails/" + doctorId);
    }
  }, [getTimeSlotsFailure]);

  const addSlotRangeHandler = (dayValue, monthValue) => {
    const new_date = moment(moment(), "_d").add(dayValue, monthValue);
    value[1] = new_date;
    setValue(value);
    setEndDate(new_date._d);
    if (isEditMode === "fasle") {
      fetchTimeSlots(clinicId);
    }
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              {isEditMode === "false" ? (
                <MDTypography variant="h6" color="white">
                  Add Slots
                </MDTypography>
              ) : (
                <MDTypography variant="h6" color="white">
                  Update Slots
                </MDTypography>
              )}
            </MDBox>
            {/* <ListSubheader> */}
            <Grid pt={3} mb={2} mx={2}>
              <MDBox style={{ float: "right" }}>
                <RangePicker value={value} onDateSelected={onDateChanges} />
              </MDBox>
              <MDBox></MDBox>
            </Grid>
            <MDBox>
              <MDBox>
                <Paper>
                  <Grid>
                    {array.map((item, index) => {
                      return (
                        <>
                          <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            mx={2}
                            style={{ padding: "10px" }}
                          >
                            {item.day} &nbsp;
                            {isEditMode === "true" ? moment(item.date).format("DD-MM-YYYY") : ""}
                          </MDTypography>

                          <MDBox
                            component="li"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            bgColor={darkMode ? "transparent" : "grey-200"}
                            borderRadius="lg"
                            p={3}
                            mx={2}
                          >
                            <MDBox
                              style={{
                                padding: 8,
                                marginTop: "-15px",
                              }}
                              flexWrap="wrap"
                              width="100%"
                              display="flex"
                              elevation={8}
                              variant="outlined"
                            >
                              {isLoading && isEditMode === "true" ? (
                                <>
                                  <MDBox style={{ display: "flex", justifyContent: "center" }}>
                                    <CircularProgress color="inherit" />
                                  </MDBox>
                                </>
                              ) : (
                                <>
                                  {item.timeSlots.map((slot, i) => {
                                    let startTime = "";
                                    let endTime = "";
                                    if (slot && slot.time && slot.time.length) {
                                      startTime = slot.time.split("-")[0];
                                      endTime = slot.time.split("-")[1];
                                    }
                                    return (
                                      <Card
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          marginRight: ".5em",
                                          marginBlock: "5px",
                                        }}
                                      >
                                        <MDBox
                                          style={{
                                            width: "12em",
                                            padding: "0.5em",
                                            height: "2.8em",
                                            pointerEvents: slot.status === "Booked" ? "none" : "",
                                            opacity: slot.status === "Booked" ? 0.6 : "",
                                          }}
                                          component="li"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="flex-start"
                                          bgColor={darkMode ? "transparent" : "white-100"}
                                          borderRadius="lg"
                                          id={i}
                                        >
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Slot"
                                            name="time1"
                                            value={startTime}
                                            onChange={(e) => handleChange(e, item, index, i)}
                                          >
                                            {timeSlotsDropdown.map((slots) => {
                                              return <MenuItem value={slots}>{slots}</MenuItem>;
                                            })}
                                          </Select>
                                          {slot.status === "Booked" ? <b>B</b> : ""}
                                          &nbsp;
                                          <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={endTime}
                                            name="time2"
                                            label="Slot"
                                            onChange={(e) => handleChange(e, item, index, i)}
                                          >
                                            {timeSlotsDropdown.map((slots) => {
                                              return <MenuItem value={slots}>{slots}</MenuItem>;
                                            })}
                                          </Select>
                                          &nbsp;
                                          <MDButton
                                            onClick={(e) => deleteSlot(item, i, index, e)}
                                            variant="gradient"
                                            color="info"
                                          >
                                            <AutoDeleteTwoToneIcon />
                                          </MDButton>
                                        </MDBox>
                                      </Card>
                                    );
                                  })}
                                  &nbsp;
                                  <MDButton
                                    style={{
                                      borderRadius: "20px",
                                      display: "flex",
                                      flexDirection: "row",
                                      marginRight: ".5em",
                                      marginBlock: "5px",
                                    }}
                                    onClick={() => addNewSlot(item, index)}
                                    variant="gradient"
                                    color="info"
                                  >
                                    <AddIcon />
                                    <b>Slot</b>
                                  </MDButton>
                                  {index !== 0 ? (
                                    <MDButton
                                      style={{
                                        borderRadius: "20px",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginRight: ".5em",
                                        marginBlock: "5px",
                                        padding: "10px 15px",
                                      }}
                                      onClick={() => sameAsAbove(item, index)}
                                      variant="gradient"
                                      color="info"
                                    >
                                      <ContentCopyIcon />
                                      <b>Above Slots</b>
                                    </MDButton>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                            </MDBox>
                          </MDBox>
                        </>
                      );
                    })}
                  </Grid>
                  <MDBox style={{ textAlign: "center", padding: "10px" }}>
                    {isEditMode === "false" ? (
                      <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                        Submit
                      </MDButton>
                    ) : (
                      <MDButton variant="gradient" color="info" onClick={handleUpdateSlots}>
                        Update
                      </MDButton>
                    )}
                  </MDBox>
                </Paper>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </DashboardLayout>
    </>
  );
}
export default AddClinicSlots;
