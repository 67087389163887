/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import moment from "moment";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components

import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function PatientCard(props) {
  const navigate = useNavigate();

  const handleClick = (data) => {
    navigate("/patientdetails/" + data.sid);
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <>
      <Grid container spacing={3}>
        {props.data
          ? props.data.map((data) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={data.mobile}>
                  <MDBox mb={1.5}>
                    <Card>
                      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                        <MDBox
                          variant="gradient"
                          bgColor="info"
                          color={"success" === "light" ? "dark" : "white"}
                          coloredShadow="info"
                          borderRadius="xl"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="3rem"
                          height="4rem"
                          mt={-3}
                        >
                          <Icon fontSize="medium" color="inherit">
                            {data.name.charAt().toUpperCase()}
                          </Icon>
                        </MDBox>
                        <MDBox textAlign="right" lineHeight={1.25}>
                          <MDTypography variant="button" fontWeight="medium" color="text">
                            {data.mobile}
                          </MDTypography>
                          <MDTypography fontWeight="medium">{toTitleCase(data.name)}</MDTypography>
                        </MDBox>
                      </MDBox>
                      <Divider />
                      <MDBox pb={2} px={2}>
                        <MDTypography component="p" variant="button" color="text" display="flex">
                          <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color="success"
                          >
                            {"Created " + moment(data.createdAt).fromNow()}
                          </MDTypography>
                        </MDTypography>
                        <br />
                        <center>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            onClick={() => handleClick(data)}
                            color="info"
                          >
                            Details
                          </MDButton>
                        </center>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Grid>
              );
            })
          : ""}
      </Grid>
    </>
  );
}
export default PatientCard;
