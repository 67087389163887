// ddAccountSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const ddAccountSlice = createSlice({
  name: "ddAccounts",
  initialState: {
    ddAccounts: [],
    isloading: false,
    addCoinsToMainAccountSuccess: false,
    addCoinsToMainAccountFailure: false,
    addCoinsSuccess: false,
    addCoinsFailure: false,
    getDentaldostAccountSuccess: false,
    getDentaldostAccountFailure: false,
    removeCoinsSuccess: false,
    removeCoinsFailure: false,
    // Other initial state properties
  },

  reducers: {
    addCoinsToMainAccount: (state, action) => {
      state.isloading = false;
    },
    addCoinsToMainAccountSuccess: (state, action) => {
      state.ddAccounts = action.payload;
      state.isloading = true;
      state.addCoinsToMainAccountSuccess = true;
    },
    addCoinsToMainAccountFailure: (state) => {
      state.isloading = false;
      state.addCoinsToMainAccountFailure = true;
    },
    addCoins: (state, action) => {
      state.isloading = false;
    },
    addCoinsSuccess: (state, action) => {
      state.ddAccounts = action.payload;
      state.isloading = true;
      state.addCoinsSuccess = true;
    },
    addCoinsFailure: (state) => {
      state.isloading = false;
      state.addCoinsFailure = true;
    },
    getDentaldostAccount: (state, action) => {
      state.isloading = false;
    },
    getDentaldostAccountSuccess: (state, action) => {
      state.dentaldostAccount = action.payload;
      state.isloading = true;
      state.getDentaldostAccountSuccess = true;
    },
    getDentaldostAccountFailure: (state) => {
      state.isloading = false;
      state.getDentaldostAccountFailure = true;
    },
    removeCoins: (state, action) => {
      state.isloading = false;
    },
    removeCoinsSuccess: (state, action) => {
      state.ddAccounts = action.payload;
      state.isloading = true;
      state.removeCoinsSuccess = true;
    },
    removeCoinsFailure: (state) => {
      state.isloading = false;
      state.removeCoinsFailure = true;
    },
    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.ddAccount;

export const ddAccountSelector = {
  addCoinsToMainAccountSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addCoinsToMainAccountSuccess
  ),
  addCoinsToMainAccountFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addCoinsToMainAccountFailure
  ),
  addCoinsSuccess: createSelector(selectKiosk, (kioskState) => kioskState.addCoinsSuccess),
  addCoinsFailure: createSelector(selectKiosk, (kioskState) => kioskState.addCoinsFailure),
  getDentaldostAccountSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getDentaldostAccountSuccess
  ),
  getDentaldostAccountFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getDentaldostAccountFailure
  ),
  removeCoinsSuccess: createSelector(selectKiosk, (kioskState) => kioskState.removeCoinsSuccess),
  removeCoinsFailure: createSelector(selectKiosk, (kioskState) => kioskState.removeCoinsFailure),

  // Add other selectors if needed
};

export const {
  addCoinsToMainAccount,
  addCoinsToMainAccountSuccess,
  addCoinsToMainAccountFailure,
  addCoins,
  addCoinsSuccess,
  addCoinsFailure,
  getDentaldostAccount,
  getDentaldostAccountSuccess,
  getDentaldostAccountFailure,
  removeCoins,
  removeCoinsSuccess,
  removeCoinsFailure,
} = ddAccountSlice.actions;
export default ddAccountSlice.reducer;
