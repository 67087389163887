// appointmentSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const appointmentSlice = createSlice({
  name: "appointments",
  initialState: {
    appointments: [],
    isloading: false,
    removeAppointmentSuccess: false,
    removeAppointmentFailure: false,
    bookAppointmentSuccess: false,
    bookAppointmentFailure: false,
    updateTimeSlotStatusSuccess: false,
    updateTimeSlotStatusFailure: false,
    getAppointmentSlotsSuccess: false,
    getAppointmentSlotsFailure: false,
    getAppointmentDetailsSuccess: false,
    getAppointmentDetailsFailure: false,
    updateAppointmentSuccess: false,
    updateAppointmentFailure: false,
    sendInvoiceSuccess: false,
    sendInvoiceFailure: false,
    addClinicSlotsSuccess: false,
    addClinicSlotsFailure: false,
    updateTimeSlotsSuccess: false,
    updateTimeSlotsFailure: false,
    getAvailableStatusSuccess: false,
    getAvailableStatusFailure: false,
    getTimeSlotsSuccess: false,
    getTimeSlotsFailure: false,
    addAppointmentSuccess: false,
    addAppointmentFailure: false,
    // Other initial state properties
  },

  reducers: {
    removeAppointment: (state, action) => {
      state.isloading = false;
    },
    removeAppointmentSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.removeAppointmentSuccess = true;
    },
    removeAppointmentFailure: (state) => {
      state.isloading = false;
      state.removeAppointmentFailure = true;
    },
    bookAppointment: (state, action) => {
      state.isloading = false;
    },
    bookAppointmentSuccess: (state, action) => {
      state.bookAppointmentData = action.payload;
      state.isloading = true;
      state.bookAppointmentSuccess = true;
    },
    bookAppointmentFailure: (state) => {
      state.isloading = false;
      state.bookAppointmentFailure = true;
    },
    updateTimeSlotStatus: (state, action) => {
      state.isloading = false;
    },
    updateTimeSlotStatusSuccess: (state, action) => {
      state.updateTimeSlotData = action.payload;
      state.isloading = true;
      state.updateTimeSlotStatusSuccess = true;
    },
    updateTimeSlotStatusFailure: (state) => {
      state.isloading = false;
      state.updateTimeSlotStatusFailure = true;
    },
    getAppointmentSlots: (state, action) => {
      state.isloading = false;
    },
    getAppointmentSlotsSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.getAppointmentSlotsSuccess = true;
    },
    getAppointmentSlotsFailure: (state) => {
      state.isloading = false;
      state.getAppointmentSlotsFailure = true;
    },
    getAppointmentDetails: (state, action) => {
      state.isloading = false;
    },
    getAppointmentDetailsSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.getAppointmentDetailsSuccess = true;
    },
    getAppointmentDetailsFailure: (state) => {
      state.isloading = false;
      state.getAppointmentDetailsFailure = true;
    },
    updateAppointment: (state, action) => {
      state.isloading = false;
    },
    updateAppointmentSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.updateAppointmentSuccess = true;
    },
    updateAppointmentFailure: (state) => {
      state.isloading = false;
      state.updateAppointmentFailure = true;
    },
    sendInvoice: (state, action) => {
      state.isloading = false;
    },
    sendInvoiceSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.sendInvoiceSuccess = true;
    },
    sendInvoiceFailure: (state) => {
      state.isloading = false;
      state.sendInvoiceFailure = true;
    },
    addClinicSlots: (state, action) => {
      state.isloading = false;
    },
    addClinicSlotsSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.addClinicSlotsSuccess = true;
    },
    addClinicSlotsFailure: (state) => {
      state.isloading = false;
      state.addClinicSlotsFailure = true;
    },
    resetAddClinicSlotsSuccess: (state) => {
      state.addClinicSlotsSuccess = false;
    },
    updateTimeSlots: (state, action) => {
      state.isloading = false;
    },
    updateTimeSlotsSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.updateTimeSlotsSuccess = true;
    },
    updateTimeSlotsFailure: (state) => {
      state.isloading = false;
      state.updateTimeSlotsFailure = true;
    },
    getAvailableStatus: (state, action) => {
      state.isloading = false;
    },
    getAvailableStatusSuccess: (state, action) => {
      state.availableStatusData = action.payload;
      state.isloading = true;
      state.getAvailableStatusSuccess = true;
    },
    getAvailableStatusFailure: (state) => {
      state.isloading = false;
      state.getAvailableStatusFailure = true;
    },
    getTimeSlots: (state, action) => {
      state.isloading = false;
    },
    getTimeSlotsSuccess: (state, action) => {
      state.clinicSlots = action.payload;
      state.isloading = true;
      state.getTimeSlotsSuccess = true;
    },
    getTimeSlotsFailure: (state) => {
      state.isloading = false;
      state.getTimeSlotsFailure = true;
    },
    addAppointment: (state, action) => {
      state.isloading = false;
    },
    resetAddAppointmentSuccess: (state) => {
      state.addAppointmentSuccess = false;
    },
    addAppointmentSuccess: (state, action) => {
      state.appointments = action.payload;
      state.isloading = true;
      state.addAppointmentSuccess = true;
    },
    addAppointmentFailure: (state) => {
      state.isloading = false;
      state.addAppointmentFailure = true;
    },
    // Other reducers if needed
  },
});

const selectAppointment = (state) => state.appointment;

export const appointmentSelector = {
  removeAppointmentSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.removeAppointmentSuccess
  ),
  removeAppointmentFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.removeAppointmentFailure
  ),
  bookAppointmentSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.bookAppointmentSuccess
  ),
  bookAppointmentFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.bookAppointmentFailure
  ),
  updateTimeSlotStatusSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateTimeSlotStatusSuccess
  ),
  updateTimeSlotStatusFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateTimeSlotStatusFailure
  ),
  getAppointmentSlotsSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAppointmentSlotsSuccess
  ),
  getAppointmentSlotsFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAppointmentSlotsFailure
  ),
  getAppointmentDetailsSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAppointmentDetailsSuccess
  ),
  getAppointmentDetailsFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAppointmentDetailsFailure
  ),
  updateAppointmentSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateAppointmentSuccess
  ),
  updateAppointmentFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateAppointmentFailure
  ),
  sendInvoiceSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.sendInvoiceSuccess
  ),
  sendInvoiceFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.sendInvoiceFailure
  ),
  addClinicSlotsSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.addClinicSlotsSuccess
  ),
  addClinicSlotsFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.addClinicSlotsFailure
  ),
  updateTimeSlotsSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateTimeSlotsSuccess
  ),
  updateTimeSlotsFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.updateTimeSlotsFailure
  ),
  getAvailableStatusSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAvailableStatusSuccess
  ),
  getAvailableStatusFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getAvailableStatusFailure
  ),
  getTimeSlotsSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getTimeSlotsSuccess
  ),
  getTimeSlotsFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.getTimeSlotsFailure
  ),

  addAppointmentSuccess: createSelector(
    selectAppointment,
    (kioskState) => kioskState.addAppointmentSuccess
  ),
  addAppointmentFailure: createSelector(
    selectAppointment,
    (kioskState) => kioskState.addAppointmentFailure
  ),

  // Add other selectors if needed
};

export const {
  removeAppointment,
  removeAppointmentSuccess,
  removeAppointmentFailure,
  bookAppointment,
  bookAppointmentSuccess,
  bookAppointmentFailure,
  updateTimeSlotStatus,
  updateTimeSlotStatusSuccess,
  updateTimeSlotStatusFailure,
  getAppointmentSlots,
  getAppointmentSlotsSuccess,
  getAppointmentSlotsFailure,
  getAppointmentDetails,
  getAppointmentDetailsSuccess,
  getAppointmentDetailsFailure,
  updateAppointment,
  updateAppointmentSuccess,
  updateAppointmentFailure,
  sendInvoice,
  sendInvoiceSuccess,
  sendInvoiceFailure,
  addClinicSlots,
  addClinicSlotsSuccess,
  addClinicSlotsFailure,
  updateTimeSlots,
  updateTimeSlotsSuccess,
  updateTimeSlotsFailure,
  getAvailableStatus,
  getAvailableStatusSuccess,
  getAvailableStatusFailure,
  getTimeSlots,
  getTimeSlotsSuccess,
  getTimeSlotsFailure,
  addAppointment,
  addAppointmentSuccess,
  addAppointmentFailure,
  resetAddAppointmentSuccess,
  resetAddClinicSlotsSuccess,
} = appointmentSlice.actions;
export default appointmentSlice.reducer;
