import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CouponsTable from "./components/couponsTable";
import React from "react";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

const Coupon = (props) => {
  const navigate = useNavigate();
  const onCLickHandler = () => {
    // add-edit-coupon
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <br />
      <MDButton variant="gradient" color="info" onClick={() => navigate("/add-edit-coupon")}>
        + Add New Coupon
      </MDButton>
      <CouponsTable />
    </DashboardLayout>
  );
};

export default Coupon;
