/* eslint-disable */
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Agent from "layouts/agent";
import Patient from "layouts/patient";
import FollowUp from "layouts/followup";
import FollowUpReminder from "layouts/followupReminder";
import OralType from "layouts/oralType";
import VideoConsultation from "layouts/videoConsultation";
import SendBulkMessages from "layouts/sendBulkMessages";
import CreateUser from "layouts/agent/components/create-user";
import AddPatient from "layouts/patient/components/addPatient";
import VideoCall from "layouts/videoConsultation/components/PatientsList/VideoCall";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
// @mui icons
import Icon from "@mui/material/Icon";
import PatientDetails from "layouts/patient/components/patientDetails";
import TestDetails from "layouts/patient/components/testDetails";
import Report from "layouts/patient/components/Report/report";
import BookAppointment from "layouts/patient/components/bookAppointment";
import AddEditFollowUp from "layouts/patient/components/addEditFollowUp";
import ViewAppointment from "layouts/patient/components/viewAppointment";
import Doctor from "layouts/doctor";
import DoctorDetails from "layouts/doctor/components/doctorDetails";
import AddEditDoctor from "layouts/doctor/components/addEditDoctor";
import AddClinicSlots from "layouts/doctor/components/AddClinicSlots";
import AddVideoConsultationSlots from "layouts/agent/components/AddVideoConsultationSlots";
import Chat from "layouts/chat";
import DdAccount from "layouts/ddAccount";
import Coupons from "layouts/coupon";
import Kiosk from "layouts/kiosk";
import AddEditKiosk from "layouts/kiosk/components/addEditKiosk";
import PatientsTests from "layouts/agentTests";
import AddEditAppointment from "layouts/patient/components/addEditAppoinment";
import TestsMapping from "layouts/testsMapping";
import Plan from "layouts/plan";
import AddEditSubscriptionPlan from "layouts/plan/components/addEditSubscritionPlans";
import Subscriptions from "layouts/subscription";
import AnnotationsReview from "layouts/annotationReview";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import InAppChat from "layouts/inAppChat";
import AddEditCoupon from "layouts/coupon/components/addEditCoupon";
import Affiliate from "layouts/affiliate";
import KioskPatients from "layouts/kioskpatient";

const loginData = JSON.parse(localStorage.getItem("loginData"));
console.log("role", loginData?.roleData?.roleName);
const routes = [
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        component: <Dashboard />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Patients",
        key: "patients",
        icon: <Icon fontSize="small">accessible_forward</Icon>,
        route: "/patients",
        component: <Patient />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Doctors",
        key: "doctor",
        icon: <Icon fontSize="small">group_add_icon</Icon>,
        route: "/doctor",
        component: <Doctor />,
      }
    : "",

  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Internal Doctors",
        key: "agent",
        icon: <Icon fontSize="small">support_agent</Icon>,
        route: "/agent",
        component: <Agent />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Followup",
        key: "followup",
        icon: <Icon fontSize="small">event</Icon>,
        route: "/followup",
        component: <FollowUp />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Reminder",
        key: "followup_reminder",
        icon: <Icon fontSize="small">event</Icon>,
        route: "/followup_reminder",
        component: <FollowUpReminder />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "My Patients Tests",
        key: "patients-tests",
        icon: <Icon fontSize="small">list_alt</Icon>,
        route: "/patients-tests",
        component: <PatientsTests />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Pending Tests",
        key: "patients-tests-mapping",
        icon: <Icon fontSize="small">pending_actions</Icon>,
        route: "/patients-tests-mapping",
        component: <TestsMapping />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Video-Consultation",
        key: "video_Consultation",
        icon: <Icon fontSize="small">queue_play_next</Icon>,
        route: "/video_Consultation",
        component: <VideoConsultation />,
      }
    : "",
  // loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
  //   ? {
  //       type: "collapse",
  //       name: "Chat",
  //       key: "chatweb",
  //       icon: <Icon fontSize="small">chat</Icon>,
  //       route: "/chatweb",
  //       component: <Chat />,
  //     }
  //   : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "In App Chat",
        key: "in-app-chat",
        icon: <Icon fontSize="small">app_shortcut</Icon>,
        route: "/in-app-chat",
        component: <InAppChat />,
      }
    : "",
  // loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
  //   ? {
  //       type: "collapse",
  //       name: "Send Bulk Messages",
  //       key: "sendmessage",
  //       icon: <Icon fontSize="small">sms</Icon>,
  //       route: "/sendmessage",
  //       component: <SendBulkMessages />,
  //     }
  //   : "",
  loginData?.roleData?.roleName.toLowerCase() !== "agent" &&
  loginData?.roleData?.roleName.toLowerCase() !== "reviewer" &&
  loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Plans",
        key: "plans",
        icon: <Icon fontSize="small">dvr</Icon>,
        route: "/plans",
        component: <Plan />,
      }
    : "",
  loginData?.roleData?.roleName !== "reviewer" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Subscriptions",
        key: "subscriptions",
        icon: <Icon fontSize="small">subscriptions</Icon>,
        route: "/subscriptions",
        component: <Subscriptions />,
      }
    : "",
  // loginData?.roleData?.roleName !== "agent" && loginData?.roleData?.roleName !== "partner"
  //   ? {
  //       type: "collapse",
  //       name: "AnnotationsReview",
  //       key: "annotations-review",
  //       icon: <Icon fontSize="small">Annotations Review</Icon>,
  //       route: "/annotations-review",
  //       component: <AnnotationsReview />,
  //     }
  //   : "",
  loginData?.roleData?.roleName !== "agent" && loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Affiliate",
        key: "affiliate",
        icon: <Icon fontSize="small">people_alt</Icon>,
        route: "/affiliate",
        component: <Affiliate />,
      }
    : "",
  loginData?.roleData?.roleName.toLowerCase() !== "agent" &&
  loginData?.roleData?.roleName.toLowerCase() !== "reviewer" &&
  loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "DD Account",
        key: "dd-account",
        icon: <Icon fontSize="small">account_balance_wallet</Icon>,
        route: "/dd-account",
        component: <DdAccount />,
      }
    : "",
  loginData?.roleData?.roleName.toLowerCase() !== "agent" &&
  loginData?.roleData?.roleName.toLowerCase() !== "reviewer" &&
  loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Coupons",
        key: "coupons",
        icon: <Icon fontSize="small">Coupon</Icon>,
        route: "/coupons",
        component: <Coupons />,
      }
    : "",
  loginData?.roleData?.roleName.toLowerCase() !== "agent" &&
  loginData?.roleData?.roleName.toLowerCase() !== "reviewer" &&
  loginData?.roleData?.roleName !== "partner"
    ? {
        type: "collapse",
        name: "Kiosk",
        key: "kiosk",
        icon: <Icon fontSize="small">Kiosk</Icon>,
        route: "/kiosk",
        component: <Kiosk />,
      }
    : "",
  { route: "/add-edit-kiosk", component: <AddEditKiosk /> },
  {
    route: "/add-edit-coupon",
    component: <AddEditCoupon />,
  },
  {
    route: "/videoCall",
    component: <VideoCall />,
  },
  {
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    route: "/add-edit-user",
    component: <CreateUser />,
  },
  {
    route: "/add-edit-patient",
    component: <AddPatient />,
  },
  {
    route: "/add-edit-plans",
    component: <AddEditSubscriptionPlan />,
  },
  {
    route: "/patientdetails/:patientId",
    component: <PatientDetails />,
  },
  {
    route: "/testdetails/:testId",
    component: <TestDetails />,
  },
  {
    route: "/report/:testId",
    component: <Report />,
  },
  {
    route: "/book-appointment",
    component: <BookAppointment />,
  },
  {
    route: "/add-edit-followup",
    component: <AddEditFollowUp />,
  },
  {
    route: "/edit-followup/:followUpId",
    component: <AddEditFollowUp />,
  },
  {
    route: "/add-appointment",
    component: <AddEditAppointment />,
  },
  {
    route: "/view-appointment/:appointmentId",
    component: <ViewAppointment />,
  },
  {
    route: "/doctordetails/:doctorId",
    component: <DoctorDetails />,
  },
  {
    route: "/add-edit-doctor",
    component: <AddEditDoctor />,
  },
  {
    route: "/clinic-slots",
    component: <AddClinicSlots />,
  },
  {
    route: "/video-consultation-slots",
    component: <AddVideoConsultationSlots />,
  },
];

export default routes;
