/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAppointment,
  sendInvoice,
  appointmentSelector,
} from "store/reducers/appointmentSlice";
import {
  addAggresiveFollowUp,
  followUpReminderSelector,
} from "store/reducers/followUpReminderSlice";
import { getAppointmentList, patientDetailsSelector } from "store/reducers/patientdetailsSlice";

function Appointment(props) {
  const navigate = useNavigate();
  const [appointmentList, setAppointmentList] = useState([]);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const dispatch = useDispatch();
  const removeAppointmentSuccess = useSelector(appointmentSelector.removeAppointmentSuccess);
  const removeAppointmentFailure = useSelector(appointmentSelector.removeAppointmentFailure);
  const sendInvoiceSuccess = useSelector(appointmentSelector.sendInvoiceSuccess);
  const sendInvoiceFailure = useSelector(appointmentSelector.sendInvoiceFailure);
  const addAggresiveFollowUpSuccess = useSelector(
    followUpReminderSelector.addAggresiveFollowUpSuccess
  );
  const addAggresiveFollowUpFailure = useSelector(
    followUpReminderSelector.addAggresiveFollowUpFailure
  );
  const appointmentListData = useSelector((state) => state?.patientDetails?.appointmentList);
  const getAppointmentListSuccess = useSelector(patientDetailsSelector.getAppointmentListSuccess);
  const getAppointmentListFailure = useSelector(patientDetailsSelector.getAppointmentListFailure);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const appointmentColumns = [
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (data) => {
        return <div>{data.row.title.length ? data.row.title : "N/A"}</div>;
      },
    },
    {
      field: "startTime",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return data?.row?.startTime ? moment(data.row.startTime).format("DD/MM/YYYY") : "N/A";
      },
    },
    {
      field: "slotsTime",
      headerName: "Time",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "doctorName",
      headerName: "Doctor Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => {
        return <div>{toTitleCase(params.row.doctorName)}</div>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 400,
      renderCell: (params) => {
        const onRemoveClick = () => {
          handleRemoveAppointment(params);
        };
        const onViewClick = () => {
          navigate("/view-appointment/" + params.row.aid, {
            state: { patientId: props.patientId },
          });
        };
        const onSendInvoiceClick = () => {
          handleSendInvoice(params.row.aid);
        };
        return (
          <>
            <MDButton variant="gradient" onClick={onViewClick} color="info">
              View
            </MDButton>
            &nbsp;&nbsp;
            <MDButton variant="gradient" onClick={onRemoveClick} color="info">
              Remove
            </MDButton>
            &nbsp;&nbsp;
            <MDButton variant="gradient" onClick={onSendInvoiceClick} color="info">
              Send Invoice
            </MDButton>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAppointmentList(props.patientId);
  }, []);

  const handleRemoveAppointment = (datavalue) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeAppointment(datavalue.id));
      }
    });
  };

  useEffect(() => {
    if (removeAppointmentSuccess) {
      Swal.fire({
        icon: "success",
        text: "Appointment removed successfully",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }, [removeAppointmentSuccess]);

  useEffect(() => {
    if (removeAppointmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeAppointmentFailure]);

  const handleSendInvoice = async (appointmentId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(sendInvoice(appointmentId, loginData.token));
      }
    });
  };

  useEffect(() => {
    if (sendInvoiceSuccess) {
      Swal.fire({
        icon: "success",
        text: "Invoice sent",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendInvoiceSuccess]);

  useEffect(() => {
    if (sendInvoiceFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendInvoiceFailure]);

  const handleClickAggresiveFollowUp = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(addAggresiveFollowUp(props.patientId, loginData.token));
      }
    });
  };

  useEffect(() => {
    if (addAggresiveFollowUpSuccess) {
      Swal.fire({
        icon: "success",
        text: "FollowUp reminder added successfully",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }, [addAggresiveFollowUpSuccess]);

  useEffect(() => {
    if (addAggresiveFollowUpFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addAggresiveFollowUpFailure]);

  const handleClickBookAppointment = () => {
    navigate("/book-appointment", { state: { patientId: props.patientId } });
  };

  const handleClickAddAppointment = () => {
    navigate("/add-appointment", {
      state: {
        patientId: props.patientId,
      },
    });
  };

  const fetchAppointmentList = (patientId) => {
    dispatch(getAppointmentList(patientId, loginData.token));
  };

  useEffect(() => {
    if (appointmentListData?.appointments) {
      setAppointmentList([...appointmentListData?.appointments].reverse());
    }
  }, [appointmentListData]);

  useEffect(() => {
    if (getAppointmentListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAppointmentListFailure]);

  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        mb={2}
      >
        <MDTypography variant="text" fontWeight="medium" textTransform="capitalize"></MDTypography>

        <MDBox
          display="flex"
          alignItems="center"
          mt={{ xs: 2, sm: 0.1 }}
          ml={{ xs: -1.5, sm: 0.1 }}
        >
          <MDBox mr={1} style={{ float: "right" }}>
            <MDButton variant="gradient" onClick={handleClickAggresiveFollowUp} color="info">
              <AddIcon /> Add Aggresive FollowUp
            </MDButton>
            &nbsp;&nbsp;
            <MDButton variant="gradient" onClick={handleClickBookAppointment} color="info">
              <AddIcon /> Book Appointment
            </MDButton>
            &nbsp;&nbsp;
            <MDButton variant="gradient" onClick={handleClickAddAppointment} color="info">
              <AddIcon /> Add Appointment
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <div style={{ height: "370px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => "auto"}
          rows={appointmentList}
          columns={appointmentColumns}
          disableSelectionOnClick
          pageSize={5}
          getRowId={(row) => row.aid}
        />
      </div>
    </>
  );
}
export default Appointment;
