// couponSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const couponSlice = createSlice({
  name: "coupons",
  initialState: {
    coupons: [],
    isloading: false,
    addCouponSuccess: false,
    deleteCouponSuccess: false,
    updateCouponSuccess: false,
    addCouponFailure: false,
    deleteCouponFailure: false,
    updateCouponFailure: false,
    // Other initial state properties
  },
  reducers: {
    resetAddCouponSuccess: (state) => {
      state.addCouponSuccess = false;
    },
    resetUpdateCouponSuccess: (state) => {
      state.updateCouponSuccess = false;
    },
    getAllCoupons: (state, action) => {
      state.isloading = false;
    },
    getCouponSuccess: (state, action) => {
      state.coupons = action.payload;
      state.isloading = true;
    },
    getCouponFailure: (state) => {
      state.isloading = false;
    },

    deleteCoupon: (state, action) => {
      state.isloading = false;
    },
    deleteCouponSuccess: (state, action) => {
      state.coupons = action.payload;
      state.isloading = true;
      state.deleteCouponSuccess = true;
    },
    deleteCouponFailure: (state) => {
      state.isloading = false;
      state.deleteCouponFailure = true;
    },
    addCoupon: (state, action) => {
      state.isloading = false;
    },
    addCouponSuccess: (state, action) => {
      state.coupons = action.payload;
      state.isloading = true;
      state.addCouponSuccess = true;
    },
    addCouponFailure: (state) => {
      state.isloading = false;
      state.addCouponFailure = true;
    },
    updateCoupon: (state, action) => {
      state.isloading = false;
    },
    updateCouponSuccess: (state, action) => {
      state.coupons = action.payload;
      state.isloading = true;
      state.updateCouponSuccess = true;
    },
    updateCouponFailure: (state) => {
      state.isloading = false;
      state.updateCouponFailure = true;
    },
    // Other reducers if needed
  },
});

const selectCoupon = (state) => state.coupon;

export const couponSelector = {
  addCouponSuccess: createSelector(selectCoupon, (couponState) => couponState.addCouponSuccess),
  addCouponFailure: createSelector(selectCoupon, (couponState) => couponState.addCouponFailure),
  deleteCouponSuccess: createSelector(
    selectCoupon,
    (couponState) => couponState.deleteCouponSuccess
  ),
  deleteCouponFailure: createSelector(
    selectCoupon,
    (couponState) => couponState.deleteCouponFailure
  ),
  updateCouponSuccess: createSelector(
    selectCoupon,
    (couponState) => couponState.updateCouponSuccess
  ),
  updateCouponFailure: createSelector(
    selectCoupon,
    (couponState) => couponState.updateCouponFailure
  ),
  // Add other selectors if needed
};

export const {
  getAllCoupons,
  getCouponSuccess,
  getCouponFailure,
  deleteCoupon,
  deleteCouponSuccess,
  deleteCouponFailure,
  addCoupon,
  addCouponSuccess,
  addCouponFailure,
  updateCoupon,
  updateCouponSuccess,
  updateCouponFailure,
  resetAddCouponSuccess,
  resetUpdateCouponSuccess,
} = couponSlice.actions;
export default couponSlice.reducer;
