import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const videoConsultationSlice = createSlice({
  name: "videoConsultations",
  initialState: {
    videoConsultations: [],
    isloading: false,
    getPatientsListSuccess: false,
    getPatientsListFailure: false,
    updateVideoConsultationStatusSuccess: false,
    updateVideoConsultationStatusFailure: false,
    removeTokenForVideoConsultationSuccess: false,
    removeTokenForVideoConsultationFailure: false,
    generateVideoCallSuccess: false,
    generateVideoCallFailure: false,
    reconnectLastVideoCallSuccess: false,
    reconnectLastVideoCallFailure: false,
    addVideoConsultationSlotsSuccess: false,
    addVideoConsultationSlotsFailure: false,
    // Other initial state properties
  },

  reducers: {
    getPatientsList: (state, action) => {
      state.isloading = false;
    },
    getPatientsListSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.getPatientsListSuccess = true;
    },
    getPatientsListFailure: (state) => {
      state.isloading = false;
      state.getPatientsListFailure = true;
    },
    updateVideoConsultationStatus: (state, action) => {
      state.isloading = false;
    },
    updateVideoConsultationStatusSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.updateVideoConsultationStatusSuccess = true;
    },
    updateVideoConsultationStatusFailure: (state) => {
      state.isloading = false;
      state.updateVideoConsultationStatusFailure = true;
    },
    removeTokenForVideoConsultation: (state, action) => {
      state.isloading = false;
    },
    removeTokenForVideoConsultationSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.removeTokenForVideoConsultationSuccess = true;
    },
    removeTokenForVideoConsultationFailure: (state) => {
      state.isloading = false;
      state.removeTokenForVideoConsultationFailure = true;
    },
    generateVideoCall: (state, action) => {
      state.isloading = false;
    },
    generateVideoCallSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.generateVideoCallSuccess = true;
    },
    generateVideoCallFailure: (state) => {
      state.isloading = false;
      state.generateVideoCallFailure = true;
    },

    reconnectLastVideoCall: (state, action) => {
      state.isloading = false;
    },
    reconnectLastVideoCallSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.reconnectLastVideoCallSuccess = true;
    },
    reconnectLastVideoCallFailure: (state) => {
      state.isloading = false;
      state.reconnectLastVideoCallFailure = true;
    },
    addVideoConsultationSlots: (state, action) => {
      state.isloading = false;
    },
    addVideoConsultationSlotsSuccess: (state, action) => {
      state.videoConsultations = action.payload;
      state.isloading = true;
      state.addVideoConsultationSlotsSuccess = true;
    },
    addVideoConsultationSlotsFailure: (state) => {
      state.isloading = false;
      state.addVideoConsultationSlotsFailure = true;
    },
    // Other reducers if needed
  },
});

const selectVideoConsultation = (state) => state.videoConsultation;

export const videoConsultationSelector = {
  getPatientsListSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.getPatientsListSuccess
  ),
  getPatientsListFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.getPatientsListFailure
  ),
  updateVideoConsultationStatusSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.updateVideoConsultationStatusSuccess
  ),
  updateVideoConsultationStatusFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.updateVideoConsultationStatusFailure
  ),
  removeTokenForVideoConsultationSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.removeTokenForVideoConsultationSuccess
  ),
  removeTokenForVideoConsultationFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.removeTokenForVideoConsultationFailure
  ),
  generateVideoCallSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.generateVideoCallSuccess
  ),
  generateVideoCallFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.generateVideoCallFailure
  ),
  reconnectLastVideoCallSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.reconnectLastVideoCallSuccess
  ),
  reconnectLastVideoCallFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.reconnectLastVideoCallFailure
  ),
  addVideoConsultationSlotsSuccess: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.addVideoConsultationSlotsSuccess
  ),
  addVideoConsultationSlotsFailure: createSelector(
    selectVideoConsultation,
    (kioskState) => kioskState.addVideoConsultationSlotsFailure
  ),
  // Add other selectors if needed
};

export const {
  getPatientsList,
  getPatientsListSuccess,
  getPatientsListFailure,
  updateVideoConsultationStatus,
  updateVideoConsultationStatusSuccess,
  updateVideoConsultationStatusFailure,
  removeTokenForVideoConsultation,
  removeTokenForVideoConsultationSuccess,
  removeTokenForVideoConsultationFailure,
  generateVideoCall,
  generateVideoCallSuccess,
  generateVideoCallFailure,
  reconnectLastVideoCall,
  reconnectLastVideoCallSuccess,
  reconnectLastVideoCallFailure,
  addVideoConsultationSlots,
  addVideoConsultationSlotsSuccess,
  addVideoConsultationSlotsFailure,
} = videoConsultationSlice.actions;
export default videoConsultationSlice.reducer;
