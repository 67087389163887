/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import { TextField, Grid, Card } from "@mui/material";
import Table from "react-bootstrap/Table";
import Constants from "utils/constants";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { useLocation } from "react-router-dom";
import routes from "routes";
const REACT_APP_API_URL = Constants.API_URL;
const loginData = JSON.parse(localStorage.getItem("loginData"));

function SendGreetings() {
  const location = useLocation();
  const [numbersList, setNumbersList] = useState("");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "bearer " + loginData.token,
  };

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const onSubmitClicked = async () => {
    await axios.post(
      REACT_APP_API_URL + "/agent/patient/sendMessage",
      { numbers: numbersList.split(",") },
      // await axios.post("http://localhost:3001/sendMessage", {numbers:numbersList.split(',')},
      { headers }
    );
    setNumbersList("");
    Swal.fire({
      icon: "success",
      text: "Message Send Successfully",
      timer: 2000,
      showConfirmButton: false,
    });
  };
  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Send Greeting Message to Patients
              </MDTypography>
            </MDBox>
            <br />
            <br />
            <Card>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" px={5}>
                  <Table responsive pt={3} py={3} px={2}>
                    <tr>
                      <td>
                        <TextField
                          multiline
                          fullWidth
                          id="standard-basic"
                          variant="standard"
                          label="Enter Patient Numbers ..."
                          value={numbersList}
                          onChange={(event) => setNumbersList(event.target.value)}
                        />
                      </td>
                    </tr>
                  </Table>
                </MDBox>
                <MDBox pt={3} px={5}>
                  <td>
                    <MDButton
                      //style={{ textAlign: "center" }}
                      variant="gradient"
                      onClick={onSubmitClicked}
                      color="info"
                    >
                      <SendIcon /> &nbsp; Send Message
                    </MDButton>
                  </td>
                </MDBox>
              </MDBox>
            </Card>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default SendGreetings;
