import React, { useEffect, useState, useRef } from "react";
import { Button, Grid, IconButton, Badge, Typography, Popover } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import "./report.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import testDetails from "services/testDetails";
import diseasesMap from "utils/diseasesMap.json";
import colorsList from "utils/colorsList.json";

export default function Report() {
  const { pathname, state } = useLocation();
  const testId = pathname.replace("/report/", "");
  const patientId = state.patientId;
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [reportData, setReportData] = useState(state.testDetails);
  const [diseasesList, setDiseasesList] = useState([]);
  const [selectedDiseaseTypes, setSelectedDiseaseTypes] = useState([]);
  // const [diseaseInformation, setDiseaseInformation] = useState([]);
  // const [diseasesKeys, setDiseasesKeys] = useState([]);
  const [imageScale, setImageScale] = useState(0);
  let [newAnnotation, setNewAnnotation] = useState([0, 0, 0, 0]);
  const [showModal, setShowModal] = useState(false);
  const [newDisease, setNewDisease] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [searchText, setSearchText] = useState("");
  const [currentDetection, setCurrentDetection] = useState();

  const getTreatmentsColor = (disease) => {
    if (diseasesMap[disease.toLowerCase()]) {
      return diseasesMap[disease.toLowerCase()].color;
    } else {
      return "#ffffff";
    }
  };

  const canvasRef = useRef();

  // const getTreatments = (disease) => {
  //   if (diseasesMap[disease.toLowerCase()] && diseasesMap[disease.toLowerCase()]["english"]) {
  //     return diseasesMap[disease.toLowerCase()]["english"];
  //   }
  // };

  useEffect(async () => {
    if (!reportData) {
      return;
    }
    let angle;
    switch (activeStep) {
      case 0: // Upper
        angle = "front";
        break;
      case 1: // Lower
        angle = "upper";
        break;
      case 2: // Front
        angle = "lower";
        break;
      default:
        angle = "front";
        break;
    }
    let detection = reportData.detections.find((d) => d.angle.toLowerCase() === angle);
    if (detection) {
      setCurrentDetection(detection._id);
      // const uniqueDiseaseTypes = [
      //   ...new Set(detection.diseaseDetailedList.map((disease) => disease.name.toLowerCase())),
      // ];
      setSelectedDiseaseTypes(
        detection.diseaseDetailedList.map((disease) => disease.name.toLowerCase())
      );
      setDiseasesList(detection.diseaseDetailedList);
      // setDiseasesKeys(Object.keys(fillInDiseaseInformation(detection.diseaseDetailedList)));
      // setDiseaseInformation(detection?.diseaseDetailedList);
      const anglesList = ["front", "upper", "lower"];
      const path = reportData.detections.find(
        (data) => data.angle.toLowerCase() === anglesList[activeStep]
      );
      if (path && path.diseaseFileURL) {
        const dynamicUrl = await testDetails.generateUrlFromFileKey(path.diseaseFileURL);
        if (dynamicUrl?.data) {
          setImageUrl(dynamicUrl?.data?.url);
        }
      }
    } else {
      console.error(`Detection with angle "${angle}" not found`);
    }
  }, [activeStep, reportData]);
  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    let FIXED_HEIGHT = 300; // You can set this value to whatever height you want
    const screenHeight = window.innerHeight;
    if (screenHeight < 1000) {
      FIXED_HEIGHT = 200;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const image = new Image();
    image.src = imageUrl;

    image.onload = () => {
      // Scale based on the fixed height
      const scale = FIXED_HEIGHT / image.height;
      setImageScale(scale);

      // Set canvas dimensions
      canvas.height = FIXED_HEIGHT;
      canvas.width = image.width * scale;

      // Draw the image on canvas
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      // Annotations
      drawAnnotations(context, 0, 0, scale);
    };
  }, [imageUrl, selectedDiseaseTypes, diseasesList, newAnnotation, reportData]);

  const drawRectangle = (ctx, coordinates, color, offsetX, offsetY, scale) => {
    const [x1, y1, x2, y2] = coordinates;
    ctx.globalAlpha = 0.7;
    ctx.fillStyle = color;
    ctx.fillRect(offsetX + x1 * scale, offsetY + y1 * scale, (x2 - x1) * scale, (y2 - y1) * scale);
  };

  const drawAnnotations = (context, offsetX, offsetY, scale) => {
    const diseaseMap = fillInDiseaseInformation(diseasesList);
    selectedDiseaseTypes.forEach((diseaseType) => {
      const diseases = diseaseMap[diseaseType];
      const color = getTreatmentsColor(diseaseType);
      if (diseases) {
        diseases.forEach((disease) => {
          const coordinates = disease.coordinates;
          coordinates.forEach((coord) =>
            drawRectangle(context, coord, color, offsetX, offsetY, scale)
          );
        });
      }
    });
    drawRectangle(context, newAnnotation, "#000000", offsetX, offsetY, scale);
  };
  const handleStep = (step) => {
    setActiveStep(step);
  };

  const fillInDiseaseInformation = (diseases) => {
    const diseaseMap = {};

    diseases.forEach((disease) => {
      const diseaseType = disease.name.toLowerCase();
      if (Object.keys(diseasesMap).includes(diseaseType)) {
        if (!diseaseMap[diseaseType]) {
          diseaseMap[diseaseType] = [];
        }
        diseaseMap[diseaseType].push(disease);
      }
    });
    return diseaseMap;
  };

  const getImageByAngleIndex = () => {
    const anglesList = ["front", "upper", "lower"];
    if (reportData && reportData.detections) {
      const path = reportData.detections.find(
        (data) => data.angle.toLowerCase() === anglesList[activeStep]
      );
      if (path && path.base_file_url) {
        setImageUrl(path.base_file_url);
      }
    }
  };

  useEffect(() => {
    getImageByAngleIndex();
  }, [reportData, activeStep]);

  const handleDiseaseClick = (disease) => {
    if (selectedDiseaseTypes.includes(disease)) {
      setSelectedDiseaseTypes(selectedDiseaseTypes.filter((item) => item !== disease));
    } else {
      setSelectedDiseaseTypes([...selectedDiseaseTypes, disease]);
    }
  };

  const isColorDark = (color) => {
    const colorValue = color.replace("#", "");
    const red = parseInt(colorValue.substr(0, 2), 16);
    const green = parseInt(colorValue.substr(2, 2), 16);
    const blue = parseInt(colorValue.substr(4, 2), 16);
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    return luminance < 0.5;
  };

  let pos = { x: 0, y: 0 };

  const setPosition = (eventType, event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    if (eventType === "start") {
      setNewAnnotation([
        offsetX / imageScale,
        offsetY / imageScale,
        offsetX / imageScale,
        offsetY / imageScale,
      ]);
    } else if (eventType === "move") {
      if (event.buttons !== 1) return;
      setNewAnnotation([
        newAnnotation[0],
        newAnnotation[1],
        offsetX / imageScale,
        offsetY / imageScale,
      ]);
    } else if (eventType === "end") {
      setNewAnnotation([
        newAnnotation[0],
        newAnnotation[1],
        offsetX / imageScale,
        offsetY / imageScale,
      ]);
      const shouldShowDiseasesList =
        offsetX / imageScale > newAnnotation[0] || offsetY / imageScale > newAnnotation[1];
      if (shouldShowDiseasesList) {
        setAnchorEl(event.nativeEvent.target);
        setShowModal(true);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <div
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                textAlign: "left",
                paddingTop: "1em",
                width: "100%",
              }}
            >
              <div className="stepper-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#EEF6FF",
                    width: "100%",

                    padding: "10px",
                  }}
                >
                  {["Front", "Upper", "Lower"].map((label, index) => (
                    <div key={label} style={{ margin: "0 10px" }}>
                      <button
                        className="buttonClass stepper-button-landscape"
                        onClick={() => {
                          handleStep(index);
                        }}
                        style={{
                          backgroundColor: activeStep === index ? "rgb(5, 38, 121)" : "inherit",
                          color: activeStep === index ? "white" : "rgb(5, 38, 121)",
                          borderRadius: "14px",
                          fontWeight: "bolder",
                          fontSize: "20px",
                          padding: "20px",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {label}
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  position: "relative",
                  display: "flex",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "4em",
                    height: "4em",
                    background: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "45%",
                    left: "10%",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Added a basic box-shadow
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      let nextStep = activeStep - 1;
                      if (nextStep < 0) {
                        nextStep = 2;
                      }
                      setActiveStep(nextStep);
                    }}
                    style={{
                      padding: "0.1em",
                      color: "#052679",
                    }}
                  >
                    <ChevronLeftIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                </div>

                <div
                  style={{
                    height: "100%",
                    borderRadius: "20px",
                    overflow: "hidden",
                    width: "fit-content",
                    margin: "auto",
                  }}
                >
                  <canvas
                    ref={canvasRef}
                    onMouseUp={(event) => setPosition("end", event)}
                    onMouseDown={(event) => setPosition("start", event)}
                    onMouseMove={(event) => setPosition("move", event)}
                    className="image image-contain"
                    style={{ objectFit: "contain" }}
                    key={imageUrl}
                  ></canvas>
                </div>

                <div
                  style={{
                    width: "4em",
                    height: "4em",
                    background: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    top: "45%",
                    right: "10%",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)", // Added box-shadow to remove border
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      let nextStep = activeStep + 1;
                      if (nextStep > 2) {
                        nextStep = 0;
                      }
                      setActiveStep(nextStep);
                    }}
                    style={{
                      padding: "0.1em",
                      color: "#052679",
                    }}
                  >
                    <ChevronRightIcon style={{ fontSize: 50 }} />{" "}
                  </IconButton>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "1em" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignContent: "space-around",
                  height: "100%",
                }}
              >
                {diseasesList.map((item, index) => {
                  const isSelected = selectedDiseaseTypes.includes(item.name.toLowerCase());
                  const buttonColor = getTreatmentsColor(item.name);

                  return (
                    <Badge
                      key={index}
                      badgeContent={"X"}
                      color={"error"}
                      onClick={async () => {
                        const result = await testDetails.removeAnnotationsOnTest(
                          testId,
                          patientId,
                          currentDetection,
                          item.name,
                          item?._id?.toString()
                        );
                        if (result && result.data) {
                          const reportDataTempCopy = reportData;
                          const detectionIndex = reportDataTempCopy.detections.findIndex(
                            (detectionInner) => detectionInner._id?.toString() === currentDetection
                          );
                          if (detectionIndex > -1) {
                            reportDataTempCopy.detections[detectionIndex] = result.data;
                            setReportData({ ...reportDataTempCopy });
                          }
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Button
                        style={{
                          backgroundColor: isSelected ? buttonColor : "transparent",
                          color: isSelected
                            ? isColorDark(buttonColor)
                              ? "white"
                              : "black"
                            : "black",
                          border: `1px solid ${buttonColor}`,
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                        className="button-disease-select"
                        variant="contained"
                        onClick={() => {
                          handleDiseaseClick(item.name.toLowerCase());
                        }}
                      >
                        <span style={{ fontSize: "1.5em" }}>{item.name}</span>
                      </Button>
                    </Badge>
                  );
                })}
                &nbsp;
                {newDisease?.length > 0 && (
                  <Badge
                    badgeContent={"X"}
                    color={"error"}
                    onClick={() => {
                      setNewDisease("");
                      setNewAnnotation([0, 0, 0, 0]);
                      setHoverIndex(-1);
                      setSearchText("");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <Button
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        border: `1px solid black`,
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      className="button-disease-select"
                      variant="contained"
                    >
                      <span style={{ fontSize: "1.5em" }}>{newDisease}</span>
                    </Button>
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <Popover
        id={"id"}
        open={showModal}
        anchorEl={anchorEl}
        onClose={() => {
          setShowModal(false);
          setNewAnnotation([0, 0, 0, 0]);
          setNewDisease("");
          setHoverIndex(-1);
          setSearchText("");
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onKeyDown={(event) => {
          setSearchText(searchText + event.key);
        }}
      >
        {Object.keys(colorsList)
          .filter((diseaseName) =>
            searchText.length ? diseaseName.includes(searchText.toUpperCase()) : true
          )
          .map((diseaseName, index) => (
            <Typography
              key={index}
              id="select-disease-name-modal"
              variant="h6"
              component="h2"
              style={{
                cursor: "pointer",
                textAlign: "left",
                backgroundColor: hoverIndex === index ? "grey" : "white",
                padding: "0.5em",
              }}
              onClick={async () => {
                setNewDisease(diseaseName);
                setShowModal(false);
                let [x1, y1, x2, y2] = newAnnotation;
                if (x1 > x2) {
                  [x1, x2] = [x2, x1];
                }
                if (y1 > y2) {
                  [y1, y2] = [y2, y1];
                }
                newAnnotation = [x1, y1, x2, y2];
                const result = await testDetails.addAnnotationsOnTest(
                  testId,
                  patientId,
                  currentDetection,
                  "ADD",
                  diseaseName,
                  newAnnotation
                );
                if (result && result.data) {
                  const reportDataTempCopy = reportData;
                  const detectionIndex = reportDataTempCopy.detections.findIndex(
                    (detectionInner) => detectionInner._id?.toString() === currentDetection
                  );
                  if (detectionIndex > -1) {
                    reportDataTempCopy.detections[detectionIndex] = result.data;
                    setReportData({ ...reportDataTempCopy });
                    setNewAnnotation([0, 0, 0, 0]);
                    setNewDisease("");
                    setHoverIndex(-1);
                    setSearchText("");
                  }
                }
              }}
              onMouseEnter={() => {
                setHoverIndex(index);
              }}
            >
              {diseaseName}
            </Typography>
          ))}
      </Popover>
    </DashboardLayout>
  );
}
