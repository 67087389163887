// subscriptionSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscriptions: [],
    isloading: false,
    addSubscriptionPlanSuccess: false,
    addSubscriptionPlanFailure: false,
    getSubscriptionPlansSuccess: false,
    getSubscriptionPlansFailure: false,
    editSubscriptionPlanSuccess: false,
    editSubscriptionPlanFailure: false,
    getAllSubscriptionsSuccess: false,
    getAllSubscriptionsFailure: false,
    addSubscriptionByAgentSuccess: false,
    addSubscriptionByAgentFailure: false,
    // Other initial state properties
  },
  reducers: {
    resetAddCouponSuccess: (state) => {
      state.addSubscriptionPlanSuccess = false;
    },
    resetUpdateCouponSuccess: (state) => {
      state.editSubscriptionPlanSuccess = false;
    },
    addSubscriptionPlan: (state, action) => {
      state.isloading = false;
    },
    addSubscriptionPlanSuccess: (state, action) => {
      state.subscriptions = action.payload;
      state.isloading = true;
      state.addSubscriptionPlanSuccess = true;
    },
    addSubscriptionPlanFailure: (state) => {
      state.isloading = false;
      state.addSubscriptionPlanFailure = true;
    },
    getSubscriptionPlans: (state, action) => {
      state.isloading = false;
    },
    getSubscriptionPlansSuccess: (state, action) => {
      state.subscriptionPlans = action.payload;
      state.isloading = true;
      state.getSubscriptionPlansSuccess = true;
    },
    getSubscriptionPlansFailure: (state) => {
      state.isloading = false;
      state.getSubscriptionPlansFailure = true;
    },
    editSubscriptionPlan: (state, action) => {
      state.isloading = false;
    },
    editSubscriptionPlanSuccess: (state, action) => {
      state.subscriptions = action.payload;
      state.isloading = true;
      state.editSubscriptionPlanSuccess = true;
    },
    editSubscriptionPlanFailure: (state) => {
      state.isloading = false;
      state.editSubscriptionPlanFailure = true;
    },
    getAllSubscriptions: (state, action) => {
      state.isloading = false;
    },
    getAllSubscriptionsSuccess: (state, action) => {
      state.subscriptionPlans = action.payload;
      state.isloading = true;
      state.getAllSubscriptionsSuccess = true;
    },
    getAllSubscriptionsFailure: (state) => {
      state.isloading = false;
      state.getAllSubscriptionsFailure = true;
    },
    addSubscriptionByAgent: (state, action) => {
      state.isloading = false;
    },
    addSubscriptionByAgentSuccess: (state, action) => {
      state.subscriptions = action.payload;
      state.isloading = true;
      state.addSubscriptionByAgentSuccess = true;
    },
    addSubscriptionByAgentFailure: (state) => {
      state.isloading = false;
      state.addSubscriptionByAgentFailure = true;
    },

    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.subscription;

export const subscriptionSelector = {
  addSubscriptionPlanSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addSubscriptionPlanSuccess
  ),
  addSubscriptionPlanFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addSubscriptionPlanFailure
  ),
  getSubscriptionPlansSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getSubscriptionPlansSuccess
  ),
  getSubscriptionPlansFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getSubscriptionPlansFailure
  ),
  editSubscriptionPlanSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.editSubscriptionPlanSuccess
  ),
  editSubscriptionPlanFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.editSubscriptionPlanFailure
  ),
  getAllSubscriptionsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAllSubscriptionsSuccess
  ),
  getAllSubscriptionsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getAllSubscriptionsFailure
  ),
  addSubscriptionByAgentSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addSubscriptionByAgentSuccess
  ),
  addSubscriptionByAgentFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addSubscriptionByAgentFailure
  ),

  // Add other selectors if needed
};
export const {
  addSubscriptionPlan,
  addSubscriptionPlanSuccess,
  addSubscriptionPlanFailure,
  getSubscriptionPlans,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  editSubscriptionPlan,
  editSubscriptionPlanSuccess,
  editSubscriptionPlanFailure,
  getAllSubscriptions,
  getAllSubscriptionsSuccess,
  getAllSubscriptionsFailure,
  addSubscriptionByAgent,
  addSubscriptionByAgentSuccess,
  addSubscriptionByAgentFailure,
  resetAddCouponSuccess,
  resetUpdateCouponSuccess,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
