// followUpSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const followUpSlice = createSlice({
  name: "followUps",
  initialState: {
    followUps: [],
    isloading: false,
    updateFollowUpSuccess: false,
    updateFollowUpFailure: false,
    addFollowUpSuccess: false,
    addFollowUpFailure: false,
    getFollowUpByIdSuccess: false,
    getFollowUpByIdFailure: false,
    // Other initial state properties
  },

  reducers: {
    resetUpdateFollowUpSuccess: (state, action) => {
      state.updateFollowUpSuccess = false;
    },
    updateFollowUp: (state, action) => {
      state.isloading = false;
    },
    updateFollowUpSuccess: (state, action) => {
      state.followUps = action.payload;
      state.isloading = true;
      state.updateFollowUpSuccess = true;
    },
    updateFollowUpFailure: (state) => {
      state.isloading = false;
      state.updateFollowUpFailure = true;
    },
    resetAddFollowUpSuccess: (state, action) => {
      state.addFollowUpSuccess = false;
    },
    addFollowUp: (state, action) => {
      state.isloading = false;
    },
    addFollowUpSuccess: (state, action) => {
      state.followUps = action.payload;
      state.isloading = true;
      state.addFollowUpSuccess = true;
    },
    addFollowUpFailure: (state) => {
      state.isloading = false;
      state.addFollowUpFailure = true;
    },
    getFollowUpById: (state, action) => {
      state.isloading = false;
    },
    getFollowUpByIdSuccess: (state, action) => {
      state.followUps = action.payload;
      state.isloading = true;
      state.getFollowUpByIdSuccess = true;
    },
    getFollowUpByIdFailure: (state) => {
      state.isloading = false;
      state.getFollowUpByIdFailure = true;
    },

    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.followUp;

export const followUpSelector = {
  updateFollowUpSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateFollowUpSuccess
  ),
  updateFollowUpFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateFollowUpFailure
  ),
  addFollowUpSuccess: createSelector(selectKiosk, (kioskState) => kioskState.addFollowUpSuccess),
  addFollowUpFailure: createSelector(selectKiosk, (kioskState) => kioskState.addFollowUpFailure),
  getFollowUpByIdSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpByIdSuccess
  ),
  getFollowUpByIdFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getFollowUpByIdFailure
  ),

  // Add other selectors if needed
};

export const {
  resetUpdateFollowUpSuccess,
  updateFollowUp,
  updateFollowUpSuccess,
  updateFollowUpFailure,
  addFollowUp,
  addFollowUpSuccess,
  resetAddFollowUpSuccess,
  addFollowUpFailure,
  getFollowUpById,
  getFollowUpByIdSuccess,
  getFollowUpByIdFailure,
} = followUpSlice.actions;
export default followUpSlice.reducer;
