/* eslint-disable */
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Grid, Card, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Table } from "@mui/material";

function DentalInsurance(props) {
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const [openSurveyModal, setOpenSurveyModal] = useState(false);
  const handleCloseSurveyModal = () => setOpenSurveyModal(false);
  const [surveyQuestions, setSurveyQuestions] = useState([]);

  useEffect(() => {
    //  GetPatientsNotifications();
  }, []);

  const insuranceDetailsColumns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 110,
      renderCell: (data, i) => {
        return 1;
      },
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.insuranceDate).format("LLL");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 150,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <MDButton variant="gradient" onClick={() => handleclickDetails(params)} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];
  const handleclickDetails = (params) => {
    setOpenSurveyModal(true);
    setSurveyQuestions(params.row.oralTypeQuestion);
  };
  return (
    <>
      <div style={{ height: "370px", width: "100%" }}>
        <DataGrid
          rows={props && props.insuranceData ? props.insuranceData : []}
          columns={insuranceDetailsColumns}
          disableSelectionOnClick
          pageSize={5}
          getRowId={(row) => row._id}
        />
      </div>

      <Modal
        open={openSurveyModal}
        onClose={handleCloseSurveyModal}
        style={{ marginLeft: "10%", overflow: "scroll" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox mb={1} lineHeight={1}>
                            <center>
                              <h4>Survey-Dental Insurance</h4>
                            </center>
                            <hr />
                            <br />
                            <Grid container spacing={2}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Questions</th>
                                    <th>Anwser</th>
                                  </tr>
                                  <hr />
                                </thead>
                                <tbody>
                                  {surveyQuestions
                                    ? surveyQuestions.map((test, index) => {
                                        return (
                                          <>
                                            <tr key={index}>
                                              <td>{test.question}</td>
                                              <td>{test.answer}</td>
                                            </tr>
                                            <hr />
                                          </>
                                        );
                                      })
                                    : null}
                                </tbody>
                              </Table>
                              <br />
                              <br />
                              <Grid item xs={12}>
                                <center>
                                  <MDButton
                                    //fullWidth
                                    variant="gradient"
                                    onClick={handleCloseSurveyModal}
                                    color="info"
                                  >
                                    Close
                                  </MDButton>
                                </center>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
    </>
  );
}
export default DentalInsurance;
