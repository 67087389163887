/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import { Select, FormControl, InputLabel, Checkbox, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import {
  addSubscriptionPlan,
  editSubscriptionPlan,
  subscriptionSelector,
  resetAddCouponSuccess,
  resetUpdateCouponSuccess,
} from "store/reducers/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";

function AddEditSubscriptionPlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [isDisabled, setDisabled] = useState(false);
  const [planType, setPlanType] = useState(state && state.planType ? state.planType : "");
  const [price, setPrice] = useState(state && state.price ? state.price : "");
  const [annualPrice, setAnnualPrice] = useState(
    state && state.annualPrice ? state.annualPrice : ""
  );
  const [originalPrice, setOriginalPrice] = useState(
    state && state.originalPrice ? state.originalPrice : ""
  );
  const [discountPercent, setDiscountPercent] = useState(
    state && state.discountPercent ? state.discountPercent : ""
  );
  const [validityInMonths, setValidityInMonths] = useState(1);
  const [newPlanData, setNewPlanData] = useState([]);
  const addSubscriptionPlanSuccess = useSelector(subscriptionSelector.addSubscriptionPlanSuccess);
  const addSubscriptionPlanFailure = useSelector(subscriptionSelector.addSubscriptionPlanFailure);
  const editSubscriptionPlanSuccess = useSelector(subscriptionSelector.editSubscriptionPlanSuccess);
  const editSubscriptionPlanFailure = useSelector(subscriptionSelector.editSubscriptionPlanFailure);

  const planArray = ["Plus", "Pro", "Digital"];
  const featureData = [
    { name: "Oral Health Coach", permission: false, color: "" },
    { name: "Scaling & Polishing (once a year)", permission: false, color: "" },
    { name: "Exclusive Treatment Offers", permission: false, color: "" },
    { name: "Tele/Video consultation", permission: false, color: "", count: "" },
    {
      name: "Oral Care Kit",
      permission: false,
      color: "",
      title: "",
      description: "",
    },
    { name: "Habit Tracker", permission: false, color: "" },
    { name: "Analytics/Reports", permission: false, color: "" },
    { name: "Disease Progression & Prediction", permission: false, color: "" },
  ];
  const [featuresArray, setFeaturesArray] = useState(
    state && state.features ? state.features : featureData
  );
  const [isActive, setIsActive] = useState(true);

  const handleToggleActive = () => {
    setIsActive(!isActive);
  };

  const handleCheckBoxChange = (event, data) => {
    let arrayCopy = [...featuresArray];
    arrayCopy.map((element) => {
      if (element.name === data.name) {
        element.permission = event.target.checked;
      }
    });
    setFeaturesArray(arrayCopy);
  };

  const handleCountChange = (event, data) => {
    let arrayCopy = [...featuresArray];
    arrayCopy.map((element) => {
      if (element.name === data.name) {
        element.count = parseInt(event.target.value);
      }
    });
    setFeaturesArray(arrayCopy);
  };

  const handleTitleChange = (event, data) => {
    let arrayCopy = [...featuresArray];
    arrayCopy.map((element) => {
      if (element.name === data.name) {
        element.title = event.target.value;
      }
    });
    setFeaturesArray(arrayCopy);
  };
  const handleDescriptionChange = (event, data) => {
    let arrayCopy = [...featuresArray];
    arrayCopy.map((element) => {
      if (element.name === data.name) {
        element.description = event.target.value;
      }
    });
    setFeaturesArray(arrayCopy);
  };
  const handleColorChange = (event, data) => {
    let arrayCopy = [...featuresArray];
    arrayCopy.map((element) => {
      if (element.name === data.name) {
        element.color = event.target.value;
      }
    });
    setFeaturesArray(arrayCopy);
  };

  const handleUpdateChange = (e, data, index) => {
    const { name, value, checked } = e.target;
    const list = [...newPlanData];
    list[index][name] =
      name == "name" ||
      name == "count" ||
      name == "title" ||
      name == "description" ||
      name == "color"
        ? value
        : checked;
    setNewPlanData(list);
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setNewPlanData([
      ...newPlanData,
      { name: "", permission: false, count: "", title: "", description: "", color: "" },
    ]);
  };
  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...newPlanData];
    list.splice(index, 1);
    setNewPlanData(list);
  };

  const newArray = [...featuresArray, ...newPlanData];
  const validateAddPack = () => {
    if (planType == "") {
      Swal.fire({
        icon: "error",
        text: "Please select plan",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (newArray.name == "") {
      Swal.fire({
        icon: "error",
        text: "Please select feature",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (price == "") {
      Swal.fire({
        icon: "error",
        text: "Price cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }

    return true;
  };

  const handleSubmitSubscription = () => {
    const formData = {
      planType: planType.toLowerCase(),
      price: parseInt(price),
      features: newArray,
      //  validityInMonths: parseInt(validityInMonths),
    };

    dispatch(addSubscriptionPlan(formData));
  };

  useEffect(() => {
    if (addSubscriptionPlanSuccess) {
      Swal.fire({
        icon: "success",
        text: "Subscription plan added successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetAddCouponSuccess());
      navigate("/plans");
    }
  }, [addSubscriptionPlanSuccess]);

  useEffect(() => {
    if (addSubscriptionPlanFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addSubscriptionPlanFailure]);

  const handleUpdateSubscription = () => {
    const planData = {
      planType: planType.toLowerCase(),
      price: parseInt(price),
      annualPrice: parseInt(annualPrice),
      originalPrice: parseInt(originalPrice),
      discountPercent: parseInt(discountPercent),
      features: newArray,
      isActive,
      //  validityInMonths: parseInt(validityInMonths),
    };
    const formData = {
      planData,
      planId: state.planId,
    };
    dispatch(editSubscriptionPlan(formData));
  };

  useEffect(() => {
    if (editSubscriptionPlanSuccess) {
      Swal.fire({
        icon: "success",
        text: "Subscription plan updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateCouponSuccess());
      navigate("/plans");
    }
  }, [editSubscriptionPlanSuccess]);

  useEffect(() => {
    if (editSubscriptionPlanFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [editSubscriptionPlanFailure]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Card>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={1} m={0}>
                <h5>
                  <center>{state && state.planId ? "Edit Plan" : "Add Plan"}</center>
                </h5>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={darkMode ? "transparent" : "grey-300"}
                  borderRadius="lg"
                  p={2}
                  mt={1}
                >
                  <MDBox
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    mb={1}
                    lineHeight={1}
                    bgColor={darkMode ? "transparent" : "grey-300"}
                  >
                    <br />
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableRow>
                          <TableCell>
                            <b>No.</b>
                          </TableCell>
                          <TableCell>
                            <b>Features</b>
                          </TableCell>
                          <TableCell>
                            <b>Permissions</b>
                          </TableCell>
                          <TableCell>
                            <FormControl variant="standard" fullWidth>
                              <InputLabel>
                                <b>Select Plan</b>
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={planType}
                                onChange={(event) => setPlanType(event.target.value)}
                              >
                                {planArray.map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={isActive}
                              onChange={handleToggleActive}
                              color="primary"
                              inputProps={{ "aria-label": "toggle active state" }}
                            />
                            <b>{isActive ? "Active" : "Inactive"}</b>
                          </TableCell>
                        </TableRow>

                        <TableBody>
                          {featuresArray
                            ? featuresArray.map((data, index) => {
                                return (
                                  <>
                                    <TableRow
                                      key={data.name}
                                      sx={{
                                        "&:last-child TableCell, &:last-child th": { border: 0 },
                                      }}
                                    >
                                      <TableCell key={index}>{index + 1}</TableCell>
                                      <TableCell>{data.name}</TableCell>
                                      <TableCell>
                                        <Checkbox
                                          checked={data.permission}
                                          onChange={(event) => handleCheckBoxChange(event, data)}
                                          name="permission"
                                          color="primary"
                                        />
                                      </TableCell>
                                      <TableCell>
                                        <TextField
                                          fullWidth
                                          name="color"
                                          value={data.color}
                                          required
                                          label="Color-Hex"
                                          onChange={(event) => handleColorChange(event, data)}
                                        />
                                      </TableCell>

                                      {(data.permission === true && data.count == "") ||
                                      data.count ? (
                                        <>
                                          <TableCell>
                                            <TextField
                                              fullWidth
                                              type="number"
                                              name="count"
                                              value={data.count}
                                              required
                                              label="/month"
                                              onChange={(event) => handleCountChange(event, data)}
                                            />
                                          </TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {(data.permission === true && data.title == "") ||
                                      data.title ? (
                                        <>
                                          <TableCell>
                                            <TextField
                                              fullWidth
                                              name="title"
                                              value={data.title}
                                              required
                                              label="Title"
                                              onChange={(event) => handleTitleChange(event, data)}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                              // multiline
                                              fullWidth
                                              name="description"
                                              value={data.description}
                                              required
                                              label="Description"
                                              onChange={(event) =>
                                                handleDescriptionChange(event, data)
                                              }
                                            />
                                          </TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </TableRow>
                                  </>
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>

                      {newPlanData.length > 0
                        ? newPlanData.map((data, i) => {
                            return (
                              <>
                                <Table responsive>
                                  <TableRow key={i}>
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        name="name"
                                        value={data.name}
                                        label="Add New Feature"
                                        onChange={(e) => handleUpdateChange(e, data, i)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <Checkbox
                                        checked={newPlanData.permission}
                                        value={data.permission}
                                        onChange={(e) => handleUpdateChange(e, data, i)}
                                        name="permission"
                                        color="primary"
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        name="count"
                                        value={data.count}
                                        label="/month"
                                        onChange={(e) => handleUpdateChange(e, data, i)}
                                      />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        name="title"
                                        value={data.title}
                                        label="Title"
                                        onChange={(e) => handleUpdateChange(e, data, i)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        name="description"
                                        value={data.description}
                                        label="Description"
                                        onChange={(e) => handleUpdateChange(e, data, i)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        fullWidth
                                        name="color"
                                        value={data.color}
                                        required
                                        label="Color-Hex"
                                        onChange={(event) => handleUpdateChange(event, data)}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <MDButton
                                        variant="gradient"
                                        onClick={(event) => handleRemoveClick(event, i)}
                                        color="error"
                                      >
                                        x
                                      </MDButton>
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </>
                            );
                          })
                        : ""}
                      <MDBox pt={3} py={3} px={2}>
                        <MDButton variant="gradient" onClick={handleAddClick} color="info">
                          +
                        </MDButton>
                        <TextField
                          style={{ float: "right" }}
                          name="price"
                          value={price}
                          required
                          label="Price"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                        <TextField
                          style={{ float: "right", marginRight: "20px" }}
                          name="annualPrice"
                          value={annualPrice}
                          required
                          label="Annual Price"
                          onChange={(e) => setAnnualPrice(e.target.value)}
                        />
                        <TextField
                          style={{ float: "right", marginRight: "20px" }}
                          name="discountPrice"
                          value={originalPrice}
                          required
                          label="Original Price"
                          onChange={(e) => setOriginalPrice(e.target.value)}
                        />
                        <TextField
                          style={{ float: "right", marginRight: "20px" }}
                          name="discountPercent"
                          value={discountPercent}
                          required
                          label="Discount Percent"
                          onChange={(e) => setDiscountPercent(e.target.value)}
                        />
                      </MDBox>
                      <br />
                    </TableContainer>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <MDButton
                          fullWidth
                          variant="gradient"
                          onClick={() => {
                            navigate(-1);
                          }}
                          color="info"
                        >
                          Cancel
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {state && state.planId ? (
                          <MDButton
                            fullWidth
                            variant="gradient"
                            disabled={isDisabled === true}
                            onClick={() => {
                              if (validateAddPack() === true) {
                                handleUpdateSubscription();
                              } else {
                                console.log("Pack not validated");
                              }
                            }}
                            color="info"
                          >
                            Update
                          </MDButton>
                        ) : (
                          <MDButton
                            fullWidth
                            variant="gradient"
                            onClick={() => {
                              if (validateAddPack() === true) {
                                handleSubmitSubscription();
                              } else {
                                console.log("Pack not validated");
                              }
                            }}
                            color="info"
                          >
                            Add
                          </MDButton>
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
export default AddEditSubscriptionPlan;
