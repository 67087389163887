/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRateCardList, doctorSelector } from "store/reducers/doctorSlice";

function RateCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [treatmentTypes, setTreatmentTypes] = useState([]);

  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const rateCardData = useSelector((state) => state?.doctor?.rateCardData);
  const getRateCardListSuccess = useSelector(doctorSelector.getRateCardListSuccess);
  const getRateCardListFailure = useSelector(doctorSelector.getRateCardListFailure);

  const rateCardList = [
    {
      id: 1,
      treatment: "Amalgam Class 1",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Amalgam Class 1"]
          ? treatmentTypes[0]["Amalgam Class 1"]
          : 0,
    },
    {
      id: 2,
      treatment: "Amalgam Class II",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Amalgam Class II"]
          ? treatmentTypes[0]["Amalgam Class II"]
          : 0,
    },
    {
      id: 3,
      treatment: "Appliances",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Appliances"]
          ? treatmentTypes[0]["Appliances"]
          : 0,
    },
    {
      id: 4,
      treatment: "Basal Implants",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Basal Implants"]
          ? treatmentTypes[0]["Basal Implants"]
          : 0,
    },
    {
      id: 5,
      treatment: "CONSULTATION",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["CONSULTATION"]
          ? treatmentTypes[0]["CONSULTATION"]
          : 0,
    },
    {
      id: 6,
      treatment: "Cbct",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Cbct"]
          ? treatmentTypes[0]["Cbct"]
          : 0,
    },
    {
      id: 7,
      treatment: "Ceramic braces",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Ceramic braces"]
          ? treatmentTypes[0]["Ceramic braces"]
          : 0,
    },
    {
      id: 8,
      treatment: "Clear Aligners",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Clear Aligners"]
          ? treatmentTypes[0]["Clear Aligners"]
          : 0,
    },
    {
      id: 9,
      treatment: "Complete Denture",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Complete Denture"]
          ? treatmentTypes[0]["Complete Denture"]
          : 0,
    },
    {
      id: 10,
      treatment: "Composite",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Composite"]
          ? treatmentTypes[0]["Composite"]
          : 0,
    },
    {
      id: 11,
      treatment: "Composite filling - Anterior",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Composite filling - Anterior"]
          ? treatmentTypes[0]["Composite filling - Anterior"]
          : 0,
    },
    {
      id: 12,
      treatment: "Composite filling - Posterior",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Composite filling - Posterior"]
          ? treatmentTypes[0]["Composite filling - Posterior"]
          : 0,
    },
    {
      id: 13,
      treatment: "Crown - All Metal",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Crown - All Metal"]
          ? treatmentTypes[0]["Crown - All Metal"]
          : 0,
    },
    {
      id: 14,
      treatment: "Crown - Ceramic",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Crown - Ceramic"]
          ? treatmentTypes[0]["Crown - Ceramic"]
          : 0,
    },
    {
      id: 15,
      treatment: "Crown - PFM",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Crown - PFM"]
          ? treatmentTypes[0]["Crown - PFM"]
          : 0,
    },
    {
      id: 16,
      treatment: "Crown - Zirconia",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Crown - Zirconia"]
          ? treatmentTypes[0]["Crown - Zirconia"]
          : 0,
    },
    {
      id: 17,
      treatment: "Crown Lengthening",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Crown Lengthening"]
          ? treatmentTypes[0]["Crown Lengthening"]
          : 0,
    },
    {
      id: 18,
      treatment: "Extraction",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Extraction"]
          ? treatmentTypes[0]["Extraction"]
          : 0,
    },
    {
      id: 19,
      treatment: "Extraction - Normal",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Extraction - Normal"]
          ? treatmentTypes[0]["Extraction - Normal"]
          : 0,
    },
    {
      id: 20,
      treatment: "Extraction third molar - Non Surgical",
      priceRange:
        treatmentTypes &&
        treatmentTypes[0] &&
        treatmentTypes[0]["Extraction third molar - Non Surgical"]
          ? treatmentTypes[0]["Extraction third molar - Non Surgical"]
          : 0,
    },
    {
      id: 21,
      treatment: "Extraction third molar - Surgical",
      priceRange:
        treatmentTypes &&
        treatmentTypes[0] &&
        treatmentTypes[0]["Extraction third molar - Surgical"]
          ? treatmentTypes[0]["Extraction third molar - Surgical"]
          : 0,
    },
    {
      id: 22,
      treatment: "Fluoride Application",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Fluoride Application"]
          ? treatmentTypes[0]["Fluoride Application"]
          : 0,
    },
    {
      id: 23,
      treatment: "GIC",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["GIC"]
          ? treatmentTypes[0]["GIC"]
          : 0,
    },
    {
      id: 24,
      treatment: "GIC - Pedo",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["GIC - Pedo"]
          ? treatmentTypes[0]["GIC - Pedo"]
          : 0,
    },
    {
      id: 25,
      treatment: "Gingival Depigmentation",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Gingival Depigmentation"]
          ? treatmentTypes[0]["Gingival Depigmentation"]
          : 0,
    },
    {
      id: 26,
      treatment: "Inlay",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Inlay"]
          ? treatmentTypes[0]["Inlay"]
          : 0,
    },
    {
      id: 27,
      treatment: "Intra-oral scanning",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Intra-oral scanning"]
          ? treatmentTypes[0]["Intra-oral scanning"]
          : 0,
    },
    {
      id: 28,
      treatment: "Iopa/RVG",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Iopa/RVG"]
          ? treatmentTypes[0]["Iopa/RVG"]
          : 0,
    },
    {
      id: 29,
      treatment: "LASER, Mention treatment name and price",
      priceRange:
        treatmentTypes &&
        treatmentTypes[0] &&
        treatmentTypes[0]["LASER, Mention treatment name and price"]
          ? treatmentTypes[0]["LASER, Mention treatment name and price"]
          : 0,
    },
    {
      id: 30,
      treatment: "Metal Braces",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Metal Braces"]
          ? treatmentTypes[0]["Metal Braces"]
          : 0,
    },
    {
      id: 31,
      treatment: "Onlay",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Onlay"]
          ? treatmentTypes[0]["Onlay"]
          : 0,
    },
    {
      id: 32,
      treatment: "Opg",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Opg"]
          ? treatmentTypes[0]["Opg"]
          : 0,
    },
    {
      id: 33,
      treatment: "Other treatments you render",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Other treatments you render"]
          ? treatmentTypes[0]["Other treatments you render"]
          : 0,
    },
    {
      id: 34,
      treatment: "Over-Denture",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Over-Denture"]
          ? treatmentTypes[0]["Over-Denture"]
          : 0,
    },
    {
      id: 35,
      treatment: "Perio surgeries",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Perio surgeries"]
          ? treatmentTypes[0]["Perio surgeries"]
          : 0,
    },
    {
      id: 36,
      treatment: "Pit and Fissure Sealant",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Pit and Fissure Sealant"]
          ? treatmentTypes[0]["Pit and Fissure Sealant"]
          : 0,
    },
    {
      id: 37,
      treatment: "Post & C0re",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Post & C0re"]
          ? treatmentTypes[0]["Post & C0re"]
          : 0,
    },
    {
      id: 38,
      treatment: "Pulpectomy",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Pulpectomy"]
          ? treatmentTypes[0]["Pulpectomy"]
          : 0,
    },
    {
      id: 39,
      treatment: "Pulpotomy",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Pulpotomy"]
          ? treatmentTypes[0]["Pulpotomy"]
          : 0,
    },
    {
      id: 40,
      treatment: "RCT",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["RCT"]
          ? treatmentTypes[0]["RCT"]
          : 0,
    },
    {
      id: 41,
      treatment: "RCT - Anterior",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["RCT - Anterior"]
          ? treatmentTypes[0]["RCT - Anterior"]
          : 0,
    },
    {
      id: 42,
      treatment: "RCT - Posterior",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["RCT - Posterior"]
          ? treatmentTypes[0]["RCT - Posterior"]
          : 0,
    },
    {
      id: 43,
      treatment: "RE-RCT",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["RE-RCT"]
          ? treatmentTypes[0]["RE-RCT"]
          : 0,
    },
    {
      id: 44,
      treatment: "RPD",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["RPD"]
          ? treatmentTypes[0]["RPD"]
          : 0,
    },
    {
      id: 45,
      treatment: "Root pieces",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Root pieces"]
          ? treatmentTypes[0]["Root pieces"]
          : 0,
    },
    {
      id: 46,
      treatment: "Scaling",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Scaling"]
          ? treatmentTypes[0]["Scaling"]
          : 0,
    },
    {
      id: 47,
      treatment: "Self-ligating Braces",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Self-ligating Braces"]
          ? treatmentTypes[0]["Self-ligating Braces"]
          : 0,
    },
    {
      id: 48,
      treatment: "Smile designing",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Smile designing"]
          ? treatmentTypes[0]["Smile designing"]
          : 0,
    },
    {
      id: 49,
      treatment: "Teeth whitening",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Teeth whitening"]
          ? treatmentTypes[0]["Teeth whitening"]
          : 0,
    },
    {
      id: 50,
      treatment: "Traditional, Mention name",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Traditional, Mention name"]
          ? treatmentTypes[0]["Traditional, Mention name"]
          : 0,
    },
    {
      id: 51,
      treatment: "Veneers",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Veneers"]
          ? treatmentTypes[0]["Veneers"]
          : 0,
    },
    {
      id: 52,
      treatment: "Zygomatic Implant",
      priceRange:
        treatmentTypes && treatmentTypes[0] && treatmentTypes[0]["Zygomatic Implant"]
          ? treatmentTypes[0]["Zygomatic Implant"]
          : 0,
    },
  ];
  const rateCardColumns = [
    {
      field: "id",
      headerName: "Sr.No.",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "treatment",
      headerName: "Treatment",
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "priceRange",
      headerName: "Price Range",
      headerAlign: "center",
      align: "center",
      width: 350,
    },
  ];

  useEffect(() => {
    fetchRateCardList(props.doctorId);
  }, []);

  const fetchRateCardList = (doctorId) => {
    dispatch(getRateCardList(doctorId));
  };

  useEffect(() => {
    if (getRateCardListSuccess) {
      setTreatmentTypes(rateCardData.rateCard);
    }
  }, [getRateCardListSuccess]);

  useEffect(() => {
    if (getRateCardListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getRateCardListFailure]);

  return (
    <>
      <div style={{ height: "670px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => "auto"}
          rows={rateCardList}
          columns={rateCardColumns}
          disableSelectionOnClick
          pageSize={52}
          getRowId={(row) => row.id}
        />
      </div>
    </>
  );
}
export default RateCard;
