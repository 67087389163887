/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, TextField, Checkbox, FormControlLabel } from "@mui/material";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import MDButton from "components/MDButton";
import PatientCard from "./patientCard";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import {
  sendMessage,
  getUsers,
  sendNotificationUser,
  patientSelector,
  searchValue,
} from "store/reducers/patientSlice";
import { useDispatch, useSelector } from "react-redux";
import { searchPatient, patientDetailsSelector } from "store/reducers/patientdetailsSlice";

function PatientTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSendNotificationModal, setOpenSendNotificationModal] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const localData = JSON.parse(localStorage.getItem("loginData"));
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({
    checkedB: false,
    checkedB1: false,
  });
  const [newPatientList, setNewPatientList] = useState([]);
  const patients = useSelector((state) => state?.patient?.patients);
  const getUsersSuccess = useSelector(patientSelector.getUsersSuccess);
  const getUsersFailure = useSelector(patientSelector.getUsersFailure);
  const searchPatients = useSelector((state) => state?.patientDetails?.searchPatients);
  const searchPatientSuccess = useSelector(patientDetailsSelector.searchPatientSuccess);
  const searchPatientFailure = useSelector(patientDetailsSelector.searchPatientFailure);
  const sendNotificationUserSuccess = useSelector(patientSelector.sendNotificationUserSuccess);
  const sendNotificationUserFailure = useSelector(patientSelector.sendNotificationUserFailure);
  const sendMessageSuccess = useSelector(patientSelector.sendMessageSuccess);
  const sendMessageFailure = useSelector(patientSelector.sendMessageFailure);
  const searchData = useSelector((state) => state?.patient?.searchValue);
  const [filterName, setFilterName] = useState(searchData ? searchData : "");

  useEffect(() => {
    patientData();
  }, []);
  const handleCloseSendNotificationModal = () => setOpenSendNotificationModal(false);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const patientColumns = [
    { field: "id", headerName: "No", headerAlign: "center", align: "center", width: 80 },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        return <div onClick={onDetailsClick}>{toTitleCase(params.row.name)}</div>;
      },
    },
    {
      field: "source",
      headerName: "Source",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (data) => {
        return (
          <div
            style={{
              color:
                data.row.source === "WhatsApp"
                  ? "green"
                  : "" || data.row.source === "App"
                  ? "#00376a"
                  : "" || data.row.source === "Kiosk"
                  ? "red"
                  : "orange",
            }}
          >
            <b>
              {data.row.source
                ? data.row.source
                : data.row.kioskPatient == "kioskPatient(Karad)"
                ? "kiosPatient(Karad)"
                : data.row.source}
            </b>
          </div>
        );
      },
    },
    {
      field: "lastTestDate",
      headerName: "Last Test Date",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (data) => {
        return (
          <div>
            {data.row.lastTestDate === "N/A" ? (
              <CloseIcon style={{ color: "red" }} />
            ) : (
              <CheckIcon style={{ color: "green" }} />
            )}
            &nbsp;&nbsp;
            {data.row.lastTestDate}
          </div>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile No",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "countryCode",
      headerName: "Country Code",
      headerAlign: "center",
      align: "center",
      width: 130,
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        const onSendMessageClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleSendMessageClick(thisRow.mobile);
        };

        return (
          <>
            <MDButton variant="gradient" onClick={onDetailsClick} color="info">
              Details
            </MDButton>
            &nbsp;&nbsp;
            <MDButton variant="gradient" onClick={onSendMessageClick} color="info">
              Send Message
            </MDButton>
          </>
        );
      },
    },
  ];

  const handleDetailsClick = (datavalue) => {
    const result = patientList.filter((patientList) => patientList.id === datavalue.id);
    localStorage.setItem("PatientID", JSON.stringify(datavalue.id));
    navigate("/patientdetails/" + result[0].sid);
  };

  const handleSendMessageClick = (datavalue) => {
    const shouldAddCountryCode = (numbers) => {
      if (datavalue && datavalue.length > 10 && datavalue.indexOf(91) === 0) {
        return false;
      }
      return true;
    };

    const MobileNo = shouldAddCountryCode(datavalue) ? datavalue : datavalue.slice(2);

    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(sendMessage(MobileNo, loginData.token));
      }
    });
  };

  useEffect(() => {
    if (sendMessageSuccess) {
      Swal.fire({
        icon: "success",
        text: "Message sent",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }, [sendMessageSuccess]);

  useEffect(() => {
    if (sendMessageFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendMessageFailure]);

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => patientList.find((row) => row.id === id));
    if (selectedRowsData[0]) {
      setCheckBoxValue(true);
    } else {
      setCheckBoxValue(false);
    }
    setSelectedRows(selectedRowsData);
  };

  const patientData = (page) => {
    const pageNumber = page === undefined ? "0" : page;
    const pageSize = "10";
    const formData = {
      pageNumber: parseInt(pageNumber) + 1,
      pageSize,
      token: loginData?.token,
    };
    dispatch(getUsers(formData));
  };

  useEffect(() => {
    if (patients.patients) {
      setIsLoading(false);
      setNewPatientList(patients.patients);
      setPatientList(patients.patients);
      setResponseData(patients);
      const cardList = patients.patients.slice(0, 3);
      setCardData(cardList);
    }
  }, [patients]);

  useEffect(() => {
    if (getUsersFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getUsersFailure]);

  const searchPatientData = (filterName, page = 0) => {
    const pageNumber = page === undefined ? "0" : page;
    const pageSize = "10";
    const formData = {
      searchData: filterName,
      pageNumber: parseInt(pageNumber) + 1,
      pageSize,
      token: loginData.token,
    };
    dispatch(searchPatient(formData));
  };

  useEffect(() => {
    if (searchData) {
      const pageNumber = 0;
      const pageSize = "10";
      const formData = {
        searchData: searchData,
        pageNumber: parseInt(pageNumber) + 1,
        pageSize,
        token: loginData.token,
      };
      dispatch(searchPatient(formData));
    }
  }, [patients]);

  useEffect(() => {
    if (searchPatients?.patients) {
      setIsLoading(false);
      setPatientList(searchPatients?.patients);
      setResponseData(searchPatients);
    }
  }, [searchPatients]);

  useEffect(() => {
    if (searchPatientFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [searchPatientFailure]);

  const handlePageChange = (newPage) => {
    if (filterName.length) {
      searchPatientData(filterName, newPage);
    } else {
      patientData(newPage);
    }
  };
  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedB === false) {
      const newData = patientList.filter((patient) => patient.lastTestDate !== "N/A");
      setPatientList(newData);
    } else {
      setPatientList(newPatientList);
    }
  };

  const handleChangeCheckboxNA = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedB1 === false) {
      const newData = patientList.filter((patient) => patient.lastTestDate === "N/A");
      setPatientList(newData);
    } else {
      setPatientList(newPatientList);
    }
  };

  const handleSendNotification = () => {
    if (validateSendNotification() === true) {
      const patientIdArray = [];
      for (const data of selectedRows) {
        patientIdArray.push({ patientId: data.sid });
      }
      const formData = {
        patient: patientIdArray,
        title: title,
        body: description,
        agentId: localData.id,
      };
      dispatch(sendNotificationUser(formData));
    } else {
      console.log("Send notification not validated");
    }
  };

  // useEffect(() => {
  //   if (sendNotificationUserSuccess) {
  //     handleCloseSendNotificationModal(true);
  //     Swal.fire({
  //       icon: "success",
  //       text: "Notification sent",
  //       showConfirmButton: false,
  //       timer: 2000,
  //     });
  //     setTitle("");
  //     setDescription("");
  //   }
  // }, [sendNotificationUserSuccess]);

  useEffect(() => {
    if (sendNotificationUserFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendNotificationUserFailure]);

  const validateSendNotification = () => {
    if (title == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Title cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (description == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Description cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };

  return (
    <>
      <MDBox pt={6} pb={3}>
        {isLoading ? (
          <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "2em" }}>
            <CircularProgress color="inherit" />
          </MDBox>
        ) : (
          <PatientCard data={cardData} />
        )}
        <MDInput
          label={filterName ? "" : "Search Patient"}
          style={{ postion: "relative", maxWidth: "235px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment>
                <MDButton
                  style={{ background: "transparent", marginRight: "-20px" }}
                  variant="gradient"
                  onClick={() =>
                    filterName.length ? searchPatientData(filterName) : patientData("0")
                  }
                >
                  <SearchIcon />
                </MDButton>
              </InputAdornment>
            ),
          }}
          value={filterName}
          onChange={(event) => {
            dispatch(searchValue(event.target.value));
            setFilterName(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter" && filterName.length) {
              setIsLoading(true);
              searchPatientData(filterName);
            } else if (event.key === "Enter" && filterName.length === 0) {
              patientData("0");
            }
          }}
        />

        <br />
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Patients
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              {checkBoxValue === true ? (
                <MDButton
                  style={{ float: "left" }}
                  variant="gradient"
                  color="info"
                  onClick={() => setOpenSendNotificationModal(true)}
                >
                  Send Notification
                </MDButton>
              ) : (
                ""
              )}
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="right"
                mt={{ xs: 2, sm: 0.1 }}
                ml={{ xs: -1.5, sm: 0.1 }}
                pt={1}
                px={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB}
                      onChange={handleChangeCheckbox}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  style={{ color: "green" }}
                  label="Result With Tests"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB1}
                      onChange={handleChangeCheckboxNA}
                      name="checkedB1"
                      color="primary"
                    />
                  }
                  style={{ color: "red" }}
                  label="Result with Test Not Done"
                />
              </MDBox>

              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <>
                    <DataGrid
                      disableSelectionOnClick={true}
                      checkboxSelection
                      rows={patientList}
                      columns={patientColumns.map((column) => ({
                        ...column,
                        filterable: false, // Disable the filter for all columns
                      }))}
                      pageSize={10}
                      rowCount={
                        responseData && responseData.totalCount ? responseData.totalCount : ""
                      }
                      paginationMode="server"
                      onPageChange={handlePageChange}
                      onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                    />
                  </>
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>

      <Modal
        open={openSendNotificationModal}
        onClose={handleCloseSendNotificationModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox mb={1} lineHeight={1}>
                            <center>
                              <h4>Send Notification</h4>
                            </center>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  onChange={(e) => setDescription(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleCloseSendNotificationModal}
                                  color="info"
                                >
                                  Cancel
                                </MDButton>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleSendNotification}
                                  color="info"
                                >
                                  Submit
                                </MDButton>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
    </>
  );
}
export default PatientTable;
