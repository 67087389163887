/* eslint-disable */
import create from "zustand";

const useStore = create((set) => ({
  width: window.innerWidth,
  height: window.innerHeight,
  setSize: ({ width, height }) => set({ width, height }),

  imageWidth: 100,
  imageHeight: 100,

  setImageSize: (size) => set(() => ({ imageWidth: size.width, imageHeight: size.height })),
  scale: 1,
  setScale: (scale) => set({ scale }),

  isAnnotation: true, // New field

  setIsAnnotation: (isAnnotation) => set({ isAnnotation }), // New setter function

  isDrawing: false,
  toggleIsDrawing: () => set((state) => ({ isDrawing: !state.isDrawing })),

  regions: [],
  setRegions: (regions) => set((state) => ({ regions })),

  selectedRegionId: null, // Fixed typo in field name
  selectRegion: (selectedRegionId) => set({ selectedRegionId }),

  brightness: 0,
  setBrightness: (brightness) => set({ brightness }, true),
}));

export default useStore;
