/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import MDBox from "components/MDBox";
import { Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Stack } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import Table from "react-bootstrap/Table";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewDoctor,
  updateDoctorDetails,
  doctorSelector,
  resetUpdateDoctorDetailsSuccess,
  resetAddNewDoctorSuccess,
} from "store/reducers/doctorSlice";
import { useEffect } from "react";

function AddEditDoctor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [gender, setGender] = useState(state && state.data ? state.data.gender : "");
  const [name, setName] = useState(state && state.data ? state.data.name : "");
  const [email, setEmail] = useState(state && state.data ? state.data.email : "");
  const [mobileNo, setMobileNo] = useState(state && state.data ? state.data.mobile : "");
  const [corrAddress, setCorrAddress] = useState(
    state && state.data ? state.data.correspondenceAddr : ""
  );
  const [city, setCity] = useState(state && state.data ? state.data.city : "");
  const [username, setUsername] = useState(state && state.data ? state.data.username : "");
  const [password, setPassword] = useState(state && state.data ? state.data.password : "");
  const [isInternal, setIsInternal] = useState(false);
  const [dobValue, setDOBValue] = useState(state && state.data ? state.data.dob : "");
  const [notes, setNotes] = useState(state && state.data ? state.data.notes : "");
  const [walletBalance, setWalletBalance] = useState(
    state && state.data ? state.data.wallet_bal : ""
  );
  const [validTill, setValidTill] = useState(state && state.data ? state.data.validTill : "");
  const [qualification, setQualification] = useState(
    state && state.data ? state.data.qualification : ""
  );
  const [experience, setExperience] = useState(state && state.data ? state.data.experience : "");
  const [availableSlots, setAvailableSlots] = useState(
    state && state.data ? state.data.availableSlots : ""
  );
  const [pinCode, setPinCode] = useState(state && state.data ? state.data.pin : "");
  const handleChange = (event) => {
    setGender(event.target.value);
  };
  const [clinics, setClinics] = useState(
    state && state.data ? state.data.clinics : [{ name: "", address: "" }]
  );
  const AddNewDoctorSuccess = useSelector(doctorSelector.AddNewDoctorSuccess);
  const AddNewDoctorFailure = useSelector(doctorSelector.AddNewDoctorFailure);
  const updateDoctorDetailsSuccess = useSelector(doctorSelector.updateDoctorDetailsSuccess);
  const updateDoctorDetailsFailure = useSelector(doctorSelector.updateDoctorDetailsFailure);

  const handleDOBValueChange = (newValue) => {
    setDOBValue(newValue);
  };
  const handleValidTillValueChange = (newValue) => {
    setValidTill(newValue._d);
  };

  const handleUpdateChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...clinics];
    list[index][name] = value;
    setClinics(list);
  };
  const handleAddClick = (event) => {
    event.preventDefault();
    setClinics([...clinics, { name: "", address: "", latitude: "", longitude: "" }]);
  };
  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...clinics];
    list.splice(index, 1);
    setClinics(list);
  };
  const validateLogin = () => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const experienceRegex = /^\d+(\.\d+)*$/;
    if (name === "") {
      Swal.fire({
        icon: "error",
        text: "Name cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (mobileNo === "") {
      Swal.fire({
        icon: "error",
        text: "Mobile Number cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (mobileNo.length <= 9) {
      Swal.fire({
        icon: "error",
        text: "Please enter valid mobile number",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (gender === "") {
      Swal.fire({
        icon: "error",
        text: "Please Select Gender",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (qualification === "") {
      Swal.fire({
        icon: "error",
        text: "Qualification cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (validTill === "") {
      Swal.fire({
        icon: "error",
        text: "Please Add Validation Date",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (notes === "") {
      Swal.fire({
        icon: "error",
        text: "Notes cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (experience === "") {
      Swal.fire({
        icon: "error",
        text: "Experience cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (!experienceRegex.test(experience)) {
      Swal.fire({
        icon: "error",
        text: "Add Experience in Numbers Only",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (availableSlots === "") {
      Swal.fire({
        icon: "error",
        text: "Available Time Slot cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (pinCode === "") {
      Swal.fire({
        icon: "error",
        text: "PinCode cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (corrAddress === "") {
      Swal.fire({
        icon: "error",
        text: "Address Time Slot cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (email === "") {
      Swal.fire({
        icon: "error",
        text: "Email id cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "error",
        text: "Email id is not valid",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (city === "") {
      Swal.fire({
        icon: "error",
        text: "City cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (dobValue === "") {
      Swal.fire({
        icon: "error",
        text: "Birth-Date cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (clinics.name === "" && clinics.address === "") {
      Swal.fire({
        icon: "error",
        text: "Clinic Name And Address Requied",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };
  const handleDoctorSubmit = () => {
    const formData = {
      name,
      mobile: mobileNo,
      gender,
      correspondenceAddr: corrAddress,
      email,
      city,
      dob: moment(dobValue).format("DD/MM/YYYY"),
      notes,
      validTill,
      clinics: [...clinics],
      qualification,
      experience,
      availableSlots,
      pin: pinCode,
      username,
      password,
    };
    if (validateLogin() === true) {
      dispatch(AddNewDoctor(formData));
    }
  };

  useEffect(() => {
    if (AddNewDoctorSuccess) {
      Swal.fire({
        icon: "success",
        text: "Doctor added successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetAddNewDoctorSuccess());
      navigate("/doctor");
    }
  }, [AddNewDoctorSuccess]);

  useEffect(() => {
    if (AddNewDoctorFailure || updateDoctorDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [AddNewDoctorFailure, updateDoctorDetailsFailure]);

  const handleUpdateDoctor = () => {
    const formData = {
      _id: state.doctorId,
      name: name,
      mobile: mobileNo,
      gender: gender,
      correspondenceAddr: corrAddress,
      email: email,
      city: city,
      dob: moment(dobValue).format("DD/MM/YYYY"),
      notes,
      validTill,
      clinics: [...clinics],
      qualification,
      experience,
      availableSlots,
      pin: pinCode,
      isInternal: isInternal == "true" ? true : false,
      walletBalance: parseInt(walletBalance),
      username,
      password,
    };
    dispatch(updateDoctorDetails(formData));
  };

  useEffect(() => {
    if (updateDoctorDetailsSuccess) {
      Swal.fire({
        icon: "success",
        text: "Doctor updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateDoctorDetailsSuccess());
      navigate("/doctordetails/" + state.doctorId);
    }
  }, [updateDoctorDetailsSuccess]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>{state && state.data ? "Edit Doctor" : "Add New Doctor"}</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Mobile "
                                value={mobileNo}
                                onChange={(e) => setMobileNo(e.target.value)}
                              />
                            </Grid>

                            <Grid item xs={16} sm={6}>
                              <RadioGroup
                                defaultChecked={gender}
                                row={true}
                                value={gender}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="male" control={<Radio />} label="Male" />
                                <FormControlLabel
                                  value="female"
                                  control={<Radio />}
                                  label="Female"
                                />
                                <FormControlLabel value="other" control={<Radio />} label="Other" />
                              </RadioGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Qualification"
                                value={qualification}
                                onChange={(e) => setQualification(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack spacing={3}>
                                  <DesktopDatePicker
                                    label="Valid Till"
                                    inputFormat="DD/MM/YYYY"
                                    value={validTill}
                                    onChange={handleValidTillValueChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Notes"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Experience (years)"
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Available time slots"
                                value={availableSlots}
                                onChange={(e) => setAvailableSlots(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="PIN code"
                                value={pinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Corr. Address"
                                value={corrAddress}
                                onChange={(e) => setCorrAddress(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="isInternal"
                                value={isInternal}
                                onChange={(e) => setIsInternal(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack spacing={3}>
                                  <DesktopDatePicker
                                    label="Date of Birth"
                                    inputFormat="DD/MM/YYYY"
                                    value={dobValue}
                                    onChange={handleDOBValueChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Grid>
                            {state && state.data && state.data.wallet_bal ? (
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="wallet Balance "
                                  value={walletBalance}
                                  onChange={(e) => setWalletBalance(e.target.value)}
                                />
                              </Grid>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <br />
                          <hr />
                          <b>Clinic Details</b>
                          <MDButton
                            variant="gradient"
                            onClick={handleAddClick}
                            style={{ float: "right" }}
                            color="info"
                          >
                            +
                          </MDButton>
                          <br />
                          <br />
                          {clinics.length > 0
                            ? clinics.map((data, i) => {
                                return (
                                  <Table responsive>
                                    <br />
                                    <tr>
                                      <td>
                                        <TextField
                                          fullWidth
                                          multiline
                                          name="name"
                                          value={data.name}
                                          label="Clinic Name"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                      <td>
                                        <TextField
                                          fullWidth
                                          multiline
                                          name="address"
                                          value={data.address}
                                          label="Clinic Address"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                      &nbsp;
                                      <td>
                                        {clinics.length !== 1 && (
                                          <MDButton
                                            variant="gradient"
                                            onClick={(event) => handleRemoveClick(event, i)}
                                            color="error"
                                          >
                                            x
                                          </MDButton>
                                        )}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <TextField
                                          fullWidth
                                          name="latitude"
                                          value={data.latitude}
                                          label="Latitude"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                      <td>
                                        <TextField
                                          fullWidth
                                          name="longitude"
                                          value={data.longitude}
                                          label="Longitude"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                    </tr>
                                  </Table>
                                );
                              })
                            : ""}
                          <br />
                          <center>
                            {state && state.data ? (
                              <MDButton
                                variant="gradient"
                                onClick={handleUpdateDoctor}
                                color="info"
                              >
                                Update Details
                              </MDButton>
                            ) : (
                              <MDButton
                                variant="gradient"
                                onClick={handleDoctorSubmit}
                                color="info"
                              >
                                Submit Details
                              </MDButton>
                            )}
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
export default AddEditDoctor;
