/* eslint-disable */

import MDBox from "components/MDBox";
import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DatePicker from "react-horizontal-datepicker";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookAppointment,
  updateTimeSlotStatus,
  getAppointmentSlots,
  appointmentSelector,
} from "store/reducers/appointmentSlice";

// BookAppointment page components
function BookAppointment() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [doctorDetail, setDoctorDetail] = useState();
  const [timeslotsData, setTimeslotsData] = useState();
  const [timeData, setTimeData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const { state } = useLocation();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const dispatch = useDispatch();
  const [dataFetched, setDataFetched] = useState(false);
  const bookAppointmentSuccess = useSelector(appointmentSelector.bookAppointmentSuccess);
  const bookAppointmentFailure = useSelector(appointmentSelector.bookAppointmentFailure);
  const updateTimeSlotStatusSuccess = useSelector(appointmentSelector.updateTimeSlotStatusSuccess);
  const updateTimeSlotStatusFailure = useSelector(appointmentSelector.updateTimeSlotStatusFailure);
  const appointmentSlotsData = useSelector((state) => state?.appointment?.appointments);
  const getAppointmentSlotsSuccess = useSelector(appointmentSelector.getAppointmentSlotsSuccess);
  const getAppointmentSlotsFailure = useSelector(appointmentSelector.getAppointmentSlotsFailure);

  useEffect(() => {
    getAppointmentSlotsData(new Date().toISOString());
  }, []);

  const onSelectedDay = (date) => {
    getAppointmentSlotsData(new Date(date).toISOString());
    setSelectedDate(new Date(date).toISOString());
  };

  const getAppointmentSlotsData = (date) => {
    dispatch(getAppointmentSlots(date, loginData.token));
  };

  useEffect(() => {
    console.log("appointmentSlotsData", appointmentSlotsData);
    if (appointmentSlotsData) {
      setDoctorDetail(appointmentSlotsData);
    }
  }, [appointmentSlotsData]);

  useEffect(() => {
    if (getAppointmentSlotsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAppointmentSlotsFailure]);

  const handleBookAppointment = (doctorData, timeData, timeslotsData, slotIndex) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, book it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const bookFormData = {
          did: doctorData && doctorData.doctorId ? doctorData.doctorId : "",
          startTime: selectedDate,
          pid: state.patientId,
          duration: "30 min",
          cid: doctorData && doctorData.clinicId ? doctorData.clinicId : "",
          slotId:
            timeData && timeData.timeSlots[slotIndex] && timeData.timeSlots[slotIndex]._id
              ? timeData.timeSlots[slotIndex]._id
              : "",
          title:
            "Appointment with Dr." +
            doctorData.doctorName +
            " Slot Time: " +
            timeData.timeSlots[slotIndex].time,
        };
        setTimeData(timeData);
        setTimeslotsData(timeslotsData);
        appointmentBook(bookFormData, timeData, timeslotsData);
      }
    });
  };

  const appointmentBook = async (bookFormData, timeData, timeslotsData) => {
    dispatch(bookAppointment(bookFormData));
  };

  useEffect(() => {
    if (bookAppointmentSuccess) {
      handleUpdateTimeSlotStatus(timeData, timeslotsData);
    }
  }, [bookAppointmentSuccess]);

  useEffect(() => {
    if (bookAppointmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [bookAppointmentFailure]);

  const handleUpdateTimeSlotStatus = (timeSlotsData, timedata) => {
    const formData = {
      timeSlotId: timeSlotsData && timeSlotsData._id ? timeSlotsData._id : "",
      timeId: timedata && timedata._id ? timedata._id : "",
      timeSlotStatus: "Booked",
      timeSlotDate: selectedDate,
    };
    dispatch(updateTimeSlotStatus(formData));
  };

  useEffect(() => {
    if (updateTimeSlotStatusSuccess) {
      Swal.fire({
        icon: "success",
        text: "Timeslot status updated successfully",
        timer: 2000,
        showConfirmButton: false,
      });
      getAppointmentSlotsData(new Date().toISOString());
    }
  }, [updateTimeSlotStatusSuccess]);

  useEffect(() => {
    if (updateTimeSlotStatusFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateTimeSlotStatusFailure]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card id="delete-account">
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            <div className="App">
              <DatePicker getSelectedDay={onSelectedDay} labelFormat={"MMMM"} color={"#374e8c"} />
            </div>
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {doctorDetail &&
              doctorDetail.map((doctor) => {
                return (
                  <>
                    <Grid container>
                      <Grid item xs={6}>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          borderRadius="lg"
                          p={3}
                          mt={2}
                        >
                          <MDBox width="100%" display="flex" flexDirection="column">
                            <MDBox mb={1} lineHeight={0.1}>
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        <MDTypography variant="button" fontWeight="medium">
                                          Clinic : {doctor.clinicName}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        <MDTypography variant="button" fontWeight="medium">
                                          Dr. {doctor.doctorName}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        <MDTypography variant="button" fontWeight="medium">
                                          Address : {doctor.clinicAddress}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </tr>
                                </tbody>
                              </Table>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Grid>
                      <Grid item xs={6}>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          borderRadius="lg"
                          p={3}
                          mt={2}
                        >
                          <MDBox width="100%" display="flex" flexDirection="column">
                            <MDBox mb={1} lineHeight={0.1}>
                              {doctor.timeSlots && doctor.timeSlots.length > 0 ? (
                                doctor.timeSlots.map((timeslots) => {
                                  return (
                                    <Grid>
                                      {timeslots.timeSlots && timeslots.timeSlots.length > 0
                                        ? timeslots.timeSlots.map((timeslotsData, i) => {
                                            return (
                                              <>
                                                <MDButton
                                                  variant="gradient"
                                                  style={{
                                                    marginBottom: "10px",
                                                    width: "120px",
                                                  }}
                                                  mb={10}
                                                  color={
                                                    timeslotsData.status === "Booked"
                                                      ? "error"
                                                      : "info"
                                                  }
                                                  disabled={timeslotsData.status === "Booked"}
                                                  onClick={(e) =>
                                                    handleBookAppointment(
                                                      doctor,
                                                      timeslots,
                                                      timeslotsData,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {timeslotsData.time}
                                                </MDButton>
                                                &nbsp;&nbsp;
                                              </>
                                            );
                                          })
                                        : ""}
                                    </Grid>
                                  );
                                })
                              ) : (
                                <MDTypography>No Slots Are Available</MDTypography>
                              )}
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default BookAppointment;
