// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getAgentsSuccess,
  getAgentsFailure,
  getAgentsDataSuccess,
  getAgentsDataFailure,
  addNewAgentSuccess,
  addNewAgentFailure,
  updateUserSuccess,
  updateUserFailure,
  getAgentDetailsSuccess,
  getAgentDetailsFailure,
  getPatientTestSuccess,
  getPatientTestFailure,
  getPatientTestByAgentSuccess,
  getPatientTestByAgentFailure,
  assignDoctorSuccess,
  assignDoctorFailure,
  getInternalDoctorsSuccess,
  getInternalDoctorsFailure,
  transferPatientsSuccess,
  transferPatientsFailure,
  getPatientsTestsMappingSuccess,
  getPatientsTestsMappingFailure,
  updateAnnotationStatusSuccess,
  updateAnnotationStatusFailure,
  checkAgentOnlineSuccess,
  checkAgentOnlineFailure,
  createUserSuccess,
  createUserFailure,
  updateAgentDetailsSuccess,
  updateAgentDetailsFailure,
  agentLoginSuccess,
  agentLoginFailure,
} from "store/reducers/agentSlice"; // Import your action creator
import agentService from "services/agent"; // Import your service function

// agentLogin

function* getAgents(action) {
  try {
    const response = yield call(agentService.getAgents, action.payload);
    yield put(getAgentsSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getAgentsFailure(error));
  }
}

function* getAgentsData(action) {
  try {
    const response = yield call(agentService.getAgentsData, action.payload);
    yield put(getAgentsDataSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getAgentsDataFailure(error));
  }
}

function* addNewAgent(action) {
  try {
    const response = yield call(agentService.addNewAgent, action.payload);
    yield put(addNewAgentSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(addNewAgentFailure(error));
  }
}

function* updateUser(action) {
  try {
    const response = yield call(agentService.updateUser, action.payload);
    yield put(updateUserSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateUserFailure(error));
  }
}

function* getAgentDetails(action) {
  try {
    const response = yield call(agentService.getAgentDetails, action.payload);
    yield put(getAgentDetailsSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getAgentDetailsFailure(error));
  }
}

function* getPatientTestByAgent(action) {
  try {
    const response = yield call(agentService.getPatientTestByAgent, action.payload);
    yield put(getPatientTestByAgentSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getPatientTestByAgentFailure(error));
  }
}

function* getPatientTest(action) {
  try {
    const response = yield call(agentService.getPatientTest, action.payload);
    yield put(getPatientTestSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getPatientTestFailure(error));
  }
}

function* assignDoctor(action) {
  try {
    const response = yield call(agentService.assignDoctor, action.payload);
    yield put(assignDoctorSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(assignDoctorFailure(error));
  }
}

function* getInternalDoctors(action) {
  try {
    const response = yield call(agentService.getInternalDoctors, action.payload);
    yield put(getInternalDoctorsSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getInternalDoctorsFailure(error));
  }
}

function* transferPatients(action) {
  try {
    const response = yield call(agentService.transferPatients, action.payload);
    yield put(transferPatientsSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(transferPatientsFailure(error));
  }
}

function* getPatientsTestsMapping(action) {
  try {
    const response = yield call(agentService.getPatientsTestsMapping, action.payload);
    yield put(getPatientsTestsMappingSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(getPatientsTestsMappingFailure(error));
  }
}

function* updateAnnotationStatus(action) {
  try {
    const response = yield call(agentService.updateAnnotationStatus, action.payload);
    yield put(updateAnnotationStatusSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateAnnotationStatusFailure(error));
  }
}

function* checkAgentOnline(action) {
  try {
    const response = yield call(agentService.checkAgentOnline, action.payload);
    yield put(checkAgentOnlineSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(checkAgentOnlineFailure(error));
  }
}

function* createUser(action) {
  try {
    const response = yield call(agentService.createUser, action.payload);
    yield put(createUserSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(createUserFailure(error));
  }
}

function* updateAgentDetails(action) {
  try {
    const response = yield call(agentService.updateAgentDetails, action.payload);
    yield put(updateAgentDetailsSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(updateAgentDetailsFailure(error));
  }
}

function* agentLogin(action) {
  try {
    console.log("action", action);
    const response = yield call(agentService.agentLogin, action.payload);
    yield put(agentLoginSuccess(response.data));
  } catch (error) {
    console.log(error);
    yield put(agentLoginFailure(error));
  }
}

function* authToken(action) {
  try {
    const response = action.payload;
  } catch (error) {
    console.log(error);
  }
}

// Watcher Saga
function* agentSaga() {
  yield takeLatest("agents/getAgents", getAgents);
  yield takeLatest("agents/getAgentsData", getAgentsData);
  yield takeLatest("agents/addNewAgent", addNewAgent);
  yield takeLatest("agents/updateUser", updateUser);
  yield takeLatest("agents/getAgentDetails", getAgentDetails);
  yield takeLatest("agents/getPatientTestByAgent", getPatientTestByAgent);
  yield takeLatest("agents/getPatientTest", getPatientTest);
  yield takeLatest("agents/assignDoctor", assignDoctor);
  yield takeLatest("agents/getInternalDoctors", getInternalDoctors);
  yield takeLatest("agents/transferPatients", transferPatients);
  yield takeLatest("agents/getPatientsTestsMapping", getPatientsTestsMapping);
  yield takeLatest("agents/updateAnnotationStatus", updateAnnotationStatus);
  yield takeLatest("agents/checkAgentOnline", checkAgentOnline);
  yield takeLatest("agents/createUser", createUser);
  yield takeLatest("agents/updateAgentDetails", updateAgentDetails);
  yield takeLatest("agents/agentLogin", agentLogin);
  yield takeLatest("agents/authToken", authToken);
}

// Export all sagas
export default agentSaga;
