/* eslint-disable */

import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getTotalInvoice = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getTotalInvoice", {
    headers,
  });
};

const getWeeklyRevenue = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getWeeklyRevenue", {
    headers,
  });
};

const getAppointmentDayWise = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getAppointmentDayWise", {
    headers,
  });
};

const getAnalysisData = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getAnalytics", {
    headers,
  });
};

const getTotalAppointments = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getTotalAppointmentData", {
    headers,
  });
};

const getKioskScanData = (formData) => {
  const { token, month, year } = formData;
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(
    constant.API_URL + "/kiosk/getScanCountOfAllKiosk?month=" + month + "&year=" + year,
    {
      headers,
    }
  );
};

const getAllScanData = (formData) => {
  let { token, startDate, endDate } = formData;
  startDate = "2023-06-20T10:42:44.248Z";
  endDate = "2023-06-14T10:42:44.248Z";
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  console.log("headers", headers);
  return cachedAxiosPost(
    constant.API_URL + "/agent/fetchAllScans?endDate=" + startDate + "&startDate=" + endDate,
    {},
    {
      headers,
    }
  );
};

const getDataCount = (token, newStartDate, newEndDate) => {
  headers = {
    Authorization: "bearer " + token,
  };
  return cachedAxiosGet(
    constant.API_URL + "/agent/totalPatientsAnalysis/" + newStartDate + "/" + newEndDate,
    // { newStartDate, newEndDate },
    {
      headers,
    }
  );
};

const doctorService = {
  getDataCount,
  getAnalysisData,
  getTotalInvoice,
  getWeeklyRevenue,
  getAppointmentDayWise,
  getTotalAppointments,
  getKioskScanData,
  getAllScanData,
};

export default doctorService;
