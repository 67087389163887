// testDetailsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const testDetailsSlice = createSlice({
  name: "testDetails",
  initialState: {
    testDetails: [],
    isloading: false,
    getBasePathArraySuccess: false,
    getBasePathArrayFailure: false,
    getBasePathVideoSuccess: false,
    getBasePathVideoFailure: false,
    getReportHistorySuccess: false,
    getReportHistoryFailure: false,
    sendReportSuccess: false,
    sendReportFailure: false,
    downloadReportSuccess: false,
    downloadReportFailure: false,
    getPendingAnnotationSuccess: false,
    getPendingAnnotationFailure: false,
    approveAnnotationSuccess: false,
    approveAnnotationFailure: false,
    rejectAnnotationSuccess: false,
    rejectAnnotationFailure: false,
    getDiseaseDataSuccess: false,
    getDiseaseDataFailure: false,
    // Other initial state properties
  },

  reducers: {
    getBasePathArray: (state, action) => {
      state.isloading = false;
    },
    getBasePathArraySuccess: (state, action) => {
      state.basePathArray = action.payload;
      state.isloading = true;
      state.getBasePathArraySuccess = true;
    },
    getBasePathArrayFailure: (state) => {
      state.isloading = false;
      state.getBasePathArrayFailure = true;
    },
    getBasePathVideo: (state, action) => {
      state.isloading = false;
    },
    getBasePathVideoSuccess: (state, action) => {
      state.basePathVideo = action.payload;
      state.isloading = true;
      state.getBasePathVideoSuccess = true;
    },
    getBasePathVideoFailure: (state) => {
      state.isloading = false;
      state.getBasePathVideoFailure = true;
    },
    getReportHistory: (state, action) => {
      state.isloading = false;
    },
    getReportHistorySuccess: (state, action) => {
      state.reportHistory = action.payload;
      state.isloading = true;
      state.getReportHistorySuccess = true;
    },
    getReportHistoryFailure: (state) => {
      state.isloading = false;
      state.getReportHistoryFailure = true;
    },
    sendReport: (state, action) => {
      state.isloading = false;
    },
    sendReportSuccess: (state, action) => {
      state.testDetails = action.payload;
      state.isloading = true;
      state.sendReportSuccess = true;
    },
    sendReportFailure: (state) => {
      state.isloading = false;
      state.sendReportFailure = true;
    },
    resetDownloadReportSuccess: (state, action) => {
      state.downloadReportSuccess = false;
    },
    downloadReport: (state, action) => {
      state.isloading = false;
    },
    downloadReportSuccess: (state, action) => {
      state.downloadReport = action.payload;
      state.isloading = true;
      state.downloadReportSuccess = true;
    },
    downloadReportFailure: (state) => {
      state.isloading = false;
      state.downloadReportFailure = true;
    },
    getPendingAnnotation: (state, action) => {
      state.isloading = false;
    },
    getPendingAnnotationSuccess: (state, action) => {
      state.testDetails = action.payload;
      state.isloading = true;
      state.getPendingAnnotationSuccess = true;
    },
    getPendingAnnotationFailure: (state) => {
      state.isloading = false;
      state.getPendingAnnotationFailure = true;
    },
    approveAnnotation: (state, action) => {
      state.isloading = false;
    },
    approveAnnotationSuccess: (state, action) => {
      state.testDetails = action.payload;
      state.isloading = true;
      state.approveAnnotationSuccess = true;
    },
    approveAnnotationFailure: (state) => {
      state.isloading = false;
      state.approveAnnotationFailure = true;
    },
    rejectAnnotation: (state, action) => {
      state.isloading = false;
    },
    rejectAnnotationSuccess: (state, action) => {
      state.testDetails = action.payload;
      state.isloading = true;
      state.rejectAnnotationSuccess = true;
    },
    rejectAnnotationFailure: (state) => {
      state.isloading = false;
      state.rejectAnnotationFailure = true;
    },
    getDiseaseData: (state, action) => {
      state.isloading = false;
    },
    getDiseaseDataSuccess: (state, action) => {
      state.diseaseData = action.payload;
      state.isloading = true;
      state.getDiseaseDataSuccess = true;
    },
    getDiseaseDataFailure: (state) => {
      state.isloading = false;
      state.getDiseaseDataFailure = true;
    },
    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.testDetails;

export const testDetailsSelector = {
  getBasePathArraySuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getBasePathArraySuccess
  ),
  getBasePathArrayFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getBasePathArrayFailure
  ),
  getBasePathVideoSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getBasePathVideoSuccess
  ),
  getBasePathVideoFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getBasePathVideoFailure
  ),
  getReportHistorySuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getReportHistorySuccess
  ),
  getReportHistoryFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getReportHistoryFailure
  ),
  sendReportSuccess: createSelector(selectKiosk, (kioskState) => kioskState.sendReportSuccess),
  sendReportFailure: createSelector(selectKiosk, (kioskState) => kioskState.sendReportFailure),
  downloadReportSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.downloadReportSuccess
  ),
  downloadReportFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.downloadReportFailure
  ),
  getPendingAnnotationSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPendingAnnotationSuccess
  ),
  getPendingAnnotationFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getPendingAnnotationFailure
  ),
  approveAnnotationSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.approveAnnotationSuccess
  ),
  approveAnnotationFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.approveAnnotationFailure
  ),
  rejectAnnotationSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.rejectAnnotationSuccess
  ),
  rejectAnnotationFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.rejectAnnotationFailure
  ),
  getDiseaseDataSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getDiseaseDataSuccess
  ),
  getDiseaseDataFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getDiseaseDataFailure
  ),

  // Add other selectors if needed
};
export const {
  getBasePathArray,
  getBasePathArraySuccess,
  getBasePathArrayFailure,
  getBasePathVideo,
  getBasePathVideoSuccess,
  getBasePathVideoFailure,
  getReportHistory,
  getReportHistorySuccess,
  getReportHistoryFailure,
  sendReport,
  sendReportSuccess,
  sendReportFailure,
  resetDownloadReportSuccess,
  downloadReport,
  downloadReportSuccess,
  downloadReportFailure,
  getPendingAnnotation,
  getPendingAnnotationSuccess,
  getPendingAnnotationFailure,
  approveAnnotation,
  approveAnnotationSuccess,
  approveAnnotationFailure,
  rejectAnnotation,
  rejectAnnotationSuccess,
  rejectAnnotationFailure,
  getDiseaseData,
  getDiseaseDataSuccess,
  getDiseaseDataFailure,
} = testDetailsSlice.actions;
export default testDetailsSlice.reducer;
