// dashboardSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboards",
  initialState: {
    dashboards: [],
    isloading: false,
    getDataCountSuccess: false,
    getDataCountFailure: false,
    getAnalysisDataSuccess: false,
    getAnalysisDataFailure: false,
    getTotalInvoiceSuccess: false,
    getTotalInvoiceFailure: false,
    getWeeklyRevenueSuccess: false,
    getWeeklyRevenueFailure: false,
    getAppointmentDayWiseSuccess: false,
    getAppointmentDayWiseFailure: false,
    getTotalAppointmentsSuccess: false,
    getTotalAppointmentsFailure: false,
    getKioskScanDataSuccess: false,
    getKioskScanDataFailure: false,
    // Other initial state properties
  },

  reducers: {
    getDataCount: (state, action) => {
      state.isloading = false;
    },
    getDataCountSuccess: (state, action) => {
      state.dashboards = action.payload;
      state.isloading = true;
      state.getDataCountSuccess = true;
    },
    getDataCountFailure: (state) => {
      state.isloading = false;
      state.getDataCountFailure = true;
    },
    getAnalysisData: (state, action) => {
      state.isloading = false;
    },
    getAnalysisDataSuccess: (state, action) => {
      state.analysis = action.payload;
      state.isloading = true;
      state.getAnalysisDataSuccess = true;
    },
    getAnalysisDataFailure: (state) => {
      state.isloading = false;
      state.getAnalysisDataFailure = true;
    },
    getTotalInvoice: (state, action) => {
      state.isloading = false;
    },
    getTotalInvoiceSuccess: (state, action) => {
      state.totalInvoice = action.payload;
      state.isloading = true;
      state.getTotalInvoiceSuccess = true;
    },
    getTotalInvoiceFailure: (state) => {
      state.isloading = false;
      state.getTotalInvoiceFailure = true;
    },
    getWeeklyRevenue: (state, action) => {
      state.isloading = false;
    },
    getWeeklyRevenueSuccess: (state, action) => {
      state.dashboards = action.payload;
      state.isloading = true;
      state.getWeeklyRevenueSuccess = true;
    },
    getWeeklyRevenueFailure: (state) => {
      state.isloading = false;
      state.getWeeklyRevenueFailure = true;
    },
    getAppointmentDayWise: (state, action) => {
      state.isloading = false;
    },
    getAppointmentDayWiseSuccess: (state, action) => {
      state.appointmentDayWise = action.payload;
      state.isloading = true;
      state.getAppointmentDayWiseSuccess = true;
    },
    getAppointmentDayWiseFailure: (state) => {
      state.isloading = false;
      state.getAppointmentDayWiseFailure = true;
    },
    getTotalAppointments: (state, action) => {
      state.isloading = false;
    },
    getTotalAppointmentsSuccess: (state, action) => {
      state.totalAppointments = action.payload;
      state.isloading = true;
      state.getTotalAppointmentsSuccess = true;
    },
    getTotalAppointmentsFailure: (state) => {
      state.isloading = false;
      state.getTotalAppointmentsFailure = true;
    },
    getKioskScanData: (state, action) => {
      state.isloading = false;
    },
    getKioskScanDataSuccess: (state, action) => {
      state.kioskScans = action.payload;
      state.isloading = true;
      state.getKioskScanDataSuccess = true;
    },
    getKioskScanDataFailure: (state) => {
      state.isloading = false;
      state.getKioskScanDataFailure = true;
    },
    getAllScanData: (state, action) => {
      state.isloading = false;
    },
    getAllScanDataSuccess: (state, action) => {
      state.allScans = action.payload;
      state.isloading = true;
      state.getAllScanDataSuccess = true;
    },
    getAllScanDataFailure: (state) => {
      state.isloading = false;
      state.getAllScanDataFailure = true;
    },
    // Other reducers if needed
  },
});

const selectDashboard = (state) => state.dashboard;

export const dashboardSelector = {
  getDataCountSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getDataCountSuccess
  ),
  getDataCountFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getDataCountFailure
  ),
  getAnalysisDataSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAnalysisDataSuccess
  ),
  getAnalysisDataFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAnalysisDataFailure
  ),
  getTotalInvoiceSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getTotalInvoiceSuccess
  ),
  getTotalInvoiceFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getTotalInvoiceFailure
  ),
  getWeeklyRevenueSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getWeeklyRevenueSuccess
  ),
  getWeeklyRevenueFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getWeeklyRevenueFailure
  ),
  getAppointmentDayWiseSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAppointmentDayWiseSuccess
  ),
  getAppointmentDayWiseFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAppointmentDayWiseFailure
  ),
  getTotalAppointmentsSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getTotalAppointmentsSuccess
  ),
  getTotalAppointmentsFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getTotalAppointmentsFailure
  ),
  getKioskScanDataSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getKioskScanDataSuccess
  ),
  getKioskScanDataFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getKioskScanDataFailure
  ),
  getAllScanDataSuccess: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAllScanDataSuccess
  ),
  getAllScanDataFailure: createSelector(
    selectDashboard,
    (kioskState) => kioskState.getAllScanDataFailure
  ),
  // Add other selectors if needed
};

export const {
  getDataCount,
  getDataCountSuccess,
  getDataCountFailure,
  getAnalysisData,
  getAnalysisDataSuccess,
  getAnalysisDataFailure,
  getTotalInvoice,
  getTotalInvoiceSuccess,
  getTotalInvoiceFailure,
  getWeeklyRevenue,
  getWeeklyRevenueSuccess,
  getWeeklyRevenueFailure,
  getAppointmentDayWise,
  getAppointmentDayWiseSuccess,
  getAppointmentDayWiseFailure,
  getTotalAppointments,
  getKioskScanData,
  getTotalAppointmentsSuccess,
  getTotalAppointmentsFailure,
  getKioskScanDataSuccess,
  getKioskScanDataFailure,
  getAllScanData,
  getAllScanDataSuccess,
  getAllScanDataFailure,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
