/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FollowUpReminderTable from "./components/followUpReminderTable";
function FollowUpReminder() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <FollowUpReminderTable />
      </DashboardLayout>
    </>
  );
}
export default FollowUpReminder;
