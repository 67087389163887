/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Grid, TextField, Box, Modal, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { darken, lighten } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addCoinsToMainAccount,
  addCoins,
  removeCoins,
  ddAccountSelector,
} from "store/reducers/ddAccountSlice";
import { getPatientDetails, patientDetailsSelector } from "store/reducers/patientdetailsSlice";

function CoinsInfo(props) {
  const dispatch = useDispatch();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [openAddCoinsModal, setOpenAddCoinsModal] = useState(false);
  const [removeCoinsData, setRemoveCoinsData] = useState(false);
  const handleCloseAddCoinsModal = () => setOpenAddCoinsModal(false);
  const [transactionTitle, setTransactionTitle] = useState("");
  const [coinAmount, setCoinAmount] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [accountBalance, setAccountBalance] = useState("");
  const patientDetailsData = useSelector((state) => state?.patientDetails?.patientDetails);
  const getPatientDetailsSuccess = useSelector(patientDetailsSelector.getPatientDetailsSuccess);
  const getPatientDetailsFailure = useSelector(patientDetailsSelector.getPatientDetailsFailure);
  const addCoinsToMainAccountSuccess = useSelector(ddAccountSelector.addCoinsToMainAccountSuccess);
  const addCoinsToMainAccountFailure = useSelector(ddAccountSelector.addCoinsToMainAccountFailure);
  const addCoinsSuccess = useSelector(ddAccountSelector.addCoinsSuccess);
  const addCoinsFailure = useSelector(ddAccountSelector.addCoinsFailure);
  const removeCoinsSuccess = useSelector(ddAccountSelector.removeCoinsSuccess);
  const removeCoinsFailure = useSelector(ddAccountSelector.removeCoinsFailure);

  useEffect(() => {
    patientsList();
  }, []);
  const coinsDetailsColumns = [
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return (
          <>
            <div
              style={{
                color: props.data.accountData._id === data.row.destinationAcount ? "green" : "red",
              }}
            >
              {props.data.accountData._id === data.row.destinationAcount ? "+" : "-"}
              {data.row.amount}
            </div>
          </>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "TransactionDate",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.createdAt).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 350,
      renderCell: (data) => {
        return data.row.title;
      },
    },
  ];

  const patientsList = () => {
    const formData = {
      patientId: props.patientId,
      token: loginData?.token,
    };
    dispatch(getPatientDetails(formData));
  };

  useEffect(() => {
    if (
      patientDetailsData &&
      patientDetailsData.patient &&
      patientDetailsData.patient.accountData &&
      Array.isArray(patientDetailsData.patient.accountData.transaction) &&
      Array.isArray(patientDetailsData.patient.accountData.balance)
    ) {
      const reversedTransactionList = [
        ...patientDetailsData.patient.accountData.transaction,
      ].reverse();
      setTransactionList(reversedTransactionList);
      setAccountBalance([...patientDetailsData.patient.accountData.balance]);
    }
  }, [patientDetailsData]);

  useEffect(() => {
    if (getPatientDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientDetailsFailure]);

  const handleRemoveCoins = () => {
    const formData = {
      patientId: props.patientId,
      transactionTitle: transactionTitle,
      coinsAmount: parseInt(coinAmount),
    };
    dispatch(removeCoins(formData));
  };

  useEffect(() => {
    if (removeCoinsSuccess) {
      patientsList();
      handleCloseAddCoinsModal(true);
      Swal.fire({
        icon: "success",
        text: "Coins Remove SuccessFully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeCoinsSuccess]);

  useEffect(() => {
    if (removeCoinsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeCoinsFailure]);

  const handleAddCoins = () => {
    const formData = {
      patientId: props.patientId,
      transactionTitle: transactionTitle,
      coinsAmount: parseInt(coinAmount),
    };
    dispatch(addCoins(formData));
  };

  useEffect(() => {
    if (addCoinsSuccess) {
      patientsList();
      handleCloseAddCoinsModal(true);
      Swal.fire({
        icon: "success",
        text: "Coins Added SuccessFully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addCoinsSuccess]);

  useEffect(() => {
    if (addCoinsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addCoinsFailure]);

  return (
    <>
      {accountBalance || transactionList.length ? (
        <>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0.1 }}
              ml={{ xs: -1.5, sm: 0.1 }}
            >
              <MDBox
                mr={1}
                style={{
                  float: "left",
                }}
              >
                Balance :&nbsp;
                {accountBalance ? accountBalance : 0}
              </MDBox>
            </MDBox>

            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0.1 }}
              ml={{ xs: -1.5, sm: 0.1 }}
            >
              <MDBox mr={1} style={{ float: "right" }}>
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    setRemoveCoinsData(false);
                    setOpenAddCoinsModal(true);
                  }}
                  color="info"
                >
                  <AddIcon />
                  Add Coins
                </MDButton>
              </MDBox>
              <MDBox mr={1} style={{ float: "right" }}>
                <MDButton
                  variant="gradient"
                  onClick={() => {
                    setRemoveCoinsData(true);
                    setOpenAddCoinsModal(true);
                  }}
                  color="info"
                >
                  <RemoveIcon />
                  Remove Coins
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </>
      ) : (
        <MDTypography>
          <h4 style={{ color: "red", textAlign: "center" }}>Yet Account Is Not Created</h4>
        </MDTypography>
      )}
      {props.data && props.data.accountData && props.data.accountData.transaction ? (
        <>
          <div style={{ height: "370px", width: "100%" }}>
            <DataGrid
              rows={transactionList ? transactionList : []}
              // rows={props.data.accountData.transaction}
              columns={coinsDetailsColumns}
              disableSelectionOnClick
              pageSize={5}
              getRowId={(row) => row._id}
              getRowClassName={(params) => `super-app-theme--${params.row.destinationAcount}`}
            />
          </div>
        </>
      ) : (
        ""
      )}
      <Modal
        open={openAddCoinsModal}
        onClose={handleCloseAddCoinsModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column">
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox mb={1} lineHeight={1}>
                            <center>
                              <h4> {removeCoinsData ? "Remove Coins" : "Add Coins"}</h4>
                            </center>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Amount"
                                  value={coinAmount}
                                  onChange={(e) => setCoinAmount(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  value={transactionTitle}
                                  onChange={(e) => setTransactionTitle(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleCloseAddCoinsModal}
                                  color="info"
                                >
                                  Cancel
                                </MDButton>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                {removeCoinsData ? (
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleRemoveCoins}
                                    color="info"
                                  >
                                    Remove
                                  </MDButton>
                                ) : (
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleAddCoins}
                                    color="info"
                                  >
                                    Add
                                  </MDButton>
                                )}
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
    </>
  );
}
export default CoinsInfo;
