import axios from 'axios';
import Constants from 'utils/constants';
import { cachedAxiosGet, } from 'services/apiCacheService';
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + (loginData === null ? "a" : loginData.token),
};

const affiliateService = {
  getAllAffiliates: async () => {
    try {
      const response = await cachedAxiosGet(`${Constants.API_URL}/agent/affiliateList`, { headers });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default affiliateService;
