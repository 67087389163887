/* eslint-disable */

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextField from "@mui/material/TextField";
import { Stack, Typography } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import { Select, FormControl, InputLabel } from "@mui/material";
import moment from "moment";
import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {
  addNewPatient,
  updatePatient,
  patientSelector,
  resetAddNewPatientSuccess,
  resetUpdatePatientSuccess,
} from "store/reducers/patientSlice";
import { getAgents, agentSelector } from "store/reducers/agentSlice";
import { useDispatch, useSelector } from "react-redux";

function AddPatient() {
  const dispatch = useDispatch();
  const medicalHistoryArray = [
    "Diabetes",
    "Hypertension",
    "Hypotension",
    "Asthma",
    "Hypo Thyroid",
    "Hyper Thyroid",
    "Epilepsy",
    "Anemia",
    "PCOD",
    "Menopause",
    "Bowel Disorders",
    "Bone Disorders",
    "Frequent Headaches",
    "Other",
  ];
  const tobaccoArray = ["Chewing", "Smoking", "Other"];
  const pregencyArray = ["Yes", "No"];
  const maritalStatusArray = ["Single", "Married", "Widowed", "Divorced"];
  const dentalHistoryArray = [
    "RCT",
    "Crown/Cap",
    "Filling",
    "Ortho",
    "Extraction",
    "Implant",
    "Scaling",
    "Other",
  ];
  const sourceArray = ["Google", "Social Media", "Referal", "Kiosk Karad", "Other"];
  const leadStatusArray = ["Hot", "Warm", "Cold"];
  const oralCareList = [
    "General Oral Type",
    "Cavity Prone mouth",
    "Malaligned Mouth",
    "Malaligned Mouth with maintenace",
    "Prosthetic Mouth",
    "Dry Mouth",
    "Sensitive Mouth",
    "Bleeding Mouth",
  ];
  const currentMedicationsArray = ["Paracetmol 650mg", "Tynuronorm 75mg"];
  const modeOfCommunicationArray = ["Call", "Message", "Mail"];
  const allergiesArray = [
    "Anox",
    "Anaesthesia",
    "AntiBiotics/Metronidazdle",
    "Analgesic",
    "Dust/Pollution",
    "Multi Vitamin",
    "Penicillin",
    "Sulphur",
  ];
  const countryList = ["India", "Bangladesh", "Nepal", "Pakistan"];
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("state", state?.data?.dob);
  const [dobValue, setDOBValue] = useState(state?.data?.dob ? state?.data?.dob : "");
  const [dobError, setDOBError] = useState("");
  const [agentData, setAgentData] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(state && state.data ? state.data.agentId : "");
  const [name, setName] = useState(state && state.data ? state.data.patientName : "");
  const [preferredName, setPreferredName] = useState(
    state && state.data ? state.data.preferredName : ""
  );
  const [selectedOralCareType, setSelectedOralCareType] = useState(
    state && state.data ? state.data.oralType : ""
  );
  const notesArray = [];
  const [medicalHistory, setMedicalHistory] = useState(
    state && state.data ? state.data.medicalHistory : []
  );
  const [pregnancy, setPregnancy] = useState(state && state.data ? state.data.pregnancy : "");
  const [maritalStatus, setMaritalStatus] = useState(
    state && state.data ? state.data.maritalStatus : ""
  );
  const [source, setSource] = useState(state && state.data ? state.data.source : "");
  const [leadStatus, setLeadStatus] = useState(state && state.data ? state.data.leadStatus : "");
  const [modeOfCommunication, setModeOfCommunication] = useState(
    state && state.data ? state.data.modeOfCommunication : ""
  );
  const [dentalHistory, setDentalHistory] = useState(
    state && state.data ? state.data.dentalHistory : []
  );
  const [dentalHistoryNote, setDentalHistoryNote] = useState(
    state && state.data ? state.data.dentalHistoryNote : ""
  );
  const [medicalHistoryNote, setMedicalHistoryNote] = useState(
    state && state.data ? state.data.medicalHistoryNote : ""
  );
  const [newMedications, setNewMedications] = useState(
    state && state.data ? state.data.newMedications : []
  );
  const [email, setEmail] = useState(state && state.data ? state.data.email : "");
  const [countryCode, setCountryCode] = useState(
    state && state.data ? state.data.countryCode : "+91"
  );
  const [mobileNumber, setMobileNumber] = useState(state && state.data ? state.data.mobile : "");
  const [gender, setGender] = useState(state && state.data ? state.data.gender : "");
  const [genderError, setGenderError] = useState("");
  const [occupation, setOccupation] = useState(state && state.data ? state.data.occupation : "");
  const [age, setAge] = useState(state && state.data ? state.data.age : "");
  const [ageError, setAgeError] = useState("");
  const [height, setHeight] = useState(state && state.data ? state.data.height : "");
  const [heightError, setHeightError] = useState("");
  const [weight, setWeight] = useState(state && state.data ? state.data.weight : "");
  const [notes, setNote] = useState(state && state.data ? state.data.notes : "");
  const [ockComments, setOckComments] = useState(state && state.data ? state.data.ockComments : "");
  const [city, setCity] = useState(state && state.data ? state.data.city : "");
  const [cityError, setCityError] = useState("");
  const [pincode, setPincode] = useState(state && state.data ? state.data.pin : "");
  const [pincodeError, setPincodeError] = useState("");
  const [country, setCountry] = useState(state && state.data ? state.data.country : "");
  const [landmark1, setLandmark1] = useState(state && state.data ? state.data.landmark1 : "");
  const [landmark2, setLandmark2] = useState(state && state.data ? state.data.landmark2 : "");
  const [tags, setTags] = useState("");
  const [brushing, setBrushing] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].dentalHabits &&
      state.data.newHabits[0].dentalHabits.brushing
      ? state.data.newHabits[0].dentalHabits.brushing
      : ""
  );
  const [otherRoutines, setOtherRoutines] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].dentalHabits &&
      state.data.newHabits[0].dentalHabits.otherRoutines
      ? state.data.newHabits[0].dentalHabits.otherRoutines
      : ""
  );
  const [otherSource, setOtherSource] = useState(state && state.data ? state.data.otherSource : "");
  const [tobacco, setTobacco] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].substanceUse &&
      state.data.newHabits[0].substanceUse.tobacco
      ? state.data.newHabits[0].substanceUse.tobacco
      : ""
  );
  const [smokingNotes, setSmokingNotes] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].substanceUse &&
      state.data.newHabits[0].substanceUse.smokingNotes
      ? state.data.newHabits[0].substanceUse.smokingNotes
      : ""
  );

  const [alcohol, setAlcohol] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].substanceUse &&
      state.data.newHabits[0].substanceUse.alcohol
      ? state.data.newHabits[0].substanceUse.alcohol
      : ""
  );
  const [beverage, setBeverage] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].lifestyleHabits &&
      state.data.newHabits[0].lifestyleHabits.beverages
      ? state.data.newHabits[0].lifestyleHabits.beverages
      : ""
  );
  const [deleterious, setDeleterious] = useState(
    state &&
      state.data &&
      state.data.newHabits[0] &&
      state.data.newHabits[0].lifestyleHabits &&
      state.data.newHabits[0].lifestyleHabits.deleteriousHabits
      ? state.data.newHabits[0].lifestyleHabits.deleteriousHabits
      : ""
  );
  const [newAllergies, setNewAllergies] = useState(
    state && state.data ? state.data.newAllergies : []
  );
  const [allergiesNote, setAllergiesNote] = useState(
    state && state.data ? state.data.allergiesNote : ""
  );
  const [chiefComplaint, setChiefComplaint] = useState(
    state && state.data ? state.data.chiefComplaint : ""
  );
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [selectedBandType, setSelectedBandType] = useState("");
  const agents = useSelector((state) => state?.agent?.agents);
  const getAgentsSuccess = useSelector(agentSelector.getAgentsSuccess);
  const getAgentsFailure = useSelector(agentSelector.getAgentsFailure);
  const addNewPatientSuccess = useSelector(patientSelector.addNewPatientSuccess);
  const addNewPatientFailure = useSelector(patientSelector.addNewPatientFailure);
  const patientExistError = useSelector(patientSelector.patientExistError);
  const updatePatientSuccess = useSelector(patientSelector.updatePatientSuccess);
  const updatePatientFailure = useSelector(patientSelector.updatePatientFailure);

  const handleDOBValueChange = (newValue) => {
    setDOBValue(newValue);
  };

  const handleChange = (event) => {
    setGender(event.target.value);
    setGenderError("");
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = () => {
    dispatch(getAgents());
  };

  useEffect(() => {
    if (agents?.agents) {
      setAgentData(agents?.agents);
    }
  }, [agents]);

  useEffect(() => {
    if (getAgentsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAgentsFailure]);

  useEffect(() => {
    if (patientExistError) {
      Swal.fire({
        icon: "error",
        text: "Mobile number already exist",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [patientExistError]);

  useEffect(() => {
    if (agents?.agents?.length) {
      setAgentData([...agents?.agents]); // Use the spread operator to create a new array
    }
  }, [agents]);

  useEffect(() => {
    if (getAgentsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAgentsFailure]);

  const handlePatientSubmit = () => {
    let formData = {};
    let userData = {
      name: name,
      preferredName: preferredName,
      email: email,
      countryCode,
      mobile: mobileNumber,
      gender: gender,
      agentId: selectedAgent,
      medicalHistory: medicalHistory,
      dentalHistory: dentalHistory,
      age: age,
      occupation: occupation,
      height: height,
      weight: weight,
      city: city,
      pin: pincode,
      source: source,
      dob: moment(dobValue).format("DD/MM/YYYY"),
      notes: notes,
      isPreganant: pregnancy,
      maritalStatus: maritalStatus,
      leadStatus: leadStatus,
      modeOfCommunication: modeOfCommunication,
      country: country,
      landmark1: landmark1,
      landmark2: landmark2,
      newAllergies: newAllergies,
      chiefComplaint: chiefComplaint,
      newHabits: {
        dentalHabits: { brushing: brushing, otherRoutines: otherRoutines },
        lifestyleHabits: { beverages: beverage, deleteriousHabits: deleterious },
        substanceUse: { tobacco: tobacco, smokingNotes: smokingNotes, alcohol: alcohol },
      },
      medicalHistoryNote: medicalHistoryNote,
      dentalHistoryNote: dentalHistoryNote,
      newMedications: newMedications,
      allergiesNote: allergiesNote,
      otherSource: otherSource,
      oralType: selectedOralCareType,
      ockComments: ockComments,
      bandType: selectedBandType,
    };
    if (state && state.data) {
      formData = {
        id: state.patientId,
        agentId: selectedAgent,
        user: userData,
      };
    } else {
      formData = userData;
    }
    if (state && state.data) {
      dispatch(updatePatient(formData, loginData.token));
    } else {
      dispatch(addNewPatient(formData, loginData.token));
    }
  };

  useEffect(() => {
    if (addNewPatientSuccess) {
      Swal.fire({
        icon: "success",
        text: "Successfully added new patient",
        timer: 2000,
        showConfirmButton: false,
      });
      dispatch(resetAddNewPatientSuccess());
      navigate("/patients"); // Use the spread operator to create a new array
    }
  }, [addNewPatientSuccess]);

  useEffect(() => {
    if (addNewPatientFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addNewPatientFailure]);

  useEffect(() => {
    if (updatePatientSuccess) {
      Swal.fire({
        icon: "success",
        text: "Patient Details Updated Successfully ",
        timer: 2000,
        showConfirmButton: false,
      });
      dispatch(resetUpdatePatientSuccess());
      navigate("/patientdetails/" + state.patientId);
    }
  }, [updatePatientSuccess]);

  useEffect(() => {
    if (updatePatientFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updatePatientFailure]);

  const validateAddPatient = () => {
    if (name == "") {
      Swal.fire({
        icon: "error",
        text: "Name cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (!age) {
      setAgeError("Please enter a age");
      Swal.fire({
        icon: "error",
        text: "Age cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (mobileNumber == "") {
      Swal.fire({
        icon: "error",
        text: "Mobile number cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    // if (mobileNumber.length <= 9) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please enter valid mobile number",
    //     timer: 2000,
    //     showConfirmButton: false,
    //   });
    //   return false;
    // }
    if (!gender) {
      setGenderError("Please select a gender");
      Swal.fire({
        icon: "error",
        text: "Gender cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (!pincode) {
      setPincodeError("Please enter a pincode");
      Swal.fire({
        icon: "error",
        text: "Pincode cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (selectedAgent == "") {
      Swal.fire({
        icon: "error",
        text: "Internal Dentist cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (!city) {
      setCityError("Please enter a city");
      Swal.fire({
        icon: "error",
        text: "City cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    return true;
  };

  const handleDentalHistoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setDentalHistory(typeof value === "string" ? value.split(",") : value);
  };
  const handleMedicalHistoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setMedicalHistory(typeof value === "string" ? value.split(",") : value);
  };

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;
    if (keyCode === enter) {
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }
    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
  };

  const validateDOB = (dateString) => {
    if (!dateString) {
      return "";
    }
    const todayDate = moment(); // Get today's date

    // Parse the input date string and check if it's valid
    const inputDate = moment(dateString, true);
    // Check if the input matches the expected date format
    if (!moment(dateString, true).isValid()) {
      return "Invalid date format";
    }
    // Check if the input date is greater than today's date
    if (inputDate.isAfter(todayDate, "day")) {
      return "Date of Birth cannot be in the future";
    }
    // Additional validation logic can be added here if needed
    // If all checks pass, return an empty string
    return "";
  };

  return (
    <DashboardLayout>
      <DashboardNavbar pathName={state?.data ? "edit-patient" : "add-patient"} />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card id="delete-account">
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <br />
                  <h4>
                    <center>{state && state.data ? "Edit Patient" : "Add Patient"}</center>
                  </h4>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="lg"
                    p={3}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox mb={1} lineHeight={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Name"
                              value={name}
                              required
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                  setName(inputValue);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Age"
                              value={age}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setAge(inputValue);
                              }}
                            />
                            {ageError && (
                              <Typography id="city-error" variant="caption" color="error">
                                {ageError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Preferred Name"
                              value={preferredName}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                                  setPreferredName(inputValue);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Occupation"
                              value={occupation}
                              onChange={(e) => setOccupation(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Height (cm)"
                              value={height}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (inputValue === "") {
                                  // Clear the height value if the input is empty
                                  setHeight("");
                                  setHeightError(""); // Clear the error message when the value is empty
                                } else if (/^\d+(\.\d*)?$/.test(inputValue)) {
                                  // Allow numeric and float input
                                  setHeight(inputValue);
                                  setHeightError(""); // Clear the error message when the value is valid
                                } else {
                                  setHeightError("Height must be a valid number"); // Show error message
                                }
                              }}
                            />
                            {heightError && (
                              <Typography variant="caption" color="error">
                                {heightError}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Weight(Kg)"
                              value={weight}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 10) {
                                  // Only numeric input is allowed, and the length is not greater than 10
                                  setWeight(inputValue);
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Grid>
                          {/* <Grid container spacing={2}> */}
                          <Grid item xs={12} sm={2}>
                            <TextField
                              fullWidth
                              label="Country Code"
                              value={countryCode}
                              onChange={(e) => setCountryCode(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              required
                              label="Mobile Number"
                              value={mobileNumber}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                // if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 10) {
                                // Only numeric input is allowed, and the length is not greater than 10
                                setMobileNumber(inputValue);
                                // }
                              }}
                            />
                          </Grid>
                          {/* </Grid> */}
                          <Grid item xs={12} sm={12}>
                            <b> Gender :</b>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <RadioGroup
                              defaultChecked={gender}
                              row={true}
                              value={gender}
                              onChange={handleChange}
                              aria-describedby="gender-error" // Reference the error message element
                            >
                              <FormControlLabel value="Male" control={<Radio />} label="Male" />
                              <FormControlLabel value="Female" control={<Radio />} label="Female" />
                              <FormControlLabel value="other" control={<Radio />} label="Other" />
                            </RadioGroup>
                            {genderError && (
                              <Typography id="gender-error" variant="caption" color="error">
                                {genderError}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <Stack spacing={3}>
                                {dobValue ? (
                                  <DesktopDatePicker
                                    label="Date of Birth"
                                    inputFormat="DD/MM/YYYY"
                                    value={dobValue ? moment(dobValue).toISOString() : ""}
                                    onChange={(newDate) => {
                                      const newDateString = newDate
                                        ? moment(newDate).toISOString()
                                        : "";
                                      setDOBValue(newDateString);
                                      const validationError = validateDOB(newDateString);
                                      setDOBError(validationError);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                ) : (
                                  <DesktopDatePicker
                                    label="Date of Birth"
                                    inputFormat="DD/MM/YYYY"
                                    onChange={(newDate) => {
                                      const newDateString = newDate
                                        ? moment(newDate).toISOString()
                                        : "";
                                      setDOBValue(newDateString);
                                      const validationError = validateDOB(newDateString);
                                      setDOBError(validationError);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                )}

                                {dobError && (
                                  <Typography variant="caption" color="error">
                                    {dobError}
                                  </Typography>
                                )}
                              </Stack>
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <b> Address :</b>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Landmark 1"
                              value={landmark1}
                              onChange={(e) => setLandmark1(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Landmark 2"
                              value={landmark2}
                              onChange={(e) => setLandmark2(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="City *"
                              value={city}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^[A-Za-z\s]*$/.test(inputValue)) {
                                  setCity(inputValue);
                                  setCityError(""); // Clear the error message when the value is valid
                                } else {
                                  setCityError("City must contain only letters and spaces");
                                }
                              }}
                            />
                            {cityError && (
                              <Typography id="city-error" variant="caption" color="error">
                                {cityError}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              fullWidth
                              label="Pincode"
                              value={pincode}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setPincode(inputValue);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Autocomplete
                              // multiple
                              id="tags-filled"
                              options={countryList.map((option) => option)}
                              freeSolo
                              value={country}
                              onChange={(event, value) => setCountry(value)}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    // key={index}
                                    style={{ backgroundColor: "#1A73E8" }}
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  {...params}
                                  variant="outlined"
                                  label="Country"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Marital Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={
                                  maritalStatus === "single"
                                    ? "Single"
                                    : maritalStatus === "married"
                                    ? "Married"
                                    : maritalStatus === "widowed"
                                    ? "Widowed"
                                    : maritalStatus === "divorced"
                                    ? "Divorced"
                                    : maritalStatus
                                }
                                onChange={(event) => setMaritalStatus(event.target.value)}
                              >
                                {maritalStatusArray.map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Pregnancy
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={
                                  pregnancy === "yes"
                                    ? "Yes"
                                    : pregnancy === "no"
                                    ? "No"
                                    : pregnancy
                                }
                                onChange={(event) => setPregnancy(event.target.value)}
                                label="Age"
                              >
                                {pregencyArray.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <b> Habits :</b>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            Substance Use
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Tobacco
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={tobacco}
                                onChange={(event) => setTobacco(event.target.value)}
                              >
                                {tobaccoArray.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Smoking Notes"
                              value={smokingNotes}
                              onChange={(e) => setSmokingNotes(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Alcohol"
                              value={alcohol}
                              onChange={(e) => setAlcohol(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            Life Style Habits
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Beverage"
                              value={beverage}
                              onChange={(e) => setBeverage(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Deleterious Habits"
                              value={deleterious}
                              onChange={(e) => setDeleterious(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            Dental Habits
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Brushing"
                              value={brushing}
                              onChange={(e) => setBrushing(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Other Routines"
                              value={otherRoutines}
                              onChange={(e) => setOtherRoutines(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Dental History
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={dentalHistory}
                                onChange={handleDentalHistoryChange}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {dentalHistoryArray.map((data) => (
                                  <MenuItem key={data} value={data}>
                                    <Checkbox checked={dentalHistory.indexOf(data) > -1} />
                                    <ListItemText
                                      style={{ textTransform: "capitalize" }}
                                      primary={data}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Dental History Notes"
                              value={dentalHistoryNote}
                              onChange={(e) => setDentalHistoryNote(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Medical History
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={medicalHistory}
                                onChange={handleMedicalHistoryChange}
                                renderValue={(selected) => selected.join(", ")}
                              >
                                {medicalHistoryArray.map((data) => (
                                  <MenuItem key={data} value={data}>
                                    <Checkbox checked={medicalHistory.indexOf(data) > -1} />
                                    <ListItemText primary={data} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Medical History Notes"
                              value={medicalHistoryNote}
                              onChange={(e) => setMedicalHistoryNote(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              multiple
                              id="tags-filled"
                              options={currentMedicationsArray.map((option) => option)}
                              freeSolo
                              value={newMedications}
                              onChange={(event, value) => setNewMedications(value)}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    // key={index}
                                    style={{ backgroundColor: "#1A73E8" }}
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="outlined"
                                  label="Current Medications"
                                  multiline
                                  rows={2}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              multiple
                              id="tags-filled"
                              options={allergiesArray.map((option) => option)}
                              freeSolo
                              value={newAllergies}
                              onChange={(event, value) => setNewAllergies(value)}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    // key={index}
                                    style={{ backgroundColor: "#1A73E8" }}
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  variant="outlined"
                                  label="Allergies"
                                  multiline
                                  rows={2}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Allergies Notes"
                              value={allergiesNote}
                              onChange={(e) => setAllergiesNote(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Lead Status
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={
                                  leadStatus === "hot"
                                    ? "Hot"
                                    : leadStatus === "warm"
                                    ? "Warm"
                                    : leadStatus === "cold"
                                    ? "Cold"
                                    : leadStatus
                                }
                                onChange={(event) => setLeadStatus(event.target.value)}
                              >
                                {leadStatusArray.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">Source</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={
                                  source === "google"
                                    ? "Google"
                                    : source === "social media"
                                    ? "Social Media"
                                    : source === "referal"
                                    ? "Referal"
                                    : source === "other"
                                    ? "Other"
                                    : source
                                }
                                onChange={(event) => setSource(event.target.value)}
                              >
                                {sourceArray.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Other Source"
                              value={otherSource}
                              onChange={(e) => setOtherSource(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Mode Of Communication
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={
                                  modeOfCommunication === "call"
                                    ? "Call"
                                    : modeOfCommunication === "mail"
                                    ? "Mail"
                                    : modeOfCommunication === "message"
                                    ? "Message"
                                    : modeOfCommunication
                                }
                                onChange={(event) => setModeOfCommunication(event.target.value)}
                              >
                                {modeOfCommunicationArray.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Oral Care Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedOralCareType}
                                onChange={(e) => {
                                  setSelectedOralCareType(e.target.value);
                                }}
                              >
                                {oralCareList.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Additional comments for ock"
                              value={ockComments}
                              onChange={(e) => setOckComments(e.target.value)}
                              multiline
                              onKeyUp={(e) => handleInput(e)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Chief Compliants"
                              multiline
                              value={chiefComplaint}
                              onChange={(e) => setChiefComplaint(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Notes"
                              value={notes}
                              onChange={(e) => setNote(e.target.value)}
                              multiline
                              onKeyUp={(e) => handleInput(e)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Internal Dentist *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                required
                                value={selectedAgent}
                                onChange={(event) => setSelectedAgent(event.target.value)}
                              >
                                {agentData.sort(compare).map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data.aid}>
                                      {data.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Band Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                required
                                value={selectedBandType}
                                onChange={(event) => setSelectedBandType(event.target.value)}
                              >
                                {["Band 1", "Band 2", "Band 3"].map((data, index) => {
                                  return (
                                    <MenuItem key={index} value={data}>
                                      {data}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <br />
                        <br />
                        <div
                          style={{ display: "flex", justifyContent: "center", fontSize: "10em" }}
                        >
                          {state && state.data ? (
                            <MDButton
                              fullWidth
                              variant="gradient"
                              onClick={() => {
                                if (validateAddPatient() === true) {
                                  handlePatientSubmit();
                                } else {
                                  console.log("Patient not validated");
                                }
                              }}
                              color="info"
                              style={{ width: "35em" }}
                            >
                              Update
                            </MDButton>
                          ) : (
                            <MDButton
                              fullWidth
                              variant="gradient"
                              onClick={() => {
                                if (validateAddPatient() === true) {
                                  handlePatientSubmit();
                                } else {
                                  console.log("Patient not validated");
                                }
                              }}
                              color="info"
                              style={{ width: "35em" }} // Set button width to 15em
                            >
                              Submit
                            </MDButton>
                          )}
                        </div>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AddPatient;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
