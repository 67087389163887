/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import Header from "./Header";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PatientsList from "layouts/videoConsultation/components/PatientsList";
import { useEffect, useState, useContext } from "react";
import { useMaterialUIController, setDirection } from "context";
import React, { Fragment, useRef } from "react";
import { css } from "styled-components";
import PatientMediaPlayer from "../../../hooks/PatientMediaPlayer.tsx";
import useAgora from "../../../hooks/useAgora.tsx";
import AgoraRTC from "agora-rtc-sdk-ng";
import MediaPlayer from "../../../hooks/MediaPlayer.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import moment from "moment";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
// import "./style.css";
import {
  removeTokenForVideoConsultation,
  videoConsultationSelector,
} from "store/reducers/videoConsultationSlice";
import { useDispatch, useSelector } from "react-redux";
import { locationValue } from "store/reducers/patientSlice";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

function VideoConsultation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let currentTime = moment().format("hh:mm A");
  const location = useLocation();
  const [appid] = useState("913c7cd202b04497b57309ea258f9cac");
  const [controller] = useMaterialUIController();
  const [isMuted, setIsMuted] = useState(false);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const removeTokenForVideoConsultationSuccess = useSelector(
    videoConsultationSelector.removeTokenForVideoConsultationSuccess
  );
  const removeTokenForVideoConsultationFailure = useSelector(
    videoConsultationSelector.removeTokenForVideoConsultationFailure
  );
  const locationData = useSelector((state) => state?.patient?.locationValue);

  const {
    localAudioTrack,
    localVideoTrack,
    leave,
    join,
    joinState = location && location.state && location.state.joinState
      ? location.state.joinState
      : "",
    remoteUsers,
  } = useAgora(client);

  useEffect(() => {
    if (location && location.state) {
      join(location.state.appid, location.state.channel, location.state.token);
    }
  }, []);
  const patientId = location.state.channel;

  const handleClickCallEnd = () => {
    dispatch(removeTokenForVideoConsultation(patientId, location.state.token, loginData.token));
  };

  useEffect(() => {
    if (removeTokenForVideoConsultationSuccess) {
      console.log("locationData", locationData);
      if (locationData) {
        navigate(locationData);
        dispatch(locationValue(""));
      } else {
        navigate("/video_Consultation");
      }
    }
  }, [removeTokenForVideoConsultationSuccess]);

  useEffect(() => {
    if (removeTokenForVideoConsultationFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeTokenForVideoConsultationFailure]);

  const handleToggleMute = () => {
    if (localAudioTrack) {
      if (isMuted) {
        localAudioTrack.setEnabled(true);
      } else {
        localAudioTrack.setEnabled(false);
      }
      setIsMuted(!isMuted);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card id="delete-account" style={{ backgroundColor: "black" }}>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={0}>
                          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={3} style={{ display: "flex", justifyContent: "center" }}>
                              <MediaPlayer
                                videoTrack={localVideoTrack}
                                audioTrack={undefined}
                              ></MediaPlayer>
                            </Grid>
                            <Grid item xs={9}>
                              {location && location.state && location.state.callType === 0 ? (
                                <img
                                  src="http://dentaldost.com/wp-content/uploads/2023/02/kiosk_profile_icon.png"
                                  className="imageClass"
                                ></img>
                              ) : (
                                <>
                                  {remoteUsers.map((user) => (
                                    <div className="remote-player-wrapper" key={user.uid}>
                                      <PatientMediaPlayer
                                        videoTrack={user.videoTrack}
                                        audioTrack={user.audioTrack}
                                      ></PatientMediaPlayer>
                                    </div>
                                  ))}
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                      <MDButton
                        component="span"
                        fontWeight="bold"
                        color="info"
                        variant="gradient"
                        onClick={() => {
                          window.open("/patientdetails/" + patientId, "_blank");
                        }}
                      >
                        <Icon>info</Icon>&nbsp;View Details
                      </MDButton>
                    </MDBox>
                  </MDBox>
                  <MDBox width="100%" display="flex" flexDirection="column">
                    <MDBox mb={1} lineHeight={0}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Item style={{ textAlign: "center", boxShadow: "none" }}>
                            <MDTypography variant="button" fontWeight="medium" color="text">
                              <MDTypography variant="button" fontWeight="medium">
                                <div style={{ textAlign: "center" }}>
                                  <MDButton
                                    variant="gradient"
                                    onClick={handleToggleMute}
                                    style={{
                                      height: "50px",
                                      borderRadius: "25px",
                                      backgroundColor: "blue",
                                      color: "white",
                                    }}
                                  >
                                    {isMuted ? <MicOffIcon /> : <MicIcon />}
                                  </MDButton>
                                  &nbsp;&nbsp;
                                  <MDButton
                                    variant="gradient"
                                    color="error"
                                    style={{ height: "50px", borderRadius: "25px" }}
                                    onClick={() => handleClickCallEnd()}
                                  >
                                    <Icon>phone</Icon>&nbsp;
                                  </MDButton>
                                  &nbsp;
                                </div>
                              </MDTypography>
                            </MDTypography>
                          </Item>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default VideoConsultation;
