/* eslint-disable */
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setDirection } from "context";
import { useEffect, useState, useContext } from "react";
import { getPatientsList, videoConsultationSelector } from "store/reducers/videoConsultationSlice";

// Billing page components
import PatientCard from "layouts/videoConsultation/components/PatientsList/Card";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import routes from "routes";

function PatientsList() {
  const dispatch = useDispatch();
  // const [, dispatch] = useMaterialUIController();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [patientsList, setPatientsList] = useState();
  const [intervalId, setIntervalId] = useState();
  const videoConsultations = useSelector((state) => state?.videoConsultation?.videoConsultations);
  const getPatientsListSuccess = useSelector(videoConsultationSelector.getPatientsListSuccess);
  const getPatientsListFailure = useSelector(videoConsultationSelector.getPatientsListFailure);

  useEffect(() => {
    // const interval = setInterval(() => {
    fetchPatientsList();
    // }, 2000);
    // setIntervalId(interval);

    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const fetchPatientsList = () => {
    dispatch(getPatientsList(loginData.token));
  };

  useEffect(() => {
    if (videoConsultations?.data) {
      setPatientsList(videoConsultations?.data);
    }
  }, [getPatientsListSuccess]);

  useEffect(() => {
    if (getPatientsListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientsListFailure]);

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Patient's List
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {patientsList && patientsList.length ? (
            patientsList.map((data) => {
              return (
                <PatientCard
                  name={data.patientName}
                  mobile={data.mobile}
                  status={data.status}
                  consultationId={data.videoConsultationId}
                  patientId={data.patientId}
                  agentId={data.agentId}
                  token={data.token}
                  callType={data.callType}
                  intervalId={intervalId}
                />
              );
            })
          ) : (
            <MDTypography variant="h6" fontWeight="medium">
              No Video Consultation available
            </MDTypography>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default PatientsList;
