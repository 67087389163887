import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const addCoupon = (formData) => {
  return axios.post(constant.API_URL + "/agent/coupon/addCoupon", formData, {
    headers,
  });
};

const getAllCoupons = () => {
  return cachedAxiosGet(constant.API_URL + "/agent/coupon/allCoupons", {
    headers,
  });
};

const updateCoupon = (formData) => {
  return axios.put(constant.API_URL + "/agent/coupon/updateCoupon", formData, {
    headers,
  });
};

const deleteCoupon = (couponId) => {
  return axios.delete(constant.API_URL + "/agent/coupon/deleteCoupon?couponId=" + couponId, {
    headers,
  });
};

const couponService = {
  getAllCoupons,
  addCoupon,
  updateCoupon,
  deleteCoupon,
};

export default couponService;
