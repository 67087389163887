/* eslint-disable */

import axios from "axios";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
import constant from "../utils/constants";

const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getPatientDetails = (formData) => {
  if (formData?.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData?.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/getUserDetails",
    { uid: formData?.patientId },
    {
      headers,
    }
  );
};

const getPatientTests = (userId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/getUserTests",
    { userId },
    {
      headers,
    }
  );
};

const getInvoiceDetails = (uid, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/invoice/",
    { uid },
    {
      headers,
    }
  );
};

const getAppointmentList = (pid, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/appointment/getPatientAppointments",
    { pid },
    {
      headers,
    }
  );
};

const searchPatient = (formData) => {
  if (formData.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData.token,
    };
  }
  return cachedAxiosGet(
    constant.API_URL +
      "/agent/patient/searchPatients/" +
      formData?.searchData +
      "/" +
      formData?.pageNumber +
      "/" +
      formData?.pageSize,
    {
      headers,
    }
  );
};

// const getPatientsNotifications = (patientId) => {
//   return cachedAxiosGet(constant.API_URL + "/agent/patient/getNotification/" + patientId, {
//     headers,
//   });
// };
const getAllNotifications = (patientId) => {
  return cachedAxiosGet(constant.API_URL + "/agent/patient/getAllNotifications/" + patientId, {
    headers,
  });
};
const getPatientWithTests = (formData) => {
  return cachedAxiosPost(constant.API_URL + "/agent/patient/getPatientWithTests", formData, {
    headers,
  });
};
const removeFileData = (formData) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/removeFilesData",
    {
      patientId: formData.patientId,
      imageUrl: formData.imageUrl,
    },
    {
      headers,
    }
  );
};
const removeDiagnosticsData = (patientId, imagesData, diagnosis) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/removeDiagnosticsData",
    {
      patientId: patientId,
      imageUrl: imagesData,
      //diagnosis: diagnosis,
    },
    {
      headers,
    }
  );
};

const getReferrals = async (patientId) => {
  return cachedAxiosGet(constant.API_URL + "/agent/patient/referralList?patientId=" + patientId, {
    headers,
  });
};

const patientDetailsService = {
  getPatientDetails,
  getInvoiceDetails,
  getAppointmentList,
  searchPatient,
  getPatientWithTests,
  removeFileData,
  removeDiagnosticsData,
  getAllNotifications,
  getPatientTests,
  getReferrals,
};

export default patientDetailsService;
