/* eslint-disable */
// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { TextField, Grid, Card, Modal, Input, InputLabel, ListItemText } from "@mui/material";
import { useMaterialUIController } from "context";
import Constants from "utils/constants";
const REACT_API_URL = Constants.API_URL;
const localstoredata = JSON.parse(localStorage.getItem("loginData"));
import axios from "axios";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@material-ui/core/Chip";
import { useNavigate, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgents,
  agentSelector,
  getAgentsData,
  assignDoctor,
  updateUser,
  transferPatients,
  addNewAgent,
  updateAgentDetails,
  resetUpdateAgentDetailsSuccess,
} from "store/reducers/agentSlice";
import { getKioskList, kioskSelector } from "store/reducers/kioskSlice";
import { getDoctorsList, doctorSelector } from "store/reducers/doctorSlice";
import routes from "routes";

function AgentTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [agentsList, setAgentsList] = useState([]);
  const [agentsLists, setAgentsLists] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agentId, setAgentId] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [largeDescription, setLargeDescription] = useState("");
  const [description, setDescription] = useState("");
  const [agentIndex, setAgentIndex] = useState("");
  const [file, setFile] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [openAddAgentModal, setOpenAddAgentModal] = useState(false);
  const handleCloseAddAgentModal = () => setOpenAddAgentModal(false);
  const [openTransferPatientModal, setOpenTransferPatientModal] = useState(false);
  const handleCloseTransferPatientsModal = () => setOpenTransferPatientModal(false);
  const [openEditAgentModal, setOpenEditAgentModal] = useState(false);
  const handleCloseEditAgentModal = () => setOpenEditAgentModal(false);
  const handleCloseEditAgentPermissionModal = () => setOpenEditAgentPermissionModal(false);
  const [openEditAgentPermissionModal, setOpenEditAgentPermissionModal] = useState(false);
  const [openAssignDoctorModal, setOpenAssignDoctorModal] = useState(false);
  const handleCloseAssignDoctorModal = () => setOpenAssignDoctorModal(false);
  const [isLoading, setIsLoading] = useState(true);
  const [doctorDetail, setDoctorsDetail] = useState([]);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [secondaryAgent, setSecondaryAgent] = useState();
  const [primaryAgent, setPrimaryAgent] = useState();
  const { state } = useLocation();
  const [selectedRole, setSelectedRole] = useState(state && state.data ? state.data.role : "");
  const [rolesArray, setRolesArray] = useState(
    state && state.data ? state.data?.permissions : [{ name: "", operations: [] }]
  );

  const [roleData] = useState(["Admin", "Agent", "Reviewer", "Partner"]);
  const [operationsData] = useState(["Add", "Edit", "Delete"]);
  const [entityData] = useState(["patient", "doctor", "agent"]);
  const [permissionData, setPermissionData] = useState();
  const [kioskList, setKioskList] = useState([]);
  const [kioskId, setKioskId] = useState("");
  const agents = useSelector((state) => state?.agent?.agents);
  const agentsData = useSelector((state) => state?.agent?.agentsData);
  const kiosks = useSelector((state) => state?.kiosk?.kiosks);
  const doctors = useSelector((state) => state?.doctor?.doctors);
  const getAgentsSuccess = useSelector(agentSelector.getAgentsSuccess);
  const getAgentsFailure = useSelector(agentSelector.getAgentsFailure);
  const getAgentsDataFailure = useSelector(agentSelector.getAgentsDataFailure);
  const addNewAgentSuccess = useSelector(agentSelector.addNewAgentSuccess);
  const addNewAgentFailure = useSelector(agentSelector.addNewAgentFailure);
  const transferPatientsSuccess = useSelector(agentSelector.transferPatientsSuccess);
  const transferPatientsFailure = useSelector(agentSelector.transferPatientsFailure);
  const getKioskListSuccess = useSelector(kioskSelector.getKioskListSuccess);
  const getKioskListFailure = useSelector(kioskSelector.getKioskListFailure);
  const updateAgentDetailsSuccess = useSelector(agentSelector.updateAgentDetailsSuccess);
  const updateAgentDetailsFailure = useSelector(agentSelector.updateAgentDetailsFailure);
  const updateUserSuccess = useSelector(agentSelector.updateUserSuccess);
  const updateUserFailure = useSelector(agentSelector.updateUserFailure);
  const getDoctorsListSuccess = useSelector(doctorSelector.getDoctorsListSuccess);
  const getDoctorsListFailure = useSelector(doctorSelector.getDoctorsListFailure);
  const assignDoctorSuccess = useSelector(agentSelector.assignDoctorSuccess);
  const assignDoctorFailure = useSelector(agentSelector.assignDoctorFailure);
  const languageArray = [
    {
      title: "தமிழ்",
      value: "tamil",
    },
    {
      title: "Bengali",
      value: "bengali",
    },
    {
      title: "తెలుగు",
      value: "telugu",
    },
  ];
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    getAgentsList();
    fetchDoctorsList();
    fetchAgentsData();
    fetchKioskList();
  }, []);

  const handleAddClick = () => {
    setRolesArray([...rolesArray, { name: "", operations: [] }]);
  };
  const handleRemoveClick = (index) => {
    const list = [...rolesArray];
    list.splice(index, 1);
    setRolesArray(list);
  };

  const handleChange = (e, data, index) => {
    let isChecked = e.target.checked;
    let list = [...rolesArray];
    if (isChecked) {
      list[index]["operations"].push(data);
    } else {
      const toRemove = data;
      const indexValue = list[index].operations.indexOf(toRemove);
      if (indexValue > -1) {
        list[index].operations.splice(indexValue, 1);
      }
    }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...rolesArray];
    list[index][name] = value;
    setRolesArray(list);
  };
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const agentColumns = [
    {
      field: "id",
      headerName: "Agent No",
      width: 100,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "Name",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div>{toTitleCase(params.row.name)}</div>;
      },
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "qualification",
      headerName: "Qualification",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      width: 280,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={params.row.status === true ? "Active" : "InActive"}
            color={params.row.status === true ? "primary" : "secondary"}
          />
        );
      },
    },
    loginData && loginData.roleData && loginData.roleData.roleName.toLowerCase() === "admin"
      ? {
          field: "action",
          headerName: "Action",
          width: 400,
          align: "center",
          headerAlign: "center",
          renderCell: (params) => {
            const onDetailsClick = (e) => {
              const api = params.api;
              const thisRow = {};

              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
              handleDetailsClick(thisRow);
            };
            const onAssignDoctorClick = () => {
              setAgentId(params.row.aid);
              setOpenAssignDoctorModal(true);
            };

            const permissionHandler = () => {
              getPermissionData(params.row);
              setAgentId(params.row.aid);
              setOpenEditAgentPermissionModal(true);
            };
            return (
              <>
                &nbsp;
                <MDButton
                  variant="gradient"
                  value={agentsList}
                  onClick={onDetailsClick}
                  color="info"
                >
                  Edit Details
                </MDButton>
                &nbsp;
                <MDButton
                  variant="gradient"
                  value={agentsList}
                  onClick={onAssignDoctorClick}
                  color="info"
                >
                  Assign doctor
                </MDButton>
                &nbsp;
                <MDButton
                  variant="gradient"
                  value={agentsList}
                  onClick={permissionHandler}
                  color="primary"
                >
                  Edit Permission
                </MDButton>
              </>
            );
          },
        }
      : [],
  ];

  const onAddSlotsClick = () => {
    navigate("/video-consultation-slots", {
      state: {
        agentId: Constants.AGENT_ID,
      },
    });
  };

  const getPermissionData = (permissionData) => {
    const filterData = agentsLists.find((agentData) => permissionData.aid === agentData.agentId);
    setSelectedRole(filterData?.role);
    setRolesArray(filterData?.permissions);
  };

  const statusArray = ["Active", "InActive"];
  const fetchDoctorsList = (page) => {
    dispatch(getDoctorsList(loginData.token));
  };

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    if (doctors?.doctors?.length) {
      setDoctorsDetail([...doctors?.doctors]); // Use the spread operator to create a new array
    }
  }, [doctors]);

  const handleAssignDoctor = () => {
    const formData = {
      doctorId,
      agentId,
    };
    dispatch(assignDoctor(formData));
  };

  useEffect(() => {
    if (assignDoctorSuccess) {
      handleCloseAssignDoctorModal();
      Swal.fire({
        icon: "success",
        text: "Doctor assign successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [assignDoctorSuccess]);

  const getAgentsList = () => {
    dispatch(getAgents());
  };

  useEffect(() => {
    if (agents?.agents) {
      setIsLoading(false);
      setAgentsList([...agents.agents].reverse());
    }
  }, [agents]);

  useEffect(() => {
    if (
      getAgentsFailure ||
      getAgentsDataFailure ||
      addNewAgentFailure ||
      transferPatientsFailure ||
      getKioskListFailure ||
      updateAgentDetailsFailure ||
      updateUserFailure ||
      getDoctorsListFailure ||
      assignDoctorFailure
    ) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAgentsFailure]);

  const handleDetailsClick = (datavalue) => {
    const result = agentsList.find((agentListData) => agentListData.id === datavalue.id);
    setAgentId(result.aid);
    setName(result.name);
    setEmail(result.email);
    setExperience(result.experience);
    setQualification(result.qualification);
    setLargeDescription(result.largeDescription);
    setDescription(result.description);
    setAgentIndex(result.agentIndex);
    setKioskId(result.kiosk);
    setOpenEditAgentModal(true);
    setStatus(result.status ? "Active" : "InActive");
  };

  const handleUpdateAgent = () => {
    let body = new FormData();
    const config = {
      "Content-Type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${localstoredata === null ? "a" : localstoredata.token}`,
      },
    };
    body.append("file", file);
    body.append("agentId", agentId);
    body.append("qualification", qualification);
    body.append("name", name);
    body.append("status", status === "Active" ? true : false);
    body.append("experience", experience);
    body.append("description", description);
    body.append("largeDescription", largeDescription);
    body.append("agentIndex", agentIndex);
    body.append("kiosk", kioskId);
    body.append("languages", JSON.stringify(selectedLanguages));
    dispatch(updateAgentDetails(body));
  };

  useEffect(() => {
    if (updateAgentDetailsSuccess) {
      handleCloseEditAgentModal(true);
      getAgentsList();
      Swal.fire({
        icon: "success",
        text: "Agent Details Updated Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateAgentDetailsSuccess());
    }
  }, [updateAgentDetailsSuccess]);

  const fetchAgentsData = () => {
    dispatch(getAgentsData(loginData.token));
  };

  useEffect(() => {
    if (agentsData?.agents) {
      setIsLoading(false);
      setAgentsLists(agentsData?.agents);
    }
  }, [agentsData]);

  const fetchKioskList = () => {
    dispatch(getKioskList(loginData.token));
  };

  useEffect(() => {
    if (kiosks) {
      setKioskList(kiosks);
    }
  }, [kiosks]);

  const handleUpdateAgentPermissions = () => {
    const formdata = {
      agentId: agentId,
      agentsData: {
        role: selectedRole,
        permission: rolesArray,
      },
    };
    dispatch(updateUser(formdata));
  };

  useEffect(() => {
    if (updateUserSuccess) {
      Swal.fire({
        icon: "success",
        text: "Agent updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      fetchAgentsData();
      handleCloseEditAgentPermissionModal();
    }
  }, [updateUserSuccess]);

  const handleAddNewAgent = () => {
    const formData = {
      email,
      password,
      name,
      city,
      qualification,
      largeDescription,
      experience,
      file,
      status: status === "Active" ? true : false,
    };
    dispatch(addNewAgent(formData));
  };

  useEffect(() => {
    if (addNewAgentSuccess) {
      handleCloseAddAgentModal(true);
      getAgentsList();
      Swal.fire({
        icon: "success",
        text: "Agent Added Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addNewAgentSuccess]);

  const handleTransferPatientsModal = () => {
    const formData = {
      primaryAgent,
      secondaryAgent,
    };
    dispatch(transferPatients(formData));
  };

  useEffect(() => {
    if (transferPatientsSuccess) {
      handleCloseTransferPatientsModal(true);
      Swal.fire({
        icon: "success",
        text: "Patients Transfered Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [transferPatientsSuccess]);

  const handleAddAgent = () => {
    setName("");
    setEmail("");
    setCity("");
    setExperience("");
    setQualification("");
    setLargeDescription("");
    setDescription("");
    setAgentIndex("");
    setOpenAddAgentModal(true);
  };
  const handleTransferPatients = () => {
    setPrimaryAgent("");
    setSecondaryAgent("");
    setOpenTransferPatientModal(true);
  };

  return (
    <>
      {loginData && loginData.roleData && loginData.roleData.roleName === "admin" ? (
        <MDBox pt={6} pb={3}>
          <MDButton variant="gradient" color="info" onClick={handleAddAgent}>
            <AddIcon /> Add New Agent
          </MDButton>
          <MDButton
            style={{ float: "right", marginLeft: "10px" }}
            variant="gradient"
            value={agentsList}
            onClick={() => onAddSlotsClick()}
            color="info"
          >
            Add Slots
          </MDButton>
          <MDButton
            style={{ float: "right" }}
            variant="gradient"
            color="info"
            onClick={handleTransferPatients}
          >
            Transfer Patients
          </MDButton>{" "}
        </MDBox>
      ) : (
        ""
      )}

      <MDBox pt={6} pb={3}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Agent's
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <DataGrid
                    disableSelectionOnClick
                    rows={agentsList}
                    columns={agentColumns}
                    pageSize={10}
                    getRowId={(row) => row.aid}
                  />
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>

      <Modal
        open={openAddAgentModal}
        onClose={handleCloseAddAgentModal}
        style={{ marginLeft: "10%" }}
      >
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>Add New Agent</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                              />
                            </Grid>
                            {/* useful */}
                            {/* <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Qualification"
                                value={qualification}
                                onChange={(e) => setQualification(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Experience "
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                              />
                            </Grid> */}
                            <Grid item xs={12} sm={6}>
                              <InputLabel id="demo-simple-select-label">Profile Photo</InputLabel>
                              <Input
                                fullWidth
                                accept="image/*"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={status}
                                  onChange={(event) => setStatus(event.target.value)}
                                >
                                  {statusArray.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data}>
                                        {data}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                multiline
                                fullWidth
                                id="standard-basic"
                                variant="standard"
                                label="Large Description"
                                value={largeDescription}
                                onChange={(event) => setLargeDescription(event.target.value)}
                              />
                            </Grid>
                          </Grid>
                          <br />

                          <center>
                            <MDButton
                              variant="gradient"
                              onClick={handleCloseAddAgentModal}
                              color="info"
                            >
                              Close
                            </MDButton>
                            &nbsp;
                            <MDButton variant="gradient" onClick={handleAddNewAgent} color="info">
                              Submit Details
                            </MDButton>
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
      <Modal
        open={openTransferPatientModal}
        onClose={handleCloseTransferPatientsModal}
        style={{ marginLeft: "10%" }}
      >
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>Transfer Patients</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">From</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  required
                                  value={secondaryAgent}
                                  onChange={(event) => setSecondaryAgent(event.target.value)}
                                >
                                  {agentsList.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data.aid}>
                                        {data.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">To</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  required
                                  value={primaryAgent}
                                  onChange={(event) => setPrimaryAgent(event.target.value)}
                                >
                                  {agentsList.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data.aid}>
                                        {data.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <br />
                          <br />
                          <center>
                            <MDButton
                              variant="gradient"
                              onClick={handleCloseTransferPatientsModal}
                              color="info"
                            >
                              Close
                            </MDButton>
                            &nbsp; &nbsp;&nbsp; &nbsp;
                            <MDButton
                              variant="gradient"
                              onClick={() => handleTransferPatientsModal()}
                              color="info"
                            >
                              Transfer
                            </MDButton>
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

      <Modal
        open={openAssignDoctorModal}
        onClose={handleCloseAssignDoctorModal}
        style={{ marginLeft: "10%" }}
      >
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>Assign doctor</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={12}>
                              <FormControl
                                variant="standard"
                                fullWidth
                                sx={{ mb: 2 }}
                                style={{ postion: "relative", maxWidth: "235px" }}
                              >
                                <InputLabel id="demo-simple-select-label">Doctors</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={(e) => {
                                    setDoctorId(e.target.value);
                                  }}
                                >
                                  {doctorDetail?.sort(compare).map((doctor, index) => {
                                    return (
                                      <MenuItem key={index} value={doctor.did}>
                                        {doctor.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <br />

                          <center>
                            <MDButton
                              variant="gradient"
                              onClick={handleCloseAssignDoctorModal}
                              color="info"
                            >
                              Cancel
                            </MDButton>
                            &nbsp;
                            <MDButton
                              variant="gradient"
                              color="info"
                              onClick={() => handleAssignDoctor()}
                            >
                              Add
                            </MDButton>
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

      <Modal
        open={openEditAgentModal}
        onClose={handleCloseAssignDoctorModal}
        style={{ marginLeft: "10%" }}
      >
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>Edit Agent Details</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Qualification"
                                value={qualification}
                                onChange={(e) => setQualification(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Experience "
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <InputLabel id="demo-simple-select-label">Profile Photo</InputLabel>
                              <Input
                                fullWidth
                                accept="image/*"
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                multiline
                                fullWidth
                                id="standard-basic"
                                variant="standard"
                                label="Description"
                                value={description}
                                onChange={(event) => setDescription(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                multiline
                                fullWidth
                                id="standard-basic"
                                variant="standard"
                                label="Large Description"
                                value={largeDescription}
                                onChange={(event) => setLargeDescription(event.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Kiosk location
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={kioskId}
                                  onChange={(event) => setKioskId(event.target.value)}
                                >
                                  {kioskList.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data._id}>
                                        {data.name}({data.location})
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={status}
                                  onChange={(event) => setStatus(event.target.value)}
                                >
                                  {statusArray.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data}>
                                        {data}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-checkbox-select-label">Language</InputLabel>
                                <Select
                                  labelId="demo-checkbox-select-label"
                                  id="demo-checkbox-select"
                                  multiple
                                  value={selectedLanguages.map((lang) => lang.value)} // Extract values for comparison
                                  onChange={(event) => {
                                    const selectedValues = event.target.value;
                                    const selectedLanguages = languageArray.filter((lang) =>
                                      selectedValues.includes(lang.value)
                                    );
                                    setSelectedLanguages(selectedLanguages);
                                  }}
                                  renderValue={(selected) => (
                                    <div>
                                      {selected.map((value) => {
                                        const selectedLang = selectedLanguages.find(
                                          (lang) => lang.value === value
                                        );
                                        return <Chip key={value} label={selectedLang.title} />;
                                      })}
                                    </div>
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 224,
                                        width: 250,
                                      },
                                    },
                                  }}
                                >
                                  {languageArray.map((data, index) => (
                                    <MenuItem key={index} value={data.value}>
                                      <Checkbox
                                        checked={selectedLanguages.some(
                                          (lang) => lang.value === data.value
                                        )}
                                      />
                                      <ListItemText primary={data.title + "(" + data.value + ")"} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <TextField
                                  multiline
                                  fullWidth
                                  id="standard-basic"
                                  variant="standard"
                                  label="Index"
                                  value={agentIndex}
                                  onChange={(event) => setAgentIndex(event.target.value)}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <br />

                          <center>
                            <MDButton
                              variant="gradient"
                              onClick={handleCloseEditAgentModal}
                              color="info"
                            >
                              Close
                            </MDButton>
                            &nbsp;
                            <MDButton variant="gradient" onClick={handleUpdateAgent} color="info">
                              Update
                            </MDButton>
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>

      <Modal
        open={openEditAgentPermissionModal}
        onClose={handleCloseEditAgentPermissionModal}
        style={{
          marginLeft: "10%",
          position: "absolute",
          left: "10%",
          overflow: "scroll",
          height: "100%",
          display: "block",
        }}
      >
        <MDBox mt={6} mb={3}>
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>Edit Agent's Permissions</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-200"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={selectedRole}
                                  onChange={(event) => setSelectedRole(event.target.value)}
                                >
                                  {roleData.map((data, index) => {
                                    return (
                                      <MenuItem key={index} value={data.toLowerCase()}>
                                        {data}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <MDBox mb={2} mt={2} style={{ overFlow: "scroll" }}>
                            {rolesArray &&
                              Array.isArray(rolesArray) &&
                              rolesArray.map((x, i) => {
                                return (
                                  <div key={i}>
                                    <div className="control-group">
                                      {rolesArray.length - 1 === i && (
                                        <MDButton
                                          variant="gradient"
                                          color="info"
                                          absolute
                                          className="btn btn-primary"
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            marginLeft: "10px",
                                          }}
                                          onClick={handleAddClick}
                                        >
                                          +&nbsp;
                                        </MDButton>
                                      )}
                                      {rolesArray.length !== 1 && (
                                        <MDButton
                                          variant="gradient"
                                          color="error"
                                          absolute
                                          className="btn btn-primary"
                                          style={{
                                            backgroundColor: "#17A2B8",
                                            marginLeft: "10px",
                                          }}
                                          onClick={() => handleRemoveClick(i)}
                                        >
                                          -
                                        </MDButton>
                                      )}

                                      <div className="controls">
                                        <MDBox mb={2}>
                                          <FormControl
                                            variant="standard"
                                            fullWidth
                                            sx={{ mb: 2, mt: 2, minWidth: 200 }}
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Permission
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              name="name"
                                              value={x.name}
                                              onChange={(e) => handleInputChange(e, i)}
                                            >
                                              {entityData.map((data, index) => {
                                                return (
                                                  <MenuItem key={index} value={data}>
                                                    {data}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </MDBox>
                                      </div>
                                    </div>
                                    <div className="control-group" style={{ marginBottom: "10px" }}>
                                      <label
                                        className="control-label"
                                        for="username"
                                        style={{ fontSize: "medium" }}
                                      >
                                        Operation
                                      </label>
                                      <div className="controls">
                                        {operationsData.map((data, index) => {
                                          return (
                                            <label style={{ fontSize: "medium" }}>
                                              <Checkbox
                                                name="operations"
                                                defaultChecked={x.operations[index] == data}
                                                onChange={(e) => handleChange(e, data, i)}
                                              />
                                              {data}
                                            </label>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </MDBox>
                          <br />
                          <center>
                            <MDButton
                              variant="gradient"
                              onClick={handleCloseEditAgentPermissionModal}
                              color="info"
                            >
                              Close
                            </MDButton>
                            &nbsp;
                            <MDButton
                              variant="gradient"
                              onClick={handleUpdateAgentPermissions}
                              color="info"
                            >
                              Update
                            </MDButton>
                          </center>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </>
  );
}
export default AgentTable;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
