// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getPatientsListSuccess,
  getPatientsListFailure,
  updateVideoConsultationStatusSuccess,
  updateVideoConsultationStatusFailure,
  removeTokenForVideoConsultationSuccess,
  removeTokenForVideoConsultationFailure,
  generateVideoCallSuccess,
  generateVideoCallFailure,
  reconnectLastVideoCallSuccess,
  reconnectLastVideoCallFailure,
  addVideoConsultationSlotsSuccess,
  addVideoConsultationSlotsFailure,
} from "store/reducers/videoConsultationSlice"; // Import your action creator
import videoConsultationService from "services/videoConsultationService"; // Import your service function

function* getPatientsList(action) {
  try {
    const response = yield call(videoConsultationService.getPatientsList, action.payload);
    yield put(getPatientsListSuccess(response.data));
  } catch (error) {
    yield put(getPatientsListFailure(error));
  }
}
function* updateVideoConsultationStatus(action) {
  try {
    const response = yield call(
      videoConsultationService.updateVideoConsultationStatus,
      action.payload
    );
    yield put(updateVideoConsultationStatusSuccess(response.data));
  } catch (error) {
    yield put(updateVideoConsultationStatusFailure(error));
  }
}
function* removeTokenForVideoConsultation(action) {
  try {
    const response = yield call(
      videoConsultationService.removeTokenForVideoConsultation,
      action.payload
    );
    yield put(removeTokenForVideoConsultationSuccess(response.data));
  } catch (error) {
    yield put(removeTokenForVideoConsultationFailure(error));
  }
}
function* generateVideoCall(action) {
  try {
    const response = yield call(videoConsultationService.generateVideoCall, action.payload);
    yield put(generateVideoCallSuccess(response.data));
  } catch (error) {
    yield put(generateVideoCallFailure(error));
  }
}
function* reconnectLastVideoCall(action) {
  try {
    const response = yield call(videoConsultationService.reconnectLastVideoCall, action.payload);
    yield put(reconnectLastVideoCallSuccess(response.data));
  } catch (error) {
    yield put(reconnectLastVideoCallFailure(error));
  }
}
function* addVideoConsultationSlots(action) {
  try {
    const response = yield call(videoConsultationService.addVideoConsultationSlots, action.payload);
    yield put(addVideoConsultationSlotsSuccess(response.data));
  } catch (error) {
    yield put(addVideoConsultationSlotsFailure(error));
  }
}

// Watcher Saga
function* videoConsultationSaga() {
  yield takeLatest("videoConsultations/getPatientsList", getPatientsList);
  yield takeLatest(
    "videoConsultations/updateVideoConsultationStatus",
    updateVideoConsultationStatus
  );
  yield takeLatest(
    "videoConsultations/removeTokenForVideoConsultation",
    removeTokenForVideoConsultation
  );
  yield takeLatest("videoConsultations/generateVideoCall", generateVideoCall);
  yield takeLatest("videoConsultations/reconnectLastVideoCall", reconnectLastVideoCall);
  yield takeLatest("videoConsultations/addVideoConsultationSlots", addVideoConsultationSlots);
}

// Export all sagas
export default videoConsultationSaga;
