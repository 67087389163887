// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getWeeklyFollowUpReminderSuccess,
  getWeeklyFollowUpReminderFailure,
  getFollowUpRemindersByDateSuccess,
  getFollowUpRemindersByDateFailure,
  getFollowUpRemindersSuccess,
  getFollowUpRemindersFailure,
  addAggresiveFollowUpSuccess,
  addAggresiveFollowUpFailure,
} from "store/reducers/followUpReminderSlice"; // Import your action creator
import followUpReminderService from "services/followUpReminderService"; // Import your service function

function* getWeeklyFollowUpReminder(action) {
  try {
    const response = yield call(followUpReminderService.getWeeklyFollowUpReminder, action.payload);
    yield put(getWeeklyFollowUpReminderSuccess(response.data));
  } catch (error) {
    yield put(getWeeklyFollowUpReminderFailure(error));
  }
}
function* getFollowUpRemindersByDate(action) {
  try {
    const response = yield call(followUpReminderService.getFollowUpRemindersByDate, action.payload);
    yield put(getFollowUpRemindersByDateSuccess(response.data));
  } catch (error) {
    yield put(getFollowUpRemindersByDateFailure(error));
  }
}
function* getFollowUpReminders(action) {
  try {
    console.log("getFollowUpReminders");
    const response = yield call(followUpReminderService.getFollowUpReminders, action.payload);
    yield put(getFollowUpRemindersSuccess(response.data));
  } catch (error) {
    yield put(getFollowUpRemindersFailure(error));
  }
}
function* addAggresiveFollowUp(action) {
  try {
    const response = yield call(followUpReminderService.addAggresiveFollowUp, action.payload);
    yield put(addAggresiveFollowUpSuccess(response.data));
  } catch (error) {
    yield put(addAggresiveFollowUpFailure(error));
  }
}

// Watcher Saga
function* followUpReminderSaga() {
  yield takeLatest("followUpReminders/getWeeklyFollowUpReminder", getWeeklyFollowUpReminder);
  yield takeLatest("followUpReminders/getFollowUpRemindersByDate", getFollowUpRemindersByDate);
  yield takeLatest("followUpReminders/getFollowUpReminders", getFollowUpReminders);
  yield takeLatest("followUpReminders/addAggresiveFollowUp", addAggresiveFollowUp);
}

// Export all sagas
export default followUpReminderSaga;
