/* eslint-disable */
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function KioskScans(kioskScanData) {
  const [menu, setMenu] = useState(null);
  const { t } = useTranslation();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const kioskColumns = [
    {
      field: "doctor",
      headerName: t("Doctor"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "city",
      headerName: t("City"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "title",
      headerName: t("Title"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "totalScans",
      headerName: t("Total Scans"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
  ];

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {t("Kiosk Scans")}
          </MDTypography>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={kioskScanData?.data}
            columns={kioskColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowId={(row) => row.kioskId}
            getRowHeight={() => "auto"}
            getRowWidth={() => "auto"}
          />
        </div>
      </MDBox>
    </Card>
  );
}

export default KioskScans;
