/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FollowUpTable from "./components/followUpTable";
function FollowUp() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <FollowUpTable />
      </DashboardLayout>
    </>
  );
}
export default FollowUp;
