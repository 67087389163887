/* eslint-disable */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState, useContext } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import useAgora from "../../../hooks/useAgora.tsx";
import { Link, useNavigate } from "react-router-dom";
import {
  updateVideoConsultationStatus,
  videoConsultationSelector,
} from "store/reducers/videoConsultationSlice.js";
import { useDispatch, useSelector } from "react-redux";

const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });

function Bill({
  name,
  mobile,
  status,
  consultationId,
  patientId,
  agentId,
  token,
  callType,
  intervalId,
  company,
  email,
  vat,
  noGutter,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [appid] = useState("913c7cd202b04497b57309ea258f9cac");
  const [channel, setChannel] = useState("");
  const { localAudioTrack, localVideoTrack, leave, join, joinState, remoteUsers } =
    useAgora(client);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const updateVideoConsultationStatusSuccess = useSelector(
    videoConsultationSelector.updateVideoConsultationStatusSuccess
  );
  const updateVideoConsultationStatusFailure = useSelector(
    videoConsultationSelector.updateVideoConsultationStatusFailure
  );

  const handleClickJoin = () => {
    if (consultationId) {
      let consultationStatus = "inprogress";
      updateVideoConsultationStatusData(consultationId, consultationStatus);
    }
  };

  const updateVideoConsultationStatusData = (consultationId, consultationStatus) => {
    const formData = {
      videoConsultationId: consultationId,
      videoConsultationStatus: consultationStatus,
    };
    dispatch(updateVideoConsultationStatus(formData, loginData.token));
  };

  useEffect(() => {
    if (updateVideoConsultationStatusSuccess) {
      navigate("/videoCall", {
        state: { appid, channel: patientId + agentId, token, joinState, callType },
      });
    }
  }, [updateVideoConsultationStatusSuccess]);

  useEffect(() => {
    if (updateVideoConsultationStatusFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateVideoConsultationStatusFailure]);

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </MDTypography>

          <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDButton
              variant="gradient"
              disabled={joinState}
              onClick={handleClickJoin}
              component="span"
              fontWeight="bold"
              color="success"
            >
              <Icon>call</Icon>&nbsp;Join {callType === 0 ? "Audio" : "Video"}
            </MDButton>
            &nbsp;
            <MDButton
              component="span"
              fontWeight="bold"
              color="info"
              variant="gradient"
              disabled={joinState}
              onClick={() => {
                window.open("/patientdetails/" + patientId, "_blank");
              }}
            >
              <Icon>info</Icon>&nbsp;View Details
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Mobile Number:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {mobile}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Status:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {status}
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Bill.defaultProps = {
  noGutter: false,
};

Bill.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  mobile: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  vat: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Bill;
