/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import { Select, FormControl, InputLabel } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFollowUp,
  addFollowUp,
  getFollowUpById,
  followUpSelector,
  resetAddFollowUpSuccess,
  resetUpdateFollowUpSuccess,
} from "store/reducers/followUpSlice";
import {
  getDoctorPatientAppointment,
  getDoctorsList,
  doctorSelector,
} from "store/reducers/doctorSlice";

function AddEditFollowUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { followUpId } = useParams();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const [selectedDoctor, setSelectedDoctor] = useState(
    state && state.data ? state.data.doctorId : ""
  );
  const [followUpTakenBy, setFollowUpTakenBy] = useState(
    state && state.data ? state.data.followUptakenBy : ""
  );
  const [selectedAppointment, setSelectedAppointment] = useState(
    state && state.data ? state.data.appointmentId : ""
  );
  const [suggestion, setSuggestion] = useState(
    state && state.data ? state.data.doctorSuggestion : ""
  );
  const [patientStatus, setPatientStatus] = useState(
    state && state.data ? state.data.patientStatus : ""
  );
  const [patientResponse, setPatientResponse] = useState(
    state && state.data ? state.data.patientResponse : ""
  );
  let [patientStatusData] = useState(["Warm", "Cold", "Hot"]);
  const [dateValue, setDateValue] = useState(state && state.data ? state.data.followUpDate : "");
  const [doctorDetails, setDoctorDetails] = useState([]);
  let [doctorPatientAppointment, setDoctorPatientAppointment] = useState([]);
  let [followUpResponse, setFollowUpResponse] = useState([]);
  const doctorsData = useSelector((state) => state?.doctor?.doctors);
  const getDoctorsListSuccess = useSelector(doctorSelector.getDoctorsListSuccess);
  const getDoctorsListFailure = useSelector(doctorSelector.getDoctorsListFailure);
  const updateFollowUpSuccess = useSelector(followUpSelector.updateFollowUpSuccess);
  const updateFollowUpFailure = useSelector(followUpSelector.updateFollowUpFailure);
  const addFollowUpData = useSelector((state) => state?.followUp?.followUps);
  const addFollowUpSuccess = useSelector(followUpSelector.addFollowUpSuccess);
  const addFollowUpFailure = useSelector(followUpSelector.addFollowUpFailure);
  const followUpData = useSelector((state) => state?.followUp?.followUps);
  const getFollowUpByIdSuccess = useSelector(followUpSelector.getFollowUpByIdSuccess);
  const getFollowUpByIdFailure = useSelector(followUpSelector.getFollowUpByIdFailure);
  const doctorPatientAppointmentData = useSelector(
    (state) => state?.doctor?.doctorsPatientAppointment
  );
  const getDoctorPatientAppointmentSuccess = useSelector(
    doctorSelector.getDoctorPatientAppointmentSuccess
  );
  const getDoctorPatientAppointmentFailure = useSelector(
    doctorSelector.getDoctorPatientAppointmentFailure
  );
  const [isDoctorFilled, setIsDoctorFilled] = useState(true);
  const [isFollowUpTakenByFilled, setIsFollowUpTakenByFilled] = useState(true);
  const [isAppointmentFilled, setIsAppointmentFilled] = useState(true);
  const [isPatientStatusFilled, setIsPatientStatusFilled] = useState(true);
  const [isDateFilled, setIsDateFilled] = useState(true);
  const [isPatientResponseFilled, setIsPatientResponseFilled] = useState(true);

  const [isDisabled, setDisabled] = useState(false);
  useEffect(() => {
    fetchDoctorsList();
    if (followUpId) {
      fetchFollowUpById(followUpId);
    }
  }, []);

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
    setIsDateFilled(true);
  };

  const handleDoctorChange = (data) => {
    setSelectedDoctor(data.target.value);
    fetchDoctorPatientAppointment(data.target.value);
    setIsDoctorFilled(true);
  };

  const fetchFollowUpById = (followUpId) => {
    dispatch(getFollowUpById(followUpId, loginData.token));
  };

  useEffect(() => {
    if (followUpData?.followUp) {
      setFollowUpResponse(followUpData?.followUp);
      const doctorId = followUpData?.followUp?.doctorId;
      const patientId = followUpData?.followUp?.patientId;
      fetchDoctorPatientAppointment(doctorId, patientId);
      setSelectedDoctor(followUpData?.followUp?.doctorId);
      setFollowUpTakenBy(followUpData?.followUp?.followUptakenBy);
      setSelectedAppointment(followUpData?.followUp?.appointmentId);
      setSuggestion(followUpData?.followUp?.doctorSuggestion);
      setPatientStatus(followUpData?.followUp?.patientStatus);
      setPatientResponse(followUpData?.followUp?.patientResponse);
      setDateValue(followUpData?.followUp?.followUpDate);
    }
  }, [followUpData]);

  useEffect(() => {
    if (getFollowUpByIdFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getFollowUpByIdFailure]);

  const fetchDoctorsList = () => {
    dispatch(getDoctorsList(loginData.token));
  };

  useEffect(() => {
    if (doctorsData?.doctors) {
      setDoctorDetails([...doctorsData?.doctors]);
    }
  }, [doctorsData]);

  useEffect(() => {
    if (getDoctorsListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorsListFailure]);

  const fetchDoctorPatientAppointment = (doctorId, patientId) => {
    if (state && state.patientId) {
      patientId = state.patientId;
    }
    const formData = {
      doctorId,
      patientId,
      token: loginData.token,
    };
    dispatch(getDoctorPatientAppointment(formData));
  };

  useEffect(() => {
    if (doctorPatientAppointmentData?.appointments) {
      setDoctorPatientAppointment(doctorPatientAppointmentData?.appointments);
    }
  }, [doctorPatientAppointmentData]);

  useEffect(() => {
    if (getDoctorPatientAppointmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorPatientAppointmentFailure]);

  const handleUpdateFollowUp = () => {
    const formData = {
      _id: followUpResponse._id,
      patientId: followUpResponse.patientId,
      followUpDate: dateValue,
      patientResponse: patientResponse,
      doctorId: selectedDoctor,
      appointmentId: selectedAppointment,
      doctorSuggestion: suggestion,
      followUptakenBy: followUpTakenBy,
      patientStatus: patientStatus,
    };

    dispatch(updateFollowUp(formData, loginData.token));
  };

  useEffect(() => {
    if (updateFollowUpSuccess) {
      navigate("/patientdetails/" + followUpResponse.patientId);
      Swal.fire({
        icon: "success",
        text: " Followup update successful",
        timer: 2000,
        showConfirmButton: false,
      });
      dispatch(resetUpdateFollowUpSuccess());
    }
  }, [updateFollowUpSuccess]);

  useEffect(() => {
    if (updateFollowUpFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateFollowUpFailure]);

  const handleFollowUpSubmit = () => {
    if (validateAddFolowUp() === true) {
      const formData = {
        patientId: state.patientId,
        followUpDate: dateValue,
        patientResponse: patientResponse,
        doctorId: selectedDoctor,
        appointmentId: selectedAppointment,
        doctorSuggestion: suggestion,
        followUptakenBy: followUpTakenBy,
        patientStatus: patientStatus,
      };
      dispatch(addFollowUp(formData, loginData.token));
    } else {
      console.log("Followup not validated");
    }
  };

  useEffect(() => {
    if (addFollowUpSuccess) {
      setDisabled(true);
      navigate("/patientdetails/" + state.patientId);
      Swal.fire({
        icon: "success",
        text: " Followup added successfully!",
        timer: 2000,
        showConfirmButton: false,
      });
      dispatch(resetAddFollowUpSuccess());
    }
  }, [addFollowUpSuccess]);

  useEffect(() => {
    if (addFollowUpFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addFollowUpFailure]);

  const validateAddFolowUp = () => {
    if (selectedDoctor == "") {
      setIsDoctorFilled(false);
      Swal.fire({
        icon: "error",
        text: "Doctor cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (followUpTakenBy == "") {
      setIsFollowUpTakenByFilled(false);
      Swal.fire({
        icon: "error",
        text: "Followup taken by cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (selectedAppointment == "") {
      setIsAppointmentFilled(false);
      Swal.fire({
        icon: "error",
        text: "Appointment cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (patientStatus == "") {
      setIsPatientStatusFilled(false);
      Swal.fire({
        icon: "error",
        text: "Patient status cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (patientResponse == "") {
      setIsPatientResponseFilled(false);
      Swal.fire({
        icon: "error",
        text: "Patient response cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    if (dateValue == "") {
      setIsDateFilled(false);
      Swal.fire({
        icon: "error",
        text: "Date and time cannot be empty",
        timer: 2000,
        showConfirmButton: false,
      });
      return false;
    }
    return true;
  };

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;
    if (keyCode === enter) {
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c))
        .join("");
      console.log("target", target);
      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }
    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value}`;
    }
  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar pathName={followUpId ? "edit-followUp" : "add-followUp"} />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={7}>
            <Card id="delete-account">
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <br />
                  <h4>
                    <center>{followUpId ? "Edit FollowUp" : "Add FollowUp"}</center>
                  </h4>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="lg"
                    p={3}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox mb={1} lineHeight={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Doctor *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedDoctor}
                                onChange={handleDoctorChange}
                              >
                                {doctorDetails
                                  ? doctorDetails.sort(compare).map((data) => {
                                      return (
                                        <MenuItem value={data.did}>
                                          {toTitleCase(data.name)}
                                        </MenuItem>
                                      );
                                    })
                                  : ""}
                              </Select>
                              {!isDoctorFilled && (
                                <p style={{ color: "red" }}>Please select a doctor.</p>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                FollowUp Taken By *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={followUpTakenBy}
                                onChange={(e) => {
                                  setFollowUpTakenBy(e.target.value);
                                  setIsFollowUpTakenByFilled(true);
                                }}
                              >
                                {doctorDetails
                                  ? doctorDetails.map((data) => {
                                      return (
                                        <MenuItem value={data.did}>
                                          {toTitleCase(data.name)}
                                        </MenuItem>
                                      );
                                    })
                                  : ""}
                              </Select>
                              {!isFollowUpTakenByFilled && (
                                <p style={{ color: "red" }}>Please select follow-up taken by.</p>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Appointment *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedAppointment}
                                onChange={(e) => {
                                  setIsAppointmentFilled(true);
                                  setSelectedAppointment(e.target.value);
                                }}
                              >
                                {doctorPatientAppointment
                                  ? doctorPatientAppointment.map((data) => {
                                      return <MenuItem value={data.aid}>{data.title}</MenuItem>;
                                    })
                                  : ""}
                              </Select>
                              {!isAppointmentFilled && (
                                <p style={{ color: "red" }}>Please select an appointment.</p>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Suggestion"
                              value={suggestion}
                              onChange={(e) => setSuggestion(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">
                                Patient Status *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={patientStatus}
                                onChange={(e) => {
                                  setIsPatientStatusFilled(true);
                                  setPatientStatus(e.target.value);
                                }}
                              >
                                {patientStatusData
                                  ? patientStatusData
                                      .sort((a, b) => a.localeCompare(b))
                                      .map((type) => {
                                        return <MenuItem value={type}> {type} </MenuItem>;
                                      })
                                  : ""}
                              </Select>
                              {!isPatientStatusFilled && (
                                <p style={{ color: "red" }}>Please select patient Status.</p>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DateTimePicker
                                  label="Date and Time *"
                                  inputFormat="dd/MM/yyyy hh.mm a"
                                  value={dateValue}
                                  onChange={handleDateChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                            {!isDateFilled && (
                              <p style={{ color: "red" }}>Please select Date and Time.</p>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Patient Response *"
                              value={patientResponse}
                              onChange={(e) => {
                                setPatientResponse(e.target.value);
                                setIsPatientResponseFilled(true);
                              }}
                              multiline
                              onKeyUp={(e) => handleInput(e)}
                            />
                            {!isPatientResponseFilled && (
                              <p style={{ color: "red" }}>Please select patient Response.</p>
                            )}
                          </Grid>
                        </Grid>
                        <br />
                        <br />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <MDButton
                              fullWidth
                              variant="gradient"
                              onClick={() => {
                                navigate(-1);
                              }}
                              color="info"
                            >
                              Cancel
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {followUpId ? (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                disabled={isDisabled === true}
                                onClick={() => {
                                  // navigate("/patientdetails/" + result[0].patientId);
                                  handleUpdateFollowUp();
                                }}
                                color="info"
                              >
                                Update
                              </MDButton>
                            ) : (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                onClick={handleFollowUpSubmit}
                                disabled={isDisabled === true}
                                color="info"
                              >
                                Submit
                              </MDButton>
                            )}
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default AddEditFollowUp;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
