/* eslint-disable */
import AgentTable from "./components/agentTable";
import CreateUser from "./components/create-user";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";

function Agent() {
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const getRoleData =
    loginData &&
    loginData.roleData &&
    loginData.roleData.permissions.find((data) => data.name.toLowerCase() === "agent");

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />
        {/* DentSea */}
        {/* {getRoleData.operations.includes("add") || getRoleData.operations.includes("Add") ? (
        <MDButton variant="gradient" color="info" onClick={handleAddAgent}> // navigate("/add-edit-user");
          <AddIcon /> Add New Agent
        </MDButton>
      ) : (
        ""
      )} */}
        {/* <MDButton variant="gradient" color="info" onClick={handleAddAgent}>
          <AddIcon /> Add New Agent
        </MDButton> */}
        <AgentTable />
        {/* <CreateUser /> */}
      </DashboardLayout>
    </>
  );
}
export default Agent;
