/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DdAccountTable from "./components/ddAccountDetails";
function DdAccount() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />
        <DdAccountTable />
      </DashboardLayout>
    </>
  );
}
export default DdAccount;
