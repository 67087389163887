// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  addKioskSuccess,
  addKioskFailure,
  getKioskListSuccess,
  getKioskListFailure,
  updateKioskSuccess,
  updateKioskFailure,
  getKioskUsersSuccess,
  getKioskUsersFailure,
} from "store/reducers/kioskSlice"; // Import your action creator
import kioskService from "services/kiosk"; // Import your service function

function* addKiosk(action) {
  try {
    const response = yield call(kioskService.addKiosk, action.payload);
    yield put(addKioskSuccess(response.data));
  } catch (error) {
    yield put(addKioskFailure(error));
  }
}

function* getKioskList(action) {
  try {
    const response = yield call(kioskService.getKioskList, action.payload);
    yield put(getKioskListSuccess(response.data));
  } catch (error) {
    yield put(getKioskListFailure(error));
  }
}

function* updateKiosk(action) {
  try {
    const response = yield call(kioskService.updateKiosk, action.payload);
    yield put(updateKioskSuccess(response.data));
  } catch (error) {
    yield put(updateKioskFailure(error));
  }
}

function* getKioskUsers(action) {
  try {
    const response = yield call(kioskService.getKioskUsers, action.payload);
    yield put(getKioskUsersSuccess(response.data));
  } catch (error) {
    yield put(getKioskUsersFailure(error));
  }
}

// Watcher Saga
function* kioskSaga() {
  yield takeLatest("kiosks/addKiosk", addKiosk);
  yield takeLatest("kiosks/getKioskList", getKioskList);
  yield takeLatest("kiosks/updateKiosk", updateKiosk);
  yield takeLatest("kiosks/getKioskUsers", getKioskUsers);
}

// Export all sagas
export default kioskSaga;
