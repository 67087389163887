/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import TextField from "@mui/material/TextField";
import { Select, FormControl, InputLabel } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointment,
  appointmentSelector,
  resetAddAppointmentSuccess,
} from "store/reducers/appointmentSlice";
import {
  getDoctorPatientAppointment,
  getDoctorsList,
  getDoctorsDetails,
  doctorSelector,
} from "store/reducers/doctorSlice";

function AddEditAppointment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { state } = useLocation();
  const [selectedDoctor, setSelectedDoctor] = useState(
    state && state.data ? state.data.doctorId : ""
  );
  const [description, setDescription] = useState("");
  const [appointmentTitle, setAppointmentTitle] = useState("");
  const [duration, setDuration] = useState(30);
  const [clinicID, setClinicID] = useState("");
  const [doctorDetails, setDoctorDetails] = useState([]);
  const [valueDate, setDateValue] = useState();
  const [newDoctorID, setNewDoctorID] = useState("");
  const [doctorId, setDoctorId] = useState("");
  const [doctorPatientAppointment, setDoctorPatientAppointment] = useState([]);
  const [doctorsClinic, setDoctorsClinic] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const doctorPatientAppointmentData = useSelector(
    (state) => state?.doctor?.doctorsPatientAppointment
  );
  const getDoctorPatientAppointmentSuccess = useSelector(
    doctorSelector.getDoctorPatientAppointmentSuccess
  );
  const getDoctorPatientAppointmentFailure = useSelector(
    doctorSelector.getDoctorPatientAppointmentFailure
  );
  const getDoctorsListSuccess = useSelector(doctorSelector.getDoctorsListSuccess);
  const getDoctorsListFailure = useSelector(doctorSelector.getDoctorsListFailure);
  const doctorsData = useSelector((state) => state?.doctor?.doctors);
  const getDoctorsDetailsSuccess = useSelector(doctorSelector.getDoctorsDetailsSuccess);
  const getDoctorsDetailsFailure = useSelector(doctorSelector.getDoctorsDetailsFailure);
  const addAppointmentSuccess = useSelector(appointmentSelector.addAppointmentSuccess);
  const addAppointmentFailure = useSelector(appointmentSelector.addAppointmentFailure);

  useEffect(() => {
    fetchDoctorsList();
    if (state && state.data) {
      fetchDoctorPatientAppointment(state.data.doctorId);
    }
  }, []);

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  const handleDoctorChange = (data) => {
    setNewDoctorID(data.target.value);
    setSelectedDoctor(data.target.value);
    fetchDoctorsDetails(data.target.value);
    fetchDoctorPatientAppointment(data.target.value);
  };

  const fetchDoctorsDetails = (newDoctorID) => {
    dispatch(getDoctorsDetails(newDoctorID));
  };

  useEffect(() => {
    if (doctorsData?.doctor) {
      setDoctorsClinic(doctorsData?.doctor?.clinics);
    }
  }, [doctorsData]);

  useEffect(() => {
    if (getDoctorsDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorsDetailsFailure]);

  const fetchDoctorsList = () => {
    dispatch(getDoctorsList(loginData.token));
  };

  useEffect(() => {
    if (doctorsData?.doctors) {
      setDoctorId([...doctorsData?.doctors]);
      setDoctorDetails([...doctorsData?.doctors]);
    }
  }, [doctorsData]);

  useEffect(() => {
    if (getDoctorsListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorsListFailure]);

  const fetchDoctorPatientAppointment = (newDoctorID) => {
    const formData = {
      doctorId: newDoctorID,
      patientId: state.patientId,
      token: loginData.token,
    };
    dispatch(getDoctorPatientAppointment(formData));
  };

  useEffect(() => {
    if (doctorPatientAppointmentData?.appointments) {
      setDoctorPatientAppointment(doctorPatientAppointmentData?.appointments);
    }
  }, [doctorPatientAppointmentData]);

  useEffect(() => {
    if (getDoctorPatientAppointmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorPatientAppointmentFailure]);

  const validateAddAppointment = () => {
    if (newDoctorID === "") {
      Swal.fire({
        icon: "error",
        text: "Doctor id cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    } else if (clinicID === "") {
      Swal.fire({
        icon: "error",
        text: "Clinic id cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    } else if (appointmentTitle === "") {
      Swal.fire({
        icon: "error",
        text: "Title cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    } else if (description === "") {
      Swal.fire({
        icon: "error",
        text: "Description cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };

  const handleAddAppointment = () => {
    if (validateAddAppointment() === true) {
      const formData = {
        did: newDoctorID,
        startTime: valueDate.toISOString(),
        pid: state.patientId,
        duration: duration,
        cid: clinicID,
        title: appointmentTitle,
        description,
        treatments: [...treatment],
      };
      dispatch(addAppointment(formData));
    } else {
      console.log("Form not validated");
    }
  };

  useEffect(() => {
    if (addAppointmentSuccess) {
      Swal.fire({
        icon: "success",
        text: "Appointment Added Succesfully",
        timer: 2000,
      });
      navigate("/patientdetails/" + state.patientId);
      dispatch(resetAddAppointmentSuccess());
    }
  }, [addAppointmentSuccess]);

  useEffect(() => {
    if (addAppointmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addAppointmentFailure]);

  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...treatment];
    list.splice(index, 1);
    setTreatment(list);
  };

  const handleUpdateChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...treatment];
    list[index][name] = value;
    setTreatment(list);
  };
  const handleAddClick = (event) => {
    event.preventDefault();
    setTreatment([...treatment, { name: "", price: "" }]);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card id="delete-account">
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <br />
                  <h4>
                    <center>Add Appointment</center>
                  </h4>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={darkMode ? "transparent" : "grey-100"}
                    borderRadius="lg"
                    p={3}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox mb={1} lineHeight={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack spacing={3}>
                                <DateTimePicker
                                  label="Date and Time"
                                  value={valueDate}
                                  inputFormat="dd/MM/yyyy hh:mm a"
                                  onChange={handleDateChange}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </Stack>
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Duration(minutes)"
                              value={duration}
                              onChange={(e) => setDuration(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">Doctor</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={handleDoctorChange}
                              >
                                {doctorDetails.sort(compare).map((doctor) => {
                                  return <MenuItem value={doctor.did}> {doctor.name} </MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                              <InputLabel id="demo-simple-select-standard-label">Clinic</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(value) => {
                                  setClinicID(value.target.value);
                                }}
                              >
                                {doctorsClinic
                                  ? doctorsClinic.map((clinic) => {
                                      return (
                                        <MenuItem value={clinic._id}> {clinic.name} </MenuItem>
                                      );
                                    })
                                  : null}
                              </Select>
                            </FormControl>
                          </Grid>
                          <br />
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Title"
                              multiline
                              value={appointmentTitle}
                              onChange={(e) => setAppointmentTitle(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              multiline
                              label="Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                        <br />
                        <br />
                        <hr />
                        <b>TREATMENTS</b>
                        <MDButton
                          variant="gradient"
                          onClick={handleAddClick}
                          style={{ float: "right" }}
                          color="info"
                        >
                          +
                        </MDButton>
                        <br />
                        <br />

                        {treatment.length > 0
                          ? treatment.map((treatmentData, i) => {
                              return (
                                <Table responsive>
                                  <br />
                                  <tr>
                                    <td>
                                      <TextField
                                        name="name"
                                        fullWidth
                                        multiline
                                        label="Treatment Name"
                                        onChange={(e) => handleUpdateChange(e, i)}
                                      />
                                    </td>
                                    <td>
                                      <TextField
                                        name="price"
                                        fullWidth
                                        label="Cost"
                                        onChange={(e) => handleUpdateChange(e, i)}
                                      />
                                    </td>
                                    <td>
                                      <MDButton
                                        variant="gradient"
                                        onClick={(event) => handleRemoveClick(event, i)}
                                        color="error"
                                      >
                                        x
                                      </MDButton>
                                    </td>
                                  </tr>
                                </Table>
                              );
                            })
                          : ""}
                        <br />
                        <br />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <MDButton
                              fullWidth
                              variant="gradient"
                              onClick={() => {
                                navigate(-1);
                              }}
                              disabled={isDisabled === "true"}
                              color="info"
                            >
                              Cancel
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MDButton
                              fullWidth
                              variant="gradient"
                              onClick={handleAddAppointment}
                              disabled={isDisabled}
                              color="info"
                            >
                              Submit
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
export default AddEditAppointment;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
