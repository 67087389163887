/* eslint-disable */

import { useEffect, useState } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import moment from "moment";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import MDBox from "components/MDBox";
import { getAppointmentList, patientDetailsSelector } from "store/reducers/patientdetailsSlice";
import { useSelector } from "react-redux";

function FollowUp(props) {
  const navigate = useNavigate();

  let [followUpTimeline, setFollowUpTimeline] = useState([]);
  const appointmentListData = useSelector((state) => state?.patientDetails?.appointmentList);
  const getAppointmentListSuccess = useSelector(patientDetailsSelector.getAppointmentListSuccess);
  const getAppointmentListFailure = useSelector(patientDetailsSelector.getAppointmentListFailure);

  const fetchAppointmentList = () => {
    dispatch(getAppointmentList(props.patientId));
  };

  useEffect(() => {
    if (appointmentListData?.appointments) {
      const newFollowUps = appointmentListData?.appointments?.filter(
        (followUpList) => followUpList?.followUps.length > 0
      );
      setFollowUpTimeline([...newFollowUps].reverse());
    }
  }, [appointmentListData]);

  useEffect(() => {
    if (getAppointmentListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAppointmentListFailure]);

  const handleClickFollowUp = (followUpData) => {
    window.open("/edit-followup/" + followUpData._id, "_blank").focus();
  };

  useEffect(() => {
    getAppointmentList();
  }, []);
  const handleClickAddFollowUp = () => {
    navigate("/add-edit-followup", {
      state: {
        patientId: props.patientId,
      },
    });
  };
  return (
    <div>
      <MDBox mr={1} style={{ float: "right" }}>
        <MDButton variant="gradient" onClick={handleClickAddFollowUp} color="info">
          <AddIcon /> Add FollowUp
        </MDButton>
      </MDBox>
      <br />
      <br />
      {followUpTimeline
        ? followUpTimeline.map((appointment) => {
            return (
              <Timeline align="alternate">
                <TimelineItem>
                  <TimelineOppositeContent>
                    <MDTypography color="textSecondary">
                      {moment(appointment.startTime).format("DD/MM/YYYY")}
                    </MDTypography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Card elevation={3} style={{ padding: "6px 16px" }}>
                      <MDTypography>
                        <strong>{appointment.title}</strong> <br /> {appointment.description}
                      </MDTypography>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
                {appointment
                  ? appointment.followUps.map((followUp) => {
                      return (
                        <TimelineItem>
                          <TimelineOppositeContent>
                            <MDTypography color="textSecondary">
                              {moment(followUp.followUpDate).format("DD/MM/YYYY")}
                            </MDTypography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot
                              color={
                                new Date(followUp.followUpDate) > new Date() ? "" : "secondary"
                              }
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Card
                              elevation={3}
                              style={{
                                padding: "6px 16px",
                                backgroundColor:
                                  followUp.patientStatus === "Warm"
                                    ? "#EFC050"
                                    : followUp.patientStatus === "Cold"
                                    ? "#45B8AC"
                                    : followUp.patientStatus === "Hot"
                                    ? "#DD4124"
                                    : "",
                              }}
                              onClick={() => handleClickFollowUp(followUp)}
                            >
                              <MDTypography>
                                {followUp.patientResponse} <br /> {followUp.doctorSuggestion}
                              </MDTypography>
                            </Card>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })
                  : null}
              </Timeline>
            );
          })
        : null}
    </div>
  );
}
export default FollowUp;
