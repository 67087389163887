import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Card } from "react-bootstrap";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getKioskList, kioskSelector } from "store/reducers/kioskSlice";
import routes from "routes";

const Kiosk = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [kioskList, setKioskList] = useState([]);
  const navigate = useNavigate();
  const kiosks = useSelector((state) => state?.kiosk?.kiosks);
  const getKioskListSuccess = useSelector(kioskSelector.getKioskListSuccess);
  const getKioskListFailure = useSelector(kioskSelector.getKioskListFailure);

  const kioskColumns = [
    {
      field: "name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "location",
      headerName: "Location",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "autoHeightAdjust",
      headerName: "AutoHeightAdjust",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "bypassOtp",
      headerName: "BypassOtp",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "Action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      width: 250,
      renderCell: (data) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() =>
                navigate("/add-edit-kiosk", {
                  state: {
                    kioskData: data.row,
                  },
                })
              }
            >
              Edit
            </MDButton>
            &nbsp;&nbsp;
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    fetchKioskData();
  }, []);

  const fetchKioskData = () => {
    dispatch(getKioskList(loginData?.token));
  };

  useEffect(() => {
    if (kiosks) {
      setKioskList(kiosks);
    }
  }, [kiosks]);

  useEffect(() => {
    if (getKioskListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getKioskListFailure]);

  return (
    <MDBox pt={6} pb={3}>
      <br />
      <br />
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Kiosk list
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={3} px={2}>
            <div style={{ height: 630, width: "100%" }}>
              {isLoading ? (
                <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                  <CircularProgress color="inherit" />
                </MDBox>
              ) : (
                <DataGrid
                  rows={kioskList}
                  columns={kioskColumns}
                  pageSize={10}
                  getRowId={(row) => row._id}
                />
              )}
            </div>
          </MDBox>
        </Card>
      </Grid>
    </MDBox>
  );
};

export default Kiosk;
