/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import Card from "@mui/material/Card";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getPatientTestByAgent, getPatientTest, agentSelector } from "store/reducers/agentSlice";
import Swal from "sweetalert2";
import routes from "routes";

function PatientsWithTests() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const [patientTestList, setPatientTestList] = useState([]);

  const [newPatientTestList, setNewPatientTestList] = useState([]);
  const [state, setState] = useState({
    checkedB: false,
  });
  const [allTests, setAllTests] = useState({
    checkeAllTests: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const patientTestByAgent = useSelector((state) => state?.agent?.patientTestByAgent);
  const getPatientTestByAgentSuccess = useSelector(agentSelector.getPatientTestByAgentSuccess);
  const getPatientTestByAgentFailure = useSelector(agentSelector.getPatientTestByAgentFailure);
  const patientTest = useSelector((state) => state?.agent?.patientTest);
  const getPatientTestSuccess = useSelector(agentSelector.getPatientTestSuccess);
  const getPatientTestFailure = useSelector(agentSelector.getPatientTestFailure);

  useEffect(() => {
    getPatientTestData(0);
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const getPatientTestData = (page) => {
    const pageNumber = page === undefined ? "0" : page;
    const pageSize = 10;
    const formData = {
      pageNumber: parseInt(pageNumber) + 1,
      pageSize,
    };
    dispatch(getPatientTestByAgent(formData));
  };

  useEffect(() => {
    if (patientTestByAgent) {
      setNewPatientTestList(patientTestByAgent?.tests);
      setPatientTestList(patientTestByAgent?.tests);
      setResponseData(patientTestByAgent);
      setIsLoading(false);
    }
  }, [patientTestByAgent]);

  useEffect(() => {
    if (getPatientTestByAgentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientTestByAgentFailure]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const patientTestColumns = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center", width: 80 },
    {
      field: "user",
      headerName: "Patient Name",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (data) => {
        return <div>{toTitleCase(data.row.name)}</div>;
      },
    },
    {
      field: "created_date",
      headerName: "Test Date",
      headerAlign: "center",
      align: "center",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY hh:mm A");
        return `${valueFormatted}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return <div>{toTitleCase(data.row.test_status)}</div>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        return (
          <>
            <MDButton variant="gradient" onClick={onDetailsClick} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];

  const handleFilterTest = (datavalue) => {
    const newTestData = patientTestList.filter((testList) => testList.id === datavalue.id);
    setTestID(datavalue.id);
    setNewtestData(newTestData[0].testsData.detections);
    const newDiseaseList = [];
    if (!isEmpty(newTestData[0].testsData.detections)) {
      newTestData[0].testsData.detections.forEach((ele) => {
        if (!isEmpty(ele.diseaseList)) {
          ele.diseaseList.forEach((element) => {
            newDiseaseList.push(element);
          });
        }
      });
    }
    const occurrences = newDiseaseList.reduce(function (acc, curr) {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
    let finalArray = [];
    for (const [key, value] of Object.entries(occurrences)) {
      finalArray.push({ name: key, count: value });
    }
    setNewDiseaseList(finalArray);
  };

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (state.checkedB === false) {
      const newData = patientTestList.filter((patient) => patient.test_status === "pending");
      setPatientTestList(newData);
    } else {
      setPatientTestList(newPatientTestList);
    }
  };
  const getAllPatientsTest = (page) => {
    const pageNumber = page === undefined ? "0" : page;
    const pageSize = 10;
    const formData = {
      pageNumber: parseInt(pageNumber) + 1,
      pageSize,
    };
    dispatch(getPatientTest(formData));
  };

  useEffect(() => {
    if (patientTest?.tests) {
      setNewPatientTestList(patientTest?.tests);
      setPatientTestList(patientTest?.tests);
      setIsLoading(false);
      setResponseData(patientTest);
    }
  }, [patientTest]);

  useEffect(() => {
    if (getPatientTestFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientTestFailure]);

  const handleShowAllTests = (event) => {
    setAllTests({ ...allTests, [event.target.name]: event.target.checked });
    if (allTests.checkeAllTests === false) {
      setIsLoading(true);
      getAllPatientsTest(0);
    } else {
      getPatientTestData(0);
    }
  };

  const handleDetailsClick = (thisRow) => {
    const newTestData = patientTestList.filter((testList) => testList.id === thisRow.id);
    navigate("/testdetails/" + newTestData[0].testsData._id, {
      state: {
        testId:
          newTestData[0] && newTestData[0].testsData && newTestData[0].testsData._id
            ? newTestData[0].testsData._id
            : "",
        patientId: newTestData[0] && newTestData[0].patientId ? newTestData[0].patientId : "",
        testDetails: newTestData[0],
        patientDetails: newTestData[0],
      },
    });
  };

  const handlePageChange = (newPage) => {
    if (allTests.checkeAllTests === false) {
      getPatientTestData(newPage);
    } else {
      getAllPatientsTest(newPage);
    }
  };

  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                My Patients Tests
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <MDBox px={2}>
                <MDBox>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkedB}
                        onChange={handleChangeCheckbox}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Show Pending Tests"
                  />
                </MDBox>
                <MDBox>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checkeAllTests}
                        onChange={handleShowAllTests}
                        name="checkeAllTests"
                        color="primary"
                      />
                    }
                    label="Show All Test"
                  />
                </MDBox>
              </MDBox>

              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <DataGrid
                    disableSelectionOnClick={true}
                    rows={patientTestList}
                    columns={patientTestColumns}
                    pageSize={10}
                    rowCount={
                      responseData && responseData.totalCount ? responseData.totalCount : ""
                    }
                    paginationMode="server"
                    onPageChange={handlePageChange}
                  />
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default PatientsWithTests;
