import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { Stack, TextField, Card, Grid } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import { useDispatch } from "react-redux";
import { getDataCount } from "store/reducers/dashboardSlice";

charts(FusionCharts);
function Projects(analysisData) {
  const dispatch = useDispatch();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const { t } = useTranslation();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [isLoading, setIsLoading] = useState(true);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const appointmentColumns = [
    {
      field: "id",
      headerName: t("Patient No"),
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "date",
      headerName: t("Date"),
      headerAlign: "center",
      align: "center",
      width: 130,
      // renderCell: (data) => {
      //   return (
      //     <div onClick={() => navigate("/patientdetails/" + data.row.patientId)}>
      //       {moment(data.row.date).format("DD/MM/YYYY")}
      //     </div>
      //   );
      // },
    },
    {
      field: "patient",
      headerName: t("Patient"),
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return (
          <div onClick={() => navigate("/patientdetails/" + data.row.patientId)}>
            {toTitleCase(data.row.patient)}
          </div>
        );
      },
    },
    {
      field: "notes",
      headerName: t("Notes"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "FollowUp Date",
      headerName: t("FollowUp Date"),
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (data) => {
        return (
          <div>
            {data.row.followUpDate !== "NA"
              ? moment(data.row.followUpDate).format("DD/MM/YYYY hh:mm A")
              : data.row.followUpDate}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: t("Action"),
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        const handleClickPastAppointment = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleClickView(thisRow);
        };
        return (
          <div>
            <MDButton variant="gradient" onClick={handleClickPastAppointment} color="info">
              View
            </MDButton>
          </div>
        );
      },
    },
  ];

  const handleClickView = (datavalue) => {
    const result = analysisData.data.filter((patientList) => patientList.id === datavalue.id);
    navigate("/patientdetails/" + result[0].patientId);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );
  const localstoredata = JSON.parse(localStorage.getItem("loginData"));

  const [dataCount, setDataCount] = useState([]);
  const [sidebarvalue, setSidebarvalue] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    // GetDataCount();
  }, []);

  const patientData = {
    chart: {
      caption: `Patient's Data 
      <br/>
      OverAll Count: ${dataCount.totalPatientsCount ? dataCount.totalPatientsCount : "0"} 
      <br/>`,
      //subcaption: dataCount.totalPatientsCount ? dataCount.totalPatientsCount : "0",
      enablesmartlabels: "1",
      // showlabels: dataCount.patientsOnAppCount ? dataCount.patientsOnAppCount : "0",
      showlabels: "1",
      //numbersuffix: " MMbbl",
      usedataplotcolorforlabels: "1",
      // plottooltext: "$label, <b>$value</b> MMbbl",
      theme: "gammel",
    },
    data: [
      {
        name: "Total Patients On App",
        value: dataCount.patientsOnAppCount ? dataCount.patientsOnAppCount : "0",
      },
      {
        label: "HelpLine",
        value: dataCount.patientsOnHelplineCount ? dataCount.patientsOnHelplineCount : "0",
      },
      {
        label: "Total Patients On WhatsApp",
        value: dataCount.patientsOnWhatsAppCount ? dataCount.patientsOnWhatsAppCount : "0",
      },
      {
        label: "Total Patients On Kiosk",
        value: dataCount.patientsOnKioskCount ? dataCount.patientsOnKioskCount : "0",
      },
    ],
  };

  const fetchDataCount = () => {
    dispatch(getDataCount(localstoredata.token))
      .unwrap()
      .then((response) => {
        setIsLoading(false);
        setDataCount(response.data);
      })
      .catch((error) => {
        // Handle API error if needed
        Swal.fire({
          icon: "error",
          text: "Error occurred",
          showConfirmButton: false,
          timer: 2000,
        });
        console.log(error);
      });
  };

  const handleClickReset = () => {
    setIsLoading(true);
    fetchDataCount();
    setStartDate(new Date());
    setEndDate(new Date());
  };

  const handleClickSearch = () => {
    const newStartDate = new Date(startDate).toISOString();
    const newEndDate = new Date(endDate).toISOString();
    // doctorService
    //   .getDataCount(localstoredata.token, newStartDate, newEndDate)
    //   .then((response) => {
    //     if (response && response.data) {
    //       setDataCount(response.data);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       console.log(error.response.statusText);
    //     }
    //     console.log(error);
    //   });
  };

  return (
    <>
      {loginData && loginData.roleData && loginData.roleData.roleName === "admin" ? (
        <>
          {/* <Card>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <MDBox>
                <MDTypography variant="h6" gutterBottom>
                  {t("Live Actions")}
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox>
              <MDBox p={2}>
                <Grid item xs={12} sm={12} container>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        label="Start Date"
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                  &nbsp; &nbsp;
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        label="End Date"
                        inputFormat="DD/MM/YYYY"
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <br />
                <MDButton variant="gradient" color="info" onClick={handleClickSearch}>
                  Search
                </MDButton>
                &nbsp; &nbsp;
                <MDButton variant="gradient" color="info" onClick={handleClickReset}>
                  Reset
                </MDButton>
                {isLoading ? (
                  <>
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "100px",
                      }}
                    >
                      <CircularProgress color="inherit" />
                    </MDBox>
                  </>
                ) : (
                  <ReactFusioncharts
                    style={{ fontSize: "20px" }}
                    type="doughnut3d"
                    width="100%"
                    height="100%"
                    dataFormat="JSON"
                    dataSource={patientData}
                  />
                )}
              </MDBox>
            </MDBox>
          </Card> */}
        </>
      ) : (
        ""
      )}

      <br />
      <Card>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDBox>
            <MDTypography variant="h6" gutterBottom>
              {t("Past Appointments")}
            </MDTypography>
            {/* <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text"></MDTypography>
          </MDBox> */}
          </MDBox>
          {/* <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox> */}
          {renderMenu}
        </MDBox>
        <MDBox>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={analysisData.data}
              columns={appointmentColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              getRowId={(row) => row.id}
              getRowHeight={() => "auto"}
              getRowWidth={() => "auto"}
            />
          </div>
        </MDBox>
      </Card>
    </>
  );
}

export default Projects;
