/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
import { useState } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Imports
import axios from "axios";
import constant from "../../../utils/constants";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { agentAuthStart, agentAuthSuccess, agentAuthFailure } from "store/reducers/agentAuthSlice";
import { agentLogin } from "services/agentAuthService";
import {
  checkAgentOnline,
  agentSelector,
  authToken,
  resetAgentOnlineSuccess,
} from "store/reducers/agentSlice";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import routes from "routes";

function Basic() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agentRole, setAgentRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const dispatch = useDispatch();
  const agentData = useSelector((state) => state?.doctor?.agentData);
  const checkAgentOnlineSuccess = useSelector(agentSelector.checkAgentOnlineSuccess);
  const checkAgentOnlineFailure = useSelector(agentSelector.checkAgentOnlineFailure);
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  useEffect(() => {
    document.title = `scanO/signin`;
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (email === "") {
      setShowEmailError(true);
    }
    if (password === "") {
      setShowPasswordError(true);
    }
    try {
      const response = await agentLogin(email, password);
      setIsLoading(false);
      localStorage.setItem("loginData", JSON.stringify(response.data));
      dispatch(agentAuthSuccess(response.data.token));
      dispatch(authToken(response.data.token));
      setAgentRole(response?.data?.roleData?.roleName);
      handleCheckAgentOnline(response?.data?.token, response?.data?.roleData?.roleName);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      Swal.fire({
        icon: "error",
        text: error.message,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleCheckAgentOnline = (token, agentRole) => {
    const agentStatusData = {
      agentStatus: true,
      token,
    };
    dispatch(checkAgentOnline(agentStatusData));
  };

  useEffect(() => {
    if (checkAgentOnlineSuccess) {
      agentRole == "reviewer"
        ? navigate("/annotations-review")
        : agentRole == "partner"
        ? navigate("/kiosk-patients")
        : navigate("/patients");
    }
    dispatch(resetAgentOnlineSuccess());
  }, [checkAgentOnlineSuccess]);

  useEffect(() => {
    if (checkAgentOnlineFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [checkAgentOnlineFailure]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <form onSubmit={() => handleSubmit()}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email *"
                  fullWidth
                  helperText={
                    showEmailError ? (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        Email is required
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setShowEmailError(false); // Hide error when typing
                  }}
                  onKeyPress={handleEnterKey}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password *"
                  fullWidth
                  helperText={
                    showPasswordError ? (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        Password is required
                      </Typography>
                    ) : (
                      ""
                    )
                  }
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setShowPasswordError(false); // Hide error when typing
                  }}
                  onKeyPress={handleEnterKey}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isLoading ? <CircularProgress color="inherit" /> : "Sign in"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
