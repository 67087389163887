// require("dotenv").config();
/* eslint-disable */
const Constants = {
  // API_URL: "http://localhost:3000", // local url
  // API_URL: "https://test-dashboardapi.dentaldost.co", // dev url
  // API_URL: "http://13.233.166.23:3000",
  IMAGE_URL: "https://test-ai.dentaldost.co",
  API_URL: "https://api-interface.dentaldost.co", // server url
  CHAT_URL: "https://bot.dentaldost.co", // "http://192.168.0.198:3001",
  SOCKET_URL: "https://bot.dentaldost.co/operator",
  // INAPP_CHAT_SOCKET_URL: "http://localhost:8000/",
  INAPP_CHAT_SOCKET_URL: "https://chat.dentaldost.co/",
  SECRET_KEY: "$W88S^0MHxjsVT$82Fh8",
  AGENT_ID: "64b52107fba455abccfbd9ad",
  JWT: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFAYi5jb20iLCJpZCI6IjYwNDlmMzQ5MzJlNzI1NWRiYTc4ZWU2MCIsImlhdCI6MTYzMjk5NTc5OX0.31HiHAhWQPzripze-3l9lWkcWU-C4l0qwasvhVH8uIU",
};
export default Constants;
