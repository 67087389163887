// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  addCoinsToMainAccountSuccess,
  addCoinsToMainAccountFailure,
  addCoinsSuccess,
  addCoinsFailure,
  getDentaldostAccountSuccess,
  getDentaldostAccountFailure,
  removeCoinsSuccess,
  removeCoinsFailure,
} from "store/reducers/ddAccountSlice"; // Import your action creator
import ddAccountService from "services/ddAccount"; // Import your service function

function* addCoinsToMainAccount(action) {
  try {
    const response = yield call(ddAccountService.addCoinsToMainAccount, action.payload);
    yield put(addCoinsToMainAccountSuccess(response.data));
  } catch (error) {
    yield put(addCoinsToMainAccountFailure(error));
  }
}
function* addCoins(action) {
  try {
    const response = yield call(ddAccountService.addCoins, action.payload);
    yield put(addCoinsSuccess(response.data));
  } catch (error) {
    yield put(addCoinsFailure(error));
  }
}
function* getDentaldostAccount(action) {
  try {
    const response = yield call(ddAccountService.getDentaldostAccount, action.payload);
    yield put(getDentaldostAccountSuccess(response.data));
  } catch (error) {
    yield put(getDentaldostAccountFailure(error));
  }
}
function* removeCoins(action) {
  try {
    const response = yield call(ddAccountService.removeCoins, action.payload);
    yield put(removeCoinsSuccess(response.data));
  } catch (error) {
    yield put(removeCoinsFailure(error));
  }
}

// Watcher Saga
function* ddAccountSaga() {
  yield takeLatest("ddAccounts/addCoinsToMainAccount", addCoinsToMainAccount);
  yield takeLatest("ddAccounts/addCoins", addCoins);
  yield takeLatest("ddAccounts/getDentaldostAccount", getDentaldostAccount);
  yield takeLatest("ddAccounts/removeCoins", removeCoins);
}

// Export all sagas
export default ddAccountSaga;
