/*eslint-disable */
import React, { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
//import arrayMove from "array-move";
import { arrayMove } from "react-sortable-hoc";

import useStore from "./store";
import { Select, MenuItem, ListSubheader } from "@mui/material";
import "./annotation-styles.css";
import { FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const regionsList = [];

const SortableItem = SortableElement(({ region, sortIndex, onRemove, onAdd }) => {
  const [isAdded, setIsAdded] = useState(false);
  const [dropdownlist] = useState([
    "ABFRACTION",
    "ABRASION",
    "APPLIANCES",
    "ATTRITION",
    "BRACES",
    "CALCULUS",
    "CERAMIC CROWN",
    "COMPOSITE FILLING",
    "DEEP CARIES",
    "DEFECTIVE CAP/ CROWN/ BRIDGE",
    "DENTAL FLUOROSIS",
    "DENTURE",
    "DIASTEMA",
    "DISCOLORED TOOTH",
    "DISLODGED PROSTHESIS / FILLING",
    "EROSION",
    "ERUPTING TEETH",
    "EXTRA TOOTH",
    "FOOD LODGEMENT",
    "FRACTURE",
    "GROSSLY DECAYED",
    "IMPACTED 3RD MOLAR / PERICORONITIS",
    "INCOMPLETE RCT",
    "INFLAMMED / RED GUMS",
    "MALALIGNED",
    "MESIODENS",
    "METAL-CERAMIC",
    "METAL-CROWN",
    "MISSING TOOTH",
    "PEG LATERALS",
    "PIT AND FISSURE CARIES",
    "RECEDED GUMS",
    "ROOT STUMP",
    "SILVER FILLING",
    "STAINS",
    "TOOTH_GAP",
  ]);

  const subCategories = ["EARLY", "INTERMEDIATE", "LATE", "SUPER LATE", "END"];
  const [mainCategory, setMainCategory] = useState("STAINS");
  const [subCategory, setSubCategory] = useState("");

  const mainCategoryItems = dropdownlist.map((category) => (
    <MenuItem key={category} value={category}>
      {category}
    </MenuItem>
  ));

  const subCategoryItems = mainCategory
    ? subCategories.map((subclass) => (
        <MenuItem key={subclass} value={subclass}>
          {subclass}
        </MenuItem>
      ))
    : [];

  return (
    <div
      className="region"
      style={{
        boxShadow: `0 0 5px ${region.color}`,
        border: `1px solid ${region.color}`,
      }}
    >
      {!isAdded ? (
        <>
          <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select-main"
              onChange={(event) => {
                const newMainCategory = event.target.value;
                setMainCategory(newMainCategory);
                // setSubCategory(subCategories[0]);
              }}
            >
              {mainCategoryItems}
            </Select>
          </FormControl>
          {mainCategory && (
            <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-sub"
                onChange={(event) => {
                  setSubCategory(event.target.value);
                }}
              >
                {subCategoryItems}
              </Select>
            </FormControl>
          )}
        </>
      ) : (
        `${mainCategory} - ${subCategory}`
      )}

      {!isAdded ? (
        <button
          className="btn btn-primary"
          onClick={() => {
            regionsList.push({ ...region });
            onAdd(mainCategory, subCategory, sortIndex);
            setIsAdded(true);
          }}
        >
          +
        </button>
      ) : (
        <button
          className="btn btn-primary"
          onClick={() => {
            onRemove(sortIndex);
          }}
        >
          X
        </button>
      )}
    </div>
  );
});

const SortableList = SortableContainer(({ items, onRemove, onAdd }) => {
  return (
    <div className="regions-list" style={{ width: "120px" }}>
      {items.map((region, index) => (
        <SortableItem
          key={`item-${region.id}`}
          index={index}
          region={region}
          onRemove={onRemove}
          onAdd={onAdd}
          sortIndex={index}
        />
      ))}
    </div>
  );
});

export default function RegionsList({ sendRegions }) {
  const regions = useStore((s) => s.regions);
  const setRegions = useStore((s) => s.setRegions);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }

  return (
    <>
      <SortableList
        items={regions}
        onSortEnd={({ oldIndex, newIndex }) => {
          setRegions(arrayMove(regions, oldIndex, newIndex));
        }}
        onRemove={(index) => {
          regions.splice(index, 1);
          setRegions(regions.concat());
        }}
        onAdd={(mainValue, subValue, index) => {
          if (mainValue) {
            regions[index].name = mainValue;
            regions[index].subclass = subValue;
            setRegions([...regions]);
          } else {
            alert("Please select a main category before adding a region");
          }
        }}
      />
      {/* {regions.length > 0 ? ( */}
      {loginData?.roleData?.roleName !== "reviewer" &&
      loginData?.roleData?.roleName !== "partner" ? (
        <div className="updatebuttonClass">
          <button
            className="btn btn-primary"
            onClick={() => {
              sendRegions(regions);
              setRegions([]);
            }}
          >
            Update
          </button>
        </div>
      ) : (
        ""
      )}

      {/* ) : null} */}
    </>
  );
}
function compare(a, b) {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
}
