// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getUsersSuccess,
  getUsersFailure,
  addNewPatientSuccess,
  addNewPatientFailure,
  patientExistError,
  sendNotificationUserSuccess,
  sendNotificationUserFailure,
  sendMessageSuccess,
  sendMessageFailure,
  updatePatientSuccess,
  updatePatientFailure,
  sendPaymentNotificationSuccess,
  sendPaymentNotificationFailure,
  assignDentalCoachSuccess,
  assignDentalCoachFailure,
} from "store/reducers/patientSlice"; // Import your action creator
import patientService from "services/patient"; // Import your service function

function* getUsers(action) {
  try {
    const response = yield call(patientService.getUsers, action.payload);
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}
function* addNewPatient(action) {
  try {
    const response = yield call(patientService.addNewPatient, action.payload);
    yield put(addNewPatientSuccess(response.data));
  } catch (error) {
    if (error?.response.data?.isExisting) {
      yield put(patientExistError("Mobile number already exist"));
    } else yield put(addNewPatientFailure(error));
  }
}
function* sendNotificationUser(action) {
  try {
    const response = yield call(patientService.sendNotificationUser, action.payload);
    yield put(sendNotificationUserSuccess(response.data));
  } catch (error) {
    yield put(sendNotificationUserFailure(error));
  }
}
function* sendMessage(action) {
  try {
    const response = yield call(patientService.sendMessage, action.payload);
    yield put(sendMessageSuccess(response.data));
  } catch (error) {
    yield put(sendMessageFailure(error));
  }
}
function* updatePatient(action) {
  try {
    const response = yield call(patientService.updatePatient, action.payload);
    yield put(updatePatientSuccess(response.data));
  } catch (error) {
    yield put(updatePatientFailure(error));
  }
}
function* sendPaymentNotification(action) {
  try {
    const response = yield call(patientService.sendPaymentNotification, action.payload);
    yield put(sendPaymentNotificationSuccess(response.data));
  } catch (error) {
    yield put(sendPaymentNotificationFailure(error));
  }
}
function* assignDentalCoach(action) {
  try {
    const response = yield call(patientService.assignDentalCoach, action.payload);
    yield put(assignDentalCoachSuccess(response.data));
  } catch (error) {
    yield put(assignDentalCoachFailure(error));
  }
}

function* searchValue(action) {
  try {
    const response = action.payload;
  } catch (error) {
    console.log(error);
  }
}

function* locationValue(action) {
  try {
    const response = action.payload;
  } catch (error) {
    console.log(error);
  }
}

// Watcher Saga
function* patientSaga() {
  yield takeLatest("patients/getUsers", getUsers);
  yield takeLatest("patients/addNewPatient", addNewPatient);
  yield takeLatest("patients/sendNotificationUser", sendNotificationUser);
  yield takeLatest("patients/sendMessage", sendMessage);
  yield takeLatest("patients/updatePatient", updatePatient);
  yield takeLatest("patients/sendPaymentNotification", sendPaymentNotification);
  yield takeLatest("patients/assignDentalCoach", assignDentalCoach);
  yield takeLatest("patients/searchValue", searchValue);
  yield takeLatest("patients/locationValue", locationValue);
}

// Export all sagas
export default patientSaga;
