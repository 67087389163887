import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getReferrals, patientDetailsSelector } from "store/reducers/patientdetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

function Referrals(props) {
  const dispatch = useDispatch();
  const [referrals, setReferrals] = useState([]);
  const referralsData = useSelector((state) => state?.patientDetails?.referralsData);
  const getReferralsSuccess = useSelector(patientDetailsSelector.getReferralsSuccess);
  const getReferralsFailure = useSelector(patientDetailsSelector.getReferralsFailure);

  const columns = [
    {
      field: "index",
      headerName: "Index",
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        return <div>{referrals.indexOf(params.row) + 1}</div>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        return <div>{params?.row?.from?.name}</div>;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              color: params.row.isWithdrawal ? "red" : "green",
            }}
          >
            {params.row.isWithdrawal ? `-${params.row.amount}` : params.row.amount}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchReferrals();
  }, []);

  const fetchReferrals = () => {
    dispatch(getReferrals(props.patientId));
  };

  useEffect(() => {
    if (referralsData?.response) {
      const rows = referralsData?.response;
      setReferrals(rows);
    }
  }, [referralsData]);

  useEffect(() => {
    if (getReferralsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getReferralsFailure]);

  return (
    <div style={{ height: 370, width: "100%" }}>
      {referrals.length === 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <p>No referral records found.</p>
        </div>
      ) : (
        <DataGrid rows={referrals} columns={columns} pageSize={5} getRowId={(row) => row._id} />
      )}
    </div>
  );
}

export default Referrals;
