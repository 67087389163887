// kioskSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const kioskSlice = createSlice({
  name: "kiosks",
  initialState: {
    kiosks: [],
    isloading: false,
    addKioskSuccess: false,
    addKioskFailure: false,
    getKioskListSuccess: false,
    getKioskListFailure: false,
    updateKioskSuccess: false,
    updateKioskFailure: false,
    getKioskUsersSuccess: false,
    getKioskUsersFailure: false,
    // Other initial state properties
  },
  reducers: {
    resetKioskSuccess: (state) => {
      state.addKioskSuccess = false;
      state.updateKioskSuccess = false;
    },
    addKiosk: (state, action) => {
      state.isloading = false;
    },
    addKioskSuccess: (state, action) => {
      state.kiosks = action.payload;
      state.isloading = true;
      state.addKioskSuccess = true;
    },
    addKioskFailure: (state) => {
      state.isloading = false;
      state.addKioskFailure = true;
    },
    getKioskList: (state, action) => {
      state.isloading = false;
    },
    getKioskListSuccess: (state, action) => {
      state.kiosks = action.payload;
      state.isloading = true;
      state.getKioskListSuccess = true;
    },
    getKioskListFailure: (state) => {
      state.isloading = false;
      state.getKioskListFailure = true;
    },
    updateKiosk: (state, action) => {
      state.isloading = false;
    },
    updateKioskSuccess: (state, action) => {
      state.kiosks = action.payload;
      state.isloading = true;
      state.updateKioskSuccess = true;
    },
    updateKioskFailure: (state) => {
      state.isloading = false;
      state.updateKioskFailure = true;
    },
    getKioskUsers: (state, action) => {
      state.isloading = false;
    },
    getKioskUsersSuccess: (state, action) => {
      state.kiosks = action.payload;
      state.isloading = true;
      state.getKioskUsersSuccess = true;
    },
    getKioskUsersFailure: (state) => {
      state.isloading = false;
      state.getKioskUsersFailure = true;
    },
    // Other reducers if needed
  },
});

// addKiosk,
//   getKioskList,
//   updateKiosk,
//   getKioskUsers,

const selectKiosk = (state) => state.kiosk;

export const kioskSelector = {
  getKioskListSuccess: createSelector(selectKiosk, (kioskState) => kioskState.getKioskListSuccess),
  getKioskListFailure: createSelector(selectKiosk, (kioskState) => kioskState.getKioskListFailure),
  addKioskSuccess: createSelector(selectKiosk, (kioskState) => kioskState.addKioskSuccess),
  addKioskFailure: createSelector(selectKiosk, (kioskState) => kioskState.addKioskFailure),
  updateKioskSuccess: createSelector(selectKiosk, (kioskState) => kioskState.updateKioskSuccess),
  updateKioskFailure: createSelector(selectKiosk, (kioskState) => kioskState.updateKioskFailure),
  getKioskUsersSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getKioskUsersSuccess
  ),
  getKioskUsersFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.getKioskUsersFailure
  ),

  // Add other selectors if needed
};

export const {
  addKiosk,
  addKioskSuccess,
  addKioskFailure,
  getKioskList,
  getKioskListSuccess,
  getKioskListFailure,
  updateKiosk,
  updateKioskSuccess,
  updateKioskFailure,
  getKioskUsers,
  getKioskUsersSuccess,
  getKioskUsersFailure,
  resetKioskSuccess,
} = kioskSlice.actions;
export default kioskSlice.reducer;
