/* eslint-disable */
import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

function UpcomingAppointment(analysisData) {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(null);
  const { t } = useTranslation();
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  const appointmentColumns = [
    {
      field: "id",
      headerName: t("Patient No"),
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "date",
      headerName: t("Date"),
      headerAlign: "center",
      align: "center",
      width: 130,
      // renderCell: (data) => {
      //   return (
      //     <div onClick={() => navigate("/patientdetails/" + data.row.patientId)}>
      //       {data.row.date !== "NA"
      //         ? moment(data.row.date).format("DD/MM/YYYY hh:mm A")
      //         : data.row.date}
      //     </div>
      //   );
      // },
    },
    {
      field: "patient",
      headerName: t("Patient"),
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return (
          <div onClick={() => navigate("/patientdetails/" + data.row.patientId)}>
            {toTitleCase(data.row.patient)}
          </div>
        );
      },
    },
    {
      field: "notes",
      headerName: t("Notes"),
      headerAlign: "center",
      align: "center",
      width: 250,
    },
    {
      field: "followUpDate",
      headerName: t("FollowUp Date"),
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (data) => {
        return (
          <div>
            {data.row.followUpDate !== "NA"
              ? moment(data.row.followUpDate).format("DD/MM/YYYY hh:mm A")
              : data.row.followUpDate}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: t("Action"),
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => {
        const handleClickPastAppointment = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleClickView(thisRow);
        };
        return (
          <div>
            <MDButton variant="gradient" onClick={handleClickPastAppointment} color="info">
              View
            </MDButton>
          </div>
        );
      },
    },
  ];

  const handleClickView = (datavalue) => {
    const result = analysisData.data.filter((patientList) => patientList.id === datavalue.id);
    navigate("/patientdetails/" + result[0].patientId);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            {t("Upcoming Appointments")}
          </MDTypography>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={analysisData.data}
            columns={appointmentColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
            getRowWidth={() => "auto"}
          />
        </div>
      </MDBox>
    </Card>
  );
}

export default UpcomingAppointment;
