/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PatientTable from "./components/patientTable";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { getAgents } from "store/reducers/agentSlice";
import { useDispatch } from "react-redux";
import routes from "routes";

function Patient() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [agentData, setAgentData] = useState();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const getRoleData =
    loginData &&
    loginData.roleData &&
    loginData.roleData.permissions.find((data) => data.name.toLowerCase() === "patient");

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const handleAddPatient = () => {
    navigate("/add-edit-patient");
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />

        {(getRoleData && getRoleData.operations && getRoleData.operations.includes("add")) ||
        (getRoleData && getRoleData.operations && getRoleData.operations.includes("Add")) ? (
          <MDButton variant="gradient" color="info" onClick={handleAddPatient}>
            + Add New Patient
          </MDButton>
        ) : (
          ""
        )}
        <br />
        <PatientTable />
      </DashboardLayout>
    </>
  );
}
export default Patient;
