/* eslint-disable */

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaterialUIController } from "context";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Annotation from "./annotation";
import { useState, useEffect } from "react";
import Constants from "utils/constants";
import useStore from "./store";
const { v4 } = require("uuid");
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import { treatments } from "../utilities/treatmentList";
import { oralCareTypes } from "../utilities/oralCareTypes";
import { Multiselect } from "multiselect-react-dropdown";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import Swal from "sweetalert2";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import moment from "moment";
import isEmpty from "layouts/IsEmpty";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiseaseData,
  getBasePathArray,
  getReportHistory,
  getPendingAnnotation,
  getBasePathVideo,
  downloadReport,
  sendReport,
  testDetailsSelector,
  resetDownloadReportSuccess,
} from "store/reducers/testDetailsSlice";
import { getPatientDetails, patientDetailsSelector } from "store/reducers/patientdetailsSlice";
import { getAgents, agentSelector } from "store/reducers/agentSlice";

function TestDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [showOriginalImages, setShowOriginalImages] = useState(false);
  const [imageCount, setImageCount] = useState();
  const testId =
    location.state.testDetails.length && location.state.testDetails[0].tid
      ? location.state.testDetails[0].tid
      : location.state.testDetails.testsData._id;
  const patientDetail = location.state.patientDetails;
  const patientId = location.state.patientId;
  const [originalImagesMap, setOriginalImagesMap] = useState([]);
  const IMAGE_URL = Constants.IMAGE_URL;
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const jwt = JSON.parse(localStorage.getItem("loginData")).token;
  const [originalLoaded, setOriginalLoaded] = useState(false);
  const [testDetailsKey, setTestDetailsKey] = useState("upper");
  const regions = useStore((state) => state.regions);
  const [selectedTreatment, setSelectedTreatment] = useState([]);
  const [isTreatmentSelected, setIsTreatmentSelected] = useState(true);
  const [isTreatmentValid, setIsTreatmentValid] = useState(true); // State to track treatment validity
  const [isPrimaryFocusValid, setIsPrimaryFocusValid] = useState(true);
  const [treatmentList] = useState(treatments);
  const [newTreatment, setNewTreatment] = useState("");
  const [primaryFocus, setPrimaryFocus] = useState("");
  const [oralCareList] = useState(oralCareTypes);
  const [selectedOralCareType, setSelectedOralCareType] = useState("");
  const [isSelectedOralCareType, setIsSelectedOralCareType] = useState(true);
  const [statusList] = useState(["pending", "healthy", "unhealthy", "urgent"]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusError, setSelectedStatusError] = useState(true);
  const [selectedTestIndex, setSelectedTestIndex] = useState(-1);
  const [Range, setRange] = useState(0);
  const [agentDetails, setAgentDetails] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [agentIdError, setAgentIdError] = useState(true);
  const updateTest = "/agent/patient/updateTestResult";
  const [reportHistoryData, setReportHistoryData] = useState();
  const [PatientID] = useState(JSON.parse(localStorage.getItem("PatientID")));
  const [patientDetailTests, setPatientDetailTests] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [quizScore, setQuizScore] = useState();
  const [treatmentData, setTreatmentData] = useState();
  let [newDiseaseList, setNewDiseaseList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const isAnnotation = location?.state?.isAnnotation;
  const [diseaseData, setDiseaseData] = useState([]);
  const [diseasesList, setDiseasesList] = useState([]);
  const [selectedDiseaseTypes, setSelectedDiseaseTypes] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const testDiseaseData = useSelector((state) => state?.testDetails?.diseaseData);
  const getDiseaseDataSuccess = useSelector(testDetailsSelector.getDiseaseDataSuccess);
  const getDiseaseDataFailure = useSelector(testDetailsSelector.getDiseaseDataFailure);
  const basePathArrayData = useSelector((state) => state?.testDetails?.basePathArray);
  const getBasePathArraySuccess = useSelector(testDetailsSelector.getBasePathArraySuccess);
  const getBasePathArrayFailure = useSelector(testDetailsSelector.getBasePathArrayFailure);
  const reportHistory = useSelector((state) => state?.testDetails?.reportHistory);
  const getReportHistorySuccess = useSelector(testDetailsSelector.getReportHistorySuccess);
  const getReportHistoryFailure = useSelector(testDetailsSelector.getReportHistoryFailure);
  const getPendingAnnotationSuccess = useSelector(testDetailsSelector.getPendingAnnotationSuccess);
  const getPendingAnnotationFailure = useSelector(testDetailsSelector.getPendingAnnotationFailure);
  const basePathVideoData = useSelector((state) => state?.testDetails?.basePathVideo);
  const getBasePathVideoSuccess = useSelector(testDetailsSelector.getBasePathVideoSuccess);
  const getBasePathVideoFailure = useSelector(testDetailsSelector.getBasePathVideoFailure);
  const downloadReportData = useSelector((state) => state?.testDetails?.downloadReport);
  const downloadReportSuccess = useSelector(testDetailsSelector.downloadReportSuccess);
  const downloadReportFailure = useSelector(testDetailsSelector.downloadReportFailure);
  const sendReportSuccess = useSelector(testDetailsSelector.sendReportSuccess);
  const sendReportFailure = useSelector(testDetailsSelector.sendReportFailure);
  const patientDetailsData = useSelector((state) => state?.patientDetails?.patientDetails);
  const getPatientDetailsSuccess = useSelector(patientDetailsSelector.getPatientDetailsSuccess);
  const getPatientDetailsFailure = useSelector(patientDetailsSelector.getPatientDetailsFailure);
  const agentsData = useSelector((state) => state?.agent?.agents);
  const getAgentsSuccess = useSelector(agentSelector.getAgentsSuccess);
  const getAgentsFailure = useSelector(agentSelector.getAgentsFailure);

  useEffect(() => {
    getAgentsList();
    loadOriginalImages();
    fetchReportHistory(patientId, testId);
    getOralTypeQuestions();
    UserDetails();
    fetchDiseaseData();
  }, []);

  const handleRange = (event) => {
    setRange(event.target.value);
  };

  const fetchDiseaseData = () => {
    const formData = {
      patientId,
      testId,
    };
    dispatch(getDiseaseData(formData));
  };

  useEffect(() => {
    if (testDiseaseData) {
      setDiseaseData(testDiseaseData);
    }
  }, [testDiseaseData]);

  useEffect(() => {
    if (getDiseaseDataFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDiseaseDataFailure]);

  useEffect(() => {
    if (!diseaseData) {
      return;
    }
    let angle;
    switch (testDetailsKey) {
      case 0: // Upper
        angle = "front";
        break;
      case 1: // Lower
        angle = "upper";
        break;
      case 2: // Front
        angle = "lower";
        break;
      default:
        angle = "front";
        setDiseasesList(diseaseData.disease);
    }
    let detection = diseaseData?.test?.find(
      (d) => d.angle.toLowerCase() === testDetailsKey.toLowerCase()
    );
    if (detection) {
      const uniqueDiseaseTypes = [
        ...new Set(detection.diseases.map((disease) => disease.name.toLowerCase())),
      ];
      setSelectedDiseaseTypes(uniqueDiseaseTypes);
      setDiseasesList(detection.diseases);
      const anglesList = ["front", "upper", "lower"];
      const path = diseaseData.test.find(
        (data) => data.angle.toLowerCase() === testDetailsKey.toLowerCase()
      );
      if (path && path.base_file_url) {
        setImageUrl(IMAGE_URL + path.base_file_url);
      }
      // setSelectedDiseaseTypes([]);
    } else {
      console.error(`Detection with angle "${angle}" not found`);
    }
  }, [diseaseData, testDetailsKey]);

  const updateSelectedDiseases = (updatedDiseases) => {
    setSelectedDiseaseTypes(updatedDiseases);
  };

  const getOralTypeQuestions = () => {
    const data = patientDetail.tests
      ? patientDetail.tests.find((test) => test.tid === testId)
      : patientDetail.testsData;
    if (data && data.oralTypeQuestion) {
      return data.oralTypeQuestion;
    }
  };

  const getSortedAgents = (agentData) => {
    if (agentData) {
      const sortedAgents = [...agentData].sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return sortedAgents;
    }
  };

  const getAgentsList = () => {
    dispatch(getAgents());
  };

  useEffect(() => {
    if (agentsData?.agents) {
      setAgentDetails(agentsData?.agents);
    }
  }, [agentsData]);

  useEffect(() => {
    if (getAgentsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAgentsFailure]);

  const handleUpdate = () => {
    if (validateUpdate() === true) {
      setIsTreatmentSelected(true);
      const config = {
        headers: { Authorization: `Bearer ${jwt}` },
        "Content-Type": "application/json",
      };
      const data = patientDetailTests.find((test) => test.tid === testId);
      const body = {
        dentistNote: newTreatment,
        status: selectedStatus,
        patientId: patientId,
        id: testId,
        numDiseased: parseInt(Range),
        annotationStatus: "done",
        agentId: agentId,
        treatments: selectedTreatment,
        oralType: selectedOralCareType,
        primaryFocus,
        reportNo: `${PatientID}${data.id}`,
      };
      axios
        .post(Constants.API_URL + updateTest, body, config)
        .then((response) => {
          if (response.status === 200) {
            // navigate("/patientdetails/" + patientId);
            Swal.fire({
              icon: "success",
              text: "Update successful",
              showConfirmButton: false,
              timer: 2000,
            });
            // setSelectedTreatment([]);
            // setNewTreatment("");
            // setSelectedOralCareType("");
            // setAgentId("");
            // setSelectedStatus("");
            // setRange(0);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: "Error occurred",
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(error);
        });
    } else {
      console.log("Update not validated");
    }
  };

  const fetchReportHistory = () => {
    const formData = {
      patientId,
      testId,
      token: loginData.token,
    };
    dispatch(getReportHistory(formData));
  };

  useEffect(() => {
    if (reportHistory?.history) {
      setReportHistoryData([...reportHistory?.history].reverse());
    }
  }, [reportHistory]);

  useEffect(() => {
    if (getReportHistoryFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getReportHistoryFailure]);

  const loadOriginalImages = () => {
    const data =
      patientDetail && patientDetail.tests
        ? patientDetail.tests.find((test) => test.tid === testId)
        : patientDetail.testsData;
    setImageCount(data.detections.length);
    // to clear region onclick of details button
    let formData = [];
    let testDataObjectVersion = 1;
    if (data && data.detections && data.detections.length) {
      formData = data.detections.map((detection) => {
        let path = detection.diseaseFileURL;
        if (path.includes("media")) {
          path = path.replace("/media/", "");
        } else {
          testDataObjectVersion = 2;
          path = path
            .replace("ml-test-data/disease/", "/get_image_from_bucket/")
            .replace(".png", "");
        }
        return {
          angle: detection.angle?.toLowerCase(),
          path,
        };
      });
    }
    if (formData.length && formData[0].path.includes("from_video")) {
      const bodyData = {
        formData,
        jwt,
        testId,
      };
      dispatch(getBasePathVideo(bodyData));
    } else {
      const bodyData = {
        formData,
        jwt,
        testId,
      };
      if (testDataObjectVersion === 2) {
        setOriginalImagesMap([
          {
            testId: testId,
            paths: formData,
          },
        ]);
        setOriginalLoaded(true);
      } else {
        dispatch(getBasePathArray(bodyData));
      }
    }
  };

  useEffect(() => {
    if (basePathVideoData?.paths) {
      setOriginalImagesMap([
        ...basePathVideoData?.paths,
        {
          testId: testId,
          paths: basePathVideoData?.paths,
        },
      ]);
      setOriginalLoaded(true);
    }
  }, [basePathVideoData]);

  useEffect(() => {
    if (getBasePathVideoFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getBasePathVideoFailure]);

  useEffect(() => {
    if (basePathArrayData?.paths) {
      setOriginalImagesMap([
        ...basePathArrayData?.paths,
        {
          testId: testId,
          paths: basePathArrayData?.paths,
        },
      ]);
      setOriginalLoaded(true);
    }
  }, [basePathArrayData]);

  useEffect(() => {
    if (getBasePathArrayFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getBasePathArrayFailure]);

  const OriginalImageURL = (angle) => {
    if (angle === "Result") {
      return "";
    }
    // if (selectedTestIndex > -1) {
    if (testId && angle && originalImagesMap) {
      const test = originalImagesMap.find((mapObject) => mapObject.testId === testId);
      if (test && test.paths) {
        const path = test.paths.find((path) => path.angle.toLowerCase() === angle.toLowerCase());
        if (path && path.path) {
          return IMAGE_URL + path.path;
        }
      }
      return "";
    }
  };

  const getTestDetailURL = (angle) => {
    if (angle === "Result") {
      return "";
    }
    if (testId) {
      if (testId && angle) {
        const currentTest = patientDetail.tests
          ? patientDetail.tests.find((test) => test.tid === testId)
          : patientDetail.testsData;
        if (currentTest && currentTest.detections && currentTest.annotationStatus == "inReview") {
          const detection = currentTest.detections.find(
            (detection) => detection.angle.toLowerCase() === angle.toLowerCase()
          );
          if (detection && detection.reviewImageURL) {
            return IMAGE_URL + detection.reviewImageURL;
          }
        } else if (currentTest && currentTest.detections) {
          const detection = currentTest.detections.find(
            (detection) => detection.angle.toLowerCase() === angle.toLowerCase()
          );
          if (detection && detection.diseaseFileURL) {
            return IMAGE_URL + detection.diseaseFileURL;
          }
        }
        return "";
      }
    } else {
      return "";
    }
  };

  const validateUpdate = () => {
    if (selectedTreatment.length === 0) {
      setIsTreatmentSelected(false);
      Swal.fire({
        icon: "error",
        text: "Treatment list cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (primaryFocus.trim() === "") {
      setIsPrimaryFocusValid(false);
      return;
    }
    if (selectedOralCareType == "") {
      setIsSelectedOralCareType(false);
      Swal.fire({
        icon: "error",
        text: "Oral care type cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (agentId == "") {
      setAgentIdError(false);
      Swal.fire({
        icon: "error",
        text: "Agent cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (selectedStatus == "") {
      setSelectedStatusError(false);
      Swal.fire({
        icon: "error",
        text: "Status cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (Range == 0) {
      Swal.fire({
        icon: "error",
        text: "Disease teeth count cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };

  const handleSendPdf = (isReportOnChat) => {
    if (patientDetailTests.length) {
      const data = patientDetailTests.find((test) => test.tid === testId);
      const formData = {
        patientId: patientId,
        tests: allTests,
        diseaseList: newDiseaseList,
        reportNo: `${PatientID}${data.id}`,
        testId: testId,
        quizScore: quizScore,
        treatmentData,
        isInAppChat: isReportOnChat ? isReportOnChat : false,
      };
      dispatch(sendReport(formData));
    }
  };

  useEffect(() => {
    if (sendReportSuccess) {
      Swal.fire({
        icon: "success",
        text: "PDF Send successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendReportSuccess]);

  useEffect(() => {
    if (sendReportFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendReportFailure]);

  const handleViewPdf = (reportType) => {
    if (patientDetailTests.length) {
      const data = patientDetailTests.find((test) => test.tid === testId);
      const formData = {
        patientId: patientId,
        tests: allTests,
        diseaseList: newDiseaseList,
        reportNo: `${PatientID}${data.id}`,
        testId: testId,
        quizScore: quizScore,
        treatmentData,
        reportType,
      };
      dispatch(downloadReport(formData));
    }
  };

  useEffect(() => {
    if (downloadReportSuccess) {
      dispatch(resetDownloadReportSuccess());
      window.open(downloadReportData?.url, "_blank").focus();
    }
  }, [downloadReportSuccess]);

  useEffect(() => {
    if (downloadReportFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [downloadReportFailure]);

  const UserDetails = () => {
    const formData = {
      patientId: patientId,
      token: jwt ? jwt : "",
    };
    dispatch(getPatientDetails(formData));
  };

  useEffect(() => {
    if (patientDetailsData?.patient) {
      filterTestData(patientDetailsData?.patient);
      setPatientDetailTests([...patientDetailsData?.patient?.tests].reverse());

      if (patientDetailsData?.patient?.dob) {
        if (patientDetailsData?.patient?.dob === "Invalid date") {
          setStartDate(new Date());
        } else {
          const dateconvert = moment(patientDetailsData?.patient?.dob, "DD/MM/YYYY");
          const dateObject = dateconvert.toDate();
          setStartDate(dateObject);
        }
      }
    }
  }, [patientDetailsData]);

  useEffect(() => {
    if (getPatientDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientDetailsFailure]);

  const filterTestData = (updatePatientDetails) => {
    const data = updatePatientDetails.tests.find((test) => test.tid === testId);
    setAllTests(data.detections);
    // setTestRowId(data.id);
    setQuizScore(data.score);
    setTreatmentData(data && data.treatments ? data.treatments : []);
    const newDiseaseList = [];
    if (!isEmpty(data.detections)) {
      data.detections.forEach((detection) => {
        if (!isEmpty(detection.diseaseList)) {
          detection.diseaseList.forEach((disease) => {
            newDiseaseList.push(disease);
          });
        }
      });
    }
    const occurrences = newDiseaseList.reduce(function (acc, curr) {
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});
    let finalArray = [];
    for (const [key, value] of Object.entries(occurrences)) {
      finalArray.push({ name: key, count: value });
    }
    setNewDiseaseList(finalArray);
  };

  const bullet = "\u2022";
  const bulletWithSpace = `${bullet} `;
  const enter = 13;

  const handleInput = (event) => {
    const { keyCode, target } = event;
    const { selectionStart, value } = target;
    if (keyCode === enter) {
      target.value = [...value]
        .map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace} ` : c))
        .join("");
      target.selectionStart = selectionStart + bulletWithSpace.length;
      target.selectionEnd = selectionStart + bulletWithSpace.length;
    }
    if (value[0] !== bullet) {
      target.value = `${bulletWithSpace}${value} `;
    }
  };
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <Card id="delete-account">
          <MDBox pt={2} px={2}>
            <MDTypography variant="h6" fontWeight="medium">
              Test Details
            </MDTypography>
          </MDBox>
          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? "transparent" : "grey-100"}
                borderRadius="lg"
                p={3}
                // mb={noGutter ? 0 : 1}
                mt={2}
              >
                <MDBox width="100%" display="flex" flexDirection="column">
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    mb={2}
                  ></MDBox>
                  <MDBox mb={1} lineHeight={1}>
                    <div style={{ display: "block", width: "100%", padding: 30 }}>
                      {originalLoaded ? (
                        <Tabs
                          activeKey={testDetailsKey}
                          onSelect={(k) => {
                            regions.splice(regions.length - 1, 1);
                            setTestDetailsKey(k);
                          }}
                        >
                          <Tab eventKey="upper" title="Upper">
                            <br />
                            <Annotation
                              imgAngle={"upper"}
                              url={
                                showOriginalImages &&
                                OriginalImageURL("upper") &&
                                OriginalImageURL("upper").length
                                  ? OriginalImageURL("upper")
                                  : getTestDetailURL("upper")
                              }
                              ImgURL={OriginalImageURL("upper")}
                              index={v4()}
                              patientId={patientId}
                              testId={testId}
                              selectedDiseaseTypes={selectedDiseaseTypes}
                              diseasesList={diseasesList}
                              updateSelectedDiseases={updateSelectedDiseases}
                            />
                          </Tab>
                          <Tab eventKey="lower" title="Lower">
                            <br />
                            <Annotation
                              imgAngle={"lower"}
                              url={
                                showOriginalImages &&
                                OriginalImageURL("lower") &&
                                OriginalImageURL("lower").length
                                  ? OriginalImageURL("lower")
                                  : getTestDetailURL("lower")
                              }
                              ImgURL={OriginalImageURL("lower")}
                              index={v4()}
                              patientId={patientId}
                              testId={testId}
                              selectedDiseaseTypes={selectedDiseaseTypes}
                              diseasesList={diseasesList}
                              updateSelectedDiseases={updateSelectedDiseases}
                            />
                          </Tab>
                          <Tab eventKey="front" title="Front">
                            <br />
                            <Annotation
                              imgAngle={"front"}
                              url={
                                showOriginalImages &&
                                OriginalImageURL("front") &&
                                OriginalImageURL("front").length
                                  ? OriginalImageURL("front")
                                  : getTestDetailURL("front")
                              }
                              ImgURL={OriginalImageURL("front")}
                              index={v4()}
                              patientId={patientId}
                              testId={testId}
                              selectedDiseaseTypes={selectedDiseaseTypes}
                              diseasesList={diseasesList}
                              updateSelectedDiseases={updateSelectedDiseases}
                            />
                          </Tab>
                          {loginData?.roleData?.roleName !== "reviewer" &&
                          loginData?.roleData?.roleName !== "partner" ? (
                            <Tab eventKey="result" title="Doctor's Note">
                              <br />
                              <MDBox mt={6} mb={2}>
                                <Grid container spacing={2} justifyContent="center">
                                  <Grid item xs={12} lg={9}>
                                    <Card>
                                      <MDBox pt={4} pb={3} px={3}>
                                        <MDBox component="form" role="form">
                                          <MDBox mb={4}>
                                            <label htmlFor="treatment">
                                              Select Treatment:{" "}
                                              <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <Multiselect
                                              showArrow
                                              options={treatmentList}
                                              onSelect={(e) => setSelectedTreatment(e)}
                                              displayValue="name"
                                              onRemove={(e) => setSelectedTreatment(e)}
                                            />
                                            <br /> <br />
                                            <TextField
                                              fullWidth
                                              label="Add Treatment"
                                              value={newTreatment}
                                              onChange={(e) => {
                                                setNewTreatment(e.target.value);
                                              }}
                                              multiline
                                              onKeyUp={(e) => handleInput(e)}
                                            />
                                            <br /> <br /> <br />
                                            <TextField
                                              fullWidth
                                              label="Primary focus*"
                                              value={primaryFocus}
                                              onChange={(e) => {
                                                setPrimaryFocus(e.target.value);
                                                setIsPrimaryFocusValid(true);
                                              }}
                                            />
                                            {!isPrimaryFocusValid && (
                                              <p style={{ color: "red" }}>
                                                Please enter the primary focus.
                                              </p>
                                            )}
                                            <br /> <br /> <br />
                                            <FormControl
                                              variant="standard"
                                              fullWidth
                                              sx={{ mb: 2 }}
                                            >
                                              <InputLabel>
                                                Oral Care Type{" "}
                                                <span style={{ color: "red" }}>*</span>
                                              </InputLabel>
                                              <Select
                                                onChange={(e) => {
                                                  setSelectedOralCareType(e.target.value);
                                                  setIsSelectedOralCareType(true);
                                                }}
                                              >
                                                {oralCareList
                                                  .sort((a, b) => a.title.localeCompare(b.title))
                                                  .map((data) => {
                                                    return (
                                                      <MenuItem value={data.data} key={data.data}>
                                                        {data.title}
                                                      </MenuItem>
                                                    );
                                                  })}
                                              </Select>
                                              {!isSelectedOralCareType && ( // Check if selectedOralCareType is falsy
                                                <p style={{ color: "red" }}>
                                                  Please select an Oral Care Type.
                                                </p>
                                              )}
                                            </FormControl>
                                            <br /> <br />
                                            <FormControl
                                              variant="standard"
                                              fullWidth
                                              sx={{ mb: 2 }}
                                            >
                                              <InputLabel>
                                                Agent <span style={{ color: "red" }}>*</span>
                                              </InputLabel>
                                              <Select
                                                onChange={(e) => {
                                                  setAgentId(e.target.value);
                                                  setAgentIdError(true);
                                                }}
                                              >
                                                {getSortedAgents(agentDetails).map((time) => {
                                                  return (
                                                    <MenuItem value={time.aid} key={time.aid}>
                                                      {time.name}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                            {!agentIdError && (
                                              <p style={{ color: "red" }}>
                                                Please select an agent.
                                              </p>
                                            )}
                                            <br /> <br />
                                            <FormControl
                                              variant="standard"
                                              fullWidth
                                              sx={{ mb: 2 }}
                                            >
                                              <InputLabel>
                                                Status <span style={{ color: "red" }}>*</span>
                                              </InputLabel>
                                              <Select
                                                onChange={(value) => {
                                                  setSelectedStatus(value.target.value);
                                                  setSelectedStatusError(true);
                                                }}
                                                value={selectedStatus}
                                                defaultValue={
                                                  selectedTestIndex === -1
                                                    ? null
                                                    : patientDetail.tests[selectedTestIndex]
                                                        .agentStatus
                                                }
                                              >
                                                {statusList.map((time) => {
                                                  return (
                                                    <MenuItem value={time} key={time}>
                                                      {time}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                            {!selectedStatusError && (
                                              <p style={{ color: "red" }}>
                                                Please select a status.
                                              </p>
                                            )}
                                            <br /> <br />
                                            <br />
                                            <TextField
                                              fullWidth
                                              label="Disease Teeth Count*"
                                              type="number"
                                              value={Range}
                                              min="0"
                                              max="32"
                                              onChange={handleRange}
                                            />
                                          </MDBox>

                                          <br />
                                          <MDBox
                                            mt={4}
                                            mb={1}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                          >
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              //fullWidth
                                              onClick={handleUpdate}
                                            >
                                              Update
                                            </MDButton>
                                            &nbsp;&nbsp;
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              onClick={handleSendPdf}
                                            >
                                              Send PDF
                                            </MDButton>
                                            &nbsp;&nbsp;
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              onClick={() => handleSendPdf(true)}
                                            >
                                              Share PDF <br /> On Chat
                                            </MDButton>
                                            &nbsp;&nbsp;
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              onClick={() => handleViewPdf("corrected")}
                                            >
                                              View PDF
                                            </MDButton>
                                            &nbsp;&nbsp;
                                            <MDButton
                                              variant="gradient"
                                              color="info"
                                              onClick={() => handleViewPdf("detected")}
                                            >
                                              View ML PDF
                                            </MDButton>
                                          </MDBox>
                                        </MDBox>
                                      </MDBox>
                                    </Card>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </Tab>
                          ) : (
                            ""
                          )}
                          {loginData?.roleData?.roleName !== "reviewer" &&
                          loginData?.roleData?.roleName !== "partner" ? (
                            <Tab eventKey="history" title="History">
                              <br />
                              <TableContainer component={Paper}>
                                <Table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Agent Name</th>
                                      <th scope="col">Annotation Status</th>
                                      <th scope="col">Date Time</th>
                                      <th scope="col">Oral Type</th>
                                      <th scope="col">Treatments</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {reportHistoryData &&
                                      reportHistoryData.map((historyData) => {
                                        return (
                                          <tr>
                                            <td> {historyData.agentName}</td>
                                            <td> {historyData.annotationStatus}</td>
                                            <td>
                                              {moment(historyData.annotationDate).format(
                                                "DD/MM/YYYY hh:mm A"
                                              )}
                                            </td>
                                            <td>
                                              <li>{historyData.oralType.title} </li>
                                            </td>
                                            <td>
                                              <ul>
                                                {historyData &&
                                                  historyData.treatments.map((data) => {
                                                    return <li>{data.name}</li>;
                                                  })}
                                              </ul>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                              </TableContainer>
                            </Tab>
                          ) : (
                            ""
                          )}
                          {loginData?.roleData?.roleName !== "reviewer" &&
                          loginData?.roleData?.roleName !== "partner" ? (
                            <Tab eventKey="questions" title="Questions">
                              <br />
                              <TableContainer component={Paper}>
                                <Table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Questions</th>
                                      <th scope="col">Answers</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {getOralTypeQuestions() &&
                                      getOralTypeQuestions().map((data, i) => {
                                        return (
                                          <tr>
                                            <td>
                                              <ol>
                                                <li value={i + 1}>{data.question} </li>
                                              </ol>
                                            </td>
                                            <td>{data.answer} </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </Table>
                              </TableContainer>
                            </Tab>
                          ) : (
                            ""
                          )}
                        </Tabs>
                      ) : (
                        ""
                      )}
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
  );
}
export default TestDetails;
