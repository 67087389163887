/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Appointments from "./appointment";
import RateCard from "./rateCard";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorsDetails,
  doctorSelector,
  assignKiosk,
  resetAssignKioskSuccess,
  removeKiosk,
  resetRemoveKioskSuccess,
} from "store/reducers/doctorSlice";
import Modal from "@mui/material/Modal";
import { getKioskList, kioskSelector } from "store/reducers/kioskSlice";
import { Select, MenuItem, FormControl, InputLabel, TextField, Grid, Input } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@material-ui/core/Chip";

function DoctorDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const now = moment().format();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { doctorId } = useParams(now);
  const [isLoading, setIsLoading] = useState(true);
  const [doctorDetails, setDoctorDetails] = useState({});
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [doctorAppointments, setDoctorAppointments] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const doctors = useSelector((state) => state?.doctor?.doctors);
  const getDoctorsDetailsSuccess = useSelector(doctorSelector.getDoctorsDetailsSuccess);
  const getDoctorsDetailsFailure = useSelector(doctorSelector.getDoctorsDetailsFailure);
  const [openAssignKioskModal, setOpenAssignKioskModal] = useState(false);
  const handleCloseAssignKioskModal = () => setOpenAssignKioskModal(false);
  const kiosks = useSelector((state) => state?.kiosk?.kiosks);
  const getKioskListSuccess = useSelector(kioskSelector.getKioskListSuccess);
  const getKioskListFailure = useSelector(kioskSelector.getKioskListFailure);
  const [kioskList, setKioskList] = useState([]);
  const [kioskId, setKioskId] = useState("");
  const [clinicData, setClinicData] = useState();
  const assignKioskSuccess = useSelector(doctorSelector.assignKioskSuccess);
  const assignKioskFailure = useSelector(doctorSelector.assignKioskFailure);
  const removeKioskSuccess = useSelector(doctorSelector.removeKioskSuccess);
  const removeKioskFailure = useSelector(doctorSelector.removeKioskFailure);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [kioskData, setKioskData] = useState({
    name: "",
    location: "",
    code: "",
    autoHeightAdjust: true,
    bypassOtp: true,
    bypasslogin: true,
    languages: selectedLanguages,
  });

  const onchangeHandler = (e) => {
    setKioskData({ ...kioskData, [e.target.name]: e.target.value });
  };

  const languageArray = [
    {
      title: "தமிழ்",
      value: "tamil",
    },
    {
      title: "Bengali",
      value: "bengali",
    },
    {
      title: "తెలుగు",
      value: "telugu",
    },
  ];

  useEffect(() => {
    dispatch(getKioskList(loginData?.token));
  }, []);

  useEffect(() => {
    if (kiosks) {
      setKioskList(kiosks);
    }
  }, [kiosks]);

  useEffect(() => {
    if (getKioskListFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getKioskListFailure]);

  const handleAssignKiosk = () => {
    const formData = {
      kioskData: kioskData,
      clinicId: clinicData?._id,
    };
    dispatch(assignKiosk(formData, loginData.token));
  };

  useEffect(() => {
    if (assignKioskSuccess) {
      handleCloseAssignKioskModal();
      Swal.fire({
        icon: "success",
        text: "Kiosk assign successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetAssignKioskSuccess());
      dispatch(getDoctorsDetails(doctorId, loginData.token));
    }
  }, [assignKioskSuccess]);

  useEffect(() => {
    if (assignKioskFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [assignKioskFailure]);

  const handleRemoveKiosk = (kioskId, clinicId) => {
    const formData = {
      kioskId,
      clinicId,
    };
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(removeKiosk(formData, loginData.token));
      }
    });
  };

  useEffect(() => {
    if (removeKioskSuccess) {
      Swal.fire({
        icon: "success",
        text: "Kiosk removed successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetRemoveKioskSuccess());
      dispatch(getDoctorsDetails(doctorId, loginData.token));
    }
  }, [removeKioskSuccess]);

  useEffect(() => {
    if (removeKioskFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeKioskFailure]);

  useEffect(() => {
    doctorDetailsList();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const doctorDetailsList = () => {
    dispatch(getDoctorsDetails(doctorId, loginData.token));
  };

  useEffect(() => {
    if (doctors?.doctor) {
      setIsLoading(false);
      setDoctorDetails(doctors?.doctor);
    }
  }, [doctors]);

  useEffect(() => {
    if (getDoctorsDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDoctorsDetailsFailure]);

  const handleEditPatient = (doctorsData) => {
    navigate("/add-edit-doctor", { state: { data: doctorsData, doctorId: doctorId } });
  };

  const handleClickAddSlots = (clinicDetails, doctorsData) => {
    navigate("/clinic-slots", {
      state: {
        data: doctorsData,
        doctorId: doctorId,
        clinicDetails: clinicDetails,
        isEditMode: "false",
      },
    });
    setIsEditMode(false);
  };

  const handleClickUpdateSlots = (clinicDetails, clinicId) => {
    clinicId = clinicDetails._id;
    navigate("/clinic-slots", {
      state: {
        clinicDetails: clinicDetails,
        doctorId: doctorId,
        clinicId: clinicDetails._id,
        isEditMode: "true",
      },
    });
    setIsEditMode(true);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <br />
        {doctorDetails ? (
          <Card id="delete-account">
            <MDBox pt={3} px={2}>
              <MDTypography variant="h5" fontWeight="medium">
                Doctor's Details
              </MDTypography>
            </MDBox>
            <List
              sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton onClick={handleClick}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Doctor Name :&nbsp;Dr. {doctorDetails.name}
                </MDTypography>
                <ListItemText />
                {open ? (
                  <>
                    <small> less details</small> <ExpandLess></ExpandLess>
                  </>
                ) : (
                  <>
                    <small> more details</small>
                    <ExpandMore></ExpandMore>
                  </>
                )}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <MDBox mr={1} style={{ float: "right" }}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => handleEditPatient(doctorDetails)}
                  >
                    <Icon>edit</Icon>&nbsp;Edit Doctor
                  </MDButton>
                </MDBox>
                <br />
                <List component="div" disablePadding>
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          {isLoading ? (
                            <>
                              <MDBox style={{ display: "flex", justifyContent: "center" }}>
                                <CircularProgress color="inherit" />
                              </MDBox>
                            </>
                          ) : (
                            <>
                              <MDBox mb={1}>
                                <Table responsive>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Mobile Number : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.mobile}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Date Of Birth : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.dob}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Gender : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.gender}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </tr>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          City : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.city}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Notes : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.notes}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Pincode : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.pin}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Corr. Address : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.correspondenceAddr}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Qualification : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.qualification}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Email : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.email}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Valid Till : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {moment(doctorDetails.validTill).format("DD/MM/YYYY")}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          wallet Balance : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.wallet_bal}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Available Slots : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {doctorDetails.availableSlots}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </MDBox>
                            </>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </List>
              </Collapse>
            </List>
            <MDBox pt={1} pb={2} px={2}>
              <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  Clinics Details
                </MDTypography>
                <MDBox
                  justifyContent="space-between"
                  alignItems="flex-start"
                  bgColor={darkMode ? "transparent" : "grey-100"}
                  borderRadius="lg"
                  p={3}
                >
                  {doctorDetails &&
                    doctorDetails.clinics &&
                    doctorDetails.clinics.map((data) => {
                      return (
                        <>
                          <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                            mb={2}
                          >
                            <Table responsive>
                              <tbody>
                                <tr>
                                  <td>
                                    <MDTypography variant="button" fontWeight="medium" color="text">
                                      Clinic Name : &nbsp;&nbsp;
                                      <MDTypography variant="button" fontWeight="medium">
                                        {data.name}
                                      </MDTypography>
                                    </MDTypography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <MDTypography variant="button" fontWeight="medium" color="text">
                                      Clinic Address : &nbsp;&nbsp;
                                      <MDTypography variant="button" fontWeight="medium">
                                        {data.address}
                                      </MDTypography>
                                    </MDTypography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <MDTypography variant="button" fontWeight="medium" color="text">
                                      Latitude : &nbsp;&nbsp;
                                      <MDTypography variant="button" fontWeight="medium">
                                        {data.latitude}
                                      </MDTypography>
                                    </MDTypography>
                                    &nbsp;&nbsp;
                                    <MDTypography variant="button" fontWeight="medium" color="text">
                                      Longitude : &nbsp;&nbsp;
                                      <MDTypography variant="button" fontWeight="medium">
                                        {data.longitude}
                                      </MDTypography>
                                    </MDTypography>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            {data.kiosk.map((kioskData) => {
                              return (
                                <Table responsive>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Kiosk Name : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {kioskData?.name}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <MDTypography
                                          variant="button"
                                          fontWeight="medium"
                                          color="text"
                                        >
                                          Kiosk Location : &nbsp;&nbsp;
                                          <MDTypography variant="button" fontWeight="medium">
                                            {kioskData?.location}
                                          </MDTypography>
                                        </MDTypography>
                                      </td>
                                    </tr>
                                    <MDBox mr={1}>
                                      <MDButton
                                        variant="gradient"
                                        color="info"
                                        onClick={() => handleRemoveKiosk(kioskData?._id, data?._id)}
                                      >
                                        <Icon>delete</Icon>&nbsp;Remove
                                      </MDButton>
                                    </MDBox>
                                  </tbody>
                                </Table>
                              );
                            })}
                            <MDBox
                              display="flex"
                              alignItems="center"
                              mt={{ xs: 2, sm: 0 }}
                              ml={{ xs: -1.5, sm: 0 }}
                            >
                              <MDBox mr={1}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  onClick={() => handleClickAddSlots(data)}
                                >
                                  <Icon>addicon</Icon>
                                  &nbsp;Add Slots
                                </MDButton>
                              </MDBox>
                              <MDBox mr={1}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  onClick={() => handleClickUpdateSlots(data)}
                                >
                                  <Icon>edit</Icon>&nbsp;Edit Slots
                                </MDButton>
                              </MDBox>
                              <MDBox mr={1}>
                                <MDButton
                                  variant="gradient"
                                  color="info"
                                  onClick={() => {
                                    setClinicData(data);
                                    setOpenAssignKioskModal(true);
                                  }}
                                >
                                  <Icon>edit</Icon>&nbsp;Assign Kiosk
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                          <hr />
                        </>
                      );
                    })}
                  &nbsp;
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        ) : (
          ""
        )}
        <br />

        <Modal
          open={openAssignKioskModal}
          onClose={handleCloseAssignKioskModal}
          style={{ marginTop: "5%", marginLeft: "10%" }}
        >
          <>
            <MDBox mt={6} mb={3}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Card id="delete-account">
                    <MDBox pt={1} pb={2} px={2}>
                      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          borderRadius="lg"
                          p={3}
                          mt={2}
                        >
                          <MDBox width="100%" display="flex" flexDirection="column">
                            <MDBox mb={1} lineHeight={1}>
                              <center>
                                <h4>Assign Kiosk</h4>
                              </center>
                              <br />
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={kioskData?.name}
                                    onChange={onchangeHandler}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Location"
                                    name="location"
                                    value={kioskData?.location}
                                    onChange={onchangeHandler}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Code"
                                    name="code"
                                    value={kioskData?.code}
                                    onChange={onchangeHandler}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>Auto Height Adjust</InputLabel>
                                    <Select
                                      label="Auto Height Adjust"
                                      name="autoHeightAdjust"
                                      value={kioskData?.autoHeightAdjust}
                                      onChange={onchangeHandler}
                                      style={{ height: "3.1em" }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>ByPass OTP</InputLabel>
                                    <Select
                                      label="ByPass OTP"
                                      name="bypassOtp"
                                      value={kioskData?.bypassOtp}
                                      onChange={onchangeHandler}
                                      style={{ height: "3.1em" }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>ByPass Login</InputLabel>
                                    <Select
                                      label="ByPass Login"
                                      name="bypasslogin"
                                      value={kioskData?.bypasslogin}
                                      onChange={onchangeHandler}
                                      style={{ height: "3.1em" }}
                                    >
                                      <MenuItem value={true}>True</MenuItem>
                                      <MenuItem value={false}>False</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                                  <InputLabel id="demo-checkbox-select-label">Language</InputLabel>
                                  <Select
                                    labelId="demo-checkbox-select-label"
                                    id="demo-checkbox-select"
                                    multiple
                                    value={selectedLanguages.map((lang) => lang.value)} // Extract values for comparison
                                    onChange={(event) => {
                                      const selectedValues = event.target.value;
                                      const selectedLanguages = languageArray.filter((lang) =>
                                        selectedValues.includes(lang.value)
                                      );
                                      setSelectedLanguages(selectedLanguages);
                                    }}
                                    renderValue={(selected) => (
                                      <div>
                                        {selected.map((value) => {
                                          const selectedLang = selectedLanguages.find(
                                            (lang) => lang.value === value
                                          );
                                          return <Chip key={value} label={selectedLang.title} />;
                                        })}
                                      </div>
                                    )}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 224,
                                          width: 250,
                                        },
                                      },
                                    }}
                                  >
                                    {languageArray.map((data, index) => (
                                      <MenuItem key={index} value={data.value}>
                                        <Checkbox
                                          checked={selectedLanguages.some(
                                            (lang) => lang.value === data.value
                                          )}
                                        />
                                        <ListItemText
                                          primary={data.title + "(" + data.value + ")"}
                                        />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleCloseAssignKioskModal}
                                    color="info"
                                  >
                                    Cancel
                                  </MDButton>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    color="info"
                                    onClick={handleAssignKiosk}
                                  >
                                    Submit
                                  </MDButton>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </>
        </Modal>

        <Card id="delete-account">
          <MDBox pt={1} pb={1} px={1}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? "transparent" : "grey-200"}
                borderRadius="lg"
                p={3}
                mt={2}
              >
                <MDBox width="100%" display="flex" flexDirection="column">
                  <MDBox mb={1} lineHeight={1}>
                    <div style={{ display: "block", width: "100%", padding: 30 }}>
                      <Tabs defaultActiveKey="appointments">
                        <Tab eventKey="appointments" title="Appointments">
                          <br />
                          <Appointments data={doctorDetails} doctorId={doctorId} />
                        </Tab>
                        <Tab eventKey="rateCard" title="Rate-Card">
                          <br />
                          <RateCard doctorId={doctorId} />
                        </Tab>
                      </Tabs>
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </DashboardLayout>
    </>
  );
}
export default DoctorDetails;
