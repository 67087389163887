/* eslint-disable */

import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getAgents = (token) => {
  console.log("getAgents");
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }

  return cachedAxiosPost(
    constant.API_URL + "/agent/getAgents",
    {},
    {
      headers,
    }
  );
};

const getAgentsData = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }

  return cachedAxiosGet(constant.API_URL + "/agent/getAgentsData", {
    headers,
  });
};

const createUser = (formdata) => {
  return axios.post(constant.API_URL + "/auth/agent/createUser", formdata, {
    headers,
  });
};

const updateUser = (formdata) => {
  return axios.post(constant.API_URL + "/agent/updateAgentData", formdata, {
    headers,
  });
};

const updateAgentDetails = (formdata) => {
  return axios.post(constant.API_URL + "/agent/updateAgentDetails", formdata, {
    "Content-Type": "multipart/form-data",
    headers: {
      Authorization: `Bearer ${loginData?.token}`,
    },
  });
};

const getAgentDetails = () => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/getAgents",
    {},
    {
      headers,
    }
  );
};

const assignDoctor = (formData) => {
  return axios.post(constant.API_URL + "/agent/mapDoctor", formData, {
    headers,
  });
};

const getPatientTestByAgent = (formData) => {
  const id = loginData ? loginData.id : "";
  if (!id || !id.length) {
    return;
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/getAllTests",
    { id, pageNumber: formData.pageNumber, pageSize: formData.pageSize },
    {
      headers,
    }
  );
};

const getPatientTest = (formData) => {
  return cachedAxiosPost(
    constant.API_URL + "/agent/patient/getAllTests",
    { pageNumber: formData.pageNumber, pageSize: formData.pageSize },
    {
      headers,
    }
  );
};
const getPatientsTestsMapping = (formData) => {
  return cachedAxiosPost(constant.API_URL + "/agent/patient/testMapping", formData, {
    headers,
  });
};

const updateAnnotationStatus = (formdata) => {
  return axios.post(constant.API_URL + "/agent/patient/updateAnnotationStatus", formdata, {
    headers,
  });
};

const getInternalDoctors = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getInternalDoctors", {
    headers,
  });
};

const addNewAgent = (formdata) => {
  return axios.post(constant.API_URL + "/auth/agent/signup", formdata, {
    headers,
  });
};
const transferPatients = (formData) => {
  return axios.post(
    constant.API_URL + "/agent/transferPatients",
    { primaryAgentId: formData.primaryAgent, secondaryAgentId: formData.secondaryAgent },
    {
      headers,
    }
  );
};

const checkAgentOnline = (formData) => {
  if (formData?.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData?.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/checkAgentOnline",
    { agentStatus: formData.agentStatus },
    { headers }
  );
};

const agentLogin = (formData) => {
  if (formData?.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formData?.token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/auth/agent/loginUser",
    {
      email,
      password,
    },
    { headers }
  );
};

const agentService = {
  checkAgentOnline,
  getAgents,
  createUser,
  getAgentsData,
  updateUser,
  getAgentDetails,
  getPatientTestByAgent,
  getPatientTest,
  addNewAgent,
  assignDoctor,
  getInternalDoctors,
  transferPatients,
  getPatientsTestsMapping,
  updateAnnotationStatus,
  updateAgentDetails,
  agentLogin,
};

export default agentService;
