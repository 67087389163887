import { Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import {
  addCoupon,
  updateCoupon,
  couponSelector,
  resetUpdateCouponSuccess,
  resetAddCouponSuccess,
} from "store/reducers/couponSlice";
import { FormControl } from "@mui/material";
import { InputLabel, MenuItem, Select } from "@mui/material";

const name = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [couponData, setCouponData] = useState({
    couponCode: "",
    title: "",
    description: "",
    discount: "",
    discounPercent: "",
    minOrderValue: "",
  });
  const addCouponSuccess = useSelector(couponSelector.addCouponSuccess);
  const addCouponFailure = useSelector(couponSelector.addCouponFailure);
  const updateCouponSuccess = useSelector(couponSelector.updateCouponSuccess);
  const updateCouponFailure = useSelector(couponSelector.updateCouponFailure);

  useEffect(() => {
    setCouponData({
      couponCode: location?.state?.couponData?.couponCode,
      description: location?.state?.couponData?.description,
      title: location?.state?.couponData?.title,
      discount: location?.state?.couponData?.discount,
      discountPercent: location?.state?.couponData?.discountPercent,
      minOrderValue: location?.state?.couponData?.minOrderValue,
      isPublic: location?.state?.couponData?.isPublic,
    });
  }, []);

  const onchangeHandler = (e) => {
    setCouponData({ ...couponData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    dispatch(addCoupon(couponData));
  };

  useEffect(() => {
    if (addCouponSuccess) {
      Swal.fire({
        icon: "success",
        text: "Coupon added successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetAddCouponSuccess());
      navigate("/coupons");
    }
  }, [addCouponSuccess]);

  useEffect(() => {
    if (addCouponFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addCouponFailure]);

  const handleUpdate = () => {
    const couponObject = {
      couponId: location?.state?.couponData?._id,
      couponData,
    };
    dispatch(updateCoupon(couponObject));
  };

  useEffect(() => {
    if (updateCouponSuccess) {
      Swal.fire({
        icon: "success",
        text: "Coupon updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      dispatch(resetUpdateCouponSuccess());
      navigate("/coupons");
    }
  }, [updateCouponSuccess]);

  useEffect(() => {
    if (updateCouponFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateCouponFailure]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card id="delete-account">
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                  <br />
                  <h4>
                    <center>{location?.state?.couponData.length ? "Add" : "Edit"} Coupon</center>
                  </h4>
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    borderRadius="lg"
                    p={3}
                    mt={2}
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox mb={1} lineHeight={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Coupon code"
                              name="couponCode"
                              value={couponData?.couponCode}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Title"
                              name="title"
                              value={couponData?.title}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Description"
                              name="description"
                              value={couponData?.description}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Minimum order value"
                              name="minOrderValue"
                              value={couponData?.minOrderValue}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Discount"
                              name="discount"
                              value={couponData?.discount}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label="Discount Percent"
                              name="discountPercent"
                              value={couponData?.discounPercent}
                              onChange={onchangeHandler}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                              <InputLabel>IsPublic</InputLabel>
                              <Select
                                label="isPublic"
                                name="isPublic"
                                value={couponData?.isPublic}
                                onChange={onchangeHandler}
                                style={{ height: "3.1em" }}
                              >
                                <MenuItem value={true}>True</MenuItem>
                                <MenuItem value={false}>False</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <br />

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <MDButton
                              fullWidth
                              variant="gradient"
                              color="info"
                              onClick={() => navigate("/coupons", {})}
                            >
                              Cancel
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {location?.state?.couponData ? (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                color="info"
                                onClick={handleUpdate}
                              >
                                Update
                              </MDButton>
                            ) : (
                              <MDButton
                                fullWidth
                                variant="gradient"
                                color="info"
                                onClick={handleSubmit}
                              >
                                Submit
                              </MDButton>
                            )}
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default name;
