/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "@mui/material/Modal";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Card,
  Input,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Constants from "utils/constants";
const REACT_API_URL = Constants.API_URL;
import axios from "axios";
import CryptoJS from "crypto-js";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllNotifications,
  patientDetailsSelector,
  resetAllNotificationsSuccess,
} from "store/reducers/patientdetailsSlice";
import {
  sendNotificationUser,
  sendPaymentNotification,
  patientSelector,
  resetSendNotificationUser,
} from "store/reducers/patientSlice";

function NotificationHistory(props) {
  const dispatch = useDispatch();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [patientsNotifications, setPatientsNotifications] = useState();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { data, patientId } = props;
  const [openSendNotificationModal, setOpenSendNotificationModal] = useState(false);
  const [openSendAppNotificationModal, setOpenSendAppNotificationModal] = useState(false);
  const [openSendPaymentNotificationModal, setOpenSendPaymentNotificationModal] = useState(false);
  const handleCloseSendNotificationModal = () => setOpenSendNotificationModal(false);
  const handleCloseSendAppNotificationModal = () => setOpenSendAppNotificationModal(false);
  const handleCloseSendPaymentNotificationModal = () => setOpenSendPaymentNotificationModal(false);
  const [notificationLink, setNotificationLink] = useState("");
  const pathList = ["Consultation", "Appointment", "Report", "Invoice", "Scan"];
  const categoryList = ["Offers", "Alerts", "Reminders"];
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [notificationFile, setNotificationFile] = useState();
  const [notificationTitle, setNotificationTitle] = useState();
  const [notificationDescription, setNotificationDescription] = useState();
  const [notificationPath, setNotificationPath] = useState();
  const [category, setCategory] = useState();
  const secretPass = Constants.SECRET_KEY;
  const [isNoAngleDetected, setIsNoAngleDetected] = useState(false);
  const notificationData = useSelector((state) => state?.patientDetails?.notificationData);
  const getAllNotificationsSuccess = useSelector(patientDetailsSelector.getAllNotificationsSuccess);
  const getAllNotificationsFailure = useSelector(patientDetailsSelector.getAllNotificationsFailure);
  const sendNotificationUserSuccess = useSelector(patientSelector.sendNotificationUserSuccess);
  const sendNotificationUserFailure = useSelector(patientSelector.sendNotificationUserFailure);
  const sendPaymentNotificationSuccess = useSelector(
    patientSelector.sendPaymentNotificationSuccess
  );
  const sendPaymentNotificationFailure = useSelector(
    patientSelector.sendPaymentNotificationFailure
  );

  useEffect(() => {
    GetPatientsNotifications();
  }, []);

  const notificationsColumns = [
    {
      field: "createdAt",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.createdAt).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 350,
    },
    {
      field: "body",
      headerName: "Description",
      headerAlign: "center",
      align: "center",
      width: 350,
      renderCell: (data) => {
        if (data.row && data.row.description && data.row.navigation.path === "chat") {
          const bytes = CryptoJS.AES.decrypt(data.row.description, secretPass);
          const encryptdata = bytes.toString(CryptoJS.enc.Utf8);
          return encryptdata;
        } else {
          return data.row.description;
        }
      },
    },
    {
      field: "agent",
      headerName: "Agent	",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return data.row && data.row.agent && data.row.agent.name ? data.row.agent.name : "scanO";
      },
    },
    {
      field: "navigation",
      headerName: "Path",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (data) => {
        return data && data.formattedValue && data.formattedValue.path
          ? data.formattedValue.path
          : "Normal Notification";
      },
    },
  ];

  const GetPatientsNotifications = () => {
    dispatch(getAllNotifications(props.patientId));
  };

  useEffect(() => {
    if (notificationData?.notification) {
      setPatientsNotifications([...notificationData?.notification?.appNotification].reverse());
    }
  }, [notificationData]);

  useEffect(() => {
    if (getAllNotificationsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAllNotificationsFailure]);

  const validateSendNotification = () => {
    if (title == "" && !isNoAngleDetected) {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Title cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (description == "" && !isNoAngleDetected) {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Description cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    return true;
  };
  const validateSendNotificationLink = () => {
    if (notificationLink == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Link cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    return true;
  };
  const validateSendAppNotification = () => {
    if (notificationTitle == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Title cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (notificationDescription == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Description cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }
    if (notificationFile == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "file cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    if (notificationPath == "") {
      setOpenSendNotificationModal(false);
      Swal.fire({
        icon: "error",
        text: "Path cannot be empty",
        showConfirmButton: false,
        timer: 2000,
      });
      return false;
    }

    return true;
  };
  const handleSendNotification = () => {
    if (validateSendNotification() === true || isNoAngleDetected) {
      const formData = {
        patient: [{ patientId }],
        title: title,
        body: description,
        agentId: loginData.id,
        isNoAngleDetected,
      };
      dispatch(sendNotificationUser(formData));
    }
  };

  useEffect(() => {
    if (sendNotificationUserSuccess) {
      dispatch(resetSendNotificationUser());
      handleCloseSendNotificationModal();
      Swal.fire({
        icon: "success",
        text: "Notification sent",
        showConfirmButton: false,
        timer: 2000,
      });
      setTitle("");
      setDescription("");
      GetPatientsNotifications();
    }
  }, [sendNotificationUserSuccess]);

  useEffect(() => {
    if (sendNotificationUserFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendNotificationUserFailure]);

  const handleSendAppNotification = () => {
    let body = new FormData();
    const config = {
      "Content-Type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${loginData === null ? "a" : loginData.token}`,
      },
    };
    body.append("file", notificationFile);
    body.append("patientId", patientId);
    body.append("description", notificationDescription);
    body.append("title", notificationTitle);
    body.append("path", notificationPath);
    body.append("agentId", loginData.id);
    body.append("category", category);
    axios
      .post(REACT_API_URL + "/agent/patient/sendAppNotification", body, config)
      .then((res) => {
        handleCloseSendAppNotificationModal();
        GetPatientsNotifications();
        Swal.fire({
          icon: "success",
          text: "Notification sent",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        if (error.response) {
        }
        console.log(error);
      });
  };

  const handleSendPaymentNotification = () => {
    if (validateSendNotificationLink() === true) {
      const formData = {
        patientId,
        notificationLink,
      };
      dispatch(sendPaymentNotification(formData));
    }
  };

  useEffect(() => {
    if (sendPaymentNotificationSuccess) {
      handleCloseSendPaymentNotificationModal();
      GetPatientsNotifications();
      setNotificationLink("");
      Swal.fire({
        icon: "success",
        text: "Payment Link Notification sent",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendPaymentNotificationSuccess]);

  useEffect(() => {
    if (sendPaymentNotificationFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendPaymentNotificationFailure]);

  return (
    <>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" width="100%">
          <MDBox mb={1} lineHeight={1}>
            <MDBox
              display="flex"
              alignItems="center"
              justifyContent="right"
              mt={{ xs: 2, sm: 0.1 }}
              ml={{ xs: -1.5, sm: 0.1 }}
              pt={1}
              px={2}
            >
              <MDBox mr={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => setOpenSendNotificationModal(true)}
                >
                  Send Notification
                </MDButton>
              </MDBox>
              <MDBox mr={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => setOpenSendAppNotificationModal(true)}
                >
                  Send App Notification
                </MDButton>
              </MDBox>
              <MDBox mr={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => setOpenSendPaymentNotificationModal(true)}
                >
                  Send Payment Notification
                </MDButton>
              </MDBox>
            </MDBox>
            <div style={{ display: "block", width: "100%" }}>
              <br />
              <div style={{ height: "370px", width: "100%" }}>
                <DataGrid
                  rows={patientsNotifications ? patientsNotifications : []}
                  columns={notificationsColumns}
                  disableSelectionOnClick
                  pageSize={5}
                  getRowHeight={() => "auto"}
                  getRowId={(row) => row._id}
                />
              </div>
            </div>
          </MDBox>
        </MDBox>
      </MDBox>

      <Modal
        open={openSendNotificationModal}
        onClose={handleCloseSendNotificationModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox mb={1} lineHeight={1}>
                            <center>
                              <h4>Send Notification</h4>
                            </center>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Title"
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <TextField
                                  fullWidth
                                  label="Description"
                                  onChange={(e) => setDescription(e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isNoAngleDetected}
                                      onChange={(e) => setIsNoAngleDetected(e.target.checked)}
                                      onClick={(e) => e.stopPropagation()}
                                    />
                                  }
                                  label="No angle detected"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleCloseSendNotificationModal}
                                  color="info"
                                >
                                  Cancel
                                </MDButton>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleSendNotification}
                                  color="info"
                                >
                                  Send
                                </MDButton>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
      <Modal
        open={openSendAppNotificationModal}
        onClose={handleCloseSendAppNotificationModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <center>
                            <h4>Send App Notification</h4>
                          </center>
                          <br />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Title"
                                onChange={(e) => setNotificationTitle(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Description"
                                onChange={(e) => setNotificationDescription(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">Path</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={(value) => {
                                    setNotificationPath(value.target.value);
                                  }}
                                >
                                  {pathList.map((data) => {
                                    return <MenuItem value={data}> {data} </MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <InputLabel id="demo-simple-select-label">
                                Notification Image
                              </InputLabel>
                              <Input
                                accept="image/*"
                                id="raised-button-file"
                                type="file"
                                onChange={(e) => setNotificationFile(e.target.files[0])}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={(value) => {
                                    setCategory(value.target.value);
                                  }}
                                >
                                  {categoryList.map((data) => {
                                    return <MenuItem value={data}> {data} </MenuItem>;
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12} sm={6}>
                              <MDButton
                                fullWidth
                                variant="gradient"
                                onClick={handleCloseSendAppNotificationModal}
                                color="info"
                              >
                                Cancel
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MDButton
                                fullWidth
                                variant="gradient"
                                onClick={handleSendAppNotification}
                                color="info"
                              >
                                Send
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </>
      </Modal>
      <Modal
        open={openSendPaymentNotificationModal}
        onClose={handleCloseSendPaymentNotificationModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox mb={1} lineHeight={1}>
                            <center>
                              <h4>Send Payment Notification</h4>
                            </center>
                            <br />
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  fullWidth
                                  label="Payment Link"
                                  value={notificationLink}
                                  onChange={(e) => setNotificationLink(e.target.value)}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleCloseSendPaymentNotificationModal}
                                  color="info"
                                >
                                  Cancel
                                </MDButton>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <MDButton
                                  fullWidth
                                  variant="gradient"
                                  onClick={handleSendPaymentNotification}
                                  color="info"
                                >
                                  Send
                                </MDButton>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
    </>
  );
}
export default NotificationHistory;
