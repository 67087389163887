/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import AffiliateTable from "./components/affiliateTable";
function Affiliate() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <AffiliateTable />
      </DashboardLayout>
    </>
  );
}
export default Affiliate;
