/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import moment from "moment";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import Swal from "sweetalert2";
import MDInput from "components/MDInput"; //required
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@material-ui/core/Chip";
import { getAllSubscriptions, subscriptionSelector } from "store/reducers/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import routes from "routes";

function SubscriptionDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [filterName, setFilterName] = useState("");
  const subscriptionPlans = useSelector((state) => state?.subscription?.subscriptionPlans);
  const getAllSubscriptionsSuccess = useSelector(subscriptionSelector.getAllSubscriptionsSuccess);
  const getAllSubscriptionsFailure = useSelector(subscriptionSelector.getAllSubscriptionsFailure);

  useEffect(() => {
    fetchAllSubscriptions();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  function toTitleCase(str) {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return str;
    }
  }
  const getMonthDifference = (date1, date2) => {
    const monthsInYear = 12;

    // Calculate the difference in years and months
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();

    // Convert the years to months and add the month difference
    const totalMonthsDiff = yearDiff * monthsInYear + monthDiff;

    return totalMonthsDiff;
  };

  const usersColumns = [
    // {
    //   field: "id",
    //   headerName: "No",
    //   headerAlign: "center",
    //   align: "center",
    //   width: 50,
    // },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation();

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        return (
          <>
            <div onClick={onDetailsClick}>
              {toTitleCase(params && params.row && params.row.user && params.row.user.name)}
            </div>
          </>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile No",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return params && params.row && params.row.user && params.row.user.mobile;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <Chip
            size="small"
            label={new Date(params.row.endDate) > new Date() ? "Active" : "InActive"}
            color={new Date(params.row.endDate) > new Date() ? "primary" : "secondary"}
          />
        );
      },
    },
    {
      field: "planType",
      headerName: "Plan",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        const date1 = new Date(params.row.startDate);
        const date2 = new Date(params.row.endDate);

        const monthsDifference = getMonthDifference(date1, date2);
        return params?.row?.pack?.planType && monthsDifference == 1
          ? params?.row?.pack?.planType
          : "Annual";
      },
    },
    {
      field: "price",
      headerName: "Price",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => {
        const date1 = new Date(params.row.startDate);
        const date2 = new Date(params.row.endDate);

        const monthsDifference = getMonthDifference(date1, date2);
        return params?.row?.pack?.price && monthsDifference == 1 ? params?.row?.pack?.price : 999;
      },
    },
    {
      field: "createdAt",
      headerName: "Start Date",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return moment(params && params.row && params.row.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return moment(params && params.row && params.row.endDate).format("DD/MM/YYYY");
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (params) => {
        const onDetailsClick = (e) => {
          e.stopPropagation();

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));
          handleDetailsClick(thisRow);
        };
        return (
          <>
            <MDButton variant="gradient" onClick={onDetailsClick} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];
  const handleDetailsClick = (datavalue) => {
    const result = subscriptionList.filter(
      (user) => user.createdAt === datavalue.createdAt && user.endDate === datavalue.endDate
    );
    navigate("/patientdetails/" + result[0].user._id);
  };

  const fetchAllSubscriptions = () => {
    dispatch(getAllSubscriptions());
  };

  useEffect(() => {
    if (subscriptionPlans) {
      console.log("subscriptionPlans", subscriptionPlans);
      setSubscriptionList(
        subscriptionPlans && subscriptionPlans && [...subscriptionPlans].reverse()
      );
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (getAllSubscriptionsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAllSubscriptionsFailure]);

  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Subscription's
              </MDTypography>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <div style={{ height: 630, width: "100%" }}>
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <DataGrid
                    rows={subscriptionList}
                    columns={usersColumns}
                    pageSize={10}
                    getRowId={(row) => row._id}
                  />
                )}
              </div>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default SubscriptionDetails;
