// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  updateChatModeSuccess,
  updateChatModeFailure,
  updateChatStatusSuccess,
  updateChatStatusFailure,
  getPatientChatsSuccess,
  getPatientChatsFailure,
  filterChatDataSuccess,
  filterChatDataFailure,
  searchChatPatientsSuccess,
  searchChatPatientsFailure,
} from "store/reducers/chatSlice"; // Import your action creator
import chatService from "services/chatServices"; // Import your service function

function* updateChatMode(action) {
  try {
    const response = yield call(chatService.updateChatMode, action.payload);
    yield put(updateChatModeSuccess(response.data));
  } catch (error) {
    yield put(updateChatModeFailure(error));
  }
}

function* updateChatStatus(action) {
  try {
    const response = yield call(chatService.updateChatStatus, action.payload);
    yield put(updateChatStatusSuccess(response.data));
  } catch (error) {
    yield put(updateChatStatusFailure(error));
  }
}

function* getPatientChats(action) {
  try {
    const response = yield call(chatService.getPatientChats, action.payload);
    yield put(getPatientChatsSuccess(response.data));
  } catch (error) {
    yield put(getPatientChatsFailure(error));
  }
}

function* filterChatData(action) {
  try {
    const response = yield call(chatService.filterChatData, action.payload);
    yield put(filterChatDataSuccess(response.data));
  } catch (error) {
    yield put(filterChatDataFailure(error));
  }
}

function* searchChatPatients(action) {
  try {
    const response = yield call(chatService.searchChatPatients, action.payload);
    yield put(searchChatPatientsSuccess(response.data));
  } catch (error) {
    yield put(searchChatPatientsFailure(error));
  }
}

// Watcher Saga
function* kioskSaga() {
  yield takeLatest("chats/updateChatMode", updateChatMode);
  yield takeLatest("chats/updateChatStatus", updateChatStatus);
  yield takeLatest("chats/getPatientChats", getPatientChats);
  yield takeLatest("chats/filterChatData", filterChatData);
  yield takeLatest("chats/searchChatPatients", searchChatPatients);
}

// Export all sagas
export default kioskSaga;
