// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  removeAppointmentSuccess,
  removeAppointmentFailure,
  bookAppointmentSuccess,
  bookAppointmentFailure,
  updateTimeSlotStatusSuccess,
  updateTimeSlotStatusFailure,
  getAppointmentSlotsSuccess,
  getAppointmentSlotsFailure,
  getAppointmentDetailsSuccess,
  getAppointmentDetailsFailure,
  updateAppointmentSuccess,
  updateAppointmentFailure,
  sendInvoiceSuccess,
  sendInvoiceFailure,
  updateTimeSlotsSuccess,
  updateTimeSlotsFailure,
  getAvailableStatusSuccess,
  getAvailableStatusFailure,
  getTimeSlotsSuccess,
  getTimeSlotsFailure,
  addAppointmentSuccess,
  addAppointmentFailure,
  addClinicSlotsSuccess,
  addClinicSlotsFailure,
} from "store/reducers/appointmentSlice"; // Import your action creator
import appointmentService from "services/appointment"; // Import your service function

function* removeAppointment(action) {
  try {
    const response = yield call(appointmentService.removeAppointment, action.payload);
    yield put(removeAppointmentSuccess(response.data));
  } catch (error) {
    yield put(removeAppointmentFailure(error));
  }
}

function* bookAppointment(action) {
  try {
    const response = yield call(appointmentService.bookAppointment, action.payload);
    yield put(bookAppointmentSuccess(response.data));
  } catch (error) {
    yield put(bookAppointmentFailure(error));
  }
}

function* updateTimeSlotStatus(action) {
  try {
    const response = yield call(appointmentService.updateTimeSlotStatus, action.payload);
    yield put(updateTimeSlotStatusSuccess(response.data));
  } catch (error) {
    yield put(updateTimeSlotStatusFailure(error));
  }
}

function* getAppointmentSlots(action) {
  try {
    const response = yield call(appointmentService.getAppointmentSlots, action.payload);
    yield put(getAppointmentSlotsSuccess(response.data));
  } catch (error) {
    yield put(getAppointmentSlotsFailure(error));
  }
}

function* getAppointmentDetails(action) {
  try {
    const response = yield call(appointmentService.getAppointmentDetails, action.payload);
    yield put(getAppointmentDetailsSuccess(response.data));
  } catch (error) {
    yield put(getAppointmentDetailsFailure(error));
  }
}

function* updateAppointment(action) {
  try {
    const response = yield call(appointmentService.updateAppointment, action.payload);
    yield put(updateAppointmentSuccess(response.data));
  } catch (error) {
    yield put(updateAppointmentFailure(error));
  }
}

function* sendInvoice(action) {
  try {
    const response = yield call(appointmentService.sendInvoice, action.payload);
    yield put(sendInvoiceSuccess(response.data));
  } catch (error) {
    yield put(sendInvoiceFailure(error));
  }
}

function* addClinicSlots(action) {
  try {
    const response = yield call(appointmentService.addClinicSlots, action.payload);
    yield put(addClinicSlotsSuccess(response.data));
  } catch (error) {
    yield put(addClinicSlotsFailure(error));
  }
}

function* updateTimeSlots(action) {
  try {
    const response = yield call(appointmentService.updateTimeSlots, action.payload);
    yield put(updateTimeSlotsSuccess(response.data));
  } catch (error) {
    yield put(updateTimeSlotsFailure(error));
  }
}

function* getAvailableStatus(action) {
  try {
    const response = yield call(appointmentService.getAvailableStatus, action.payload);
    yield put(getAvailableStatusSuccess(response.data));
  } catch (error) {
    yield put(getAvailableStatusFailure(error));
  }
}

function* getTimeSlots(action) {
  try {
    const response = yield call(appointmentService.getTimeSlots, action.payload);
    yield put(getTimeSlotsSuccess(response.data));
  } catch (error) {
    yield put(getTimeSlotsFailure(error));
  }
}

function* addAppointment(action) {
  try {
    const response = yield call(appointmentService.addAppointment, action.payload);
    yield put(addAppointmentSuccess(response.data));
  } catch (error) {
    yield put(addAppointmentFailure(error));
  }
}

// Watcher Saga
function* kioskSaga() {
  yield takeLatest("appointments/removeAppointment", removeAppointment);
  yield takeLatest("appointments/bookAppointment", bookAppointment);
  yield takeLatest("appointments/updateTimeSlotStatus", updateTimeSlotStatus);
  yield takeLatest("appointments/getAppointmentSlots", getAppointmentSlots);
  yield takeLatest("appointments/getAppointmentDetails", getAppointmentDetails);
  yield takeLatest("appointments/updateAppointment", updateAppointment);
  yield takeLatest("appointments/sendInvoice", sendInvoice);
  yield takeLatest("appointments/updateTimeSlots", updateTimeSlots);
  yield takeLatest("appointments/getAvailableStatus", getAvailableStatus);
  yield takeLatest("appointments/getTimeSlots", getTimeSlots);
  yield takeLatest("appointments/addAppointment", addAppointment);
  yield takeLatest("appointments/addClinicSlots", addClinicSlots);
}

// Export all sagas
export default kioskSaga;
