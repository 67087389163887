/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { Select, FormControl, InputLabel, Stack, TextField, Grid, Card, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { darken, lighten } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getFollowUpRemindersByDate,
  getWeeklyFollowUpReminder,
} from "store/reducers/followUpReminderSlice";
import { useDispatch, useSelector } from "react-redux";
// import { getInternalDoctors } from "store/reducers/agentSlice";
import routes from "routes";
import { getInternalDoctors, agentSelector } from "store/reducers/agentSlice";

function FollowUpReminderTable() {
  const dispatch = useDispatch();
  const [doctorDetail, setDoctorsDetail] = useState([]);
  const [page, setPage] = useState(JSON.parse(localStorage.getItem("PageNumber")));
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [followUpData, setFollowUpData] = useState([]);
  const [followUpDate, setFollowUpDate] = useState(new Date());
  let [newDoctorID, setNewDoctorID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const internalDoctors = useSelector((state) => state?.agent?.internalDoctors);
  const getInternalDoctorsSuccess = useSelector(agentSelector.getInternalDoctorsSuccess);
  const getInternalDoctorsFailure = useSelector(agentSelector.getInternalDoctorsFailure);

  const navigate = useNavigate();
  useEffect(() => {
    GetDoctorsList();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const followUpColumns = [
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "reminderDate",
      headerName: "Reminder Date",
      headerAlign: "center",
      align: "center",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY hh:mm A");
        return `${valueFormatted}`;
      },
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      width: 200,
      renderCell: (params) => {
        return <div>{toTitleCase(params.row.patientName)}</div>;
      },
    },
    {
      field: "appointmentDate",
      headerName: "Appointment Date",
      width: 200,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY hh:mm A");
        return `${valueFormatted}`;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const handleclickDetails = () => {
          navigate("/patientdetails/" + params.row.patientId, { state: { data: params.row } });
        };
        return (
          <>
            <MDButton variant="gradient" onClick={handleclickDetails} color="info">
              Details
            </MDButton>
          </>
        );
      },
    },
  ];
  const GetDoctorsList = () => {
    dispatch(getInternalDoctors(loginData.token));
    //   .unwrap()
    //   .then((response) => {
    //     setDoctorsDetail(response.doctors);
    //   })
    //   .catch((error) => {
    //     // Handle API error if needed
    //     Swal.fire({
    //       icon: "error",
    //       text: "Error occurred",
    //       showConfirmButton: false,
    //       timer: 2000,
    //     });
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (internalDoctors?.doctors) {
      setDoctorsDetail([...internalDoctors?.doctors]);
    }
  }, [internalDoctors]);

  useEffect(() => {
    if (getInternalDoctorsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getInternalDoctorsFailure]);

  const fetchWeeklyFollowUpReminder = (newDoctorID) => {
    setIsLoading(true);
    const formData = {
      newDoctorID,
      token: loginData.token,
    };
    dispatch(getWeeklyFollowUpReminder(formData));
    // .unwrap()
    // .then((response) => {
    //   setIsLoading(false);
    //   setFollowUpData(response.followUps);
    // })
    // .catch((error) => {
    //   // Handle API error if needed
    //   Swal.fire({
    //     icon: "error",
    //     text: "Error occurred",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   console.log(error);
    // });
  };
  const getFollowUpReminders = (newDoctorID) => {
    setIsLoading(true);
    const formData = {
      newDoctorID,
      followUpDate,
      token: loginData.token,
    };
    dispatch(getFollowUpRemindersByDate(formData));
    // .unwrap()
    // .then((response) => {
    //   setIsLoading(false);
    //   setFollowUpData(response.followUps);
    // })
    // .catch((error) => {
    //   // Handle API error if needed
    //   Swal.fire({
    //     icon: "error",
    //     text: "Error occurred",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    //   console.log(error);
    // });
  };

  const handleChangeDoctor = (value) => {
    setNewDoctorID(value.target.value);
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                FollowUp Reminder
              </MDTypography>
            </MDBox>
            <br />
            <MDBox width="100%" display="flex" flexDirection="column" py={3} px={2}>
              <MDBox mb={1}>
                <Grid item xs={12} sm={12} container>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{ postion: "relative", maxWidth: "235px" }}
                  >
                    <InputLabel id="demo-simple-select-label">Doctors</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeDoctor}
                    >
                      {doctorDetail.sort(compare).map((doctor) => {
                        return <MenuItem value={doctor.did}> {toTitleCase(doctor.name)} </MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Select Date"
                          inputFormat="DD/MM/YYYY"
                          value={followUpDate}
                          onChange={(date) => setFollowUpDate(date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                  <MDButton
                    style={{ textAlign: "center" }}
                    variant="gradient"
                    color="info"
                    onClick={() => getFollowUpReminders(newDoctorID)}
                  >
                    <SearchIcon />
                    Search
                  </MDButton>
                  &nbsp; &nbsp; &nbsp;
                  <MDButton
                    style={{ textAlign: "center" }}
                    variant="gradient"
                    color="info"
                    onClick={() => fetchWeeklyFollowUpReminder(newDoctorID)}
                  >
                    Weekly
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <Box
                sx={{
                  // height: 400,
                  width: "100%",
                  "& .super-app-theme--Warm": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.info.main, theme.palette.mode),
                  },
                  "& .super-app-theme--Hot": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                  },
                  "& .super-app-theme--Cold": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                  },
                  "& .super-app-theme--Rejected": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                  },
                }}
              >
                {isLoading ? (
                  <MDBox style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress color="inherit" />
                  </MDBox>
                ) : (
                  <div style={{ height: 630, width: "100%" }}>
                    <DataGrid
                      rows={followUpData}
                      columns={followUpColumns}
                      getRowId={(row) => row.reminderId}
                      getRowClassName={(params) => `super-app-theme--${params.row.patientStatus}`}
                    />
                  </div>
                )}
              </Box>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default FollowUpReminderTable;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
