/* eslint-disable */

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import EditIcon from "@mui/icons-material/Edit";

function PlanCard({
  planType,
  price,
  features,
  planId,
  annualPrice,
  discountPercent,
  originalPrice,
  validityInMonths,
  noGutter,
}) {
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickEditPlan = () => {
    navigate("/add-edit-plans", {
      state: {
        planId: planId,
        planType: planType,
        price: price,
        features: features,
        annualPrice: annualPrice,
        discountPercent: discountPercent,
        originalPrice: originalPrice,
      },
    });
  };

  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={darkMode ? "transparent" : "grey-200"}
      borderRadius="lg"
      p={3}
      mb={noGutter ? 0 : 1}
    >
      <MDBox width="100%" display="flex" flexDirection="row">
        <List
          sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleClick}>
            <MDBox mb={1} lineHeight={0}>
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
                style={{ fontSize: "15px" }}
              >
                Plan:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                  style={{ fontSize: "15px" }}
                >
                  {planType}
                </MDTypography>
              </MDTypography>
            </MDBox>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <MDBox mb={1} lineHeight={0}>
              <MDTypography
                variant="caption"
                fontWeight="medium"
                textTransform="capitalize"
                style={{ fontSize: "15px" }}
              >
                Price:&nbsp;&nbsp;&nbsp;
                <MDTypography
                  variant="caption"
                  fontWeight="medium"
                  textTransform="capitalize"
                  style={{ fontSize: "15px" }}
                >
                  <CurrencyRupeeIcon />
                  {price}&nbsp;/-
                </MDTypography>
              </MDTypography>
            </MDBox>
            <ListItemText />
            {open ? (
              <>
                <MDButton
                  style={{ float: "left" }}
                  variant="gradient"
                  color="info"
                  onClick={handleClickEditPlan}
                >
                  <EditIcon />
                  &nbsp;Edit Plan
                </MDButton>
                &nbsp; &nbsp;
                <small>Features</small> &nbsp; &nbsp;
                <ExpandLess></ExpandLess>
              </>
            ) : (
              <>
                <small>Features</small>
                <ExpandMore></ExpandMore>
              </>
            )}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Table aria-label="simple table">
                <TableRow>
                  <TableCell>
                    <b>No.</b>
                  </TableCell>
                  <TableCell>
                    <b>Features</b>
                  </TableCell>
                  <TableCell>
                    <b>Permissions</b>
                  </TableCell>
                  <TableCell>
                    <b>/Month</b>
                  </TableCell>
                  <TableCell>
                    <b>Title</b>
                  </TableCell>
                  <TableCell>
                    <b>Description</b>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {features
                    ? features.map((data, index) => {
                        return (
                          <>
                            <TableRow
                              sx={{
                                "&:last-child TableCell, &:last-child th": { border: 0 },
                              }}
                            >
                              <TableCell key={index}>{index + 1}</TableCell>
                              <TableCell key={data.name}>{data.name}</TableCell>
                              <TableCell key={data.permission}>
                                {data.permission === true ? "Yes" : "No"}
                              </TableCell>
                              <TableCell>{data.count ? data.count : "-"}</TableCell>
                              <TableCell>{data.title ? data.title : "-"}</TableCell>
                              <TableCell>{data.description ? data.description : "-"}</TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </List>
          </Collapse>
        </List>
      </MDBox>
    </MDBox>
  );
}

PlanCard.defaultProps = {
  noGutter: false,
};

export default PlanCard;
