/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Grid, Modal, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Constants from "utils/constants";
import "./filedata.css";
const REACT_API_URL = Constants.API_URL;
const localstoredata = JSON.parse(localStorage.getItem("loginData"));
const loginData = JSON.parse(localStorage.getItem("loginData"));

import { Select, FormControl, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  removeFileData,
  getPatientDetails,
  patientDetailsSelector,
} from "store/reducers/patientdetailsSlice";

function FilesData(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openFileModal, setOpenFileModal] = useState(false);
  const handleCloseFileModal = () => setOpenFileModal(false);
  const [patientDetails, setPatientDetails] = useState({});
  const [patientImagesData, setPatientImagesData] = useState();
  const { data, fileData, patientId } = props;
  const removeFileDataSuccess = useSelector(patientDetailsSelector.removeFileDataSuccess);
  const removeFileDataFailure = useSelector(patientDetailsSelector.removeFileDataFailure);
  const patientDetailsData = useSelector((state) => state?.patientDetails?.patientDetails);
  const getPatientDetailsSuccess = useSelector(patientDetailsSelector.getPatientDetailsSuccess);
  const getPatientDetailsFailure = useSelector(patientDetailsSelector.getPatientDetailsFailure);

  useEffect(() => {
    patientsList();
  }, []);
  const [reportData, setReportData] = useState([]);
  const ddReportArray = ["Treatment Report", "Image", "Prescription"];

  const handleUploadImage = () => {
    let body = new FormData();
    const config = {
      "Content-Type": "multipart/form-data",
      headers: {
        Authorization: `Bearer ${localstoredata === null ? "a" : localstoredata.token}`,
      },
    };
    reportData.forEach((item) => {
      body.append("mainFile", item.file);
      body.append("title", item.reportField);
    });
    axios
      .post(REACT_API_URL + `/agent/patient/uploadPatientsImages/${props.patientId}`, body, config)
      .then((res) => {
        patientsList();
        handleCloseFileModal();
        Swal.fire({
          icon: "success",
          text: "File added Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        if (error.response) {
        }
        console.log(error);
      });
  };

  const patientsList = () => {
    const formData = {
      patientId,
      token: loginData?.token,
    };
    dispatch(getPatientDetails(formData));
  };

  useEffect(() => {
    if (patientDetailsData?.patient) {
      setPatientDetails(patientDetailsData?.patient);
      setPatientImagesData([...patientDetailsData?.patient?.imagesData].reverse());
    }
  }, [patientDetailsData]);

  useEffect(() => {
    if (getPatientDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientDetailsFailure]);

  const fileDetailsColumns = [
    {
      field: "url,",
      headerName: "Image",
      width: 150,
      editable: true,
      renderCell: (data) => (
        <div
          className="zoom"
          onClick={() => {
            window.open(data.row.imageUrl, "_blank");
          }}
        >
          <img style={{ height: "3em", width: "3em" }} src={data.row.imageUrl} />
        </div>
      ),
    },
    {
      field: "reportTitle",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return data.row.reportTitle;
      },
    },
    {
      field: "url",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data, i) => {
        return data.row.imageUrl.replace(/^.*[//]/, "");
      },
    },

    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return moment(data.row.createdDate).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <>
            <MDButton
              variant="gradient"
              onClick={() => {
                window.open(params.row.imageUrl, "_blank");
              }}
              color="info"
            >
              Open
            </MDButton>
            &nbsp;
            <MDButton variant="gradient" onClick={() => handleRemoveFiles(params)} color="info">
              Remove
            </MDButton>
          </>
        );
      },
    },
  ];
  const handleRemoveFiles = (data) => {
    const formData = {
      patientId: props.patientId,
      imageUrl: data.row.imageUrl,
    };
    dispatch(removeFileData(formData));
  };

  useEffect(() => {
    if (removeFileDataSuccess) {
      patientsList();
      Swal.fire({
        icon: "success",
        text: "File Removed Successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeFileDataSuccess]);

  useEffect(() => {
    if (removeFileDataFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [removeFileDataFailure]);

  const handleAddClick = (event) => {
    event.preventDefault();
    setReportData([...reportData, { reportField: "", file: "" }]);
  };

  const handleUpdateChange = (e, index) => {
    const list = [...reportData];
    const name = e.target.name;
    name === "reportField"
      ? (list[index][name] = e.target.value)
      : (list[index][name] = e.target.files[0]);
    setReportData(list);
  };

  const handleRemoveClick = (e, i) => {
    e.preventDefault();
    const list = [...reportData];
    list.splice(i, 1);
    setReportData(list);
  };
  return (
    <>
      <MDBox mr={1} style={{ float: "right" }}>
        <MDButton variant="gradient" onClick={() => setOpenFileModal(true)} color="info">
          <AddIcon /> Add Reports
        </MDButton>
      </MDBox>
      <br />
      <br />
      <br />
      <div style={{ height: "570px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => "auto"}
          rows={patientImagesData ? patientImagesData : []}
          columns={fileDetailsColumns}
          pageSize={5}
          getRowId={(row) => row.imageUrl}
        />
      </div>
      <Modal
        open={openFileModal}
        onClose={handleCloseFileModal}
        style={{ marginTop: "5%", marginLeft: "10%" }}
      >
        <>
          <MDBox mt={6} mb={3}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={7}>
                <Card id="delete-account">
                  <MDBox pt={1} pb={2} px={2}>
                    <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                      <MDBox
                        component="li"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        borderRadius="lg"
                        p={3}
                        mt={2}
                      >
                        <MDBox width="100%" display="flex" flexDirection="column">
                          <MDBox width="100%" display="flex" flexDirection="column">
                            <MDBox mb={1} lineHeight={1}>
                              <center>
                                <h4>Add scanO Report</h4>
                              </center>
                              <hr />
                              <MDButton
                                variant="gradient"
                                onClick={handleAddClick}
                                style={{ float: "right" }}
                                color="info"
                              >
                                +
                              </MDButton>
                              <br />
                              <Grid container spacing={2}>
                                {reportData.length > 0
                                  ? reportData.map((reportData, i) => {
                                      return (
                                        <>
                                          <Grid item xs={12} sm={5}>
                                            <FormControl
                                              variant="standard"
                                              fullWidth
                                              sx={{ mb: 2 }}
                                            >
                                              <InputLabel id="demo-simple-select-standard-label">
                                                Title
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                name="reportField"
                                                // value={reportData.reportField}
                                                onChange={(e) => handleUpdateChange(e, i)}
                                              >
                                                {ddReportArray.map((data, index) => {
                                                  return (
                                                    <MenuItem key={index} value={data}>
                                                      {data}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                          </Grid>
                                          <Grid item xs={12} sm={5}>
                                            <input
                                              name="file"
                                              style={{ width: "250px" }}
                                              accept="image/*"
                                              id="raised-button-file"
                                              type="file"
                                              onChange={(e) => handleUpdateChange(e, i)}
                                            />
                                          </Grid>
                                          {reportData.length !== 1 && (
                                            <Grid item xs={12} sm={2}>
                                              <MDButton
                                                variant="gradient"
                                                onClick={(e) => handleRemoveClick(e, i)}
                                                color="error"
                                              >
                                                X
                                              </MDButton>
                                            </Grid>
                                          )}
                                        </>
                                      );
                                    })
                                  : ""}
                                <br />
                                <br />
                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleCloseFileModal}
                                    color="info"
                                  >
                                    Cancel
                                  </MDButton>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleUploadImage}
                                    color="info"
                                  >
                                    Submit
                                  </MDButton>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      </Modal>
    </>
  );
}
export default FilesData;
