import { CircularProgress, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons, deleteCoupon, couponSelector } from "store/reducers/couponSlice";
import routes from "routes";

const couponsTable = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const usersColumns = [
    {
      field: "couponCode",
      headerName: "Coupon Code",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "discount",
      headerName: "Discount",
      align: "center",
      headerAlign: "center",
      width: 150,
    },

    {
      field: "isPublic",
      headerName: "IsPublic",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "minOrderValue",
      headerName: "MinOrder Value",
      align: "center",
      headerAlign: "center",
      width: 150,
    },
    {
      field: "Action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      width: 250,
      renderCell: (data) => {
        return (
          <>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() =>
                navigate("/add-edit-coupon", {
                  state: {
                    couponData: data.row,
                  },
                })
              }
            >
              Edit Coupon
            </MDButton>
            &nbsp;&nbsp;
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => deleteCouponHandler(data.row._id)}
            >
              Delete
            </MDButton>
          </>
        );
      },
    },
  ];
  const coupons = useSelector((state) => state?.coupon?.coupons);
  const deleteCouponSuccess = useSelector(couponSelector.deleteCouponSuccess);
  const deleteCouponFailure = useSelector(couponSelector.deleteCouponFailure);

  const deleteCouponHandler = (couponId) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCoupon(couponId));
        dispatch(getAllCoupons());
      }
    });
  };

  useEffect(() => {
    dispatch(getAllCoupons());
  }, [dispatch]);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    if (coupons?.coupondata) {
      setSubscriptionList(coupons?.coupondata);
    }
  }, [coupons]);

  useEffect(() => {
    if (deleteCouponSuccess) {
      Swal.fire({
        icon: "success",
        text: "Coupon deleted successfully",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }, [deleteCouponSuccess]);

  useEffect(() => {
    if (deleteCouponFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [deleteCouponFailure]);

  return (
    <MDBox pt={6} pb={3}>
      <br />
      <br />
      <Grid item xs={12}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Coupons list
            </MDTypography>
          </MDBox>
          <MDBox pt={3} py={3} px={2}>
            <div style={{ height: 630, width: "100%" }}>
              {isLoading ? (
                <MDBox style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
                  <CircularProgress color="inherit" />
                </MDBox>
              ) : (
                <DataGrid
                  rows={subscriptionList}
                  columns={usersColumns}
                  pageSize={10}
                  getRowId={(row) => row._id}
                />
              )}
            </div>
          </MDBox>
        </Card>
      </Grid>
    </MDBox>
  );
};

export default couponsTable;
