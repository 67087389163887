// patientSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const patientSlice = createSlice({
  name: "patients",
  initialState: {
    patients: [],
    isloading: false,
    getUsersSuccess: false,
    getUsersFailure: false,
    addNewPatientSuccess: false,
    addNewPatientFailure: false,
    patientExistError: false,
    sendNotificationUserSuccess: false,
    sendNotificationUserFailure: false,
    sendMessageSuccess: false,
    sendMessageFailure: false,
    updatePatientSuccess: false,
    updatePatientFailure: false,
    sendPaymentNotificationSuccess: false,
    sendPaymentNotificationFailure: false,
    assignDentalCoachSuccess: false,
    assignDentalCoachFailure: false,
    // Other initial state properties
  },

  reducers: {
    searchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    locationValue: (state, action) => {
      state.locationValue = action.payload;
    },
    getUsers: (state, action) => {
      state.isloading = false;
    },
    getUsersSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.getUsersSuccess = true;
    },
    getUsersFailure: (state) => {
      state.isloading = false;
      state.getUsersFailure = true;
    },
    addNewPatient: (state, action) => {
      state.isloading = false;
    },
    resetAddNewPatientSuccess: (state) => {
      state.addNewPatientSuccess = false;
      state.patientExistError = false;
    },
    addNewPatientSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.addNewPatientSuccess = true;
    },
    patientExistError: (state) => {
      state.isloading = false;
      state.patientExistError = true;
    },
    addNewPatientFailure: (state) => {
      state.isloading = false;
      state.addNewPatientFailure = true;
    },
    resetSendNotificationUser: (state, action) => {
      state.sendNotificationUserSuccess = false;
    },
    sendNotificationUser: (state, action) => {
      state.isloading = false;
    },
    sendNotificationUserSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.sendNotificationUserSuccess = true;
    },
    sendNotificationUserFailure: (state) => {
      state.isloading = false;
      state.sendNotificationUserFailure = true;
    },
    sendMessage: (state, action) => {
      state.isloading = false;
    },
    sendMessageSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.sendMessageSuccess = true;
    },
    sendMessageFailure: (state) => {
      state.isloading = false;
      state.sendMessageFailure = true;
    },
    updatePatient: (state, action) => {
      state.isloading = false;
    },
    resetUpdatePatientSuccess: (state) => {
      state.updatePatientSuccess = false;
    },
    updatePatientSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.updatePatientSuccess = true;
    },
    updatePatientFailure: (state) => {
      state.isloading = false;
      state.updatePatientFailure = true;
    },
    sendPaymentNotification: (state, action) => {
      state.isloading = false;
    },
    sendPaymentNotificationSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.sendPaymentNotificationSuccess = true;
    },
    sendPaymentNotificationFailure: (state) => {
      state.isloading = false;
      state.sendPaymentNotificationFailure = true;
    },
    assignDentalCoach: (state, action) => {
      state.isloading = false;
    },
    assignDentalCoachSuccess: (state, action) => {
      state.patients = action.payload;
      state.isloading = true;
      state.assignDentalCoachSuccess = true;
    },
    assignDentalCoachFailure: (state) => {
      state.isloading = false;
      state.assignDentalCoachFailure = true;
    },

    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.patient;

export const patientSelector = {
  getUsersSuccess: createSelector(selectKiosk, (kioskState) => kioskState.getUsersSuccess),
  getUsersFailure: createSelector(selectKiosk, (kioskState) => kioskState.getUsersFailure),
  addNewPatientSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addNewPatientSuccess
  ),
  addNewPatientFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.addNewPatientFailure
  ),
  patientExistError: createSelector(selectKiosk, (kioskState) => kioskState.patientExistError),
  sendNotificationUserSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendNotificationUserSuccess
  ),
  sendNotificationUserFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendNotificationUserFailure
  ),
  sendMessageSuccess: createSelector(selectKiosk, (kioskState) => kioskState.sendMessageSuccess),
  sendMessageFailure: createSelector(selectKiosk, (kioskState) => kioskState.sendMessageFailure),
  updatePatientSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updatePatientSuccess
  ),
  updatePatientFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updatePatientFailure
  ),
  sendPaymentNotificationSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendPaymentNotificationSuccess
  ),
  sendPaymentNotificationFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendPaymentNotificationFailure
  ),
  assignDentalCoachSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.assignDentalCoachSuccess
  ),
  assignDentalCoachFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.assignDentalCoachFailure
  ),

  // Add other selectors if needed
};

export const {
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  addNewPatient,
  addNewPatientSuccess,
  addNewPatientFailure,
  patientExistError,
  resetSendNotificationUser,
  sendNotificationUser,
  sendNotificationUserSuccess,
  sendNotificationUserFailure,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  updatePatient,
  updatePatientSuccess,
  updatePatientFailure,
  sendPaymentNotification,
  sendPaymentNotificationSuccess,
  sendPaymentNotificationFailure,
  assignDentalCoach,
  assignDentalCoachSuccess,
  assignDentalCoachFailure,
  resetAddNewPatientSuccess,
  resetUpdatePatientSuccess,
  searchValue,
  locationValue,
} = patientSlice.actions;
export default patientSlice.reducer;
