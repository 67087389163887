import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import KioskTable from "./components/kioskTable";
import { useNavigate } from "react-router-dom";

const Kiosk = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <br />
      <br />
      <MDButton variant="gradient" color="info" onClick={() => navigate("/add-edit-kiosk")}>
        + Add New Kiosk
      </MDButton>
      <KioskTable />
    </DashboardLayout>
  );
};

export default Kiosk;
