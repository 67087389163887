/* eslint-disable */

import axios from "axios";
import { cachedAxiosGet } from "./apiCacheService";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

//patient
const addCoins = (formData) => {
  return axios.post(constant.API_URL + "/agent/patient/addCoins", formData, {
    headers,
  });
};

const removeCoins = (formData) => {
  return axios.post(constant.API_URL + "/agent/patient/removeCoins", formData, {
    headers,
  });
};

//main account
const addCoinsToMainAccount = (formData) => {
  return axios.post(constant.API_URL + "/agent/account/addCoinsToMainAccount", formData, {
    headers,
  });
};
const getDentaldostAccount = () => {
  return cachedAxiosGet(constant.API_URL + "/agent/getDentaldostAccount", {
    headers,
  });
};

const ddAccountService = {
  addCoinsToMainAccount,
  addCoins,
  getDentaldostAccount,
  removeCoins,
};

export default ddAccountService;
