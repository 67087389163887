/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DoctorTable from "./components/doctorTable";
function Doctor() {
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />
        <DoctorTable />
      </DashboardLayout>
    </>
  );
}
export default Doctor;
