// doctorSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const doctorSlice = createSlice({
  name: "doctors",
  initialState: {
    doctors: [],
    isloading: false,
    getDoctorsListSuccess: false,
    getDoctorsListFailure: false,
    getDoctorPatientAppointmentSuccess: false,
    getDoctorPatientAppointmentFailure: false,
    searchDoctorsSuccess: false,
    searchDoctorsFailure: false,
    AddNewDoctorSuccess: false,
    AddNewDoctorFailure: false,
    getDoctorsDetailsSuccess: false,
    getDoctorsDetailsFailure: false,
    updateDoctorDetailsSuccess: false,
    updateDoctorDetailsFailure: false,
    getDoctorAppointmentsSuccess: false,
    getDoctorAppointmentsFailure: false,
    getRateCardListSuccess: false,
    getRateCardListFailure: false,
    getFollowUpsSuccess: false,
    getFollowUpsFailure: false,
    getFollowUpsWeeklySuccess: false,
    getFollowUpsWeeklyFailure: false,
    assignKioskSuccess: false,
    assignKioskFailure: false,
    resetAssignKioskSuccess: false,
    // Other initial state properties
  },
  reducers: {
    getDoctorsList: (state, action) => {
      state.isloading = false;
    },
    getDoctorsListSuccess: (state, action) => {
      state.doctors = action.payload;
      state.isloading = true;
      state.getDoctorsListSuccess = true;
    },
    getDoctorsListFailure: (state) => {
      state.isloading = false;
      state.getDoctorsListFailure = true;
    },
    getDoctorPatientAppointment: (state, action) => {
      state.isloading = false;
    },
    getDoctorPatientAppointmentSuccess: (state, action) => {
      state.doctorsPatientAppointment = action.payload;
      state.isloading = true;
      state.getDoctorPatientAppointmentSuccess = true;
    },
    getDoctorPatientAppointmentFailure: (state) => {
      state.isloading = false;
      state.getDoctorPatientAppointmentFailure = true;
    },
    searchDoctors: (state, action) => {
      state.isloading = false;
    },
    searchDoctorsSuccess: (state, action) => {
      state.doctorsData = action.payload;
      state.isloading = true;
      state.searchDoctorsSuccess = true;
    },
    searchDoctorsFailure: (state) => {
      state.isloading = false;
      state.searchDoctorsFailure = true;
    },
    updateDoctorDetails: (state, action) => {
      state.isloading = false;
    },
    resetUpdateDoctorDetailsSuccess: (state) => {
      state.updateDoctorDetailsSuccess = false;
    },
    updateDoctorDetailsSuccess: (state, action) => {
      state.doctors = action.payload;
      state.isloading = true;
      state.updateDoctorDetailsSuccess = true;
    },
    updateDoctorDetailsFailure: (state) => {
      state.isloading = false;
      state.updateDoctorDetailsFailure = true;
    },
    getDoctorAppointments: (state, action) => {
      state.isloading = false;
    },
    getDoctorAppointmentsSuccess: (state, action) => {
      state.appointmentData = action.payload;
      state.isloading = true;
      state.getDoctorAppointmentsSuccess = true;
    },
    getDoctorAppointmentsFailure: (state) => {
      state.isloading = false;
      state.getDoctorAppointmentsFailure = true;
    },
    getRateCardList: (state, action) => {
      state.isloading = false;
    },
    getRateCardListSuccess: (state, action) => {
      state.rateCardData = action.payload;
      state.isloading = true;
      state.getRateCardListSuccess = true;
    },
    getRateCardListFailure: (state) => {
      state.isloading = false;
      state.getRateCardListFailure = true;
    },
    getFollowUps: (state, action) => {
      state.isloading = false;
    },
    getFollowUpsSuccess: (state, action) => {
      state.followUps = action.payload;
      state.isloading = true;
      state.getFollowUpsSuccess = true;
    },
    getFollowUpsFailure: (state) => {
      state.isloading = false;
      state.getFollowUpsFailure = true;
    },
    getFollowUpsWeekly: (state, action) => {
      state.isloading = false;
    },
    getFollowUpsWeeklySuccess: (state, action) => {
      state.followUpsWeekly = action.payload;
      state.isloading = true;
      state.getFollowUpsWeeklySuccess = true;
    },
    getFollowUpsWeeklyFailure: (state) => {
      state.isloading = false;
      state.getFollowUpsWeeklyFailure = true;
    },
    assignKiosk: (state, action) => {
      state.isloading = false;
    },
    resetAssignKioskSuccess: (state, action) => {
      state.assignKioskSuccess = false;
    },
    assignKioskSuccess: (state, action) => {
      state.assignKiosk = action.payload;
      state.isloading = true;
      state.assignKioskSuccess = true;
    },
    assignKioskFailure: (state) => {
      state.isloading = false;
      state.assignKioskFailure = true;
    },

    removeKiosk: (state, action) => {
      state.isloading = false;
    },
    resetRemoveKioskSuccess: (state, action) => {
      state.removeKioskSuccess = false;
    },
    removeKioskSuccess: (state, action) => {
      state.removeKiosk = action.payload;
      state.isloading = true;
      state.removeKioskSuccess = true;
    },
    removeKioskFailure: (state) => {
      state.isloading = false;
      state.removeKioskFailure = true;
    },
    getDoctorsDetails: (state, action) => {
      state.isloading = false;
    },
    getDoctorsDetailsSuccess: (state, action) => {
      state.doctors = action.payload;
      state.isloading = true;
      state.getDoctorsDetailsSuccess = true;
    },
    getDoctorsDetailsFailure: (state) => {
      state.isloading = false;
      state.getDoctorsDetailsFailure = true;
    },
    AddNewDoctor: (state, action) => {
      state.isloading = false;
    },
    resetAddNewDoctorSuccess: (state) => {
      state.AddNewDoctorSuccess = false;
    },
    AddNewDoctorSuccess: (state, action) => {
      state.doctors = action.payload;
      state.isloading = true;
      state.AddNewDoctorSuccess = true;
    },
    AddNewDoctorFailure: (state) => {
      state.isloading = false;
      state.AddNewDoctorFailure = true;
    },
    // Other reducers if needed
  },
});

const selectDoctor = (state) => state.doctor;

export const doctorSelector = {
  getDoctorsListSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorsListSuccess
  ),
  getDoctorsListFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorsListFailure
  ),
  assignKioskSuccess: createSelector(selectDoctor, (doctorState) => doctorState.assignKioskSuccess),
  assignKioskFailure: createSelector(selectDoctor, (doctorState) => doctorState.assignKioskFailure),

  removeKioskSuccess: createSelector(selectDoctor, (doctorState) => doctorState.removeKioskSuccess),
  removeKioskFailure: createSelector(selectDoctor, (doctorState) => doctorState.removeKioskFailure),
  getDoctorPatientAppointmentSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorPatientAppointmentSuccess
  ),
  getDoctorPatientAppointmentFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorPatientAppointmentFailure
  ),
  searchDoctorsSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.searchDoctorsSuccess
  ),
  searchDoctorsFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.searchDoctorsFailure
  ),
  AddNewDoctorSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.AddNewDoctorSuccess
  ),
  AddNewDoctorFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.AddNewDoctorFailure
  ),
  getDoctorsDetailsSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorsDetailsSuccess
  ),
  getDoctorsDetailsFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorsDetailsFailure
  ),
  updateDoctorDetailsSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.updateDoctorDetailsSuccess
  ),
  updateDoctorDetailsFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.updateDoctorDetailsFailure
  ),
  getDoctorAppointmentsSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorAppointmentsSuccess
  ),
  getDoctorAppointmentsFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getDoctorAppointmentsFailure
  ),
  getRateCardListSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getRateCardListSuccess
  ),
  getRateCardListFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getRateCardListFailure
  ),
  getFollowUpsSuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getFollowUpsSuccess
  ),
  getFollowUpsFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getFollowUpsFailure
  ),
  getFollowUpsWeeklySuccess: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getFollowUpsWeeklySuccess
  ),
  getFollowUpsWeeklyFailure: createSelector(
    selectDoctor,
    (doctorState) => doctorState.getFollowUpsWeeklyFailure
  ),

  // Add other selectors if needed
};

export const {
  getDoctorsList,
  getDoctorsListSuccess,
  getDoctorsListFailure,
  getDoctorPatientAppointment,
  getDoctorPatientAppointmentSuccess,
  getDoctorPatientAppointmentFailure,
  searchDoctors,
  searchDoctorsSuccess,
  searchDoctorsFailure,
  AddNewDoctor,
  AddNewDoctorSuccess,
  AddNewDoctorFailure,
  getDoctorsDetails,
  getDoctorsDetailsSuccess,
  getDoctorsDetailsFailure,
  updateDoctorDetails,
  updateDoctorDetailsSuccess,
  updateDoctorDetailsFailure,
  getDoctorAppointments,
  getDoctorAppointmentsSuccess,
  getDoctorAppointmentsFailure,
  getRateCardList,
  getRateCardListSuccess,
  getRateCardListFailure,
  getFollowUps,
  getFollowUpsSuccess,
  getFollowUpsFailure,
  getFollowUpsWeekly,
  getFollowUpsWeeklySuccess,
  getFollowUpsWeeklyFailure,
  resetAssignKioskSuccess,
  assignKioskSuccess,
  assignKioskFailure,
  assignKiosk,
  resetRemoveKioskSuccess,
  removeKioskSuccess,
  removeKioskFailure,
  removeKiosk,
  resetUpdateDoctorDetailsSuccess,
  resetAddNewDoctorSuccess,
} = doctorSlice.actions;
export default doctorSlice.reducer;
