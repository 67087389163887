/* eslint-disable*/
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabPane from "react-bootstrap/Tab";
import Tests from "./tests";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Invoice from "./invoice";
import Appointment from "./appointment";
import Habits from "./habits";
import FollowUp from "./followUp";
import FollowUpReminder from "./followupReminder";
import CoinsInfo from "./coinsInfo";
import NotificationHistory from "./notificationHistory";
import Survey from "./survey";
import DentalInsurance from "./dentalInsurance";
import FilesData from "./filesData";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import DiagnosticsFiles from "./diagnosticsFiles";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AgoraRTC from "agora-rtc-sdk-ng";
import useAgora from "../../videoConsultation/hooks/useAgora.tsx";
import Modal from "@mui/material/Modal";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Grid,
  Input,
  Button,
} from "@mui/material";
const client = AgoraRTC.createClient({ codec: "h264", mode: "rtc" });
import Referrals from "./referrals";
import Constants from "utils/constants";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionPlans,
  addSubscriptionByAgent,
  subscriptionSelector,
} from "store/reducers/subscriptionSlice";
import {
  getPatientTests,
  getPatientDetails,
  patientDetailsSelector,
  defaultTabKey,
} from "store/reducers/patientdetailsSlice";
import { assignDentalCoach, patientSelector, locationValue } from "store/reducers/patientSlice";
import {
  generateVideoCall,
  videoConsultationSelector,
} from "store/reducers/videoConsultationSlice";
import { getAgentsData, agentSelector } from "store/reducers/agentSlice";

function PatientDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const now = moment().format();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [patientDetails, setPatientDetails] = useState({});
  const [patientTests, setPatientTests] = useState();
  const [patientFileData, setPatientFileData] = useState();
  const [patientTransaction, setPatientTransaction] = useState();
  const { patientId } = useParams(now);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const getRoleData =
    loginData &&
    loginData.roleData &&
    loginData.roleData.permissions.find((data) => data.name.toLowerCase() === "patient");

  const [isLoading, setIsLoading] = useState(true);

  const [patientHabits, setPatientHabits] = useState();
  const [patientSurvey, setPatientSurvey] = useState();
  const [patientInsurance, setPatientInsurance] = useState();
  const [appid] = useState("913c7cd202b04497b57309ea258f9cac");
  const { localAudioTrack, localVideoTrack, leave, join, joinState, remoteUsers } =
    useAgora(client);
  const [openAssignDentalCoachModal, setOpenAssignDentalCoachModal] = useState(false);
  const handleCloseAssignDentalCoachModal = () => setOpenAssignDentalCoachModal(false);
  const [openAddSubscriptionModal, setOpenAddSubscriptionModal] = useState(false);
  const handleCloseAddSubscriptionModal = () => setOpenAddSubscriptionModal(false);
  const [agentsList, setAgentsList] = useState([]);
  const [agentId, setAgentId] = useState("");
  const [subscriptionPlanList, setSubscriptionPlanList] = useState([]);
  const [planId, setPlanId] = useState();
  const patientDetailsData = useSelector((state) => state?.patientDetails?.patientDetails);
  const getPatientDetailsSuccess = useSelector(patientDetailsSelector.getPatientDetailsSuccess);
  const getPatientDetailsFailure = useSelector(patientDetailsSelector.getPatientDetailsFailure);
  const agentsData = useSelector((state) => state?.agent?.agentsData);
  const getAgentsDataSuccess = useSelector(agentSelector.getAgentsDataSuccess);
  const getAgentsDataFailure = useSelector(agentSelector.getAgentsDataFailure);
  const subscriptions = useSelector((state) => state?.subscription?.subscriptionPlans);
  const getSubscriptionPlansSuccess = useSelector(subscriptionSelector.getSubscriptionPlansSuccess);
  const getSubscriptionPlansFailure = useSelector(subscriptionSelector.getSubscriptionPlansFailure);
  const addSubscriptionByAgentSuccess = useSelector(
    subscriptionSelector.addSubscriptionByAgentSuccess
  );
  const addSubscriptionByAgentFailure = useSelector(
    subscriptionSelector.addSubscriptionByAgentFailure
  );
  const patientTestsData = useSelector((state) => state?.patientDetails?.patientTests);
  const getPatientTestsSuccess = useSelector(patientDetailsSelector.getPatientTestsSuccess);
  const getPatientTestsFailure = useSelector(patientDetailsSelector.getPatientTestsFailure);
  const videoCallData = useSelector((state) => state?.videoConsultation?.videoConsultations);
  const generateVideoCallSuccess = useSelector(videoConsultationSelector.generateVideoCallSuccess);
  const generateVideoCallFailure = useSelector(videoConsultationSelector.generateVideoCallFailure);
  const assignDentalCoachSuccess = useSelector(patientSelector.assignDentalCoachSuccess);
  const assignDentalCoachFailure = useSelector(patientSelector.assignDentalCoachFailure);
  const locationData = useSelector((state) => state?.patient?.locationValue);
  const defaultKeyValue = useSelector((state) =>
    state?.agent?.defaultTabKey ? state?.agent?.defaultTabKey : "tests"
  );

  useEffect(() => {
    patientsList();
    patientsTests();
    getAgentList();
    getAllSubscriptionPlans();
  }, []);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const patientsList = () => {
    const formData = {
      patientId,
      token: loginData?.token,
    };
    dispatch(getPatientDetails(formData));
  };

  useEffect(() => {
    if (patientDetailsData?.patient) {
      setPatientDetails(patientDetailsData?.patient);
      setPatientFileData([...patientDetailsData?.patient?.imagesData].reverse());
      setPatientHabits([...patientDetailsData?.patient?.remainderList].reverse());
      setPatientSurvey([...patientDetailsData?.patient?.survey].reverse());
      setPatientInsurance([...patientDetailsData?.patient?.dental_Insurance].reverse());

      setPatientTransaction(patientDetailsData?.patient?.accountData);
      setIsLoading(false);
    }
  }, [patientDetailsData]);

  useEffect(() => {
    if (getPatientDetailsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientDetailsFailure]);

  const getAllSubscriptionPlans = () => {
    dispatch(getSubscriptionPlans(loginData && loginData.token ? loginData.token : ""));
  };

  useEffect(() => {
    if (subscriptions) {
      setSubscriptionPlanList(subscriptions);
      setIsLoading(false);
    }
  }, [subscriptions]);

  useEffect(() => {
    if (getSubscriptionPlansFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getSubscriptionPlansFailure]);

  const handleAddSubscription = () => {
    const formData = {
      patientId,
      packId: planId,
      paymentMethod: "cash",
    };
    dispatch(addSubscriptionByAgent(formData, loginData && loginData.token ? loginData.token : ""));
  };

  useEffect(() => {
    if (addSubscriptionByAgentSuccess) {
      handleCloseAddSubscriptionModal();
      Swal.fire({
        icon: "success",
        text: "Subscription added successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addSubscriptionByAgentSuccess]);

  useEffect(() => {
    if (addSubscriptionByAgentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addSubscriptionByAgentFailure]);

  const patientsTests = () => {
    dispatch(getPatientTests(patientId, loginData && loginData.token ? loginData.token : ""));
  };

  useEffect(() => {
    if (patientTestsData?.tests) {
      setPatientTests([...patientTestsData?.tests].reverse());
      setIsLoading(false);
    }
  }, [patientTestsData]);

  useEffect(() => {
    if (getPatientTestsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getPatientTestsFailure]);

  const handleEditPatient = (patientsData) => {
    navigate("/add-edit-patient", { state: { data: patientsData, patientId: patientId } });
  };

  const toTitleCase = (str) => {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (videoCallData?.token) {
      navigate("/videoCall", {
        state: {
          appid,
          channel: patientId + loginData.id,
          token: videoCallData?.token,
          joinState,
        },
      });
    }
  }, [videoCallData]);

  const getAgentList = () => {
    dispatch(getAgentsData());
  };

  useEffect(() => {
    if (agentsData?.agents) {
      setAgentsList(agentsData?.agents);
    }
  }, [agentsData]);

  useEffect(() => {
    if (getAgentsDataFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAgentsDataFailure]);

  const handleClickStartVideoCall = (callType) => {
    dispatch(locationValue("/patientdetails/" + patientId));
    dispatch(generateVideoCall(patientId, callType, loginData.token));
  };

  useEffect(() => {
    if (generateVideoCallFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [generateVideoCallFailure]);

  const handleAssignDentalCoach = () => {
    if (!agentId) {
      Swal.fire({
        icon: "error",
        text: "Please select a Dental Coach.",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }
    const formData = {
      userId: patientId,
      agentId,
    };
    dispatch(assignDentalCoach(formData));
  };

  useEffect(() => {
    if (assignDentalCoachSuccess) {
      // setAgentsList(response.data && response.data.agents ? response.data.agents : []);
      patientsList();
      handleCloseAssignDentalCoachModal();
      Swal.fire({
        icon: "success",
        text: "Coach assign successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [assignDentalCoachSuccess]);

  useEffect(() => {
    if (assignDentalCoachFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [assignDentalCoachFailure]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <br />

        <Card id="delete-account">
          <MDBox pt={3} px={2}>
            <MDTypography variant="h5" fontWeight="medium">
              Patient Details
            </MDTypography>
          </MDBox>
          <List
            sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton onClick={handleClick}>
              <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                Patient Name : {patientDetails.patientName}
              </MDTypography>
              <ListItemText />
              {open ? (
                <>
                  <small>less details</small> <ExpandLess></ExpandLess>
                </>
              ) : (
                <>
                  <small>more details</small>
                  <ExpandMore></ExpandMore>
                </>
              )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <MDBox mr={1} style={{ float: "right" }}>
                {(getRoleData.operations.includes("edit") ||
                  getRoleData.operations.includes("Edit")) &&
                loginData?.roleData?.roleName !== "partner" ? (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => handleEditPatient(patientDetails)}
                  >
                    <Icon>edit</Icon>&nbsp;edit patient
                  </MDButton>
                ) : (
                  ""
                )}
              </MDBox>
              <MDBox mr={1} style={{ float: "right" }}>
                {(loginData?.roleData?.roleName).toLowerCase() === "admin" ? (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setOpenAddSubscriptionModal(true)}
                  >
                    +&nbsp;Add Subscription
                  </MDButton>
                ) : (
                  ""
                )}
              </MDBox>
              <MDBox mr={1} style={{ float: "right" }}>
                {(getRoleData.operations.includes("edit") ||
                  getRoleData.operations.includes("Edit")) &&
                loginData?.roleData?.roleName !== "partner" ? (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => setOpenAssignDentalCoachModal(true)}
                  >
                    <Icon>edit</Icon>&nbsp;Assign Dental-Coach
                  </MDButton>
                ) : (
                  ""
                )}
              </MDBox>
              <br />
              <List component="div" disablePadding>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        {isLoading ? (
                          <MDBox style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress color="inherit" />
                          </MDBox>
                        ) : (
                          <>
                            <MDBox mb={1}>
                              <Table responsive key={patientDetails.mobile}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Mobile Number :&nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.countryCode ? (
                                            <>
                                              ({patientDetails.countryCode}) {patientDetails.mobile}
                                            </>
                                          ) : (
                                            <>{patientDetails.mobile}</>
                                          )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Email : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.email}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Occupation : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.occupation}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Gender : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.gender}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Date Of Birth : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.dob}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Age : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.age}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Height : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.height}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Weight : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.weight}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Country : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {toTitleCase(patientDetails.country)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        City : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.city}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Landmark 1 : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.landmark1?.charAt(0).toUpperCase() +
                                            patientDetails.landmark1?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Landmark 2 : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.landmark2?.charAt(0).toUpperCase() +
                                            patientDetails.landmark2?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Pin Code : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.pin}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Marital Status : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.maritalStatus?.charAt(0).toUpperCase() +
                                            patientDetails.maritalStatus?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Pregnancy : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.pregnancy?.charAt(0).toUpperCase() +
                                            patientDetails.pregnancy?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <br />
                                  <div>
                                    <b>Habits:-</b>
                                  </div>
                                  <div>Substance Use :</div>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Tobacco : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].substanceUse &&
                                            patientDetails.newHabits[0].substanceUse.tobacco
                                              ?.charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].substanceUse.tobacco?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Smoking Notes : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].substanceUse &&
                                            patientDetails.newHabits[0].substanceUse.smokingNotes
                                              ?.charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].substanceUse.smokingNotes?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Alcohol : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].substanceUse &&
                                            patientDetails.newHabits[0].substanceUse.alcohol
                                              ?.charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].substanceUse.alcohol?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <div>Life Style Habits :</div>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Beverages : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].lifestyleHabits &&
                                            patientDetails.newHabits[0].lifestyleHabits.beverages
                                              ?.charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].lifestyleHabits.beverages?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Deleterious Habit : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].lifestyleHabits &&
                                            patientDetails.newHabits[0].lifestyleHabits.deleteriousHabits
                                              ?.charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].lifestyleHabits.deleteriousHabits?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <div>Dental Habits :</div>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Brushing : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].dentalHabits &&
                                            patientDetails.newHabits[0].dentalHabits.brushing
                                              .charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].dentalHabits.brushing?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Other Routines : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails &&
                                            patientDetails.newHabits &&
                                            patientDetails.newHabits[0] &&
                                            patientDetails.newHabits[0].dentalHabits &&
                                            patientDetails.newHabits[0].dentalHabits.otherRoutines
                                              .charAt(0)
                                              .toUpperCase() +
                                              patientDetails.newHabits[0].dentalHabits.otherRoutines?.slice(
                                                1
                                              )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Dental History : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          <ul>
                                            {patientDetails && patientDetails.dentalHistory
                                              ? patientDetails.dentalHistory.map((data, i) => {
                                                  return <li key={i}>{data}</li>;
                                                })
                                              : ""}
                                          </ul>
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Dental History Notes : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.dentalHistoryNote
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            patientDetails.dentalHistoryNote?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Medical History : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          <ul>
                                            {patientDetails
                                              ? patientDetails.medicalHistory?.map((data, i) => {
                                                  return <li key={i}>{data}</li>;
                                                })
                                              : ""}
                                          </ul>
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Medical History Notes : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.medicalHistoryNote
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            patientDetails.medicalHistoryNote?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Current Medications : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          <ul>
                                            {patientDetails
                                              ? patientDetails.newMedications?.map((data, i) => {
                                                  return <li key={i}>{data}</li>;
                                                })
                                              : ""}
                                          </ul>
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Allergies : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          <ul>
                                            {patientDetails
                                              ? patientDetails.newAllergies?.map((data, i) => {
                                                  return <li key={i}>{data}</li>;
                                                })
                                              : ""}
                                          </ul>
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Oral Type : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails ? patientDetails.oralType : ""}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Ock Comments : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails ? patientDetails.ockComments : "N/A"}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Source : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.source === "other"
                                            ? patientDetails.otherSource &&
                                              patientDetails.otherSource?.charAt(0).toUpperCase() +
                                                patientDetails.otherSource?.slice(1)
                                            : patientDetails.source &&
                                              patientDetails.source?.charAt(0).toUpperCase() +
                                                patientDetails.source?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Lead Status : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.leadStatus?.charAt(0).toUpperCase() +
                                            patientDetails.leadStatus?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Communication Mode : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.modeOfCommunication
                                            ?.charAt(0)
                                            .toUpperCase() +
                                            patientDetails.modeOfCommunication?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Internal Dentist : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.agent}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Active Checklist : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.hasActiveChecklist ? "Yes" : "No"}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Chief Complaint : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.chiefComplaint?.charAt(0).toUpperCase() +
                                            patientDetails.chiefComplaint?.slice(1)}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Subscribed Plan : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {toTitleCase(
                                            patientDetails &&
                                              patientDetails.subscription &&
                                              patientDetails.subscription.planType
                                          )}
                                          &nbsp;&nbsp; (
                                          {patientDetails &&
                                          patientDetails.subscription &&
                                          patientDetails.subscription.newPrice
                                            ? patientDetails.subscription.newPrice
                                            : "0"}
                                          /- )
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Assigned Dental Coach : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {toTitleCase(
                                            patientDetails &&
                                              patientDetails.subscription &&
                                              patientDetails.subscription.dentalCoach &&
                                              patientDetails.subscription.dentalCoach.name
                                          )}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Subscription Date : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {moment(
                                            patientDetails &&
                                              patientDetails.subscription &&
                                              patientDetails.subscription.startDate &&
                                              patientDetails.subscription.startDate
                                          ).format("LL")}{" "}
                                          -{" "}
                                          {moment(
                                            patientDetails &&
                                              patientDetails.subscription &&
                                              patientDetails.subscription.endDate &&
                                              patientDetails.subscription.endDate
                                          ).format("LL")}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                  </tr>
                                </tbody>
                              </Table>
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Notes : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails.notes}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color="text"
                                      >
                                        Band Type : &nbsp;&nbsp;
                                        <MDTypography variant="button" fontWeight="medium">
                                          {patientDetails?.bandType}
                                        </MDTypography>
                                      </MDTypography>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              &nbsp;
                            </MDBox>
                          </>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </List>
            </Collapse>
          </List>
        </Card>
        <br />

        <Card id="delete-account">
          <div>
            <MDBox pt={3} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                More Details &nbsp;
                {loginData?.roleData?.roleName !== "reviewer" &&
                loginData?.roleData?.roleName !== "partner" ? (
                  <div>
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => handleClickStartVideoCall(1)}
                    >
                      Start Video Call
                    </MDButton>
                    &nbsp;&nbsp;
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => handleClickStartVideoCall(0)}
                    >
                      Start Audio Call
                    </MDButton>
                  </div>
                ) : (
                  ""
                )}
                &nbsp;&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
          </div>

          <MDBox pt={1} pb={2} px={2}>
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
              <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? "transparent" : "grey-200"}
                borderRadius="lg"
                p={3}
                mt={2}
              >
                <MDBox width="100%" display="flex" flexDirection="column">
                  <MDBox mb={1} lineHeight={1}>
                    <div style={{ display: "block", width: "100%", padding: 30 }}>
                      <Tabs
                        defaultActiveKey={defaultKeyValue}
                        onSelect={(selectedTab) => {
                          console.log("Selected tab:", selectedTab);
                          dispatch(defaultTabKey(selectedTab));
                          localStorage.setItem("defaultTabKey", JSON.stringify(selectedTab));
                        }}
                      >
                        <Tab eventKey="tests" title="Tests">
                          <br />
                          <Tests
                            data={patientDetails}
                            testData={patientTests}
                            patientId={patientId}
                            // isAnnotation={location?.state?.isAnnotation}
                          />
                        </Tab>
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="invoice" title="Invoice">
                            <br />
                            <Invoice patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}{" "}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="followup" title="FollowUp">
                            <br />
                            <FollowUp patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="appointment" title="Appointment">
                            <br />
                            <Appointment patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="followupReminder" title="Reminder">
                            <br />
                            <FollowUpReminder patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="filesData" title="DentalDost Report">
                            <br />
                            <FilesData
                              data={patientDetails}
                              fileData={patientFileData}
                              patientId={patientId}
                            />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="diagnostics" title="Diagnostics Files">
                            <br />
                            <DiagnosticsFiles data={patientDetails} patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="coinsInfo" title="Coins Info">
                            <br />
                            <CoinsInfo
                              data={patientDetails ? patientDetails : []}
                              transactionData={patientTransaction ? patientTransaction : []}
                              patientId={patientId}
                            />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="notificationHistory" title="Notifications">
                            <br />

                            <NotificationHistory data={patientDetails} patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                        {loginData?.roleData?.roleName !== "reviewer" &&
                        loginData?.roleData?.roleName !== "partner" ? (
                          <Tab eventKey="referrals" title="Referrals">
                            <br />
                            <Referrals patientId={patientId} />
                          </Tab>
                        ) : (
                          ""
                        )}
                      </Tabs>
                    </div>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
          <Modal
            open={openAddSubscriptionModal}
            onClose={handleCloseAddSubscriptionModal}
            style={{ marginTop: "5%", marginLeft: "10%" }}
          >
            <>
              <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Card id="delete-account">
                      <MDBox pt={1} pb={2} px={2}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          <MDBox
                            component="li"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            borderRadius="lg"
                            p={3}
                            mt={2}
                          >
                            <MDBox width="100%" display="flex" flexDirection="column">
                              <MDBox mb={1} lineHeight={1}>
                                <center>
                                  <h4>Add Subscription</h4>
                                </center>
                                <br />
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Select Plan
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        onChange={(value) => {
                                          setPlanId(value.target.value);
                                        }}
                                      >
                                        {subscriptionPlanList?.map((data) => {
                                          return (
                                            <MenuItem value={data._id}>
                                              {" "}
                                              {data.planType} ({data.price})
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <MDButton
                                      fullWidth
                                      variant="gradient"
                                      onClick={handleCloseAddSubscriptionModal}
                                      color="info"
                                    >
                                      Cancel
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <MDButton
                                      fullWidth
                                      variant="gradient"
                                      onClick={handleAddSubscription}
                                      color="info"
                                    >
                                      Add
                                    </MDButton>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          </Modal>

          <Modal
            open={openAssignDentalCoachModal}
            onClose={handleCloseAssignDentalCoachModal}
            style={{ marginTop: "5%", marginLeft: "10%" }}
          >
            <>
              <MDBox mt={6} mb={3}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12} lg={7}>
                    <Card id="delete-account">
                      <MDBox pt={1} pb={2} px={2}>
                        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                          <MDBox
                            component="li"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            borderRadius="lg"
                            p={3}
                            mt={2}
                          >
                            <MDBox width="100%" display="flex" flexDirection="column">
                              <MDBox mb={1} lineHeight={1}>
                                <center>
                                  <h4>Assign Dental Coach</h4>
                                </center>
                                <br />
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                    <FormControl variant="standard" fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Dental Coach
                                      </InputLabel>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={agentId}
                                        onChange={(event) => setAgentId(event.target.value)}
                                        required // Add the required attribute to make the field mandatory
                                      >
                                        {agentsList.map((data) => (
                                          <MenuItem key={data.agentId} value={data.agentId}>
                                            {data.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <MDButton
                                      fullWidth
                                      variant="contained"
                                      color="info"
                                      onClick={handleCloseAssignDentalCoachModal}
                                    >
                                      Cancel
                                    </MDButton>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <MDButton
                                      fullWidth
                                      variant="contained"
                                      color="info"
                                      onClick={handleAssignDentalCoach}
                                    >
                                      Assign
                                    </MDButton>
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </>
          </Modal>
        </Card>
      </DashboardLayout>
    </>
  );
}
export default PatientDetails;
