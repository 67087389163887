/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import { TextField, Grid, Card, Modal } from "@mui/material";
import Table from "react-bootstrap/Table";
import Constants from "utils/constants";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
const REACT_APP_API_URL = Constants.API_URL;
const localstoredata = JSON.parse(localStorage.getItem("loginData"));
import AddIcon from "@mui/icons-material/Add";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getDentaldostAccount,
  addCoinsToMainAccount,
  ddAccountSelector,
} from "store/reducers/ddAccountSlice";
import routes from "routes";

function DdAccountTable() {
  const dispatch = useDispatch();
  const [openAddCoinsModal, setOpenAddCoinsModal] = useState(false);
  const handleCloseAddCoinsModal = () => setOpenAddCoinsModal(false);
  const [coinsAmount, setCoinsAmount] = useState("");
  const [transactionTitle, setTransactionTitle] = useState("");
  const [accountData, setAccountData] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const dentaldostAccount = useSelector((state) => state?.ddAccount?.dentaldostAccount);
  const getDentaldostAccountSuccess = useSelector(ddAccountSelector.getDentaldostAccountSuccess);
  const getDentaldostAccountFailure = useSelector(ddAccountSelector.getDentaldostAccountFailure);
  const addCoinsToMainAccountSuccess = useSelector(ddAccountSelector.addCoinsToMainAccountSuccess);
  const addCoinsToMainAccountFailure = useSelector(ddAccountSelector.addCoinsToMainAccountFailure);

  useEffect(() => {
    fetchDentaldostAccount();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const fetchDentaldostAccount = () => {
    dispatch(getDentaldostAccount());
  };

  useEffect(() => {
    if (dentaldostAccount?.data) {
      setAccountData(dentaldostAccount?.data);
      setAccountBalance(dentaldostAccount?.data?.accountBalance);
    }
  }, [dentaldostAccount]);

  useEffect(() => {
    if (getDentaldostAccountFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getDentaldostAccountFailure]);

  const handleAddCoins = () => {
    if (coinsAmount && transactionTitle) {
      const formData = {
        coinsAmount: parseInt(coinsAmount),
        transactionTitle,
      };
      dispatch(addCoinsToMainAccount(formData));
    } else {
      Swal.fire({
        icon: "error",
        text: "amount and title are Required",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  useEffect(() => {
    if (addCoinsToMainAccountSuccess) {
      Swal.fire({
        icon: "success",
        text: "Coins Added SuccessFully",
        showConfirmButton: false,
        timer: 2000,
      });
      handleCloseAddCoinsModal(true);
      setCoinsAmount("");
      setTransactionTitle("");
      fetchDentaldostAccount();
    }
  }, [addCoinsToMainAccountSuccess]);

  useEffect(() => {
    if (addCoinsToMainAccountFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [addCoinsToMainAccountFailure]);
  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                scanO Account
              </MDTypography>
            </MDBox>
            <br />
            <br />
            <Card>
              <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" px={5}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    mb={2}
                  >
                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      {accountBalance ? (
                        <MDBox
                          mr={1}
                          style={{
                            float: "left",
                          }}
                        >
                          DD Account Balance :&nbsp;
                          <MDTypography component="span" fontWeight="bold" color="success">
                            + {accountBalance}
                          </MDTypography>
                        </MDBox>
                      ) : (
                        <>
                          <MDBox style={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress color="inherit" />
                          </MDBox>
                        </>
                      )}
                    </MDBox>

                    <MDBox
                      display="flex"
                      alignItems="center"
                      mt={{ xs: 2, sm: 0 }}
                      ml={{ xs: -1.5, sm: 0 }}
                    >
                      <MDBox mr={1} style={{ float: "right" }}>
                        <MDButton
                          variant="gradient"
                          onClick={() => setOpenAddCoinsModal(true)}
                          color="info"
                        >
                          <AddIcon />
                          Add Coins
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Card>
        </Grid>
        <Modal
          open={openAddCoinsModal}
          onClose={handleCloseAddCoinsModal}
          style={{ marginTop: "5%", marginLeft: "10%" }}
        >
          <>
            <MDBox mt={6} mb={3}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={7}>
                  <Card id="delete-account">
                    <MDBox pt={1} pb={2} px={2}>
                      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        <MDBox
                          component="li"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          borderRadius="lg"
                          p={3}
                          mt={2}
                        >
                          <MDBox width="100%" display="flex" flexDirection="column">
                            <MDBox mb={1} lineHeight={1}>
                              <center>
                                <h4>Add Coins</h4>
                              </center>
                              <br />
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Amount"
                                    value={coinsAmount}
                                    onChange={(e) => setCoinsAmount(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    fullWidth
                                    label="Title"
                                    value={transactionTitle}
                                    onChange={(e) => setTransactionTitle(e.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleCloseAddCoinsModal}
                                    color="info"
                                  >
                                    Cancel
                                  </MDButton>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <MDButton
                                    fullWidth
                                    variant="gradient"
                                    onClick={handleAddCoins}
                                    color="info"
                                  >
                                    Add
                                  </MDButton>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </>
        </Modal>
      </MDBox>
    </>
  );
}
export default DdAccountTable;
