/* eslint-disable */

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowUpReminders,
  followUpReminderSelector,
} from "store/reducers/followUpReminderSlice";

function FollowUpReminder(props) {
  const dispatch = useDispatch();
  const [followUpReminder, setFollowUpReminder] = useState([]);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [isLoading, setIsLoading] = useState(true);
  const followUpRemindersData = useSelector((state) => state?.followUpReminder?.followUpReminders);
  const getFollowUpRemindersSuccess = useSelector(
    followUpReminderSelector.getFollowUpRemindersSuccess
  );
  const getFollowUpRemindersFailure = useSelector(
    followUpReminderSelector.getFollowUpRemindersFailure
  );

  const followUpReminderColumns = [
    {
      field: "reminderDate",
      headerName: "Reminder Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY");
        return `${valueFormatted}`;
      },
    },
    {
      field: "appointmentDate",
      headerName: "Appointment Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY");
        return `${valueFormatted}`;
      },
    },
    {
      field: "appointmentTitle",
      headerName: "Appointment Title",
      headerAlign: "center",
      align: "center",
      width: 350,
    },
  ];

  useEffect(() => {
    fetchFollowUpReminders();
  }, []);

  const fetchFollowUpReminders = () => {
    const formData = {
      patientId: props.patientId,
      token: loginData?.token,
    };
    dispatch(getFollowUpReminders(formData));
  };

  useEffect(() => {
    if (followUpRemindersData?.followUpReminders) {
      setIsLoading(false);
      setFollowUpReminder(followUpRemindersData?.followUpReminders);
    }
  }, [followUpRemindersData]);

  useEffect(() => {
    if (getFollowUpRemindersFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getFollowUpRemindersFailure]);

  return (
    <>
      <br />
      <br />
      <br />
      {isLoading ? (
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <div style={{ height: "370px", width: "100%" }}>
          <DataGrid
            rows={followUpReminder ? followUpReminder : []}
            columns={followUpReminderColumns}
            disableSelectionOnClick
            pageSize={5}
            getRowId={(row) => row.reminderId}
          />
        </div>
      )}
    </>
  );
}
export default FollowUpReminder;
