// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  addSubscriptionPlanSuccess,
  addSubscriptionPlanFailure,
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFailure,
  editSubscriptionPlanSuccess,
  editSubscriptionPlanFailure,
  getAllSubscriptionsSuccess,
  getAllSubscriptionsFailure,
  addSubscriptionByAgentSuccess,
  addSubscriptionByAgentFailure,
} from "store/reducers/subscriptionSlice"; // Import your action creator
import subscriptionService from "services/subscription"; // Import your service function

function* addSubscriptionPlan(action) {
  try {
    const response = yield call(subscriptionService.addSubscriptionPlan, action.payload);
    yield put(addSubscriptionPlanSuccess(response.data));
  } catch (error) {
    yield put(addSubscriptionPlanFailure(error));
  }
}
function* getSubscriptionPlans(action) {
  try {
    const response = yield call(subscriptionService.getSubscriptionPlans, action.payload);
    yield put(getSubscriptionPlansSuccess(response.data));
  } catch (error) {
    yield put(getSubscriptionPlansFailure(error));
  }
}
function* editSubscriptionPlan(action) {
  try {
    const response = yield call(subscriptionService.editSubscriptionPlan, action.payload);
    yield put(editSubscriptionPlanSuccess(response.data));
  } catch (error) {
    yield put(editSubscriptionPlanFailure(error));
  }
}
function* getAllSubscriptions(action) {
  try {
    const response = yield call(subscriptionService.getAllSubscriptions, action.payload);
    yield put(getAllSubscriptionsSuccess(response.data));
  } catch (error) {
    yield put(getAllSubscriptionsFailure(error));
  }
}
function* addSubscriptionByAgent(action) {
  try {
    const response = yield call(subscriptionService.addSubscriptionByAgent, action.payload);
    yield put(addSubscriptionByAgentSuccess(response.data));
  } catch (error) {
    yield put(addSubscriptionByAgentFailure(error));
  }
}

// Watcher Saga
function* subscriptionSaga() {
  yield takeLatest("subscriptions/addSubscriptionPlan", addSubscriptionPlan);
  yield takeLatest("subscriptions/getSubscriptionPlans", getSubscriptionPlans);
  yield takeLatest("subscriptions/editSubscriptionPlan", editSubscriptionPlan);
  yield takeLatest("subscriptions/addSubscriptionByAgent", addSubscriptionByAgent);
  yield takeLatest("subscriptions/getAllSubscriptions", getAllSubscriptions);
}

// Export all sagas
export default subscriptionSaga;
