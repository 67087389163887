// inAppChatSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "@reduxjs/toolkit";

const inAppChatSlice = createSlice({
  name: "inAppChats",
  initialState: {
    inAppChats: [],
    isloading: false,
    getChatsByIdSuccess: false,
    getChatsByIdFailure: false,
    getAllChatsSuccess: false,
    getAllChatsFailure: false,
    updateChatByUserIdSuccess: false,
    updateChatByUserIdFailure: false,
    searchPatientsSuccess: false,
    searchPatientsFailure: false,
    sendAttachmentSuccess: false,
    sendAttachmentFailure: false,
    // Other initial state properties
  },

  reducers: {
    getChatsById: (state, action) => {
      state.isloading = false;
    },
    getChatsByIdSuccess: (state, action) => {
      state.chatsById = action.payload;
      state.isloading = true;
      state.getChatsByIdSuccess = true;
    },
    getChatsByIdFailure: (state) => {
      state.isloading = false;
      state.getChatsByIdFailure = true;
    },
    getAllChats: (state, action) => {
      state.isloading = false;
    },
    getAllChatsSuccess: (state, action) => {
      state.allChats = action.payload;
      state.isloading = true;
      state.getAllChatsSuccess = true;
    },
    getAllChatsFailure: (state) => {
      state.isloading = false;
      state.getAllChatsFailure = true;
    },
    updateChatByUserId: (state, action) => {
      state.isloading = false;
    },
    updateChatByUserIdSuccess: (state, action) => {
      state.inAppChats = action.payload;
      state.isloading = true;
      state.updateChatByUserIdSuccess = true;
    },
    updateChatByUserIdFailure: (state) => {
      state.isloading = false;
      state.updateChatByUserIdFailure = true;
    },
    searchPatients: (state, action) => {
      state.isloading = false;
    },
    searchPatientsSuccess: (state, action) => {
      state.searchPatients = action.payload;
      state.isloading = true;
      state.searchPatientsSuccess = true;
    },
    searchPatientsFailure: (state) => {
      state.isloading = false;
      state.searchPatientsFailure = true;
    },
    sendAttachment: (state, action) => {
      state.isloading = false;
    },
    sendAttachmentSuccess: (state, action) => {
      state.inAppChats = action.payload;
      state.isloading = true;
      state.sendAttachmentSuccess = true;
    },
    sendAttachmentFailure: (state) => {
      state.isloading = false;
      state.sendAttachmentFailure = true;
    },

    // Other reducers if needed
  },
});

const selectKiosk = (state) => state.inAppChat;

export const inAppChatSelector = {
  getChatsByIdSuccess: createSelector(selectKiosk, (kioskState) => kioskState.getChatsByIdSuccess),
  getChatsByIdFailure: createSelector(selectKiosk, (kioskState) => kioskState.getChatsByIdFailure),
  getAllChatsSuccess: createSelector(selectKiosk, (kioskState) => kioskState.getAllChatsSuccess),
  getAllChatsFailure: createSelector(selectKiosk, (kioskState) => kioskState.getAllChatsFailure),
  updateChatByUserIdSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatByUserIdSuccess
  ),
  updateChatByUserIdFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.updateChatByUserIdFailure
  ),
  searchPatientsSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchPatientsSuccess
  ),
  searchPatientsFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.searchPatientsFailure
  ),
  sendAttachmentSuccess: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendAttachmentSuccess
  ),
  sendAttachmentFailure: createSelector(
    selectKiosk,
    (kioskState) => kioskState.sendAttachmentFailure
  ),
  // Add other selectors if needed
};

export const {
  getChatsById,
  getChatsByIdSuccess,
  getChatsByIdFailure,
  getAllChats,
  getAllChatsSuccess,
  getAllChatsFailure,
  updateChatByUserId,
  updateChatByUserIdSuccess,
  updateChatByUserIdFailure,
  searchPatients,
  searchPatientsSuccess,
  searchPatientsFailure,
  sendAttachment,
  sendAttachmentSuccess,
  sendAttachmentFailure,
} = inAppChatSlice.actions;
export default inAppChatSlice.reducer;
