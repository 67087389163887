/*eslint-disable*/

import React, { useState } from "react";
import Canvas from "./canvas";
import RegionsList from "./regionsList";
import axios from "axios";
import "./annotation-styles.css";
import urlParse from "url-parse";
import Constants from "../../../utils/constants";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { useRef } from "react";
import { useEffect } from "react";
import { labelsMap } from "../../../utils/i18n";
import { Checkbox } from "@mui/material";
import { useSelector } from "react-redux";

export default function Annotation({
  url,
  imgAngle,
  index,
  patientId,
  testId,
  ImgURL,
  selectedDiseaseTypes,
  diseasesList,
  newImageUrl,
  updateSelectedDiseases,
}) {
  const [showOriginal, setShowOriginal] = useState(true);
  const [showDetections, setShowDetections] = useState(false);
  const [imageUrl, setImageUrl] = useState(url);
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const jwt = JSON.parse(localStorage.getItem("loginData")).token;
  // const addAnnotation = `/addannotation`;
  // const addAnnotationVideo = "/addannotationvideo";
  const addAnnotation = `/agent/patient/addAnnotation`;
  const addAnnotationVideo = "/agent/patient/addAnnotationVideo";
  const updateTest = "/agent/patient/updateTestResult";
  let imageDetails = {};
  // const [selectedDiseaseTypes, setSelectedDiseaseTypes] = useState(selectedDiseaseTypesData);
  const language = "english";

  const canvasRef = useRef();

  useEffect(() => {
    if (showDetections) {
      if (!imageUrl) {
        return;
      }
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        const canvas = canvasRef.current;
        if (!canvas) {
          return; // Exit if canvas element is not found
        }
        const parentElement = canvas.parentElement;
        if (!parentElement) {
          return; // Exit if parent element is not found
        }
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.height >= 288 ? canvas.height : 288;
        const context = canvas.getContext("2d");
        const offsetX = (canvas.width - image.width) / 2;
        const offsetY = (canvas.height - image.height) / 2;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, offsetX, offsetY, image.width, image.height);
        drawAnnotations(context, offsetX, offsetY);
      };
    }
  }, [imageUrl, selectedDiseaseTypes, diseasesList, showDetections]);

  const drawRectangle = (ctx, coordinates, color, offsetX, offsetY) => {
    const [x1, y1, x2, y2] = coordinates;
    ctx.globalAlpha = 0.7;

    ctx.fillStyle = color;
    ctx.fillRect(offsetX + x1, offsetY + y1, x2 - x1, y2 - y1);
  };

  const fillInDiseaseInformation = (diseases) => {
    const diseaseMap = {};

    diseases?.forEach((disease) => {
      const diseaseType = disease.name.toLowerCase();

      if (Object.keys(labelsMap?.diseasesMap).includes(diseaseType)) {
        if (!diseaseMap[diseaseType]) {
          diseaseMap[diseaseType] = [];
        }
        diseaseMap[diseaseType].push(disease);
      }
    });

    return diseaseMap;
  };

  const getTreatmentsColor = (disease) => {
    if (labelsMap.diseasesMap[disease.toLowerCase()]) {
      return labelsMap.diseasesMap[disease.toLowerCase()].color;
    } else {
      return "#ffffff";
    }
  };

  const drawAnnotations = (context, offsetX, offsetY) => {
    const diseaseMap = fillInDiseaseInformation(diseasesList);
    selectedDiseaseTypes.forEach((diseaseType) => {
      const diseases = diseaseMap[diseaseType];
      const color = getTreatmentsColor(diseaseType);
      if (diseases) {
        diseases.forEach((disease) => {
          const coordinates = disease.coordinates;
          coordinates.forEach((coord) => drawRectangle(context, coord, color, offsetX, offsetY));
        });
      }
    });
  };

  let diseasesKeys = Object.keys(fillInDiseaseInformation(diseasesList));

  const handleDiseaseTypeChange = (event) => {
    const diseaseName = event.target.name;
    if (selectedDiseaseTypes.includes(diseaseName)) {
      // Filter out the disease if it already exists in the selectedDiseaseTypes
      const updatedSelectedDiseases = selectedDiseaseTypes.filter((item) => item !== diseaseName);
      updateSelectedDiseases(updatedSelectedDiseases); // Call the callback function with the updated array
    } else {
      // Add disease to the selectedDiseaseTypes
      const updatedSelectedDiseases = [...selectedDiseaseTypes, diseaseName];
      updateSelectedDiseases(updatedSelectedDiseases); // Call the callback function with the updated array
    }
  };

  const getTreatments = (disease) => {
    return labelsMap.diseasesMap[disease.toLowerCase()][language];
  };

  const handleDownload = () => {
    if (!showOriginal) {
      saveAs(ImgURL, "original.png");
    } else {
      saveAs(url, "disease.png");
    }
  };

  const handleClick = () => {
    console.log("url", url, ImgURL);
    if (!showOriginal) {
      setImageUrl(url);
    } else {
      setImageUrl(ImgURL || url);
    }
    setShowOriginal(!showOriginal);
  };

  return (
    <>
      <div className="App">
        <div className="left-panel" style={{ width: "120px" }}>
          <div>
            {loginData?.roleData?.roleName !== "reviewer" ? (
              <button
                className="btn btn-primary"
                onClick={() => setShowDetections(!showDetections)}
              >
                {showDetections ? "Hide detections" : "Show detections"}
              </button>
            ) : (
              ""
            )}
          </div>
          &nbsp;
          <div>
            {loginData?.roleData?.roleName !== "reviewer" ? (
              <button className="btn btn-primary" onClick={handleClick}>
                {showOriginal === true ? "Original Image" : "Disease Image"}
              </button>
            ) : (
              ""
            )}
          </div>
          &nbsp;
          <div>
            {loginData?.roleData?.roleName !== "reviewer" &&
            loginData?.roleData?.roleName !== "partner" ? (
              <button className="btn btn-primary" onClick={handleDownload}>
                Download
              </button>
            ) : (
              ""
            )}
          </div>
          &nbsp;
          <RegionsList
            sendRegions={(regions) => {
              let regionData = {};
              const config = {
                headers: { Authorization: `Bearer ${jwt}` },
                "Content-Type": "application/json",
              };
              let urlParsed = new urlParse(ImgURL);
              regionData.angle = imgAngle;
              regionData.path = urlParsed.pathname.slice(7);
              regionData.regions = regions;
              regionData.imageDetails = imageDetails;
              const body = {
                patientId,
                original: 1,
                jwt: jwt,
                regionData: regionData,
                testId,
              };
              if (regionData.path && regionData.path.includes("from_video")) {
                axios.post(Constants.API_URL + addAnnotationVideo, body, config).then((res) => {
                  const config = {
                    headers: { Authorization: `Bearer ${jwt}` },
                    "Content-Type": "application/json",
                  };
                  const body = {
                    patientId: patientId,
                    id: testId,
                    angle: imgAngle,
                    url: res.data.disease_file_url,
                    diseaseList: res.data.diseases,
                  };
                  axios.post(Constants.API_URL + updateTest, body, config).then((res) => {
                    Swal.fire({
                      icon: "success",
                      text: "Update successful",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  });
                });
              } else {
                axios.post(Constants.API_URL + addAnnotation, body, config).then((res) => {
                  const config = {
                    headers: { Authorization: `Bearer ${jwt}` },
                    "Content-Type": "application/json",
                  };
                  const body = {
                    patientId: patientId,
                    id: testId,
                    angle: imgAngle,
                    url: res.data.disease_file_url,
                    diseaseList: res.data.diseases,
                  };
                  axios.post(Constants.API_URL + updateTest, body, config).then((res) => {
                    Swal.fire({
                      icon: "success",
                      text: "Update successful",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  });
                });
              }
            }}
          />
        </div>
        <div className="right-panel">
          <div className="image-panel">
            {showDetections ? (
              <div style={{ display: "flex" }}>
                <div
                  className="image-container"
                  style={{
                    flex: 1,
                    backgroundColor: "rgb(0 36 70)",
                  }}
                >
                  <canvas
                    ref={canvasRef}
                    className="image image-contain"
                    style={{ maxWidth: "100%", maxHeight: "100%" }} // Set maximum width and height to maintain proper aspect ratio
                    getImageDetails={(value) => (imageDetails = value)}
                  ></canvas>
                </div>

                <div
                  className="disease-panel"
                  style={{
                    flex: 1,
                    backgroundColor: "#D9E9FC",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <div
                      className="heading-scan bold-heading"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      Detected diseases
                    </div>
                  </div>
                  <br />
                  {diseasesKeys.length > 0 ? (
                    diseasesKeys.map((item, index) => (
                      <div
                        key={index}
                        className="disease-item"
                        // onClick={() => handleDiseaseClick(item)}
                        style={{ marginRight: "10px", flex: "1" }} // Add spacing between disease items
                      >
                        <div className="disease-content">
                          <div
                            className="disease-header"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start", // Align text on the left
                              marginLeft: "20%",
                            }}
                          >
                            {/* Checkbox for disease selection */}
                            <Checkbox
                              size="small" // Set the size attribute to "small"
                              checked={selectedDiseaseTypes.includes(item)}
                              onChange={handleDiseaseTypeChange}
                              name={item}
                              color="primary"
                            />
                            <div
                              className="color-indicator"
                              style={{
                                backgroundColor: getTreatmentsColor(item),
                                marginLeft: "10px",
                              }}
                            ></div>
                            <div className="disease-info">
                              <strong>
                                {getTreatments(item) && getTreatments(item).name
                                  ? getTreatments(item).name
                                  : ""}
                              </strong>
                            </div>
                          </div>
                          <div className="treatment-info"></div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="no-diseases">
                      <p className="no-detections">No diseases found.</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                <Canvas url={imageUrl} getImageDetails={(value) => (imageDetails = value)} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
