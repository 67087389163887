/* eslint-disable */

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import { useMaterialUIController } from "context";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { getAppointmentDetails, updateAppointment } from "store/reducers/appointmentSlice";
import { useDispatch } from "react-redux";

function ViewAppointment() {
  const navigate = useNavigate();
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [updateTreatment, setUpdateTreatment] = useState([{ name: "", price: "" }]);
  const { appointmentId } = useParams();
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [dateValue, setDateValue] = useState(new Date());
  const [newAppointmentTitle, setNewAppointmentTitle] = useState("");
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const dispatch = useDispatch();

  const handleRemoveClick = (event, index) => {
    event.preventDefault();
    const list = [...updateTreatment];
    list.splice(index, 1);
    setUpdateTreatment(list);
  };

  const handleUpdateChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...updateTreatment];
    list[index][name] = value;
    setUpdateTreatment(list);
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    setUpdateTreatment([...updateTreatment, { name: "", price: "" }]);
  };

  const handleDateChange = (newValue) => {
    setDateValue(newValue);
  };

  useEffect(() => {
    fetchAppointmentDetails(appointmentId);
  }, []);

  const fetchAppointmentDetails = (appointmentId) => {
    dispatch(getAppointmentDetails(appointmentId));
  };

  const handleUpdateAppointment = () => {
    const formData = {
      aid: appointmentId,
      startTime: new Date(dateValue).toISOString(),
      status: appointmentDetails?.status,
      treatments: [...updateTreatment],
      title: newAppointmentTitle,
    };
    dispatch(updateAppointment(formData));
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={6} mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={7}>
              <Card id="delete-account">
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <br />
                    <h4>
                      <center>View Appointment</center>
                    </h4>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={1} lineHeight={1}>
                          <b>DETAILS</b>
                          <br />
                          <br />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Stack spacing={3}>
                                  <DateTimePicker
                                    label="Appointment Date"
                                    inputFormat="dd/MM/yyyy hh.mm a"
                                    value={dateValue ? new Date(dateValue) : new Date()}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                label="Title"
                                value={newAppointmentTitle ? newAppointmentTitle : ""}
                                onChange={(e) => setNewAppointmentTitle(e.target.value)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                disabled
                                label="Clinic Name"
                                value={appointmentDetails ? appointmentDetails.clinic : ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                disabled
                                label="Doctor Name"
                                value={appointmentDetails ? appointmentDetails.doctor : ""}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                disabled
                                label="Patient Name"
                                value={
                                  appointmentDetails ? appointmentDetails.patient.patientName : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                disabled
                                label="Duration"
                                value={
                                  appointmentDetails
                                    ? appointmentDetails.duration + " " + "minutes"
                                    : "" + "minutes"
                                }
                                minutes
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                disabled
                                label="Status"
                                value={appointmentDetails ? appointmentDetails.status : ""}
                              />
                            </Grid>
                          </Grid>
                          <br />
                          <hr />
                          <b>INVOICES</b>
                          <br />
                          <br />
                          <Table responsive>
                            <tr>
                              <td>URL</td>
                              <td>Status</td>
                            </tr>
                            <br />
                            {appointmentDetails &&
                              appointmentDetails.invoices &&
                              appointmentDetails.invoices.map((invoice, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <a
                                        href={invoice.short_url}
                                        style={{ fontSize: "smaller" }}
                                        target="_blank"
                                      >
                                        Download
                                      </a>
                                    </td>
                                    <td>
                                      <div style={{ fontSize: "smaller" }}>{invoice.status}</div>
                                    </td>
                                  </tr>
                                );
                              })}
                          </Table>
                          <br />
                          <hr />
                          <b>TREATMENTS</b>
                          <MDButton
                            variant="gradient"
                            onClick={handleAddClick}
                            style={{ float: "right" }}
                            color="info"
                          >
                            +
                          </MDButton>
                          <br />
                          <br />

                          {updateTreatment.length > 0
                            ? updateTreatment.map((treatmentData, i) => {
                                return (
                                  <Table responsive>
                                    <br />
                                    <tr>
                                      <td>
                                        <TextField
                                          name="name"
                                          label="Treatment Name"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                      <td>
                                        <TextField
                                          name="price"
                                          label="Cost"
                                          onChange={(e) => handleUpdateChange(e, i)}
                                        />
                                      </td>
                                      <td>
                                        <MDButton
                                          variant="gradient"
                                          onClick={(event) => handleRemoveClick(event, i)}
                                          color="error"
                                        >
                                          x
                                        </MDButton>
                                      </td>
                                    </tr>
                                  </Table>
                                );
                              })
                            : ""}
                          <br />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <MDButton
                                fullWidth
                                variant="gradient"
                                onClick={() => {
                                  navigate(-1);
                                }}
                                color="info"
                              >
                                Cancel
                              </MDButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MDButton
                                fullWidth
                                variant="gradient"
                                onClick={handleUpdateAppointment}
                                color="info"
                              >
                                Update
                              </MDButton>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    </>
  );
}
export default ViewAppointment;
