// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getDataCountSuccess,
  getDataCountFailure,
  getAnalysisDataSuccess,
  getAnalysisDataFailure,
  getTotalInvoiceSuccess,
  getTotalInvoiceFailure,
  getWeeklyRevenueSuccess,
  getWeeklyRevenueFailure,
  getAppointmentDayWiseSuccess,
  getAppointmentDayWiseFailure,
  getTotalAppointmentsSuccess,
  getTotalAppointmentsFailure,
  getKioskScanDataFailure,
  getKioskScanDataSuccess,
  getAllScanDataFailure,
  getAllScanDataSuccess,
} from "store/reducers/dashboardSlice"; // Import your action creator
import dashboardService from "services/dashboard"; // Import your service function

function* getDataCount(action) {
  try {
    const response = yield call(dashboardService.getDataCount, action.payload);
    yield put(getDataCountSuccess(response.data));
  } catch (error) {
    yield put(getDataCountFailure(error));
  }
}
function* getAnalysisData(action) {
  try {
    const response = yield call(dashboardService.getAnalysisData, action.payload);
    yield put(getAnalysisDataSuccess(response.data));
  } catch (error) {
    yield put(getAnalysisDataFailure(error));
  }
}
function* getTotalInvoice(action) {
  try {
    const response = yield call(dashboardService.getTotalInvoice, action.payload);
    yield put(getTotalInvoiceSuccess(response.data));
  } catch (error) {
    yield put(getTotalInvoiceFailure(error));
  }
}
function* getWeeklyRevenue(action) {
  try {
    const response = yield call(dashboardService.getWeeklyRevenue, action.payload);
    yield put(getWeeklyRevenueSuccess(response.data));
  } catch (error) {
    yield put(getWeeklyRevenueFailure(error));
  }
}
function* getAppointmentDayWise(action) {
  try {
    const response = yield call(dashboardService.getAppointmentDayWise, action.payload);
    yield put(getAppointmentDayWiseSuccess(response.data));
  } catch (error) {
    yield put(getAppointmentDayWiseFailure(error));
  }
}
function* getTotalAppointments(action) {
  try {
    const response = yield call(dashboardService.getTotalAppointments, action.payload);
    yield put(getTotalAppointmentsSuccess(response.data));
  } catch (error) {
    yield put(getTotalAppointmentsFailure(error));
  }
}

function* getKioskScanData(action) {
  try {
    const response = yield call(dashboardService.getKioskScanData, action.payload);
    yield put(getKioskScanDataSuccess(response.data));
  } catch (error) {
    yield put(getKioskScanDataFailure(error));
  }
}

function* getAllScanData(action) {
  try {
    const response = yield call(dashboardService.getAllScanData, action.payload);
    yield put(getAllScanDataSuccess(response.data));
  } catch (error) {
    yield put(getAllScanDataFailure(error));
  }
}

// Watcher Saga
function* dashboardSaga() {
  yield takeLatest("dashboards/getDataCount", getDataCount);
  yield takeLatest("dashboards/getAnalysisData", getAnalysisData);
  yield takeLatest("dashboards/getTotalInvoice", getTotalInvoice);
  yield takeLatest("dashboards/getWeeklyRevenue", getWeeklyRevenue);
  yield takeLatest("dashboards/getAppointmentDayWise", getAppointmentDayWise);
  yield takeLatest("dashboards/getTotalAppointments", getTotalAppointments);
  yield takeLatest("dashboards/getKioskScanData", getKioskScanData);
  yield takeLatest("dashboards/getAllScanData", getAllScanData);
}

// Export all sagas
export default dashboardSaga;
