// couponSagas.js
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  getChatsByIdSuccess,
  getChatsByIdFailure,
  getAllChatsSuccess,
  getAllChatsFailure,
  updateChatByUserIdSuccess,
  updateChatByUserIdFailure,
  searchPatientsSuccess,
  searchPatientsFailure,
  sendAttachmentSuccess,
  sendAttachmentFailure,
} from "store/reducers/inAppChatSlice"; // Import your action creator
import inAppChatService from "services/inAppChatServices"; // Import your service function

function* getChatsById(action) {
  try {
    const response = yield call(inAppChatService.getChatsById, action.payload);
    yield put(getChatsByIdSuccess(response.data));
  } catch (error) {
    yield put(getChatsByIdFailure(error));
  }
}
function* getAllChats(action) {
  try {
    const response = yield call(inAppChatService.getAllChats, action.payload);
    yield put(getAllChatsSuccess(response.data));
  } catch (error) {
    yield put(getAllChatsFailure(error));
  }
}
function* updateChatByUserId(action) {
  try {
    const response = yield call(inAppChatService.updateChatByUserId, action.payload);
    yield put(updateChatByUserIdSuccess(response.data));
  } catch (error) {
    yield put(updateChatByUserIdFailure(error));
  }
}
function* searchPatients(action) {
  try {
    const response = yield call(inAppChatService.searchPatients, action.payload);
    yield put(searchPatientsSuccess(response.data));
  } catch (error) {
    yield put(searchPatientsFailure(error));
  }
}
function* sendAttachment(action) {
  try {
    const response = yield call(inAppChatService.sendAttachment, action.payload);
    yield put(sendAttachmentSuccess(response.data));
  } catch (error) {
    yield put(sendAttachmentFailure(error));
  }
}

// Watcher Saga
function* kioskSaga() {
  yield takeLatest("inAppChats/getChatsById", getChatsById);
  yield takeLatest("inAppChats/getAllChats", getAllChats);
  yield takeLatest("inAppChats/updateChatByUserId", updateChatByUserId);
  yield takeLatest("inAppChats/searchPatients", searchPatients);
  yield takeLatest("inAppChats/sendAttachment", sendAttachment);
}

// Export all sagas
export default kioskSaga;
