/* eslint-disable */

import axios from "axios";
import { cachedAxiosGet } from "./apiCacheService";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const addSubscriptionPlan = (formdata) => {
  return axios.post(constant.API_URL + "/agent/addSubscriptionPlan", formdata, {
    headers,
  });
};
const editSubscriptionPlan = (formData) => {
  return axios.post(constant.API_URL + "/agent/editSubscriptionPlan", formData, {
    headers,
  });
};

const addSubscriptionByAgent = (formData, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/patient/addSubscription", formData, {
    headers,
  });
};

const getSubscriptionPlans = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/getAllSubscriptionPlans", {
    headers,
  });
};

const getAllSubscriptions = () => {
  return cachedAxiosGet(constant.API_URL + "/agent/subscription/getAllSubscriptions", {
    headers,
  });
};

const subscriptionService = {
  addSubscriptionPlan,
  getSubscriptionPlans,
  editSubscriptionPlan,
  getAllSubscriptions,
  addSubscriptionByAgent,
};

export default subscriptionService;
