/* eslint-disable */
import axios from "axios";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
import Constants from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const getPatientChats = (mobileNumber) => {
  return cachedAxiosGet(Constants.CHAT_URL + "/getChatsById/" + mobileNumber, {
    headers,
  });
};

const updateChatMode = (data) => {
  return axios.post(
    Constants.CHAT_URL + "/closeChatById",
    {
      id: data.id,
      mode: data.mode,
    },
    {
      headers,
    }
  );
};

const updateChatStatus = (mobileNumber, chatId) => {
  return axios.post(
    Constants.CHAT_URL + "/updateChatStatusById",
    { chatId: chatId, customerId: mobileNumber },
    {
      headers,
    }
  );
};

const filterChatData = (pageLimit) => {
  return cachedAxiosPost(
    Constants.API_URL + "/agent/patient/filterChatData",
    { pageLimit: pageLimit },
    {
      headers,
    }
  );
};
const searchChatPatients = (searchData) => {
  return cachedAxiosPost(
    Constants.API_URL + "/agent/patient/searchChatPatients",
    { searchData },
    {
      headers,
    }
  );
};
const chatServices = {
  updateChatMode,
  updateChatStatus,
  getPatientChats,
  filterChatData,
  searchChatPatients,
};

export default chatServices;
