/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import UpcomingAppointment from "layouts/dashboard/components/UpcomingAppointment";
import KioskScans from "layouts/dashboard/components/KioskScans";
import AllScans from "layouts/dashboard/components/AllScans";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useMaterialUIController, setDirection } from "context";
import { useEffect, useContext, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnalysisData,
  getTotalInvoice,
  getAppointmentDayWise,
  getTotalAppointments,
  dashboardSelector,
  getKioskScanData,
  getAllScanData,
} from "store/reducers/dashboardSlice";
import "./index.css";
import routes from "routes";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [weeklyRevenueLoading, setWeeklyRevenueLoading] = useState(true);
  const [totalInvoiceLoading, setTotalInvoiceLoading] = useState(true);
  const [appointmentDaywiseLoading, setAppointmentDaywiseLoading] = useState(true);
  const [liveActionsLoading, setLiveActionsLoading] = useState(true);
  const [totalAppointmentLoading, setTotalAppointmentLoading] = useState(true);
  const { sales, tasks } = reportsLineChartData;
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [analysisData, setAnalysisData] = useState();
  const [liveActions, setLiveActions] = useState();
  const [totalInvoiceData, setTotalInvoiceData] = useState();
  const [totalAppointment, setTotalAppointment] = useState();
  const analysis = useSelector((state) => state?.dashboard?.analysis);
  const getAnalysisDataSuccess = useSelector(dashboardSelector.getAnalysisDataSuccess);
  const getAnalysisDataFailure = useSelector(dashboardSelector.getAnalysisDataFailure);
  const totalInvoice = useSelector((state) => state?.dashboard?.totalInvoice);
  const getTotalInvoiceSuccess = useSelector(dashboardSelector.getTotalInvoiceSuccess);
  const getTotalInvoiceFailure = useSelector(dashboardSelector.getTotalInvoiceFailure);
  const appointmentDayWise = useSelector((state) => state?.dashboard?.appointmentDayWise);
  const getAppointmentDayWiseSuccess = useSelector(dashboardSelector.getAppointmentDayWiseSuccess);
  const getAppointmentDayWiseFailure = useSelector(dashboardSelector.getAppointmentDayWiseFailure);
  const totalAppointments = useSelector((state) => state?.dashboard?.totalAppointments);
  const getTotalAppointmentsSuccess = useSelector(dashboardSelector.getTotalAppointmentsSuccess);
  const getTotalAppointmentsFailure = useSelector(dashboardSelector.getTotalAppointmentsFailure);
  const kioskScans = useSelector((state) => state?.dashboard?.kioskScans);
  const allScans = useSelector((state) => state?.dashboard?.allScans);
  const [kioskScanData, setKioskScanData] = useState();
  const [allScanData, setAllScanData] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedDate, setSelectedDate] = useState(null);

  const [appointmentData, setAppointmentData] = useState({
    labels: ["M", "T", "W", "T", "F", "S", "S"],
    datasets: { label: "Appointments", data: [0, 0, 0, 0, 0, 0, 0] },
  });
  const [impressionsData, setImpressionsData] = useState({
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Impressions", data: [0, 0, 0, 0, 0, 0, 0] },
  });
  const [weeklyRevenueData, setWeeklyRevenueData] = useState({
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: { label: "Revenue", data: [0, 0, 0, 0, 0, 0, 0] },
  });
  const cardHeight = "8.5em";
  useEffect(() => {
    fetchAnalysisData();
    fetchTotalInvoice();
    fetchAppointmentDayWise();
    getLiveActionsData();
    fetchKioskScanData();
    fetchAllScanData();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const getLiveActionsData = () => {
    dispatch(getTotalAppointments(loginData.token));
  };

  const fetchKioskScanData = () => {
    const formData = {
      year: selectedYear,
      month: selectedMonth,
    };
    dispatch(getKioskScanData(formData, loginData.token));
  };

  const fetchAllScanData = () => {
    const formData = {
      year: selectedYear,
      month: selectedMonth,
      token: loginData.token,
    };
    dispatch(getAllScanData(formData));
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    const formData = {
      year: moment(newDate).year(),
      month: moment(newDate).month() + 1,
    };
    dispatch(getKioskScanData(formData, loginData.token));
  };

  useEffect(() => {
    document.title = "scanO/Dashboard";
    return () => {};
  }, []);

  useEffect(() => {
    if (totalAppointments) {
      setLiveActions(totalAppointments);
      setLiveActionsLoading(false);
      setTotalAppointment(totalAppointments);
      setTotalAppointmentLoading(false);
    }
  }, [totalAppointments]);

  useEffect(() => {
    if (kioskScans) {
      setKioskScanData(kioskScans);
    }
  }, [kioskScans]);

  useEffect(() => {
    if (allScans?.data) {
      setAllScanData(allScans.data);
    }
  }, [allScans]);

  useEffect(() => {
    if (getTotalAppointmentsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getTotalAppointmentsFailure]);

  const fetchAppointmentDayWise = () => {
    dispatch(getAppointmentDayWise(loginData.token));
  };

  useEffect(() => {
    if (appointmentDayWise) {
      let appointmentData = {
        datasets: {
          label: "Appointments",
          data: appointmentDayWise?.data?.appointmentDayWise[0]?.datasets,
        },
        labels:
          appointmentDayWise &&
          appointmentDayWise.appointmentDayWise &&
          appointmentDayWise.appointmentDayWise[0].labels
            ? appointmentDayWise.appointmentDayWise[0].labels
            : [],
        time: appointmentDayWise && appointmentDayWise.time ? appointmentDayWise.time : "",
      };
      setAppointmentData(appointmentData);
      let impressionsData = {
        datasets: {
          label: "Impressions",
          data:
            appointmentDayWise?.data?.impressionData &&
            appointmentDayWise?.data?.impressionData[0]?.datasets
              ? appointmentDayWise?.data?.impressionData[0]?.datasets
              : [],
        },
        labels:
          appointmentDayWise &&
          appointmentDayWise.impressionData &&
          appointmentDayWise.impressionData[0].labels
            ? appointmentDayWise.impressionData[0].labels
            : [],
        time: appointmentDayWise && appointmentDayWise.time ? appointmentDayWise.time : "",
      };
      setImpressionsData(impressionsData);
      setAppointmentDaywiseLoading(false);
    }
  }, [appointmentDayWise]);

  useEffect(() => {
    if (getAppointmentDayWiseFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAppointmentDayWiseFailure]);

  const fetchTotalInvoice = () => {
    dispatch(getTotalInvoice(loginData.token));
  };

  useEffect(() => {
    if (totalInvoice) {
      setTotalInvoiceData(totalInvoice);
      setTotalInvoiceLoading(false);
      let weeklyRevenueData = {
        datasets: {
          label: "Revenue",
          data:
            totalInvoice?.weeklyRevenue && totalInvoice?.weeklyRevenue[0]?.datasets
              ? totalInvoice?.weeklyRevenue[0]?.datasets
              : [],
        },
        labels:
          totalInvoice?.weeklyRevenue && totalInvoice?.weeklyRevenue[0]?.labels
            ? totalInvoice?.weeklyRevenue[0]?.labels
            : [],
        time: totalInvoice.time,
      };
      setWeeklyRevenueData(weeklyRevenueData);
      setWeeklyRevenueLoading(false);
    }
  }, [totalInvoice]);

  useEffect(() => {
    if (getTotalInvoiceFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getTotalInvoiceFailure]);

  const fetchAnalysisData = () => {
    dispatch(getAnalysisData(loginData.token));
  };

  useEffect(() => {
    if (analysis) {
      setAnalysisData(analysis);
      setIsLoading(false);
    }
  }, [analysis]);

  useEffect(() => {
    if (getAnalysisDataFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAnalysisDataFailure]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5} onClick={() => navigate("/patients")}>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                  <CircularProgress />
                </Box>
              ) : (
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title={"Patients"}
                  count={analysisData && analysisData.usersCount ? analysisData.usersCount : 0}
                  percentage={{
                    color: "success",
                    label:
                      analysisData && analysisData.time
                        ? moment(analysisData.time).fromNow()
                        : moment().fromNow(),
                  }}
                  height={cardHeight}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                  <CircularProgress />
                </Box>
              ) : (
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title={"DD Scans"}
                  count={analysisData && analysisData.testsCount ? analysisData.testsCount : 0}
                  percentage={{
                    color: "success",
                    // amount: "+3%",
                    label:
                      analysisData && analysisData.time
                        ? moment(analysisData.time).fromNow()
                        : moment().fromNow(),
                  }}
                  height={cardHeight}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              {totalInvoiceLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                  <CircularProgress />
                </Box>
              ) : (
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title={"Revenue"}
                  count={
                    "Rs " +
                    (totalInvoiceData && totalInvoiceData.invoiceAmount
                      ? totalInvoiceData.invoiceAmount
                      : 0)
                  }
                  percentage={{
                    color: "success",
                    label:
                      totalInvoiceData && totalInvoiceData.time
                        ? moment(totalInvoiceData.time).fromNow()
                        : moment().fromNow(),
                  }}
                  height={cardHeight}
                />
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              {isLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  Loading...
                  <CircularProgress />
                </Box>
              ) : (
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title={"Upcoming Appointments"}
                  count={
                    analysisData && analysisData.appointmentData ? analysisData.appointmentData : 0
                  }
                  percentage={{
                    color: "success",
                    amount: "",
                    label:
                      analysisData && analysisData.time
                        ? moment(analysisData.time).fromNow()
                        : moment().fromNow(),
                  }}
                  height={cardHeight}
                />
              )}
            </MDBox>
          </Grid>
        </Grid>
        {loginData && loginData.roleData && loginData.roleData.roleName === "admin" ? (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  {appointmentDaywiseLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Loading...
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReportsBarChart
                      color="info"
                      title={"Appointments"}
                      date={
                        appointmentData && appointmentData.time
                          ? moment(appointmentData.time).fromNow()
                          : moment().fromNow()
                      }
                      chart={appointmentData}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  {weeklyRevenueLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Loading...
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReportsLineChart
                      color="success"
                      title={"Monthly Revenue"}
                      date={
                        weeklyRevenueData && weeklyRevenueData.time
                          ? moment(weeklyRevenueData.time).fromNow()
                          : moment().fromNow()
                      }
                      chart={weeklyRevenueData}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={3}>
                  {appointmentDaywiseLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Loading...
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReportsLineChart
                      color="dark"
                      title={"DD Impressions"}
                      date={
                        impressionsData && impressionsData.time
                          ? moment(impressionsData.time).fromNow()
                          : moment().fromNow()
                      }
                      chart={impressionsData}
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        ) : (
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  {appointmentDaywiseLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Loading...
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReportsBarChart
                      color="info"
                      title={"Appointments"}
                      date={
                        appointmentData && appointmentData.time
                          ? moment(appointmentData.time).fromNow()
                          : moment().fromNow()
                      }
                      chart={appointmentData}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  {weeklyRevenueLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      Loading...
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ReportsLineChart
                      color="success"
                      title={"Weekly Revenue"}
                      date={
                        weeklyRevenueData && weeklyRevenueData.time
                          ? moment(weeklyRevenueData.time).fromNow()
                          : moment().fromNow()
                      }
                      chart={weeklyRevenueData}
                    />
                  )}
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        label="Select Month and Year"
                        views={["year", "month"]}
                        value={selectedDate}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={handleDateChange}
                      />
                    </Stack>
                  </LocalizationProvider>
                </MDBox>
                <KioskScans data={kioskScanData ? kioskScanData : []} />
                <br />
                {/* <AllScans data={allScanData ? allScanData : []} /> */}
                <Projects
                  data={
                    totalAppointment &&
                    totalAppointment.totalAppointmentData &&
                    totalAppointment.totalAppointmentData.pastAppointment
                      ? totalAppointment.totalAppointmentData.pastAppointment
                      : []
                  }
                />
                <br />
                <UpcomingAppointment
                  data={
                    totalAppointment &&
                    totalAppointment.totalAppointmentData &&
                    totalAppointment.totalAppointmentData.upcomingAppointment
                      ? totalAppointment.totalAppointmentData.upcomingAppointment
                      : []
                  }
                />
              </>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
