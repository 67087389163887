/* eslint-disable */

import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import moment from "moment";
import MDTypography from "components/MDTypography";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "utils/constants";
import io from "socket.io-client";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import AttachFile from "@material-ui/icons/AttachFile";
import { useMaterialUIController } from "context";
import ddLogo from "../../../utilities/icon/scanoLogo1.png";
import "../components/inappchat.css";
import Badge from "@material-ui/core/Badge";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  TextField,
  Divider,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItem,
  Fab,
  List,
} from "@mui/material";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTypography,
  MDBInputGroup,
  MDBScrollbar,
} from "mdb-react-ui-kit";
// import MDBScrollbar from "mdb-react-ui-kit";
import * as _ from "lodash";
// import ScrollToBottom from "react-scroll-to-bottom";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleOn from "@material-ui/icons/ToggleOn";
import ToggleOff from "@material-ui/icons/ToggleOff";
import CryptoJS from "crypto-js";
import {
  getChatsById,
  sendAttachment,
  getAllChats,
  searchPatients,
  inAppChatSelector,
  updateChatByUserId,
} from "store/reducers/inAppChatSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import routes from "routes";

const permission = Notification.permission;
const REACT_APP_CHAT_URL = Constants.INAPP_CHAT_SOCKET_URL;
const secretPass = Constants.SECRET_KEY;
const loginData = JSON.parse(localStorage.getItem("loginData"));
const socket = io(REACT_APP_CHAT_URL, {
  transports: ["websocket"],
  autoconnect: true,
  auth: { token: loginData && loginData.token ? loginData.token : "" },
});

const useStyles = makeStyles((theme) => ({
  attachIconClass: {
    height: "40px",
    width: "30px",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function InAppChatWeb() {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [name, setName] = useState("");
  const classes = useStyles();
  const [messageNotification, setMessageNotification] = useState("");
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const inputFile = useRef(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [limit, setLimit] = useState(10);
  const [username, setUsername] = useState("");
  let [room, setRoom] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [isSender, setIsSender] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [floatingText, setFloatingText] = useState("");
  const [messageList, setMessageList] = useState([]);
  let [toggle, setToggle] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchData, setSearchData] = useState("");
  const [roomId, setRoomId] = useState("");
  const chatsByIdData = useSelector((state) => state?.inAppChat?.chatsById);
  const getChatsByIdSuccess = useSelector(inAppChatSelector.getChatsByIdSuccess);
  const getChatsByIdFailure = useSelector(inAppChatSelector.getChatsByIdFailure);
  const sendAttachmentSuccess = useSelector(inAppChatSelector.sendAttachmentSuccess);
  const sendAttachmentFailure = useSelector(inAppChatSelector.sendAttachmentFailure);
  const allChatsData = useSelector((state) => state?.inAppChat?.allChats);
  const getAllChatsSuccess = useSelector(inAppChatSelector.getAllChatsSuccess);
  const getAllChatsFailure = useSelector(inAppChatSelector.getAllChatsFailure);
  const searchPatientsChats = useSelector((state) => state?.inAppChat?.searchPatients);
  const searchPatientsSuccess = useSelector(inAppChatSelector.searchPatientsSuccess);
  const searchPatientsFailure = useSelector(inAppChatSelector.searchPatientsFailure);
  const updateChatByUserIdSuccess = useSelector(inAppChatSelector.searchPatientsSuccess);
  const updateChatByUserIdFailure = useSelector(inAppChatSelector.searchPatientsFailure);

  const messageContainerRef = useRef();
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const [suggestedTexts, setSuggestedTexts] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(-1);

  const suggestionArray = [
    `Hi, Greetings from scanO! 🙏`,
    "Please share details: Name, Age, Pincode, and dental issue.",
  ];

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  useEffect(() => {
    let element = document.getElementById("chatsId");
    element.scrollTop = element.scrollHeight;
    fetchAllChats(toggle);
  }, [limit]);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket connected successfully");
    });
  }, [socket]);

  const handleShowChats = (data) => {
    let element = document.getElementById("chatsId");
    element.scrollTop = element.scrollHeight;
    setRoom(data && data.user && data.user._id);
    setMobileNumber(data && data.user && data.user.mobile);
    setName(data && data.user && data.user.name);
    room = data && data.user && data.user._id;
    setRoomId(room);
    socket.on("connect", () => {
      console.log("socket connected successfully");
      socket.emit("join_room", room);
      console.log("join_room", room);
    });
    fetchChatsById();
    setShowChat(true);
    if (room) {
      const userId = room;
      dispatch(updateChatByUserId(userId, loginData));
    }
  };

  useEffect(() => {
    if (updateChatByUserIdSuccess) {
      fetchAllChats(toggle);
    }
  }, [updateChatByUserIdSuccess]);

  useEffect(() => {
    if (updateChatByUserIdFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [updateChatByUserIdFailure]);

  useEffect(() => {
    socket.on("receive_attachment", (data) => {
      console.log("receive_attachment");
      fetchChatsById();
      fetchAllChats();
      setMessageNotification("File received");
    });
  }, [socket]);

  useEffect(() => {
    socket.once("receive_all_message", (data) => {
      // fetchAllChats(toggle);
      updateAllChats(data, usersList);
      incrementCount(data, usersList);
      const bytes = CryptoJS.AES.decrypt(data.message, secretPass);
      const message = bytes.toString(CryptoJS.enc.Utf8);
      setMessageNotification(message);
      if (data.room === roomId) {
        setMessageList((list) => [...list, data]);
      }
    });
  }, [socket, usersList]);

  const updateAllChats = (socketData, usersList) => {
    const index = usersList.findIndex((data) => data?.user?._id == socketData.room);
    if (index >= 0) {
      usersList[index]?.conversation?.push({
        isSender: socketData.isSender,
        message: socketData.message,
        messageStatus: false,
        messageTime: moment().toISOString(),
        messageType: socketData.messageType,
      });
    }
    setUsersList(usersList);
  };

  useEffect(() => {
    socket.on("receive_message", (data) => {
      incrementCount(data, usersList);
      const bytes = CryptoJS.AES.decrypt(data.message, secretPass);
      const message = bytes.toString(CryptoJS.enc.Utf8);
      setMessageNotification(message);
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  const incrementCount = (patientMessage, usersList) => {
    if (patientMessage.isSender === true) {
      setOpen(true);
    }
    usersList.map((data) => {
      if (data?.user?._id == patientMessage.room) {
        data.unreadCount = data.unreadCount + 1;
      }
    });
    setUsersList(usersList);
    const bytes = CryptoJS.AES.decrypt(patientMessage.message, secretPass);
    const message = bytes.toString(CryptoJS.enc.Utf8);
    setMessageNotification(message);
  };

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setLimit(limit + 10);
    }
  };

  const handleClickAttachment = () => {
    inputFile.current.click();
  };

  const sendAttachmentData = () => {
    let body = new FormData();
    body.append("file", inputValue);
    body.append("isSender", isSender);
    body.append("userId", room);
    body.append("agrntId", loginData.id);
    dispatch(sendAttachment(body, loginData));
  };

  useEffect(() => {
    if (sendAttachmentSuccess) {
      setOpen(true);
      socket.emit("send_attachment");
      setMessageNotification("File sent");
      fetchChatsById();
      fetchAllChats(toggle);
      setInputValue("");
    }
  }, [sendAttachmentSuccess]);

  useEffect(() => {
    if (sendAttachmentFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [sendAttachmentFailure]);

  const handleSend = (event) => {
    if (suggestedTexts.length > 0 && suggestionIndex !== -1) {
      const message = CryptoJS.AES.encrypt(suggestedTexts[suggestionIndex], secretPass).toString();
      const messageData = {
        room: room,
        isSender: isSender,
        message: message,
        messageType: "text",
      };
      socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
      fetchAllChats(toggle);
    } else {
      if (inputValue) {
        sendAttachmentData();
      }
      let element = document.getElementById("chatsId");
      element.scrollTop = element.scrollHeight;
      if (currentMessage.trim() !== "") {
        const message = CryptoJS.AES.encrypt(currentMessage, secretPass).toString();
        const messageData = {
          room: room,
          isSender: isSender,
          message: message,
          messageType: "text",
        };
        socket.emit("send_message", messageData);
        setMessageList((list) => [...list, messageData]);
        setCurrentMessage("");
        fetchAllChats(toggle);
      }
    }
    setSuggestionIndex(-1);
    setSuggestedTexts([]);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setCurrentMessage(inputValue);
    if (inputValue.startsWith("/")) {
      const query = inputValue.slice(1);
      const filteredSuggestions = suggestionArray.filter((suggestion) =>
        suggestion.includes(query)
      );
      setSuggestedTexts(filteredSuggestions);
    } else {
      setSuggestedTexts([]);
    }
  };

  const fetchChatsById = () => {
    setIsChatLoading(true);
    if (room) {
      const userId = room;
      dispatch(getChatsById(userId));
    }
  };

  useEffect(() => {
    if (chatsByIdData?.conversation) {
      setMessageList([...chatsByIdData?.conversation].reverse());
      setIsChatLoading(false);
    }
  }, [chatsByIdData]);

  useEffect(() => {
    if (getChatsByIdFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getChatsByIdFailure]);

  const fetchAllChats = (toggle) => {
    const agentId = loginData && loginData.id ? loginData.id : "";
    if (toggle === false) {
      dispatch(getAllChats(agentId));
    } else {
      dispatch(getAllChats());
    }
  };

  useEffect(() => {
    if (allChatsData) {
      setIsLoading(false);
      setUsersList(allChatsData);
    }
  }, [allChatsData]);

  useEffect(() => {
    if (getAllChatsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getAllChatsFailure]);

  const searchPatientsData = (searchData) => {
    dispatch(searchPatients(searchData));
  };

  useEffect(() => {
    if (searchPatientsChats) {
      setIsLoading(false);
      setUsersList(searchPatientsChats);
    }
  }, [searchPatientsChats]);

  useEffect(() => {
    if (searchPatientsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [searchPatientsFailure]);

  const handleSwitchMode = () => {
    setToggle(!toggle);
    fetchAllChats(!toggle);
  };

  const handleClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function toTitleCase(str) {
    if (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return str;
    }
  }

  const getEncryptedMessage = (messageData, messageType) => {
    if (messageType === "message") {
      const bytes = CryptoJS.AES.decrypt(messageData, secretPass);
      const data = bytes.toString(CryptoJS.enc.Utf8);
      return data ? data : "";
    } else {
      const bytes = CryptoJS.AES.decrypt(messageData, secretPass);
      const data = bytes.toString(CryptoJS.enc.Utf8);
      return data ? data.replace(/['"]+/g, "") : "";
    }
  };

  const handleOpenFile = async (messageData) => {
    const bytes = CryptoJS.AES.decrypt(messageData.message, secretPass);
    const data = bytes.toString(CryptoJS.enc.Utf8);
    window.open(data ? data.replace(/['"]+/g, "") : "", "_blank");
  };

  return (
    <>
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            mx={2}
            mt={-3}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              In App Chat
            </MDTypography>
          </MDBox>
          <MDBContainer
            fluid
            className="py-4"
            item
            xs={12}
            container
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            borderRadius="lg"
          >
            <MDBRow>
              <MDBCol md="12">
                <MDBCard id="chat3" style={{ borderRadius: "15px" }}>
                  <MDBCardBody>
                    <MDBRow>
                      <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0">
                        <div className="rounded mb-3">
                          <MDBInputGroup className="rounded mb-3">
                            <input
                              className="form-control rounded"
                              placeholder="Search Patients"
                              type="search"
                              onChange={(event) => setSearchData(event.target.value)}
                              onKeyPress={(event) => {
                                if (event.key === "Enter" && searchData.length) {
                                  searchPatientsData(searchData);
                                } else if (event.key === "Enter" && searchData.length === 0) {
                                  fetchAllChats("0");
                                }
                              }}
                            />
                            <span className="input-group-text border-0" id="search-addon">
                              <MDButton
                                style={{ background: "transparent" }}
                                variant="gradient"
                                onClick={() =>
                                  searchData.length
                                    ? searchPatientsData(searchData)
                                    : fetchAllChats("0")
                                }
                              >
                                <SearchIcon />
                              </MDButton>
                            </span>
                          </MDBInputGroup>
                          {isLoading ? (
                            <>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: "150px",
                                }}
                              >
                                <CircularProgress color="inherit" />
                              </MDBox>
                            </>
                          ) : (
                            <List
                              listUnStyled
                              className="mb-0"
                              style={{ overflowY: "scroll", height: "22em" }}
                              onScroll={onScroll}
                            >
                              {toggle ? (
                                <div className="small text-muted">
                                  All-Patients
                                  <Tooltip title="Show Asign-Patients" placement="right">
                                    <ToggleOn
                                      style={{
                                        float: "right",
                                        transform: "scale(1.8)",
                                        margin: "10px",
                                        color: "#0D6EFD",
                                      }}
                                      onClick={handleSwitchMode}
                                    ></ToggleOn>
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="small text-muted">
                                  Assign-Patients
                                  <Tooltip title="Show All-Patients" placement="right">
                                    <ToggleOff
                                      style={{
                                        float: "right",
                                        transform: "scale(1.8)",
                                        margin: "10px",
                                        color: "#0D6EFD",
                                      }}
                                      onClick={handleSwitchMode}
                                    ></ToggleOff>
                                  </Tooltip>
                                </div>
                              )}
                              {usersList.map((data, index) => {
                                return (
                                  <>
                                    <ListItem
                                      className="border-bottom"
                                      //className="d-flex justify-content-between"
                                      key={index}
                                      button
                                      onClick={() => handleShowChats(data)}
                                    >
                                      <div className="d-flex flex-row">
                                        <ListItemIcon>
                                          <Avatar
                                            alt="avtar image"
                                            className="d-flex align-self-center me-2"
                                            style={{ width: "50px", height: "50px" }}
                                          />
                                        </ListItemIcon>
                                        <div>
                                          <p className=" mb-0">
                                            {toTitleCase(
                                              data && data.user && data.user.name && data.user.name
                                            )}
                                          </p>
                                          <p className="small text-muted">
                                            {moment(data.lastMessageTime).format("DD/MM/YYYY") &&
                                            moment(data.lastMessageTime).format("DD/MM/YYYY") ==
                                              moment().format("DD/MM/YYYY")
                                              ? moment(data && data.lastMessageTime).format(
                                                  "hh:mm a"
                                                )
                                              : moment(data && data.lastMessageTime).format(
                                                  "DD/MM/YYYY"
                                                )}
                                          </p>
                                        </div>
                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <div className="pt-1">
                                        <span className="badge bg-danger rounded-pill float-end">
                                          {data && data.unreadCount ? data.unreadCount : ""}
                                        </span>
                                      </div>
                                    </ListItem>
                                  </>
                                );
                              })}
                            </List>
                          )}
                        </div>
                      </MDBCol>
                      <MDBCol md="6" lg="7" xl="8">
                        {showChat ? (
                          <>
                            <MDBox className="columnClass">
                              <ListItemIcon>
                                <Avatar
                                  alt="avtar image"
                                  className="d-flex align-self-center me-3"
                                  style={{ width: "45px", height: "45px" }}
                                />
                              </ListItemIcon>
                              <span className="headerClass">
                                {toTitleCase(name ? name : "")}&nbsp;(
                                {mobileNumber ? mobileNumber : ""})
                              </span>
                            </MDBox>
                          </>
                        ) : (
                          ""
                        )}

                        <div
                          className="chat-body"
                          style={
                            mobileNumber
                              ? { overflowY: "scroll", height: "22em" }
                              : { height: "22em" }
                          }
                          id="chatsId"
                        >
                          {/* <ScrollToBottom className="message-container"> */}
                          {isChatLoading ? (
                            <>
                              <MDBox
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: "150px",
                                }}
                              >
                                <CircularProgress color="inherit" />
                              </MDBox>
                            </>
                          ) : (
                            <div
                              ref={messageContainerRef}
                              style={{ overflowY: "auto", maxHeight: "22em" }}
                            >
                              {messageList &&
                                messageList.map((messageContent, index) => {
                                  return (
                                    messageList &&
                                    messageList.map((messageContent, index) => {
                                      return (
                                        <>
                                          <div
                                            // key={index}
                                            style={{
                                              textAlign: "center",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            <div className="dateBoxClass">
                                              {moment(messageContent.messageTime).format(
                                                "DD/MM/YYYY"
                                              )}
                                            </div>
                                          </div>
                                          <div
                                            // key={index}
                                            className={
                                              messageContent.isSender === false
                                                ? "d-flex flex-row justify-content-start"
                                                : "d-flex flex-row justify-content-end"
                                            }
                                            style={{ backgroundColor: "#f5f6f7" }}
                                            id={messageContent.isSender === false ? "you" : "other"}
                                          >
                                            <ListItemIcon>
                                              <Avatar
                                                src={
                                                  messageContent.isSender === false ? ddLogo : ""
                                                }
                                                alt="avtar image"
                                                className="d-flex align-self-center me-1"
                                                style={{ width: "45px", height: "45px" }}
                                              />
                                            </ListItemIcon>
                                            <div>
                                              <div
                                                className={
                                                  messageContent.isSender === true
                                                    ? "small p-1 ms-3 rounded-3"
                                                    : "small p-1 ms-3 text-white rounded-3 bg-primary"
                                                }
                                                style={{ backgroundColor: "#d1cccc" }}
                                              >
                                                {messageContent.messageType !== "text" ? (
                                                  <>
                                                    <img
                                                      src={
                                                        messageContent.messageType === "text" ||
                                                        messageContent.messageType.includes("pdf")
                                                          ? "https://dentaldost.com/wp-content/uploads/2023/01/pdficon.png"
                                                          : // : messageContent.message
                                                            getEncryptedMessage(
                                                              messageContent.message,
                                                              "file"
                                                            )
                                                      }
                                                      style={{ height: "8em", width: "8em" }}
                                                      onClick={() => handleOpenFile(messageContent)}
                                                    />
                                                  </>
                                                ) : (
                                                  // <p>{messageContent.message}</p>
                                                  <p>
                                                    {getEncryptedMessage(
                                                      messageContent.message,
                                                      "message"
                                                    )}
                                                  </p>
                                                )}
                                              </div>
                                              <p className="message-meta small p-1 ms-3">
                                                {messageContent.messageTime &&
                                                moment(messageContent.messageTime).format(
                                                  "DD/MM/YYYY"
                                                ) == moment().format("DD/MM/YYYY")
                                                  ? moment(
                                                      messageContent && messageContent.messageTime
                                                    ).format("hh:mm a")
                                                  : moment(
                                                      messageContent && messageContent.messageTime
                                                    ).format("hh:mm a")}
                                              </p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  );
                                })}
                            </div>
                          )}
                          {/* </ScrollToBottom> */}
                        </div>
                        <div className="suggestions">
                          {suggestedTexts.length > 0 && (
                            <>
                              <br />
                              <ul
                                className="suggestions-list"
                                style={{ border: "1px solid #0d6efd" }}
                              >
                                {suggestedTexts.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    className={
                                      index === suggestionIndex
                                        ? "selected-suggestion"
                                        : "suggestion"
                                    }
                                    onClick={() => {
                                      setSuggestionIndex(index);
                                      setCurrentMessage(suggestion);
                                    }}
                                  >
                                    {suggestion}
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                        {showChat ? (
                          <div className="text-muted d-flex justify-content-start align-items-center pe-3 pt-3 mt-2">
                            <ListItemIcon>
                              <Avatar
                                src={ddLogo}
                                alt="avtar image"
                                className="d-flex align-self-center me-3"
                                style={{ width: "45px", height: "45px" }}
                              />
                            </ListItemIcon>
                            <input
                              className="form-control form-control-lg"
                              type="text"
                              placeholder={!currentMessage ? "Type message" : currentMessage}
                              value={currentMessage}
                              onChange={handleInputChange}
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  handleSend();
                                }
                              }}
                            />
                            <div>
                              <AttachFile
                                className={classes.attachIconClass}
                                xs={1}
                                onClick={handleClickAttachment}
                              />
                              <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                onChange={(e) => setInputValue(e.target.files[0])}
                                style={{ display: "none" }}
                              />
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <div>
                              <Fab
                                className="form-control rounded"
                                // style={{ backgroundColor: "#0D6EFD" }}
                              >
                                <SendIcon fontSize="large" onClick={() => handleSend()} />
                              </Fab>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </Card>
      </MDBox>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClosePopup}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClosePopup} severity="success">
          {messageNotification}
        </Alert>
      </Snackbar>
    </>
  );
}
