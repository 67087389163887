/* eslint-disable */

import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { DataGrid } from "@mui/x-data-grid";
import MDButton from "components/MDButton";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import SearchIcon from "@material-ui/icons/Search";
import moment from "moment";
import { Select, FormControl, InputLabel, Stack, TextField, Grid, Card, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { darken, lighten } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { getInternalDoctors, agentSelector } from "store/reducers/agentSlice";
import { getFollowUps, getFollowUpsWeekly, doctorSelector } from "store/reducers/doctorSlice";
import routes from "routes";

function FollowUpTable() {
  const dispatch = useDispatch();
  const [doctorDetail, setDoctorsDetail] = useState([]);
  const [page, setPage] = useState(JSON.parse(localStorage.getItem("PageNumber")));
  const loginData = JSON.parse(localStorage.getItem("loginData"));
  const authTokenData = useSelector((state) => state?.agent?.authToken);
  if (loginData?.token) {
    loginData.token = authTokenData ? authTokenData : loginData?.token;
  }
  const [followUpData, setFollowUpData] = useState([]);
  const [followUpDate, setFollowUpDate] = useState(new Date());
  let [newDoctorID, setNewDoctorID] = useState("");
  const internalDoctors = useSelector((state) => state?.agent?.internalDoctors);
  const getInternalDoctorsSuccess = useSelector(agentSelector.getInternalDoctorsSuccess);
  const getInternalDoctorsFailure = useSelector(agentSelector.getInternalDoctorsFailure);
  const followUpsData = useSelector((state) => state?.doctor?.followUps);
  const getFollowUpsSuccess = useSelector(doctorSelector.getFollowUpsSuccess);
  const getFollowUpsFailure = useSelector(doctorSelector.getFollowUpsFailure);
  const followUpsWeeklyData = useSelector((state) => state?.doctor?.followUpsWeekly);
  const getFollowUpsWeeklySuccess = useSelector(doctorSelector.getFollowUpsWeeklySuccess);
  const getFollowUpsWeeklyFailure = useSelector(doctorSelector.getFollowUpsWeeklyFailure);

  const navigate = useNavigate();
  useEffect(() => {
    GetDoctorsList();
  }, []);

  useEffect(() => {
    const currentRoute = routes.find((route) => route.route === location.pathname);
    if (currentRoute && currentRoute.name) {
      document.title = `scanO/${currentRoute.name}`;
    }
  }, []);

  const followUpColumns = [
    {
      field: "title",
      headerName: "Title",
      headerAlign: "center",
      align: "center",
      width: 300,
      renderCell: (data) => {
        return data?.row?.appointmentId?.title;
      },
    },
    {
      field: "followUpDate",
      headerName: "Next FollowUp Date",
      headerAlign: "center",
      align: "center",
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY");
        return `${valueFormatted}`;
      },
      width: 200,
    },
    {
      field: "name",
      headerName: "Patient Name",
      width: 200,
      renderCell: (data) => {
        return data?.row?.patientId?.name ? toTitleCase(data?.row?.patientId?.name) : "";
      },
    },
    { field: "patientResponse", headerName: "Patient Response", width: 400 },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 130,
      type: "date",
      valueFormatter: (params) => {
        const valueFormatted = moment(params.value).format("DD/MM/YYYY hh:mm A");
        return `${valueFormatted}`;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 250,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const handleclickDetails = () => {
          window.open("/patientdetails/" + params.row.patientId._id, "_blank").focus();
        };
        const handleclickTrack = () => {
          window.open("/edit-followup/" + params.row._id, "_blank").focus();
        };
        return (
          <>
            <MDButton variant="gradient" onClick={handleclickDetails} color="info">
              Details
            </MDButton>
            &nbsp;
            <MDButton variant="gradient" onClick={handleclickTrack} color="info">
              Edit
            </MDButton>
          </>
        );
      },
    },
  ];
  const GetDoctorsList = (page) => {
    dispatch(getInternalDoctors(loginData.token));
  };

  useEffect(() => {
    if (internalDoctors?.doctors) {
      setDoctorsDetail([...internalDoctors?.doctors]);
    }
  }, [internalDoctors]);

  useEffect(() => {
    if (getInternalDoctorsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getInternalDoctorsFailure]);

  const fetchFollowUpsWeekly = (newDoctorID) => {
    const formData = {
      newDoctorID,
      token: loginData.token,
    };
    dispatch(getFollowUpsWeekly(formData));
  };

  useEffect(() => {
    if (followUpsWeeklyData) {
      setFollowUpData(followUpsWeeklyData);
    }
  }, [followUpsWeeklyData]);

  useEffect(() => {
    if (getFollowUpsWeeklyFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getFollowUpsWeeklyFailure]);

  const fetchFollowUps = (newDoctorID) => {
    const formData = {
      newDoctorID,
      followUpDate,
      token: loginData.token,
    };
    dispatch(getFollowUps(formData));
  };

  useEffect(() => {
    if (followUpsData) {
      setFollowUpData(followUpsData);
    }
  }, [followUpsData]);

  useEffect(() => {
    if (getFollowUpsFailure) {
      Swal.fire({
        icon: "error",
        text: "Error occurred",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [getFollowUpsFailure]);

  const handleclickSearchWeekly = () => {
    fetchFollowUpsWeekly(newDoctorID);
  };

  const handleclickSearch = () => {
    fetchFollowUps(newDoctorID);
  };

  const handleChangeDoctor = (value) => {
    setNewDoctorID(value.target.value);
  };

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return (
    <>
      <MDBox pt={6} pb={3}>
        <br />
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                FollowUp
              </MDTypography>
            </MDBox>
            <br />
            <MDBox width="100%" display="flex" flexDirection="column" py={3} px={2}>
              <MDBox mb={1}>
                <Grid item xs={12} sm={12} container>
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{ postion: "relative", maxWidth: "235px" }}
                  >
                    <InputLabel id="demo-simple-select-label">Doctors</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleChangeDoctor}
                    >
                      {doctorDetail?.sort(compare).map((doctor) => {
                        return (
                          <MenuItem value={doctor?.did}>
                            {" "}
                            {doctor?.name ? toTitleCase(doctor?.name) : ""}{" "}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Select Date"
                          inputFormat="DD/MM/YYYY"
                          value={followUpDate}
                          onChange={(date) => setFollowUpDate(date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                  <MDButton
                    style={{ textAlign: "center" }}
                    variant="gradient"
                    color="info"
                    onClick={() => handleclickSearch()}
                  >
                    <SearchIcon />
                    Search
                  </MDButton>
                  &nbsp; &nbsp; &nbsp;
                  <MDButton
                    style={{ textAlign: "center" }}
                    variant="gradient"
                    color="info"
                    onClick={() => handleclickSearchWeekly()}
                  >
                    Weekly
                  </MDButton>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox pt={3} py={3} px={2}>
              <Box
                sx={{
                  // height: 400,
                  width: "100%",
                  "& .super-app-theme--Warm": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
                  },
                  "& .super-app-theme--Hot": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.error.main, theme.palette.mode),
                  },
                  "& .super-app-theme--Cold": {
                    bgcolor: (theme) =>
                      getBackgroundColor(theme.palette.success.main, theme.palette.mode),
                  },
                }}
              >
                <div style={{ height: 630, width: "100%" }}>
                  <DataGrid
                    getRowHeight={() => "auto"}
                    rows={followUpData}
                    columns={followUpColumns}
                    getRowId={(row) => row._id}
                    getRowClassName={(params) => `super-app-theme--${params.row.patientStatus}`}
                  />
                </div>
              </Box>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
    </>
  );
}
export default FollowUpTable;
function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
