/*eslint-disable */
import React from "react";
import Konva from "konva";
import { Stage } from "react-konva";

import Regions from "./regions";
import BaseImage from "./baseImage";

import useStore from "./store";
import { Fragment } from "react";
import MediaQuery from "react-responsive";

let id = 1;

function getRelativePointerPosition(node) {
  // the function will return pointer position relative to the passed node
  const transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();

  // get pointer (say mouse or touch) position
  const pos = node.getStage().getPointerPosition();

  // now we find relative point
  return transform.point(pos);
}

function zoomStage(stage, scaleBy) {
  const oldScale = stage.scaleX();

  const pos = {
    x: stage.width() / 2,
    y: stage.height() / 2,
  };
  const mousePointTo = {
    x: pos.x / oldScale - stage.x() / oldScale,
    y: pos.y / oldScale - stage.y() / oldScale,
  };

  const newScale = Math.max(0.05, oldScale * scaleBy);

  const newPos = {
    x: -(mousePointTo.x - pos.x / newScale) * newScale,
    y: -(mousePointTo.y - pos.y / newScale) * newScale,
  };

  const newAttrs = limitAttributes(stage, { ...newPos, scale: newScale });

  stage.to({
    x: newAttrs.x,
    y: newAttrs.y,
    scaleX: newAttrs.scale,
    scaleY: newAttrs.scale,
    duration: 0.1,
  });
  stage.batchDraw();
}

function limitAttributes(stage, newAttrs) {
  const box = stage.findOne("Image").getClientRect();
  const minX = -box.width + stage.width() / 2;
  const maxX = stage.width() / 2;

  const x = Math.max(minX, Math.min(newAttrs.x, maxX));

  const minY = -box.height + stage.height() / 2;
  const maxY = stage.height() / 2;

  const y = Math.max(minY, Math.min(newAttrs.y, maxY));

  const scale = Math.max(0.05, newAttrs.scale);

  return { x, y, scale };
}

export default function Canvas({ url, getImageDetails }) {
  const stageRef = React.useRef();
  const { width, height } = useStore((s) => {
    getImageDetails({ height: s.imageHeight, width: s.imageWidth });
    return {
      width: s.width,
      height: s.height,
    };
  });

  const setSize = useStore((state) => state.setSize);
  const scale = useStore((state) => state.scale);
  const isDrawing = useStore((state) => state.isDrawing);
  const toggleDrawing = useStore((state) => state.toggleIsDrawing);
  const regions = useStore((state) => state.regions);
  const setRegions = useStore((state) => state.setRegions);

  const selectRegion = useStore((s) => s.selectRegion);
  const isAnnotation = useStore((state) => state.isAnnotation);
  const setIsAnnotation = useStore((state) => state.setIsAnnotation);
  React.useEffect(() => {
    function checkSize() {
      const container = document.querySelector(".right-panel");
      setSize({
        width: container.offsetWidth,
        height,
      });
    }
    checkSize();
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, [height, setSize]);
  return (
    <React.Fragment>
      <Fragment>
        <MediaQuery minDeviceWidth={1224}>
          {/* for desktop */}
          <Stage
            ref={stageRef}
            width={width}
            height={height}
            scaleX={scale}
            scaleY={scale}
            className="canvas"
            onClick={(e) => {
              if (!isAnnotation) {
                return;
              }
              const clickedNotOnRegion = e.target.name() !== "region";
              if (clickedNotOnRegion) {
                selectRegion(null);
              }
            }}
            onWheel={(e) => {
              e.evt.preventDefault();
              if (!isAnnotation) {
                return;
              }
              const stage = stageRef.current;

              const dx = -e.evt.deltaX;
              const dy = -e.evt.deltaY;
              const pos = limitAttributes(stage, {
                x: stage.x() + dx,
                y: stage.y() + dy,
                scale: stage.scaleX(),
              });
              stageRef.current.position(pos);
              stageRef.current.batchDraw();
            }}
            //for mobile device

            onMouseDown={(e) => {
              if (!isAnnotation) {
                return;
              }
              toggleDrawing(true);
              const point = getRelativePointerPosition(e.target.getStage());
              const region = {
                id: id++,
                color: Konva.Util.getRandomColor(),
                points: [point],
              };
              setRegions(regions.concat([region]));
            }}
            onMouseMove={(e) => {
              if (!isAnnotation) {
                return;
              }
              if (!isDrawing) {
                return;
              }
              const lastRegion = { ...regions[regions.length - 1] };
              const point = getRelativePointerPosition(e.target.getStage());
              lastRegion.points = lastRegion.points.concat([point]);
              regions.splice(regions.length - 1, 1);
              setRegions(regions.concat([lastRegion]));
            }}
            onMouseUp={(e) => {
              if (!isAnnotation) {
                return;
              }
              if (!isDrawing) {
                return;
              }
              const lastRegion = regions[regions.length - 1];
              if (lastRegion.points.length < 3) {
                regions.splice(regions.length - 1, 1);
                setRegions(regions.concat());
              }
              toggleDrawing();
            }}
          >
            <BaseImage url={url} />
            <Regions />
          </Stage>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          {/* for mobile device */}
          <Stage
            ref={stageRef}
            width={width}
            height={height}
            scaleX={scale}
            scaleY={scale}
            className="canvas"
            onClick={(e) => {
              if (!isAnnotation) {
                return;
              }
              const clickedNotOnRegion = e.target.name() !== "region";
              if (clickedNotOnRegion) {
                selectRegion(null);
              }
            }}
            onWheel={(e) => {
              if (!isAnnotation) {
                return;
              }
              e.evt.preventDefault();
              const stage = stageRef.current;

              const dx = -e.evt.deltaX;
              const dy = -e.evt.deltaY;
              const pos = limitAttributes(stage, {
                x: stage.x() + dx,
                y: stage.y() + dy,
                scale: stage.scaleX(),
              });
              stageRef.current.position(pos);
              stageRef.current.batchDraw();
            }}
            onTouchStart={(e) => {
              if (!isAnnotation) {
                return;
              }
              toggleDrawing(true);
              const point = getRelativePointerPosition(e.target.getStage());
              const region = {
                id: id++,
                color: Konva.Util.getRandomColor(),
                points: [point],
              };
              setRegions(regions.concat([region]));
            }}
            onTouchMove={(e) => {
              if (!isAnnotation) {
                return;
              }
              if (!isDrawing) {
                return;
              }
              const lastRegion = { ...regions[regions.length - 1] };
              const point = getRelativePointerPosition(e.target.getStage());
              lastRegion.points = lastRegion.points.concat([point]);
              regions.splice(regions.length - 1, 1);
              setRegions(regions.concat([lastRegion]));
            }}
            onTouchEnd={(e) => {
              if (!isAnnotation) {
                return;
              }
              if (!isDrawing) {
                return;
              }
              const lastRegion = regions[regions.length - 1];
              if (lastRegion.points.length < 3) {
                regions.splice(regions.length - 1, 1);
                setRegions(regions.concat());
              }
              toggleDrawing();
            }}
          >
            <BaseImage url={url} />
            <Regions />
          </Stage>
        </MediaQuery>
      </Fragment>

      <div className="zoom-container">
        <button
          className="btn btn-primary"
          onClick={() => {
            zoomStage(stageRef.current, 1.2);
          }}
        >
          +
        </button>
        &nbsp;
        <button
          className="btn btn-primary"
          onClick={() => {
            zoomStage(stageRef.current, 0.8);
          }}
        >
          -
        </button>
      </div>
    </React.Fragment>
  );
}
