exports.treatments = [
  {
    name: "Stains: Teeth cleaning and polishing is required to remove stains.",
  },
  {
    name: "Pit and fissure caries: Tooth colour fillings necessary for small cavities to prevent root canal treatment",
  },
  {
    name: "Deep caries - Deep filling/root canal treatment depending on Xray finding.",
  },
  {
    name: "Missing tooth: Replace your missing tooth with either implants, bridge or a denture",
  },
  { name: "Fracture: Rebuild fractured tooth with tooth colour fillings." },
  {
    name: "Grossly decayed: Grossly decayed tooth cannot be saved and needs extraction of the tooth followed by replacement of the tooth with either an implant , bridge or a denture.",
  },
  {
    name: "Rootstump: Pieces of the tooth need to be extracted (removed) to avoid reinfection followed by replacement of the tooth with either an implant , bridge or a denture",
  },
  {
    name: "Attrition: Flattening of teeth seen due to grinding and clenching. Tooth colour fillings required to rebuild the tooth as before.",
  },
  {
    name: "Malaliged: Teeth are not in alignment and needs braces or clear aligners to straighten your teeth",
  },
  {
    name: "Diastema: Toothspace between your front teeth needs closure with fillings, veneers or braces.",
  },
  {
    name: "Silver filling: Get silver fillings replaced with tooth colour fillings as silver fillings are harmful!",
  },
  {
    name: "Composite filling: Composite fillings in your mouth seem healthy and no action is required. Repeat composite filling if discoloured/fractured.",
  },
  {
    name: "Metal crown: Metal cap in your mouth seems healthy and requires no treatment unless there is pain.",
  },
  {
    name: "Ceramic crown: Ceramic crown in your mouth seems healthy and requires no treatment unless it’s painful.",
  },
  {
    name: "Calculus: Cleaning and polishing is a MUST to remove hard yellow white calculus deposits on your teeth.",
  },
  {
    name: "Tooth gap: Gaps between the teeth need space closure treatments like tooth colour fillings/ veneers/ caps / braces / clear aligners. Teeth cleaning and polishing is required to clean the areas in between.",
  },
  {
    name: "Abfraction: Abfraction tooth require a tooth filling or even a root canal treatment depending on severity.",
  },
  { name: "Abrasion: Tooth colour fillings required for abrasion" },
  {
    name: "Extra tooth: You have an extra tooth! Need not worry unless its causing you or the other teeth any harm.",
  },
  {
    name: "Dental fluorosis: White brown patches on your teeth will not go away with teeth cleaning and polishing. You need a teeth whitening procedure or veneer/caps for that beautiful smile.",
  },
  {
    name: "Appliances: Dental Appliance detected. Maintain appliance’s hygiene.",
  },
  {
    name: "Impacted 3rd molar / pericoronitis: Your wisdom tooth is compromised and infected. You need to get it extracted.",
  },
  {
    name: "Discolored tooth: Discoloured tooth needs a root canal treatment with a cap.",
  },
  {
    name: "Food lodgement: Food is stuck in and between your teeth. Tooth cavities may be present. Floss your teeth regularly.",
  },
  {
    name: "Denture: You are a denture wearer. Seek dental counselling if any problems with your denture. Keep your dentures clean with denture cleaning aids.",
  },
  {
    name: "Braces: There is a lot of plaque and calculus detected in and around your braces. Get a teeth cleaning and polishing treatment done every 3 months. Use orthodontic toothbrush and include interdental toothbrush and water flossers to keep a good oral hygiene.",
  },
  {
    name: "Receded gums: Gums receeded, red, swollen and show initial signs of inflammation. Gum surgery required to give the tooth support.",
  },
  {
    name: "Mesiodens: You have one extra tooth present between the two upper front teeth. Extraction of the tooth is required followed by space closure between the two front teeth with braces or clear aligners.",
  },
  {
    name: "Defective cap/ crown/ bridge: The cap or bridge is either broken or damaged. You need to get a new one.",
  },
  {
    name: "Incomplete RCT: The root canal treatment is not completed. Get the root canal treatment completed followed by a cap on the tooth.",
  },
  {
    name: "Dislodged prosthesis / filling: The filling / prosthesis is either damaged or displaced. You need to repeat the treatment process.",
  },
  {
    name: "Peg laterals Peg lateral teeth can be made to look like other teeth with the help of ceramic caps, or veneers.",
  },
  {
    name: "Erosion: Eroded teeth may cause severe sensitivity. Eroded teeth need fillings, veneers, or in severe cases caps.",
  },
  {
    name: "Metal ceramic: Metal ceramic caps seem healthy and need not require any treatment unless painful.",
  },
  {
    name: "Inflammed / Red gums: Gums are inflamed due to plaque and calculus deposits. You need urgent gum care and a teeth cleaning and polishing.",
  },
];
