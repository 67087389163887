/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* eslint-disable */
// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";

function OrdersOverview(liveActionsData) {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          {t("Live Actions")}
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle"></MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        {liveActionsData.data && liveActionsData.data.length
          ? liveActionsData.data.map((data) => {
              return (
                <TimelineItem
                  color="success"
                  icon={data.category === "testSubmit" ? "notifications" : "inventory_2"}
                  title={
                    data.name +
                    (data.category === "testSubmit"
                      ? " submitted new scan"
                      : " has scheduled video consultation")
                  }
                  dateTime={data.date}
                />
              );
            })
          : "No data available"}
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
