/* eslint-disable */
import axios from "axios";
import constant from "../utils/constants";
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";
const loginData = JSON.parse(localStorage.getItem("loginData"));

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const removeAppointment = (appointmentID, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(
    constant.API_URL + "/agent/appointment/removeAppointment",
    { appointmentId: appointmentID },
    {
      headers,
    }
  );
};

const bookAppointment = (formData) => {
  return axios.post(constant.API_URL + "/agent/appointment/bookAppointment", formData, {
    headers,
  });
};

const updateTimeSlotStatus = (formData) => {
  return axios.post(constant.API_URL + "/user/updateTimeSlotStatus", formData, {
    headers,
  });
};

const getAppointmentSlots = (date, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/user/getAvailableTimeSlots",
    { timeSlotDate: date },
    {
      headers,
    }
  );
};

const getAppointmentDetails = (aid, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/appointment/getAppointmentDetails",
    { aid },
    {
      headers,
    }
  );
};

const updateAppointment = (formdata) => {
  return axios.post(constant.API_URL + "/agent/appointment/updateAppointment", formdata, {
    headers,
  });
};

const sendInvoice = (appointmentId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosGet(constant.API_URL + "/agent/invoice/sendInvoice/" + appointmentId, {
    headers,
  });
};

const addClinicSlots = (formdata) => {
  if (formdata?.token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + formdata?.token,
    };
  }
  return axios.post(constant.API_URL + "/agent/doctor/addClinicsTimeSlots", formdata, {
    headers,
  });
};

const updateTimeSlots = (formdata) => {
  return axios.post(constant.API_URL + "/agent/timeSlot/updateTimeSlots", formdata, {
    headers,
  });
};

const getAvailableStatus = (day) => {
  return cachedAxiosPost(
    constant.API_URL + "/user/getAvailableTimeSlots",
    { timeSlotDate: day },
    {
      headers,
    }
  );
};

const getTimeSlots = (clinicId) => {
  return cachedAxiosGet(constant.API_URL + "/agent/timeSlot/getTimeSlots/" + clinicId, {
    headers,
  });
};
const addAppointment = (formdata) => {
  return axios.post(constant.API_URL + "/agent/appointment/addAppointment", formdata, {
    headers,
  });
};

const appointmentService = {
  removeAppointment,
  bookAppointment,
  updateTimeSlotStatus,
  getAppointmentSlots,
  getAppointmentDetails,
  updateAppointment,
  sendInvoice,
  addClinicSlots,
  updateTimeSlots,
  getAvailableStatus,
  getTimeSlots,
  addAppointment,
};

export default appointmentService;
