/* eslint-disable */

import axios from "axios";
import constant from "../utils/constants";
const loginData = JSON.parse(localStorage.getItem("loginData"));
import { cachedAxiosGet, cachedAxiosPost } from "./apiCacheService";

let headers = {
  "Content-Type": "application/json",
  Authorization: "bearer " + loginData?.token,
};

const updateVideoConsultationStatus = (formData, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/updatePatientsStatusVideoConsultation", formData, {
    headers,
  });
};

const getPatientsList = (token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return cachedAxiosPost(
    constant.API_URL + "/agent/getPatientsForVideoConsultation",
    {},
    {
      headers,
    }
  );
};

const removeTokenForVideoConsultation = (patientId, videoToken, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(
    constant.API_URL + "/user/removeTokenForVideoConsultationAgent",
    { patientId, token: videoToken },
    {
      headers,
    }
  );
};

const generateVideoCall = (patientId, callType, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(
    constant.API_URL + "/agent/patient/generateVideoCall",
    { patientId, callType },
    {
      headers,
    }
  );
};

const reconnectLastVideoCall = (patientId, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(
    constant.API_URL + "/agent/patient/reconnectLastVideoCall",
    { patientId },
    {
      headers,
    }
  );
};

const addVideoConsultationSlots = (formdata, token) => {
  if (token) {
    headers = {
      "Content-Type": "application/json",
      Authorization: "bearer " + token,
    };
  }
  return axios.post(constant.API_URL + "/agent/addVideoConsultationSlot", formdata, {
    headers,
  });
};

const videoConsultationService = {
  getPatientsList,
  updateVideoConsultationStatus,
  removeTokenForVideoConsultation,
  generateVideoCall,
  reconnectLastVideoCall,
  addVideoConsultationSlots,
};

export default videoConsultationService;
